import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Grid, InputLabel } from "@mui/material";
import { Button } from "./Button";
import { TextInput } from "./TextInput";
import DoneIcon from "@mui/icons-material/Done";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import "./CommonButton.css";
import { CalendarTimeComponent } from "./CalendarTimeComponent";

export const CalForm = (props) => {
  const methods = useForm({});

  useEffect(() => {
    if (props.defaultValues?.length) {
      Object.entries(props.defaultValues[0]).forEach(([key, value]) => {
        methods.setValue(key, value);
      });
    }
  }, [props.defaultValues]);

  const updateData = () => {
    const values = methods.getValues();
    const valuesWithId = {
      id: props.editId,
      ...values,
    };
    props.onUpData(valuesWithId);
  };

  const saveData = (data) => {
    const savedData = { ...data };

    props.onAdd(savedData);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    props.onClose();
  };
  return (
    <>
      <form
        onSubmit={methods.handleSubmit(!props.showForm ? updateData : saveData)}
      >
        <FormProvider {...methods}>
          {props.columns?.map((inputs) => {
            return (
              <>
                <React.Fragment key={inputs.field}>
                  <Grid container sx={{ margin: "4px" }}>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <InputLabel sx={{ fontSize: "0.9rem" }}></InputLabel>
                    </Grid>
                  </Grid>

                  {inputs.type === "calendarTime" ? (
                    <CalendarTimeComponent
                      label={inputs.headerName}
                      name={inputs.field}
                      value={
                        methods.getValues(inputs.field)
                          ? methods.getValues(inputs.field)
                          : null
                      }
                      required={inputs.required}
                      width={inputs.textFieldWidth}
                      showLabel={inputs.showLabel}
                    />
                  ) : inputs.type === "text" ? (
                    <TextInput
                      type={inputs.type}
                      required={inputs.required}
                      label={inputs.headerName}
                      disable={inputs.disable}
                      maxLength={inputs.maxLength}
                      multiline={inputs.multiline}
                      variant="outlined"
                      name={inputs.field}
                      rows={inputs.row}
                      size={inputs.size}
                      value={
                        methods.getValues(inputs.field)
                          ? methods.getValues(inputs.field)
                          : ""
                      }
                      width={inputs.textFieldWidth}
                      defaultValue={inputs.defaultValue}
                      pattern={inputs.pattern}
                      errorMessage={inputs.errorMessage}
                    />
                  ) : null}
                </React.Fragment>
              </>
            );
          })}
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Button
                sx={{
                  marginRight: "4px",
                  padding: "4px 16px",
                  "&:hover": {
                    backgroundColor: "#1976d2",
                  },
                }}
                label={<DoneIcon />}
                disabled={props.isLoading}
                type={"submit"}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </form>
    </>
  );
};
