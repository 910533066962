// Dashboard.jsx
import React, { useState } from "react";
import { Box } from "@mui/material";
import TopSection from "./sections/TopSection";
import MiddleSection from "./sections/MiddleSection";
import BottomSection from "./sections/BottomSection";

const Dashboard = () => {
  const [heights, setHeights] = useState({
    topFullWidth: 30,
    topRow: 35,
    bottomRow: 35,
  });

  const [widths, setWidths] = useState({
    leftColumn: 50,
    rightColumn: 50,
    bottomLeftColumn: 50,
    bottomRightColumn: 50,
  });

  const handleMouseDown = (e, direction) => {
    e.preventDefault();
    const startY = e.clientY;
    const startX = e.clientX;
    const startHeights = { ...heights };
    const startWidths = { ...widths };

    const handleMouseMove = (e) => {
      if (direction === "horizontalTop" || direction === "horizontalBottom") {
        const deltaX = ((e.clientX - startX) / window.innerWidth) * 100;
        if (direction === "horizontalTop") {
          setWidths((prev) => ({
            ...prev,
            leftColumn: Math.max(10, Math.min(90, startWidths.leftColumn + deltaX)),
            rightColumn: Math.max(10, Math.min(90, startWidths.rightColumn - deltaX)),
          }));
        } else {
          setWidths((prev) => ({
            ...prev,
            bottomLeftColumn: Math.max(10, Math.min(90, startWidths.bottomLeftColumn + deltaX)),
            bottomRightColumn: Math.max(10, Math.min(90, startWidths.bottomRightColumn - deltaX)),
          }));
        }
      } else if (direction === "verticalTop") {
        const deltaY = ((e.clientY - startY) / window.innerHeight) * 100;
        setHeights((prev) => ({
          topFullWidth: Math.max(10, Math.min(80, startHeights.topFullWidth + deltaY)),
          topRow: Math.max(10, startHeights.topRow - deltaY / 2),
          bottomRow: Math.max(10, startHeights.bottomRow - deltaY / 2),
        }));
      } else if (direction === "verticalBottom") {
        const deltaY = ((e.clientY - startY) / window.innerHeight) * 100;
        setHeights((prev) => ({
          ...prev,
          topRow: Math.max(10, Math.min(80, startHeights.topRow + deltaY)),
          bottomRow: Math.max(10, Math.min(80, startHeights.bottomRow - deltaY)),
        }));
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "90vh",
        overflow: "hidden",
      }}
    >
      <TopSection 
        height={heights.topFullWidth} 
        handleMouseDown={handleMouseDown} 
      />
      <MiddleSection 
        heights={heights}
        widths={widths}
        handleMouseDown={handleMouseDown}
      />
      <BottomSection 
        heights={heights}
        widths={widths}
        handleMouseDown={handleMouseDown}
      />
    </Box>
  );
};

export default Dashboard;