import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../../common/DataGridComponent";
import DeleteDialog from "../../../common/DeleteDialog";
import { useCommonData } from "../../../context/CommonDataProvider";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import AddEditToolBar from "../../../common/AddEditToolBar";
import {
  WorkOrderPrefix,
  GeneralPrefix,
  GridWidthMedium,
  GridWidthSmall,
  medium,
} from "../../../constants/global";
import { useMenuContext } from "../../../context/MenuContextProvider";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import { CommonBox } from "../assetManagementSteps/CommonBox";

export const Checklist = ({ workOrderId, selectedView, checklistColumns, setChecklistColumns }) => {
  const { employeDetails: employeeDetails } = useCommonData();
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [selectedChecklistGroup, setSelectedChecklistGroup] = useState();
  const [rows, setRows] = useState([]);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [viewLoaded, setViewLoaded] = useState(true);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const handleRowClick = (params) => {
    setDeleteId(params.id);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { mutateAsync: addWorkOrderChecklist } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/checklist/addchecklist`,
    method: "post",
    showError: false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderChecklist,
    isLoading: deleteWorkOrderChecklistLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/checklist/delete?checklistId=`,
    method: "delete",
    showError: false,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { data: getWorkOrderChecklistGroupData } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/CheckListGroup`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { data: getChecklistTemplateData } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/${workOrderId}/checklist-template?chkGroupId=${selectedChecklistGroup}`,
    retrieveOnMount:
      selectedChecklistGroup && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { data: workOrderChecklist } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/checklist?woId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { data: getAllChecklistTemplateData } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/${workOrderId}/checklist-template`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const availableEmpOpt = [
    { label: "Select a value", id: 0 },
    ...employeeDetails,
  ];

  const [columns, setColumns] = useState([
    {
      field: "chkListCode",
      headerName: "Code",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "chkListId",
      headerName: "Checklist Template Id",
      type: "number",
      hide: true,
    },
    {
      field: "chkListDesc",
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "wocWoIdFk",
      headerName: "Workorder Id",
      type: "number",
      hide: true,
    },
    {
      field: "wocStatus",
      headerName: "Status",
      type: "boolean",
      editable: true,
      width: GridWidthMedium,
      renderCell: (params) => {
        const changeStatus = (event) => {
          handleChange(params, "wocStatus", event.target.checked);
        };
        return (
          <>
            <Checkbox
              size="small"
              onChange={changeStatus}
              checked={params.row.wocStatus}
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "wocRemarks",
      headerName: "Remarks",
      type: "text",
      width: GridWidthMedium,
      renderCell: (params) => {
        const editRemarks = (event) => {
          handleChange(params, "wocRemarks", event.target.value);
        };
        return (
          <>
            <TextField onChange={editRemarks} value={params.row.wocRemarks} />
          </>
        );
      },
    },
    {
      field: "wocCheckedBy",
      headerName: "Completed By",
      type: "singleSelect",
      width: GridWidthMedium,
      renderCell: (params) => {
        const changeCheckedBy = (event) => {
          handleChange(params, "wocCheckedBy", event.target.value);
        };
        return (
          <>
            <Select
              size="medium"
              sx={{ width: "30vw" }}
              onChange={changeCheckedBy}
              value={params.row.wocCheckedBy}
            >
              {availableEmpOpt?.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </>
        );
      },
    },
    {
      field: "wocModifiedDate",
      headerName: "Updated Date",
      type: "date",
      hide: true,
    },
    {
      field: "wocModifiedBy",
      headerName: "Updated By",
      type: "number",
      hide: true,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }
   
    try {
      let configColumns = [];
   
      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.woc_checklist) {
        configColumns = updatedConfig.sub_component.woc_checklist;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === 'object') {
        configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
          name: field,
          title: details?.headerName,
          visible: details?.visible,
          width: details?.width ? `${details.width}px` : undefined,
        }));
      }
   
      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }
   
      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });
   
      setColumns(newColumns);
   
      const configToUpdate = {
        ...columnConfig,
        category: "workorder",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          woc_checklist: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };
   
      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.woc_checklist) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.woc_checklist
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (checklistColumns) {
      setColumns(checklistColumns);
    } else {
      fetchColumnConfig();
    }
  }, [checklistColumns, columnConfig, defaultConfig, workOrderId]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleDeleteClick = useCallback(async () => {
    const deletedRow = rows.find((item) => item.id === deleteId);
    const updatedRows = rows.filter((item) => item.id !== deleteId);
    const res =
      deletedRow.addEditId !== 0
        ? await deleteWorkOrderChecklist({
            dynamicEndpointSuffix: deleteId,
          })
        : null;
    res !== null && res?.status !== 200 && toast.error("Something went wrong");
    res?.status === 200 && setDeleteId(null);
    setOpenDialog(false);
    return setRows(updatedRows);
  }, [deleteId, deleteWorkOrderChecklist, rows]);

  const handleChange = (params, fieldName, value) => {
    setRows(
      rows.map((item) => {
        if (item.id === params?.row?.id) {
          item[fieldName] = value;
        }
        return item;
      })
    );
  };

  const onChecklistGroupChange = (event) => {
    setSelectedChecklistGroup(event.target.value);
  };

  const addNewChecklistRows = useCallback(
    (checklistParams) => {
      const data = checklistParams?.map((w, i) => {
        let availableRows = rows?.length ? rows : [{ id: 0 }];
        return {
          id: Math.max(...availableRows.map((o) => o?.id || 0)) + i,
          addEditId: 0,
          wocChListIdFk: w.chkListId,
          wocChListCode: w.chkListCode,
          wocChListDesc: w.chkListDesc,
          wocWoIdFk: workOrderId,
          wocStatus: w.wocStatus === "true" || false,
          wocRemarks: w.wocRemarks || "",
          wocCheckedBy: w.wocCheckedBy || "",
          wocModifiedDate: w.wocModifiedDate || "",
          wocModifiedBy: w.wocModifiedBy || "",
          ...w,
        };
      });
      return data;
    },
    [workOrderId, rows]
  );

  const getChecklistRows = useCallback(
    (checklistParams) => {
      const data = workOrderChecklist?.map((w, i) => {
        const correspondingParam = checklistParams.find(
          (param) => param.chkListId === w.wocChListIdFk
        );

        const checkedByValue = employeeDetails.map(
          (item) => item.id === w.wocCheckedBy && item.id
        );

        const wocCheckedByValue = checkedByValue.find((item) => item !== false);

        return {
          id: w.wocId,
          addEditId: w.wocId,
          wocChListIdFk: w.wocChListIdFk,
          wocChListCode: correspondingParam
            ? correspondingParam.chkListCode
            : null,
          wocChListDesc: correspondingParam
            ? correspondingParam.chkListDesc
            : null,
          wocWoIdFk: workOrderId,
          wocStatus: w.wocStatus === "true" || false,
          wocRemarks: w.wocRemarks || "",
          wocCheckedBy: wocCheckedByValue || "",
          wocModifiedDate: w.wocModifiedDate || "",
          wocModifiedBy: w.wocModifiedBy || "",
          ...correspondingParam,
        };
      });
      return data;
    },
    [employeeDetails, workOrderChecklist, workOrderId]
  );

  const valueChangedRow = useMemo(
    () =>
      rows.map((item) => {
        const updatedItem = { ...item };

        employeeDetails.forEach((val) => {
          if (val.label === item.wocCheckedBy) {
            updatedItem.wocCheckedBy = val.id;
          }
        });

        if (item.wocCheckedBy === "Select a value") {
          updatedItem.wocCheckedBy = null;
        }

        return updatedItem;
      }),
    [employeeDetails, rows]
  );

  const callChecklistTemplate = () => {
    if (getChecklistTemplateData) {
      const combinedArray = rows?.concat(getChecklistTemplateData);
      const checklistRows = addNewChecklistRows(combinedArray);
      setRows(checklistRows);
    } else {
      toast.error("Please select a checklist group");
    }
  };

  const submitChecklistData = async () => {
    if (valueChangedRow.length > 0) {
      try {
        const res = await addWorkOrderChecklist({ data: valueChangedRow });
        if (res.status === 200) {
          toast.success("Checklist saved successfully");
          res.data.map((item) => {
            setRows((prev) => {
              return prev.map((row) => {
                return row.chkListId === item.wocChListIdFk
                  ? { ...row, addEditId: item.wocId }
                  : row;
              });
            });
            return item;
          });
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    } else {
      toast.error("Please Add Checklists!!");
    }
  };

  useEffect(() => {
    if (workOrderChecklist && getAllChecklistTemplateData) {
      const wocChListIdFkIds = workOrderChecklist.map(
        (data) => data.wocChListIdFk
      );
      const checklistParams = getAllChecklistTemplateData.filter((item) =>
        wocChListIdFkIds.includes(item.chkListId)
      );

      const checklistRows = getChecklistRows(checklistParams);
      setRows(checklistRows);
    }
  }, [workOrderChecklist, getAllChecklistTemplateData, getChecklistRows]);

  return (
    <>
      <Box>
        <Box sx={{ paddingY: "1rem" }}>
          <InputLabel id="demo-simple-select-label" sx={{ marginTop: 1 }}>
            Checklist Group
          </InputLabel>
          <Select
            size="small"
            onChange={onChecklistGroupChange}
            sx={{
              width: "50%",
              marginTop: 1,
              color: "black",
              borderRadius: "15px",
            }}
          >
            <MenuItem key={0} value={0}>
              Select a value
            </MenuItem>
            {getWorkOrderChecklistGroupData?.map((o) => (
              <MenuItem key={o.checkListGroupId} value={o.checkListGroupId}>
                {o.checkListGroupDesc}
              </MenuItem>
            ))}
          </Select>
          <Button size="small" onClick={callChecklistTemplate} sx={{ ml: 2 }}>
            Add Checklist
          </Button>
        </Box>
        <Box sx={{ paddingY: "1rem" }}>
          <Typography fontWeight={"bold"}>Checklist</Typography>

          <Box sx={{}}>
            <CommonBox
              rows={rows}
              columns={columns}
              onClickColumnCustom={() => handleOpenColumnPopup()}
              sortingField={"chkListCode"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickDelete={() => handleClickOpen()}
                        
                        checkList={true}
                        editId={deleteId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            />
          </Box>
        </Box>
        <Button
          size="small"
          onClick={submitChecklistData}
          sx={{ mt: 0.5, mr: 1 }}
        >
          Save
        </Button>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Checklist?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteWorkOrderChecklistLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
