import React, { useState } from "react";
import { Box, StepLabel, StepContent } from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";

import { CalendarWorkingDays } from "./CalendarWorkingDays";
import { CalendarHoliday } from "./CalendarHoliday";

export const CalendarSystemMultiSteps = ({
  onClose,
  calsId,
  open,
  setEditDefWorkingValues,
  editDefWorkingValues,
  setOpen,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (index) => () => {
    setActiveStep(index);
  };
  const steps = [
    {
      label: "Calendar Working Days",
      form: (
        <>
          <CalendarWorkingDays
            label="Add Calendar Working Days"
            calsId={calsId}
            open={open}
            setEditDefWorkingValues={setEditDefWorkingValues}
            editDefWorkingValues={editDefWorkingValues}
            setOpen={setOpen}
          />
        </>
      ),
    },
    {
      label: "Calendar Holiday",
      form: (
        <>
          <CalendarHoliday label="Add Calendar Holiday" calsId={calsId} />
        </>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ maxWidth: "55vw", marginLeft: "4vh" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={handleStep(index)}>{step.label}</StepLabel>
              <StepContent>
                {steps[activeStep].form}
                {/* <Box sx={{ mb: 2 }}>
                  {index !== steps.length - 1 && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 0.5, mr: 1 }}
                      label="Continue"
                    ></Button>
                  )}

                  {index === steps.length - 1 && (
                    <Button
                      size="small"
                      disabled={index === 0}
                      onClick={onClose}
                      sx={{ mt: 0.5, mr: 1 }}
                      label="finish"
                    ></Button>
                  )}
                  <Button
                    size="small"
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 0.5, mr: 1 }}
                    label="back"
                    variant="outlined"
                  ></Button>
                </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};
