import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { AddEditForm } from "../../common/AddEditForm";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import { Progressor } from "../../common/Progressor";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
} from "../../utils/common";
import { useCommonData } from "../../context/CommonDataProvider";
import {
  GridWidthExtraLargeWidth,
  GridWidthLargeWidth,
  GridWidthMedium,
  PlantPrefix,
  gridHeight,
  medium,
  GeneralPrefix,
} from "../../constants/global";
import AddEditToolBar from "../../common/AddEditToolBar";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

const PlantView = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const userId = commonData.userId;
  const [id, setId] = useState(0);
  const [disable, setDisable] = useState(false);
  const [editDefValues, setEditDefValues] = useState();
  const [showPlantForm, setShowPlantForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showPlantEditForm, setShowPlantEditForm] = useState(false);
  const [editData, setEditData] = useState();
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "plant" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const tenantId = commonData?.userData?.tenant_id;

  const {
    mutateAsync: addPlant,
    isSuccess: plantAddIsSuccess,
    isLoading: plantAddIsLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/addPlants`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updatePlant,
    isSuccess: plantUpdated,
    isLoading: plantIsUpdating,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: plantDataList,
    isFetchedAfterMount: isFetch,
    isFetching: isPlantDataIsFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/tenant/${tenantId}/plants`,
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    refetch();
    if (plantAddIsSuccess || plantUpdated) {
      refetch();
    }
  }, [plantAddIsSuccess, plantUpdated, refetch]);

  const [plantMasterData, setPlantMasterData] = useState(plantDataList);

  useEffect(() => {
    if (plantDataList) {
      const plantData = plantDataList.map((item) => {
        const newPlantControlling = mapDropdownValueToLabelWithListId(
          commonData.plantControlling,
          item.plantControlling
        );
        const newCompany = mapDropdownValueToLabelWithListId(
          commonData.company,
          item.plantCompany
        );
        const newPlantCompUnit = mapDropdownValueToLabelWithListId(
          commonData.plantCompUnit,
          item.plantBusinessUnit
        );
        const newPlantCalId = mapDropdownValueToLabelWithId(
          commonData.getCalendarSystemData,
          item.plantCalsIdFk
        );
        return {
          ...item,
          plantControlling: newPlantControlling,
          plantCompany: newCompany,
          plantBusinessUnit: newPlantCompUnit,
          plantCalsIdFk: newPlantCalId,
        };
      });
      setPlantMasterData(plantData);
    }
  }, [commonData, plantDataList]);

  console.log("plantdatalist is being fetched", plantDataList);

  useEffect(() => {
    if (isFetch && plantMasterData && canRead) {
      setRows(() =>
        plantMasterData?.map((p) => {
          return { id: p.plantId, ...p };
        })
      );
    }
    // plantData();
  }, [plantMasterData, plantAddIsSuccess, isFetch, canRead, rightsArray]);
  const handlePlantEditClick = () => {
    setShowPlantEditForm(true);
    setId(editData.id);
    const newPlantControlling = mapDropdownValueToListIdWithLabel(
      commonData.plantControlling,
      editData.plantControlling
    );
    const newCompany = mapDropdownValueToListIdWithLabel(
      commonData.company,
      editData.plantCompany
    );
    const newPlantCompUnit = mapDropdownValueToListIdWithLabel(
      commonData.plantCompUnit,
      editData.plantBusinessUnit
    );
    const newPlantCalId = mapDropdownValueToIdWithLabel(
      commonData.getCalendarSystemData,
      editData.plantCalsIdFk
    );
    setEditDefValues({
      ...editData,
      plantControlling: newPlantControlling,
      plantCompany: newCompany,
      plantBusinessUnit: newPlantCompUnit,
      plantCalsIdFk: newPlantCalId,
    });
    setDisable(true);
  };

  const saveUpdateData = async (workDataToSave) => {
    updatePlant({
      id: workDataToSave.wo_id,
      plantTenantIdFk: tenantId,
      ...workDataToSave,
      plantControlling: workDataToSave.plantControlling?.listId,
      plantCompany: workDataToSave.plantCompany?.listId,
      plantBusinessUnit: workDataToSave.plantBusinessUnit?.listId,
      plantCalsIdFk: workDataToSave?.plantCalsIdFk?.id
        ? workDataToSave?.plantCalsIdFk?.id
        : "",
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Plant updated successfully");
          setShowPlantEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAddPlantData = async (newPlantData) => {
    addPlant({
      plantTenantIdFk: tenantId,
      ...newPlantData,
      plantControlling: newPlantData.plantControlling?.listId,
      plantCompany: newPlantData.plantCompany?.listId,
      plantBusinessUnit: newPlantData.plantBusinessUnit?.listId,
      plantCalsIdFk: newPlantData?.plantCalsIdFk?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Plant Added successfully");
          setShowPlantEditForm(!showPlantEditForm);
          setShowPlantForm(false);
        } else if (response.status === 208) {
          toast.error(response?.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const allcolumns = [
    {
      field: "plantDesc",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "plantCode",
      headerName: "Code",
      type: "text",
      disable,
      required: true,
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "plantControlling",
      headerName: "Controlling",
      type: "select",
      apiendpoint: "plantControlling",
      textFieldWidth: medium,
      required: true,
      width: GridWidthExtraLargeWidth,
    },
    {
      field: "plantCompany",
      headerName: "Company",
      type: "select",
      apiendpoint: "company",
      textFieldWidth: medium,
      required: true,
      width: GridWidthMedium,
    },

    {
      field: "plantBusinessUnit",
      headerName: "Business Unit",
      type: "select",
      apiendpoint: "plantCompUnit",
      textFieldWidth: medium,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "plantCatalog",
      headerName: "Catalog",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "plantCalsIdFk",
      headerName: "Calendar",
      type: "select",
      apiendpoint: "getCalendarSystemData",
      textFieldWidth: medium,
      width: GridWidthExtraLargeWidth,
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_plant`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_plant`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);
  console.log(
    "Rendering with viewLoaded:",
    viewLoaded,
    "columns:",
    columns,
    "rows:",
    rows
  );

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_plant`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowPlantEditForm(!showPlantEditForm);
    setShowPlantForm(false);
  };

  const addPlantClick = () => {
    setShowPlantEditForm(true);
    setShowPlantForm(true);
    setEditDefValues({});
    setDisable(false);
  };
  return (
    <>
      <ToastContainer />
      {isPlantDataIsFetching && <Progressor />}
      <Box>
        <Box sx={{ padding: "1rem" }}>
          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"plantDesc"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => addPlantClick()}
                        onClickEdit={() => handlePlantEditClick()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={id}
                        plant={true}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="plant"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showPlantEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showPlantForm}
          setShowForm={setShowPlantForm}
          columns={columns}
          isLoading={plantAddIsLoading || plantIsUpdating}
          onUpData={saveUpdateData}
          onAdd={submitAddPlantData}
          editId={id}
          defaultValues={editDefValues}
          label={"Plant"}
        />
      )}
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="plant"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default PlantView;
