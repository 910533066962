const MIcon = ({ style }) => {
  return (
    <svg
      version={1.0}
      xmlns="http://www.w3.org/2000/svg"
      width={style.width || "2rem"}
      height={style.height || "2rem"}
      viewBox="0 0 67.000000 67.000000"
      preserveAspectRatio="xMidYMid meet"
      style={style}
    >
      <g
        transform="translate(0.000000,67.000000) scale(0.100000,-0.100000)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M77 533 c-4 -3 -7 -91 -7 -195 l0 -188 265 0 265 0 -2 193 -3 192
-256 3 c-140 1 -258 -1 -262 -5z m493 -188 l0 -165 -235 0 -235 0 0 165 0 165
235 0 235 0 0 -165z"
        />
        <path
          d="M154 457 c-2 -7 -3 -67 -2 -133 l3 -119 25 0 c25 0 25 1 30 104 l5
103 39 -93 c46 -111 52 -119 81 -119 29 0 35 8 81 119 l39 93 5 -103 c5 -103
5 -104 30 -104 l25 0 0 130 0 130 -40 0 -39 0 -50 -114 -50 -113 -18 38 c-10
22 -33 74 -52 117 -34 75 -36 77 -71 77 -20 0 -38 -6 -41 -13z"
        />
        <path
          d="M10 110 l0 -30 325 0 325 0 0 30 0 30 -125 0 c-77 0 -125 -4 -125
-10 0 -6 -32 -10 -75 -10 -43 0 -75 4 -75 10 0 6 -48 10 -125 10 l-125 0 0
-30z"
        />
      </g>
    </svg>
  );
};

export default MIcon;
