import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { AddEditForm } from "../../../common/AddEditForm";
import { medium } from "../../../constants/global";
import { CommonBox } from "../assetManagementSteps/CommonBox";

export const WorkLabor = ({ label }) => {
  const [id, setId] = useState(0);

  const [editDefValues, setEditDefValues] = useState();
  const [showLaborEditForm, setShowLaborEditForm] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleLaborEditClick = (event, data) => {
    setShowLaborEditForm(true);
    setId(data.id);
    setEditDefValues(data);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowLaborEditForm(false);
  };
  const columns = [
    {
      field: "action",
      headerName: "Action",
      type: "",
      sortable: false,
      hideable: false,
      width: 100,
      required: false,
      renderCell: (params) => {
        const onClick = (e) => {
          handleLaborEditClick(e, params.row);
          setShowEdit(true);
        };

        return (
          <>
            <IconButton onClick={onClick}>
              <EditIcon />
            </IconButton>

            <IconButton
              onClick={() => {
                setRows((prev) => {
                  return prev.filter((item) => item.id !== params.id);
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "Description",
      headerName: "Description",
      type: "select",
      textFieldWidth: medium,
    },
    {
      field: "UoM",
      headerName: "UoM",
      type: "select",
      textFieldWidth: medium,
    },
    {
      field: "Qty",
      headerName: "Qty",
      textFieldWidth: medium,
      type: "number",
    },
    {
      field: "Cost",
      headerName: "Cost",
      textFieldWidth: medium,
      type: "number",
    },
    {
      field: "Structure",
      headerName: "Structure",
      type: "select",
      textFieldWidth: medium,
    },
  ];

  const [rows, setRows] = useState([]);

  const onAdd = () => {
    setShowLaborEditForm(true);
  };

  return (
    <>
      <CommonBox
        onClick={onAdd}
        isLoading={false}
        columns={columns}
        rows={rows}
        label={"Labor"}
      />
      {showLaborEditForm && (
        <AddEditForm
          onClose={handleClose}
          rows={rows}
          columns={columns}
          showForm={showLaborEditForm}
          showWorkOrderEditForm={showLaborEditForm}
          setShowWorkOrderEditForm={setShowLaborEditForm}
          setRows={setRows}
          label={"Labor"}
        />
      )}
    </>
  );
};
