import React, { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import numeral from "numeral";
import {
  GridWidthMedium,
  defaultNumberFormateAmount,
  defaultNumberFormatePrice,
  defaultNumberFormateQty,
  WorkOrderPrefix,
  baseCurrencyOptions,
  foreignCurrencyOptions,
  gridHeight,
  small,
  GeneralPrefix,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import {
  mapDropdownValueToFindIdWithLabel,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../utils/common";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const Currency = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData(0);
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showCurrencyForm, setShowCurrencyForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showCurrencyEditForm, setShowCurrencyEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [idDisable, setIdDisable] = useState(false);
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "currency" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setIdDisable(true);
  };
  const {
    mutateAsync: addCurrency,
    isSuccess: isCurrencySuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/currency/addCurrencyDetail`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateCurrency,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/currency?curId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteCurrency, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${WorkOrderPrefix}/currency?curId=${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getCurrencyData,
    isFetchedAfterMount: isFetch,
    isFetching: isCurrencyFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/currency`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isCurrencySuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isCurrencySuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getMainCurrencyData, setGetMainCurrencyData] =
    useState(getCurrencyData);

  useEffect(() => {
    if (getCurrencyData && rightsArray[0]?.urRead) {
      const unitData = getCurrencyData.map((item) => {
        const newBaseCurrency = mapDropdownValueToLabelWithId(
          baseCurrencyOptions,
          item.curBaseCurrency
        );
        const newForCurrency = mapDropdownValueToFindIdWithLabel(
          foreignCurrencyOptions,
          item.curForeignCur
        );

        return {
          ...item,
          curBaseCurrency: newBaseCurrency,
          curForeignCur: newForCurrency,
        };
      });

      setGetMainCurrencyData(unitData);
    }
  }, [getCurrencyData, rightsArray]);

  useEffect(() => {
    if (isFetch && getMainCurrencyData && canRead) {
      setRows(() =>
        getMainCurrencyData?.map((w) => {
          return { id: w.curId, ...w };
        })
      );
    }
  }, [isFetch, getMainCurrencyData, rightsArray, canRead]);

  const handleCurrencyEditClick = () => {
    setShowCurrencyEditForm(true);
    setId(editData.id);
    const newBaseCurrency = mapDropdownValueToIdWithLabel(
      baseCurrencyOptions,
      editData.curBaseCurrency
    );
    const newForCurrency = mapDropdownValueToIdWithLabel(
      foreignCurrencyOptions,
      editData.curForeignCur
    );
    setEditDefValues({
      ...editData,
      curBaseCurrency: newBaseCurrency,
      curForeignCur: newForCurrency,
    });
  };
  const saveUpdateData = async (CurrencyDataToSave) => {
    updateCurrency({
      id: CurrencyDataToSave.curId,
      ...CurrencyDataToSave,
      curBaseCurrency: CurrencyDataToSave?.curBaseCurrency?.id,
      curExchRate: CurrencyDataToSave?.curExchRate,
      curForeignCur: CurrencyDataToSave?.curForeignCur?.label,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Currency updated successfully");
          setShowCurrencyEditForm(false);
          setId();
          setIdDisable(false);
          commonData.refetchCurrency();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitCurrencyData = async (newCurrencyData) => {
    addCurrency({
      ...newCurrencyData,
      curBaseCurrency: newCurrencyData?.curBaseCurrency?.id,
      curExchRate: newCurrencyData?.curExchRate,
      curForeignCur: newCurrencyData?.curForeignCur?.label,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Currency Added successfully");
          setShowCurrencyEditForm(!showCurrencyEditForm);
          setShowCurrencyForm(false);
          commonData.refetchCurrency();
          setIdDisable(false);
        } else if (response.status === 208) {
          toast.error(response?.data?.message);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteCurrency()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Currency Deleted successfully");
          setOpenDialog(false);
          setId();
          setIdDisable(false);
          commonData.refetchCurrency();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowCurrencyEditForm(!showCurrencyEditForm);
    setShowCurrencyForm(false);
    setIdDisable(false);
  };

  const allcolumns = [
    {
      field: "curId",
      headerName: "Currency Code",
      required: true,
      type: "text",
      width: GridWidthMedium,
      disable: idDisable,
      textFieldWidth: small,
    },
    {
      field: "curDescription",
      headerName: "Description",
      required: true,
      type: "text",
      maxLength: 40,
      width: GridWidthMedium,
    },
    {
      field: "curBaseCurrency",
      headerName: "Base Currency",
      type: "select",
      width: GridWidthMedium,
      options: baseCurrencyOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      textFieldWidth: small,
      required: true,
    },
    {
      field: "curForeignCur",
      headerName: "Foreign Currency",
      type: "select",
      width: GridWidthMedium,
      options: foreignCurrencyOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      textFieldWidth: small,
    },
    {
      field: "curExchRate",
      headerName: "Exchange Rate",
      required: true,
      type: "number",
      width: GridWidthMedium,
      textFieldWidth: small,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateQty);
      },
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_currency`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_currency`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_currency`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  const onAdd = () => {
    setShowCurrencyEditForm(true);
    setShowCurrencyForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isCurrencyFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"curId"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleCurrencyEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="currency"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showCurrencyEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showCurrencyForm}
          setShowForm={setShowCurrencyForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitCurrencyData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Currency"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Currency?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="currency"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};
