import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import {Button} from "../../../common/Button"; // Import the existing Button component
import {
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  small,
  GeneralPrefix,
} from "../../../constants/global";
import { useCommonData } from "../../../context/CommonDataProvider";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  formatTime,
  getFormattedDate,
  getFormattedTime,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
} from "../../../utils/common";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import dayjs from "dayjs";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";
import GanttChart from './GanttChart';

export const WorkOrderTasks = ({
  workOrderId,
  wostColumns,
  setWostColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showTasksForm, setShowTasksForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showTasksEditForm, setShowTasksEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' or 'gantt'
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addWorkOrderTasks,
    isSuccess: isWorkOrderTasksSuccess,
    isLoading: isTaskLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/tasks/addtask`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateWorkOrderTasks,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/tasks?taskId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderTasks,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/tasks?taskId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getWorkOrderTasksData,
    isFetchedAfterMount: isFetch,
    isFetching: isTaskFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/tasks?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderTasksSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderTasksSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getWoServiceTaskData, setGetWoServiceTaskData] = useState(
    getWorkOrderTasksData
  );

  useEffect(() => {
    if (getWorkOrderTasksData) {
      const serviceTaskData = getWorkOrderTasksData.map((item) => {
        const newStatus = mapDropdownValueToLabelWithListId(
          commonData.workOrderSubStatus,
          item.wosStatus
        );
        const newUoM = mapDropdownValueToLabelWithId(
          commonData.getUomDataList,
          item.wosUomIdFk
        );
        return {
          ...item,
          wosStatus: newStatus,
          wosUomIdFk: newUoM,
        };
      });
      setGetWoServiceTaskData(serviceTaskData);
    }
  }, [getWorkOrderTasksData, commonData]);

  useEffect(() => {
    if (isFetch && getWoServiceTaskData && canRead) {
      setRows(() =>
        getWoServiceTaskData?.map((w) => {
          return { id: w.wosId, ...w };
        })
      );
    }
  }, [isFetch, getWoServiceTaskData, rightsArray, canRead]);

  const handleWorkOrderTasksEditClick = () => {
    setShowTasksEditForm(true);
    setId(editData.id);
    const newStatus = mapDropdownValueToListIdWithLabel(
      commonData.workOrderSubStatus,
      editData.wosStatus
    );
    const newUoM = mapDropdownValueToIdWithLabel(
      commonData.getUomDataList,
      editData.wosUomIdFk
    );
    setEditDefValues({ ...editData, wosStatus: newStatus, wosUomIdFk: newUoM });
  };

  const saveUpdateData = async (WorkOrderTasksDataToSave) => {
    if (
      WorkOrderTasksDataToSave?.wosStartTime &&
      !WorkOrderTasksDataToSave?.wosStartDate
    ) {
      toast.error("Start date is required when start time is provided");
      return;
    }

    if (
      WorkOrderTasksDataToSave?.wosEndTime &&
      !WorkOrderTasksDataToSave?.wosEndDate
    ) {
      toast.error("End date is required when end time is provided");
      return;
    }

    if (
      dayjs(WorkOrderTasksDataToSave?.wosStartDate).isAfter(
        WorkOrderTasksDataToSave?.wosEndDate
      )
    ) {
      toast.error(
        "Work order End date should be greater than or equal to Work order start date"
      );
      return;
    }
    updateWorkOrderTasks({
      id: WorkOrderTasksDataToSave.wosId,
      ...WorkOrderTasksDataToSave,
      wosUomIdFk: WorkOrderTasksDataToSave?.wosUomIdFk?.id
        ? WorkOrderTasksDataToSave?.wosUomIdFk?.id
        : null,
      wosStatus: WorkOrderTasksDataToSave?.wosStatus?.listId,
      wosStartTime: formatTime(WorkOrderTasksDataToSave?.wosStartTime),
      wosEndTime: formatTime(WorkOrderTasksDataToSave?.wosEndTime),
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Task Added successfully");
          setShowTasksEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data?.message);
        }
      });
  };

  const submitTasksData = async (newWorkOrderTasksData) => {
    if (
      newWorkOrderTasksData?.wosStartTime &&
      !newWorkOrderTasksData?.wosStartDate
    ) {
      toast.error("Start date is required when start time is provided");
      return;
    }

    if (
      newWorkOrderTasksData?.wosEndTime &&
      !newWorkOrderTasksData?.wosEndDate
    ) {
      toast.error("End date is required when end time is provided");
      return;
    }

    if (
      dayjs(newWorkOrderTasksData?.wosStartDate).isAfter(
        newWorkOrderTasksData?.wosEndDate
      )
    ) {
      toast.error(
        "Work order End date should be greater than or equal to Work order start date"
      );
      return;
    }

    addWorkOrderTasks({
      workOrderId,
      ...newWorkOrderTasksData,
      wosUomIdFk: newWorkOrderTasksData?.wosUomIdFk?.id
        ? newWorkOrderTasksData?.wosUomIdFk?.id
        : null,
      wosStatus: newWorkOrderTasksData?.wosStatus?.listId,
      wosStartTime:
        newWorkOrderTasksData?.wosStartTime &&
        typeof newWorkOrderTasksData.wosStartTime === "object"
          ? dayjs(newWorkOrderTasksData.wosStartTime).format("HH:mm:ss")
          : " ",
      wosEndTime:
        newWorkOrderTasksData?.wosEndTime &&
        typeof newWorkOrderTasksData.wosEndTime === "object"
          ? dayjs(newWorkOrderTasksData.wosEndTime).format("HH:mm:ss")
          : " ",
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Tasks added successfully");
          setShowTasksEditForm(!showTasksEditForm);
          setShowTasksForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderTasks({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Task delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTasksEditForm(!showTasksEditForm);
    setShowTasksForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "wosTaskCode",
      headerName: "Task Code",
      required: true,
      type: "text",
      maxLength: 12,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wosTaskDesc",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      width: GridWidthMedium,
    },
    {
      field: "wosStartDate",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "wosStartTime",
      headerName: "Start Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "wosDuration",
      headerName: "Duration",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wosUomIdFk",
      headerName: "Unit",
      type: "select",
      apiendpoint: "getUomDataList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "wosEndDate",
      headerName: "End Date",
      type: "Date",
      minDateLabel: "wosStartDate",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "wosEndTime",
      headerName: "End Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "wosStatus",
      headerName: "Status",
      type: "select",
      textFieldWidth: medium,
      maxLength: 20,
      apiendpoint: "workOrderSubStatus",
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.wos_service_tasks || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.wos_service_tasks)
    ) {
      configColumns = updatedConfig.sub_component.wos_service_tasks;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        wos_service_tasks: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.wos_service_tasks
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.wos_service_tasks
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => !col.hide);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (wostColumns) {
      setColumns(wostColumns);
    } else {
      fetchColumnConfig();
    }
  }, [wostColumns, columnConfig, defaultConfig, workOrderId]);

  const onAdd = () => {
    setShowTasksEditForm(true);
    setShowTasksForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <div className="flex gap-2">
          <Button 
            onClick={() => setViewMode('grid')} 
            variant={viewMode === 'grid' ? "contained" : "outlined"}
            label="Grid View"
          />
          <Button 
            onClick={() => setViewMode('gantt')} 
            variant={viewMode === 'gantt' ? "contained" : "outlined"}
            label="Gantt Chart"
          />
        </div>
      </div>
      
      {viewMode === 'grid' ? (
        <CommonBox
          onClickAdd={onAdd}
          onClickEdit={handleWorkOrderTasksEditClick}
          onClickDelete={handleClickOpen}
          onRowClick={handleRowClick}
          editId={id}
          isLoading={isTaskFetching}
          onClickColumnCustom={handleOpenColumnPopup}
          columns={columns.filter((col) => !col.hide && col.width > 0)}
          rows={rows}
          label={"Service Tasks"}
          urWrite={rightsArray[0]?.urWrite}
          urModify={rightsArray[0]?.urModify}
          urDelete={rightsArray[0]?.urDelete}
          urAdmin={rightsArray[0]?.urAdmin}
          sortingField={"workOrderId"}
        />
      ) : (
        <GanttChart tasks={rows} />
      )}

      {showTasksEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showTasksForm}
          setShowForm={setShowTasksForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitTasksData}
          editId={id}
          defaultValues={editDefValues}
          label={"Service Tasks"}
          isLoading={isUpdateLoading || isTaskLoading}
        />
      )}
      
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Service Task?"}
        handleApprove={handleDeleteClick}
        isLoading={isDeleteLoading}
      />
      
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};