import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  GridWidthLargeWidth,
  GridWidthMedium,
  WorkOrderPrefix,
  GeneralPrefix,
  dateWidth,
  defaultDateFormate,
  medium,
} from "../../../constants/global";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { getFormattedDate } from "../../../utils/common";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import { useCommonData } from "../../../context/CommonDataProvider";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
// Configuration variable to determine storage service
const STORAGE_SERVICE = "S3"; // Change this to "ONEDRIVE" for OneDrive storage

export const WorkDocumentUpload = ({ workOrderId, selectedView, woDocColumns,setWoDocColumns }) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [editDefValues, setEditDefValues] = useState();
  const [showWoDocumentEditForm, setShowWoDocumentEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectFile, setSelectFile] = useState([]);
  const [documentSizeError, setDocumentSizeError] = useState(false);
  const [editData, setEditData] = useState();
  const [uniqueIds, setUniqueIds] = useState({});
  const commonData = useCommonData();
  const tenantId = commonData?.userData?.tenant_id;
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [viewLoaded, setViewLoaded] = useState(true);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };


  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const handleDocumentEditClick = () => {
    setShowWoDocumentEditForm(true);
    setId(editData.id);
    setEditDefValues(editData);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const {
    mutateAsync: addWorkOrderDocument,
    isSuccess: isWorkOrderDocumentSuccess,
    isLoading: isDocumentLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/docs`,
    method: "post",
    contentType: "multipart/form-data",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateWorkOrderDocument,
    isSuccess: isUpdateSuccess,
    isLoading: updateDocumentLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/docs?docId=${id}`,
    method: "put",
    contentType: "multipart/form-data",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: deleteWorkOrderDocument,
    isSuccess: isDeleteSuccess,
    isLoading: deleteDocumentLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/docs?docId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    isSuccess: isDocumentFetchSuccess,
    isLoading: isDocumentFetching,
    refetch,
    data: workOrderDocument,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/docs?woId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderDocumentSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderDocumentSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    setRows(() => {
      return (
        isDocumentFetchSuccess &&
        canRead &&
        workOrderDocument
          ?.filter((doc) => doc.docType === "wo")
          ?.map((w) => {
            return { id: w.docId, ...w };
          })
      );
    });
  }, [workOrderDocument, isDocumentFetchSuccess, rightsArray, canRead]);

  const uploadToStorage = async (file, docId, description) => {
    if (!file) {
      console.error("No file selected for upload");
      throw new Error("No file selected for upload");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("workOrderId", workOrderId);
    formData.append("fileName", file.name);
    formData.append("docId", docId);
    formData.append("docDescription", description || "");
    formData.append("tenantId", tenantId);

    if (STORAGE_SERVICE === "S3") {
      formData.append("docRefIdFk", workOrderId);
    }

    let endpoint = "";
    if (STORAGE_SERVICE === "ONEDRIVE") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${WorkOrderPrefix}/upload-to-onedrive`;
    } else if (STORAGE_SERVICE === "S3") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${WorkOrderPrefix}/docs`;
    } else {
      throw new Error("Invalid storage service specified");
    }

    try {
      const response = await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Menu_id: rightsArray[0]?.urMenuIdFk,
        },
      });

      if (response.status === 200) {
        console.log(`Document uploaded to ${STORAGE_SERVICE} successfully`);
        if (STORAGE_SERVICE === "ONEDRIVE") {
          const uniqueId = response.data.uniqueId;
          setUniqueIds((prevIds) => ({ ...prevIds, [docId]: uniqueId }));
        }
        return response;
      } else {
        throw new Error(`Failed to upload document to ${STORAGE_SERVICE}`);
      }
    } catch (error) {
      console.error(`Error in uploadToStorage (${STORAGE_SERVICE}):`, error);
      throw error;
    }
  };

  const submitDocumentData = async (newWorkOrderDocumentData) => {
    try {
      if (!selectFile || selectFile.length === 0) {
        toast.error("No file selected. Please select a file to upload.");
        return;
      }
      if (!newWorkOrderDocumentData.docDescription) {
        toast.error("Document description is required.");
        return;
      }

      const response = await uploadToStorage(
        selectFile[0],
        null,
        newWorkOrderDocumentData.docDescription
      );

      if (response.status === 200) {
        toast.success(`Document added successfully to ${STORAGE_SERVICE}`);
        setShowDocumentForm(false);
        setShowWoDocumentEditForm(false);
        setSelectFile([]);
        refetch(); // Refresh the document list
      } else {
        toast.error(`Failed to upload document to ${STORAGE_SERVICE}`);
      }
    } catch (error) {
      console.error("Error in submitDocumentData:", error);
      toast.error(error?.response?.data?.message || "Error uploading document");
    }
  };

  const tenentId = localStorage.getItem("X-tenant-id");

  const saveUpdateData = async (WorkOrderDocumentDataToSave) => {
    updateWorkOrderDocument({
      dynamicEndpointSuffix: `&tenantId=${tenantId}&docDescription=${
        WorkOrderDocumentDataToSave?.docDescription
      }&docRefIdFk=${workOrderId}&docUpDate=${
        dayjs().toISOString().split("Z")[0]
      }`,
      file: selectFile,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Document updated successfully");
          setShowWoDocumentEditForm(false);
          setSelectFile([]);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderDocument({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Document deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowDocumentForm(false);
    setShowWoDocumentEditForm(!showWoDocumentEditForm);
  };

  const fetchDocument = (docId, downloadDocName) => {
    let endpoint = "";
    if (STORAGE_SERVICE === "ONEDRIVE") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${WorkOrderPrefix}/get-from-onedrive/${workOrderId}/${docId}/${uniqueIds[docId]}`;
    } else if (STORAGE_SERVICE === "S3") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${WorkOrderPrefix}/download/${docId}`;
    }

    axios
      .get(endpoint, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Menu_id: rightsArray[0]?.urMenuIdFk,
        },
      })
      .then((response) => {
        if (STORAGE_SERVICE === "ONEDRIVE") {
          handleFileInfo(response.data);
        } else {
          const blobObj = new Blob([response.data]);
          const href = URL.createObjectURL(blobObj);

          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", downloadDocName.split("/").slice(1));

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        toast.error(
          error?.response?.data?.message ||
            `Error fetching file from ${STORAGE_SERVICE}`
        );
      });
  };

  const handleFileInfo = (fileInfo) => {
    if (fileInfo.webUrl) {
      window.open(fileInfo.webUrl, "_blank");
    } else if (fileInfo["@microsoft.graph.downloadUrl"]) {
      window.open(fileInfo["@microsoft.graph.downloadUrl"], "_blank");
    } else {
      toast.error("Unable to access the file");
    }
  };

  const handleChange = (event) => {
    const file = event?.target?.files?.[0];
    if (!file) {
      setSelectFile([]);
      setDocumentSizeError(false);
      return;
    }
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > 10) {
      setDocumentSizeError(true);
      setSelectFile([]);
      return;
    }
    setDocumentSizeError(false);
    setSelectFile([file]);
  };

  const onAdd = () => {
    setShowDocumentForm(true);
    setShowWoDocumentEditForm(true);
    setEditDefValues({});
  };

  const [columns, setColumns] = useState([
    {
      field: "file",
      headerName: "File",
      type: "file",
      maxLength: 40,
      required: true,
      hide: true,
      width: GridWidthMedium,
    },
    {
      field: "docDescription",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "docUrl",
      headerName: "URL",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "docPath",
      headerName: "Path",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      renderCell: (params) => {
        const onClick = () => {
          fetchDocument(params.row.docId, params.row.docPath);
        };
        return (
          <Typography
            mt={1}
            component="a"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.875rem",
            }}
            variant="body1"
            onClick={onClick}
          >
            {params.value}
          </Typography>
        );
      },
      width: GridWidthLargeWidth,
    },
    {
      field: "docUpDate",
      headerName: "Date",
      type: "text",
      textFieldWidth: dateWidth,
      value: dayjs().format(defaultDateFormate),
      valueFormatter: (params) => getFormattedDate(params.value),
      disable: true,
      width: GridWidthMedium,
    },
  ]);
  

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }
   
    try {
      let configColumns = [];
   
      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.doc_upload) {
        configColumns = updatedConfig.sub_component.doc_upload;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === 'object') {
        configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
          name: field,
          title: details?.headerName,
          visible: details?.visible,
          width: details?.width ? `${details.width}px` : undefined,
        }));
      }
   
      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }
   
      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });
   
      setColumns(newColumns);
   
      const configToUpdate = {
        ...columnConfig,
        category: "workorder",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          doc_upload: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };
   
      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.doc_upload) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.doc_upload
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (woDocColumns) {
      setColumns(woDocColumns);
    } else {
      fetchColumnConfig();
    }
  }, [woDocColumns, columnConfig, defaultConfig, workOrderId]);

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleDocumentEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={() => handleOpenColumnPopup()}
        editId={id}
        isLoading={isDocumentFetching}
        columns={columns}
        rows={rows}
        label={"Document"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"docDescription"}
      />

      {showWoDocumentEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showDocumentForm}
          setShowForm={setShowDocumentForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitDocumentData}
          editId={id}
          documentSizeError={documentSizeError}
          handleFileUpload={handleChange}
          defaultValues={editDefValues}
          selectFile={selectFile}
          label={"Document/Photo Upload"}
          isLoading={updateDocumentLoading || isDocumentLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Document data?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteDocumentLoading}
      />

      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
