import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  GeneralPrefix,
  GridWidthMedium,
  gridHeight,
  medium,
  GridWidthExtraLargeWidth,
  GridWidthLargeWidth,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box, SwipeableDrawer } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../utils/common";
import { IncidentItemMultiStep } from "./IncidentItemSteps/IncidentItemMultiStep";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const IncidentItem = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [rows, setRows] = useState([]);
  const [showIncidentItemForm, setShowIncidentItemForm] = useState(false);
  const [showIncidentItemEditForm, setShowIncidentItemEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const userId = commonData?.userData?.userId;
  const [editDefWorkingValues, setEditDefWorkingValues] = useState();
  const [incItemId, setincItemId] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [viewLoaded, setViewLoaded] = useState(true);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleRowClick = (params) => {
    setId(params.row.incItemId);
    setEditData(params.row);
    setincItemId(params.row.id);
  };

  const {
    mutateAsync: addIncidentItem,
    isSuccess: isIncidentItemSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/incident-item`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateIncidentItem,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/incident-item/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const { mutateAsync: deleteIncidentItem, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${GeneralPrefix}/incident-item/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getIncidentItemData,
    isFetchedAfterMount: isFetch,
    isFetching: isIncidentItemFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/incident-item`,
    retrieveOnMount: false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    setIsMounted(true);
    return () => setIsMounted(false);
  }, []);

  useEffect(() => {
    if (isMounted && rightsArray[0]?.urMenuIdFk) {
      refetch();
    }
  }, [isMounted, rightsArray, refetch]);

  useEffect(() => {
    if (isIncidentItemSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isIncidentItemSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [plantMasterData, setPlantMasterData] = useState(getIncidentItemData);

  useEffect(() => {
    if (isFetch && getIncidentItemData && canRead) {
      setRows(() =>
        getIncidentItemData?.map((w) => {
          const incidentSubClass = commonData.getIncidentSubClassData.find(
            (item) => item.id === w.incInsSubIdFk
          );
          return {
            id: w.incItemId,
            ...w,
            incInsSubIdFk: incidentSubClass
              ? `${incidentSubClass.insCode || ""} ${
                  incidentSubClass.insCode ? "-" : ""
                } ${incidentSubClass.label || ""}`
              : "",
          };
        })
      );
    }
  }, [
    isFetch,
    getIncidentItemData,
    canRead,
    commonData.getIncidentSubClassData,
  ]);

  useEffect(() => {
    if (getIncidentItemData) {
      const plantData = getIncidentItemData.map((item) => {
        const incidentSubClass = commonData.getIncidentSubClassData.find(
          (subClass) => subClass.id === item.incInsSubIdFk
        );
        return {
          ...item,
          incInsSubIdFk: incidentSubClass
            ? `${incidentSubClass.insCode || ""} ${
                incidentSubClass.insCode ? "-" : ""
              } ${incidentSubClass.label || ""}`
            : "",
        };
      });
      setPlantMasterData(plantData);
    }
  }, [commonData.getIncidentSubClassData, getIncidentItemData]);

  const handleIncidentItemEditClick = () => {
    setShowIncidentItemEditForm(true);
    setId(editData.incItemId);
    // Map the incident subclass back to the correct format
    const mappedIncidentSubClass = commonData.getIncidentSubClassData.find(
      (item) =>
        `${item.insCode || ""} ${item.insCode ? "-" : ""} ${
          item.label || ""
        }` === editData.incInsSubIdFk
    );
    setEditDefValues({
      ...editData,
      incInsSubIdFk: mappedIncidentSubClass || null, // Set the full object as the value
    });
  };

  const saveUpdateData = async (IncidentItemDataToSave) => {
    updateIncidentItem({
      incItemId: IncidentItemDataToSave.incItemId,
      ...IncidentItemDataToSave,
      incInsSubIdFk: IncidentItemDataToSave.incInsSubIdFk?.id || null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident Item updated successfully");
          setShowIncidentItemEditForm(false);
          setId();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitIncidentItemData = async (newIncidentItemData) => {
    addIncidentItem({
      ...newIncidentItemData,
      incInsSubIdFk: newIncidentItemData.incInsSubIdFk?.id || null,
      incModifiedBy: userId,
      incModifiedDate: new Date().toISOString(),
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident Item Added successfully");
          setShowIncidentItemEditForm(!showIncidentItemEditForm);
          setShowIncidentItemForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDeleteClick = async () => {
    deleteIncidentItem()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident Item Deleted successfully");
          setOpenDialog(false);
          setId();
        } else if (response.status === 208) {
          toast.error(response?.data.message);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.status === 208) {
          toast.error(error?.response?.data.message);
        } else {
          toast.error(error);
        }
      });
  };

  const [failColumns, setFailColumns] = useState([
    {
      field: "failFailureCause",
      headerName: "Failure Cause",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const [remedyColumns, setRemedyColumns] = useState([
    {
      field: "incrRemedy",
      headerName: "Remedy",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "failModeLabel",
      headerName: "Failure Mode",
      width: GridWidthMedium,
    },
  ]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowIncidentItemEditForm(!showIncidentItemEditForm);
    setShowIncidentItemForm(false);
  };

  const allcolumns = [
    {
      field: "incIncident",
      headerName: "Incident",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "incFailureCode",
      headerName: "Failure Code",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "incInsSubIdFk",
      headerName: "Incident Sub Class",
      required: true,
      type: "select",
      apiendpoint: "getIncidentSubClassData",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
      valueGetter: (params) => params.value,
    },
    {
      field: "incSeverityRating",
      headerName: "Severity Rating",
      required: true,
      type: "number",
      width: GridWidthLargeWidth,
    },
    {
      field: "incDetectionRating",
      headerName: "Detection Rating",
      required: true,
      type: "number",
      width: GridWidthLargeWidth,
    },
    {
      field: "incOccurrenceRating",
      headerName: "Occurrence Rating",
      required: true,
      type: "number",
      width: GridWidthLargeWidth,
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    setColumns(updateColumns(columns, updatedConfig.grid.columns));
    setFailColumns(
      updateColumns(failColumns, updatedConfig.sub_component?.fail_mode)
    );

    setRemedyColumns(
      updateColumns(remedyColumns, updatedConfig.sub_component?.incr_remedy)
    );

    const configToUpdate = {
      ...updatedConfig,
      sub_component: {
        fail_mode: failColumns,
        incr_remedy: remedyColumns,
      },
    };

    Object.keys(configToUpdate.sub_component).forEach((key) => {
      configToUpdate.sub_component[key] = configToUpdate.sub_component[key].map(
        (col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })
      );
    });

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_incident_item`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "incident_item" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_incident_item`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  useEffect(() => {
    fetchColumnConfig();
  }, [columnConfig, defaultConfig, incItemId]);

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.fail_mode
      ) {
        const updatedFailColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.fail_mode } },
          failColumns
        );
        setFailColumns(updatedFailColumns);
      } else {
        setFailColumns(failColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.incr_remedy
      ) {
        const updatedRemedyColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.incr_remedy } },
          remedyColumns
        );
        setRemedyColumns(updatedRemedyColumns);
      } else {
        setRemedyColumns(remedyColumns.map((col) => ({ ...col, hide: false })));
      }

      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_incident_item`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const fetchColumnConfig = async () => {
    try {
      if (columnConfig && Object.keys(columnConfig).length > 0) {
        applyConfiguration(columnConfig);
        if (columnConfig.view_name && columnConfig.view_id) {
          setSelectedView({
            name: columnConfig.view_name,
            id: columnConfig.view_id,
          });
        }
      } else if (defaultConfig) {
        setColumnConfig(defaultConfig);
        applyConfiguration(defaultConfig);
      } else {
        setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
    }
  };

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, allcolumns);
      setColumns(transformedColumns);
    }

    if (config.sub_component) {
      if (config.sub_component.fail_mode) {
        const transformedFailColumns = transformConfig(
          { grid: { columns: config.sub_component.fail_mode } },
          failColumns
        );
        setFailColumns(transformedFailColumns);
      }
    }

    if (config.sub_component) {
      if (config.sub_component.incr_remedy) {
        const transformedRemedyColumns = transformConfig(
          { grid: { columns: config.sub_component.incr_remedy } },
          remedyColumns
        );
        setRemedyColumns(transformedRemedyColumns);
      }
    }

    if (config.view_name) {
      setSelectedView({ name: config.view_name, id: config.view_id });
    }
  };

  const onAdd = () => {
    setShowIncidentItemEditForm(true);
    setShowIncidentItemForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isIncidentItemFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={columns}
              rows={rows}
              sortingField={"incIncident"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleIncidentItemEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickProperties={() => toggleDrawer(true)()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={id}
                        propId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="incident_item"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
              onCellDoubleClick={toggleDrawer(true)}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showIncidentItemEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showIncidentItemForm}
          setShowForm={setShowIncidentItemForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitIncidentItemData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Incident Item"
        />
      )}

      <Box>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ width: "60vw" }}>
            <IncidentItemMultiStep
              onClose={toggleDrawer(false)}
              incItemId={incItemId}
              open={open}
              setOpen={setOpen}
              setEditDefWorkingValues={setEditDefWorkingValues}
              editDefWorkingValues={editDefWorkingValues}
              selectedView={selectedView}
              columnConfig={columnConfig}
              updateColumnConfig={updateColumnConfig}
              setColumnConfig={setColumnConfig}
              failColumns={failColumns}
              setFailColumns={setFailColumns}
              remedyColumns={remedyColumns}
              setRemedyColumns={setRemedyColumns}
            />
          </Box>
        </SwipeableDrawer>
      </Box>

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Incident Item?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="incident_item"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default IncidentItem;
