import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import AddEditToolBar from "./AddEditToolBar";
import { Box, Tooltip } from "@mui/material";
import { useTheme, alpha } from "@mui/material/styles";

export function CustomToolbar() {
  const buttonStyle = {
    minWidth: 0,
    minHeight: 0,
    backgroundColor: "transparent",
    color: "rgba(0,0,0,0.3)",
    border: "none",
    boxShadow: "none",
    TextFormat: "capitalize",
    "&:hover": {
      color: "primary.main",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
  };
  return (
    <Box
      sx={{
        ".MuiButton-startIcon": { display: "flex", mx: 0 },
        marginBottom: "0.1vh",
      }}
    >
      <GridToolbarContainer sx={{ gap: 1 }}>
        <Tooltip title={"Search Columns"}>
          <GridToolbarColumnsButton sx={buttonStyle} />
        </Tooltip>
        <Tooltip>
          <GridToolbarFilterButton sx={buttonStyle} />
        </Tooltip>
        <Tooltip title={"Density Selector"}>
          <GridToolbarDensitySelector sx={buttonStyle} />
        </Tooltip>
        <Tooltip title={"Export"}>
          <GridToolbarExport sx={buttonStyle} />
        </Tooltip>
      </GridToolbarContainer>
    </Box>
  );
}
export const DataGridComponent = ({
  columns,
  rows,
  sx,
  sortingField,
  onCellDoubleClick,
  onRowClick,
  ...rest
}) => {
  const theme = useTheme();
  const [sortModel, setSortModel] = useState([]);
  const processedColumns = columns.map((column) => ({
    ...column,
    flex: column.flex || undefined,
    width: column.width || undefined,
  }));

  useEffect(() => {
    setSortModel([{ field: sortingField, sort: "asc" }]);
  }, [sortingField]);

  const fadedGrayColor = alpha(theme.palette.grey[500], 0.15);
  return (
    <DataGrid
      sx={{
        height: "82vh !important",
        // marginRight:"-4.5vw",
        width: "95vw",
        ...sx,
        "& .MuiDataGrid-row": {
          maxHeight: "26.5px !important",
          minHeight: "26.5px !important",
          height: "26.5px !important",
          fontSize: "12.5px",
          "&:hover": {
            backgroundColor: fadedGrayColor,
          },
        },
        "& .MuiDataGrid-cell": {
          maxHeight: "26.5px !important",
          minHeight: "26.5px !important",
          height: "26.5px !important",
          lineHeight: "26.5px !important",
        },
        "& .MuiDataGrid-columnHeaders": {
          backgroundColor: fadedGrayColor,
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          fontSize: "13px",
        },
        "@media (max-width: 600px)": {
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "12px",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "10px",
          },
        },
        "& .MuiDataGrid-footerContainer": {
          minHeight: "30px !important",
          maxHeight: "30px !important",
          height: "30px !important",
          fontSize: "2px !important",
        },
        "& .MuiTablePagination-toolbar": {
          minHeight: "40px !important",
          height: "40px !important",
        },
      }}
      columns={processedColumns}
      density="compact"
      rows={rows}
      headerHeight={40}
      components={{
        Toolbar: rest.components.Toolbar
          ? rest.components.Toolbar
          : (props) => (
              <div>
                <CustomToolbar {...props} />
                <AddEditToolBar />
              </div>
            ),
      }}
      localeText={{
        toolbarColumns: "",
        toolbarFilters: "",
        toolbarDensity: "",
        toolbarExport: "",
      }}
      onCellDoubleClick={onCellDoubleClick}
      onRowClick={onRowClick}
      initialState={{
        ...rows?.initialState,
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      sortModel={sortModel}
      onSortModelChange={(model) => setSortModel(model)}
      pageSizeOptions={[5, 10, 25]}
    />
  );
};
