import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import numeral from "numeral";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  defaultNumberFormatePrice,
  defaultNumberFormateQty,
  GeneralPrefix,
} from "../../../constants/global";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToIdWithMaterialCode,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToMaterialCodeWithId,
  modifyOptionsWithCodeAndTag,
} from "../../../utils/common";
import { useCommonData } from "../../../context/CommonDataProvider";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const WorkMaterials = ({
  workOrderId,
  selectedView,
  materialColumns,
  setMaterialColumns
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showWoMaterialForm, setShowWoMaterialForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showWoMaterialEditForm, setShowWoMaterialEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [viewLoaded, setViewLoaded] = useState(true);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getMainMaterialDataList,
    "mtrlCode",
    "mtrlDesc"
  );
  const handleMaterialTaskEditClick = () => {
    setShowWoMaterialEditForm(true);
    setId(editData.id);
    const newStructure = mapDropdownValueToIdWithLabel(
      commonData.getCbsDataList,
      editData.womCbsIdFk
    );
    const newUoM = mapDropdownValueToIdWithLabel(
      commonData.getUomDataList,
      editData.womUomIdFk
    );
    const newMaterial = mapDropdownValueToIdWithMaterialCode(
      modifiedOptions,
      editData.womMaterialIdFk
    );
    setEditDefValues({
      ...editData,
      womCbsIdFk: newStructure,
      womUomIdFk: newUoM,
      womMaterialIdFk: newMaterial,
    });
  };

  const {
    mutateAsync: addWorkOrderMaterial,
    isSuccess: isWorkOrderMaterialSuccess,
    isLoading: isMaterialLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material/addMaterial`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderMaterial,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material?materialId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderMaterial,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material?materialId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    isSuccess: isMaterialFetchSuccess,
    isFetchedAfterMount: isFetch,
    isLoading: isMaterialFetching,
    refetch,
    data: workOrderMaterial,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/material?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderMaterialSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderMaterialSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getMaterialTaskData, setGetMaterialTaskData] =
    useState(workOrderMaterial);

  useEffect(() => {
    if (workOrderMaterial) {
      const materialTaskData = workOrderMaterial.map((item) => {
        const newStructure = mapDropdownValueToLabelWithId(
          commonData.getCbsDataList,
          item.womCbsIdFk
        );
        const newUoM = mapDropdownValueToLabelWithId(
          commonData.getUomDataList,
          item.womUomIdFk
        );
        const newMaterial = mapDropdownValueToMaterialCodeWithId(
          commonData.getMainMaterialDataList,
          item.womMaterialIdFk
        );
        return {
          ...item,
          womCbsIdFk: newStructure,
          womUomIdFk: newUoM,
          womMaterialIdFk: newMaterial,
        };
      });
      setGetMaterialTaskData(materialTaskData);
    }
  }, [workOrderMaterial, commonData]);

  useEffect(() => {
    if (isFetch && getMaterialTaskData && canRead) {
      setRows(() => {
        return (
          isMaterialFetchSuccess &&
          getMaterialTaskData?.map((w) => {
            return { id: w.womId, ...w };
          })
        );
      });
    }
  }, [
    canRead,
    getMaterialTaskData,
    isFetch,
    isMaterialFetchSuccess,
    rightsArray,
  ]);

  const saveUpdateData = async (WorkOrderMaterialDataToSave) => {
    updateWorkOrderMaterial({
      id: WorkOrderMaterialDataToSave.womId,
      ...WorkOrderMaterialDataToSave,
      womUomIdFk: WorkOrderMaterialDataToSave?.womUomIdFk?.id,
      womMaterialIdFk: WorkOrderMaterialDataToSave?.womMaterialIdFk?.mtrlId,
      womCbsIdFk: WorkOrderMaterialDataToSave?.womCbsIdFk?.id,
      womCost: WorkOrderMaterialDataToSave?.womCost
        ? Array.isArray(WorkOrderMaterialDataToSave?.womCost)
          ? Number(WorkOrderMaterialDataToSave?.womCost[0])
          : WorkOrderMaterialDataToSave?.womCost
        : "",
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material updated  successfully");
          setShowWoMaterialEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitMaterialData = async (newWorkOrderMaterialData) => {
    addWorkOrderMaterial({
      womWoIdFk: workOrderId,
      ...newWorkOrderMaterialData,
      womUomIdFk: newWorkOrderMaterialData?.womUomIdFk?.id,
      womMaterialIdFk: newWorkOrderMaterialData?.womMaterialIdFk?.mtrlId,
      womCbsIdFk: newWorkOrderMaterialData?.womCbsIdFk?.id,
      womCost: newWorkOrderMaterialData?.womCost
        ? Array.isArray(newWorkOrderMaterialData?.womCost)
          ? Number(newWorkOrderMaterialData?.womCost[0])
          : newWorkOrderMaterialData?.womCost
        : "",
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material added successfully");
          setShowWoMaterialEditForm(!showWoMaterialEditForm);
          setShowWoMaterialForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderMaterial({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowWoMaterialEditForm(!showWoMaterialEditForm);
    setShowWoMaterialForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "womMaterialIdFk",
      headerName: "Description",
      type: "selectTwo",
      textFieldWidth: medium,
      options: modifyOptionsWithCodeAndTag(
        commonData?.getMainMaterialDataList,
        "mtrlCode",
        "mtrlDesc"
      ),
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "womUomIdFk",
      headerName: "UoM",
      type: "select",
      apiendpoint: "getUomDataList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "womQty",
      headerName: "Qty",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateQty);
      },
    },
    {
      field: "womCost",
      headerName: "Cost",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "womCbsIdFk",
      headerName: "Structure",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getCbsDataList",
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }
   
    try {
      let configColumns = [];
   
      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.wom_material) {
        configColumns = updatedConfig.sub_component.wom_material;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === 'object') {
        configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
          name: field,
          title: details?.headerName,
          visible: details?.visible,
          width: details?.width ? `${details.width}px` : undefined,
        }));
      }
   
      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }
   
      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });
   
      setColumns(newColumns);
   
      const configToUpdate = {
        ...columnConfig,
        category: "workorder",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          wom_material: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };
   
      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.wom_material) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.wom_material
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (materialColumns) {
      setColumns(materialColumns);
    } else {
      fetchColumnConfig();
    }
  }, [materialColumns, columnConfig, defaultConfig, workOrderId]);


  const onAdd = () => {
    setShowWoMaterialEditForm(true);
    setShowWoMaterialForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleMaterialTaskEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={handleOpenColumnPopup}
        editId={id}
        isLoading={isMaterialFetching}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Material"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"womMaterialIdFk"}
      />

      {showWoMaterialEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showWoMaterialForm}
          setShowForm={setShowWoMaterialForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitMaterialData}
          editId={id}
          defaultValues={editDefValues}
          label={"Materials"}
          workMaterial={true}
          isLoading={isUpdateLoading || isMaterialLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order?"}
        handleApprove={handleDeleteClick}
        isLoading={isDeleteLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
