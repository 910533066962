import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Box, Grid, Typography } from "@mui/material";
import useMutationApi from "../../../hooks/useMutationApi";
import useFetchApi from "../../../hooks/useFetchApi";
import { ListGroupPrefix, gridHeight } from "../../../constants/global";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../../common/DataGridComponent";
import AddEditToolBar from "../../../common/AddEditToolBar";
import { Progressor } from "../../../common/Progressor";
import { useMenuContext } from "../../../context/MenuContextProvider";

const Options = ({ selectedGroup }) => {
  const { rightsArray } = useMenuContext();
  const menuId = rightsArray[0]?.urMenuIdFk;
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const canUpdate = rightsArray[0]?.urWrite || rightsArray[0]?.urModify;

  const [optionRows, setOptionRows] = useState([]);
  const [selectedOptionId, setSelectedOptionId] = useState(null);
  const [editOptionData, setEditOptionData] = useState(null);
  const [editDefValues, setEditDefValues] = useState({});
  const [showOptionForm, setShowOptionForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const optionColumns = [
    {
      field: "listValue1",
      headerName: "List Value",
      width: 200,
      required: true,
      type: "text",
    },
  ];

  const {
    data: listValuesData,
    isFetching: isListValuesFetching,
    refetch: refetchListValues,
    error,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/getListValuesByLcmCode/${selectedGroup?.code}`,
    retrieveOnMount: true,
    enabled: !!selectedGroup?.code,
    Menu_id: menuId,
    queryParams: {
      listGroupCode: selectedGroup?.code,
    },
  });

  const {
    mutateAsync: addOptions,
    isSuccess: isOptionsSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/addListConfig/${selectedGroup?.code}`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateListValue1,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/updateListConfig/${selectedGroup?.code}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (listValuesData && canRead) {
      const formattedRows = listValuesData.map((item) => ({
        id: item.recId,
        listValue1: item.listValue1,
        ...item,
      }));
      setOptionRows(formattedRows);
    }
  }, [listValuesData, canRead]);

  useEffect(() => {
    if (selectedGroup?.code) {
      refetchListValues();
    }
  }, [selectedGroup?.code, refetchListValues]);

  useEffect(() => {
    if (isOptionsSuccess || isUpdateSuccess) {
      refetchListValues();
      //  toast.success("Option updated successfully");
    }
  }, [isOptionsSuccess, isUpdateSuccess, refetchListValues]);

  const handleOptionRowClick = (params) => {
    setSelectedOptionId(params.row.recId);
    setEditOptionData(params.row);
  };

  const handleEditClick = () => {
    if (selectedOptionId && canUpdate) {
      setShowOptionForm(true);
      setShowAddForm(false);
    }
  };

  const handleAddClick = () => {
    setShowOptionForm(true);
    setShowAddForm(true);
    setEditOptionData({});
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowOptionForm(false);
    setShowAddForm(false);
  };

  // Function to handle adding new data
  const onAdd = async (data) => {
    try {
      await addOptions({
        ...data,
        listGroupCode: selectedGroup?.code,
      });
      setShowOptionForm(false);
      setShowAddForm(false);
      toast.success("Option added successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to add option.");
    }
  };

  // Function to handle updating existing data
  const onUpData = async (data) => {
    try {
      await updateListValue1(data);
      setShowOptionForm(false);
      setShowAddForm(false);
      toast.success("Option updated successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message || "Failed to update option.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6" component="div">
              {selectedGroup
                ? ` ${selectedGroup.description}`
                : "Select a group to view options"}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isListValuesFetching ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Progressor />
              </Box>
            ) : (
              selectedGroup && (
                <Box sx={{ height: `${100 - gridHeight}vh`, width: "90vw" }}>
                  <DataGridComponent
                    columns={optionColumns}
                    rows={optionRows}
                    sortingField="listValue1"
                    getRowId={(row) => row.id}
                    components={{
                      Toolbar: (props) => (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CustomToolbar {...props} />
                          <AddEditToolBar
                            onClickAdd={handleAddClick}
                            onClickEdit={handleEditClick}
                            onClickDelete={() => setOpenDeleteDialog(true)}
                            editId={selectedOptionId}
                            urWrite={rightsArray[0]?.urWrite}
                            urModify={rightsArray[0]?.urModify}
                            urDelete={rightsArray[0]?.urDelete}
                            urAdmin={rightsArray[0]?.urAdmin}
                          />
                        </div>
                      ),
                    }}
                    onRowClick={handleOptionRowClick}
                  />
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Box>

      {showOptionForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showAddForm}
          setShowForm={setShowAddForm}
          columns={optionColumns}
          editId={selectedOptionId}
          defaultValues={editOptionData}
          label="List Option"
          onAdd={onAdd} // Correctly passing the onAdd prop
          onUpData={onUpData} // Correctly passing the onUpData prop
        />
      )}

      <DeleteDialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        title="Delete Option"
      />
    </>
  );
};

export default Options;
