// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customPicker .MuiOutlinedInput-root {
  border-radius: 15px;
}
.customPicker .MuiInputBase-input {
  font-size: 0.9rem;
  font-weight: 500;
}
.timePicker .MuiInputBase-input {
  padding: 9.5px 14px;
}
`, "",{"version":3,"sources":["webpack://./src/common/DateInput.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB","sourcesContent":[".customPicker .MuiOutlinedInput-root {\n  border-radius: 15px;\n}\n.customPicker .MuiInputBase-input {\n  font-size: 0.9rem;\n  font-weight: 500;\n}\n.timePicker .MuiInputBase-input {\n  padding: 9.5px 14px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
