import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Box, SwipeableDrawer } from "@mui/material";
import useFetchApi from "../../hooks/useFetchApi";
import { ListGroupPrefix, gridHeight } from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import { GlobalMultiSteps } from "./GlobalSteps/GlobalMultiSteps";
import { useMenuContext } from "../../context/MenuContextProvider";

const Global = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [rows, setRows] = useState([]);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [selectedGroup, setSelectedGroup] = useState(null);
  const menuId = rightsArray[0]?.urMenuIdFk;

  const columns = [
    {
      field: "code",
      headerName: "Code",
      width: 140,
      required: true,
      type: "text",
    },
    {
      field: "description",
      headerName: "Description",
      width: 320,
      required: true,
      type: "text",
    },
    {
      field: "enable",
      headerName: "Status",
      width: 140,
      type: "boolean",
      renderCell: (params) =>
        params.value ? "Editing Enabled" : "Editing Disabled",
    },
  ];

  const {
    data: listGroupData,
    isFetchedAfterMount: isFetch,
    isFetching: isListGroupFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/getAllListGroupMaster`,
    retrieveOnMount: !!menuId,
    Menu_id: menuId,
  });

  useEffect(() => {
    if (isFetch && listGroupData && canRead) {
      setRows(() =>
        listGroupData?.map((item) => ({
          id: item.recId,
          code: item.listGroup,
          description: item.description,
          enable: item.enable,
          recId: item.recId,
          ...item,
        }))
      );
    }
  }, [isFetch, listGroupData, canRead]);

  const handleRowClick = (params) => {
    setId(params.row.recId);
    setEditData(params.row);
    setSelectedGroup(params.row);
  };

  const handleEditClick = () => {
    setShowEditForm(true);
    setId(editData.recId);
    setEditDefValues(editData);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowEditForm(false);
    setShowAddForm(false);
  };

  const onAdd = () => {
    setShowEditForm(true);
    setShowAddForm(true);
    setEditDefValues({});
  };

  const handlePropertiesClick = () => {
    if (selectedGroup) {
      // Assuming you want to fetch the options for the selected group when opening the properties
      refetch(); // Call this if you want to refetch data in the `Options` component
      setOpen(true);
    }
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isListGroupFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={columns}
              rows={rows}
              sortingField={"code"}
              sortModel={[
                {
                  field: "code",
                  sort: "asc",
                },
              ]}
              sortingOrder={["asc", "desc"]}
              components={{
                Toolbar: (props) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CustomToolbar {...props} />
                    <AddEditToolBar
                      // onClickAdd={onAdd}
                      //  onClickEdit={handleEditClick}
                      //  onClickDelete={() => setOpenDialog(true)}
                      onClickProperties={handlePropertiesClick}
                      propId={selectedGroup}
                      editId={id}
                      urWrite={rightsArray[0]?.urWrite}
                      urModify={rightsArray[0]?.urModify}
                      urDelete={rightsArray[0]?.urDelete}
                      urAdmin={rightsArray[0]?.urAdmin}
                    />
                  </div>
                ),
              }}
              onRowClick={handleRowClick}
              onCellDoubleClick={handlePropertiesClick}
            />
          </Box>
        </Box>
      </Box>

      {showEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showAddForm}
          setShowForm={setShowAddForm}
          columns={columns}
          editId={id}
          defaultValues={editDefValues}
          label="List Group"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="Delete List Group"
      />

      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ width: "60vw" }}>
          <GlobalMultiSteps
            onClose={() => setOpen(false)}
            open={open}
            setOpen={setOpen}
            selectedGroup={selectedGroup}
          />
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default Global;
