export const menuInitialData = {
  members: [
    {
      menu_code: 1,
      menu_order: 1,
      menu_group: "home",
      menu_e_name: "My calendar",
      menu_e_paramName: "my-calendar",
      menu_link: "1()",
      route: "/home/my-calendar",
    },
    {
      menu_code: 2,
      menu_order: 2,
      menu_group: "home",
      menu_e_name: "Dashboard",
      menu_e_paramName: "dashboard",
      menu_link: "2()",
      route: "/home/dashboard",
    },
    {
      menu_code: 3,
      menu_order: 3,
      menu_group: "home",
      menu_e_name: "Asset Management",
      menu_e_paramName: "asset-management",
      menu_link: "3()",
      route: "/home/asset-management",
    },
    {
      menu_code: 4,
      menu_order: 4,
      menu_group: "home",
      menu_e_name: "Work order",
      menu_e_paramName: "work-order",
      menu_link: "4()",
      route: "/home/work-order",
    },
    {
      menu_code: 5,
      menu_order: 5,
      menu_group: "home",
      menu_e_name: "Scheduled Maintenance",
      menu_e_paramName: "scheduled-maintenance",
      menu_link: "5()",
      route: "/home/scheduled-maintenance",
    },
    {
      menu_code: 6,
      menu_order: 6,
      menu_group: "home",
      menu_e_name: "Requests",
      menu_e_paramName: "requests",
      menu_link: "6()",
      route: "/home/requests",
    },
    {
      menu_code: 7,
      menu_order: 7,
      menu_group: "home",
      menu_e_name: "Material",
      menu_e_paramName: "material",
      menu_link: "7()",
      route: "/home/material",
    },
    {
      menu_code: 8,
      menu_order: 8,
      menu_group: "home",
      menu_e_name: "Services",
      menu_e_paramName: "services",
      menu_link: "8()",
      route: "/home/services",
    },
    {
      menu_code: 9,
      menu_order: 9,
      menu_group: "home",
      menu_e_name: "Business Partner",
      menu_e_paramName: "business-partner",
      menu_link: "9()",
      route: "/home/business-partner",
    },
    {
      menu_code: 10,
      menu_order: 10,
      menu_group: "settings",
      menu_e_name: "Plant",
      menu_e_paramName: "plant",
      menu_link: "10()",
      route: "/settings/plant",
    },
    {
      menu_code: 12,
      menu_order: 12,
      menu_group: "report",
      menu_e_name: "Asset List",
      menu_e_paramName: "asset-list",
      menu_link: "12()",
      route: "/report/asset-list",
    },
    {
      menu_code: 13,
      menu_order: 13,
      menu_group: "report",
      menu_e_name: "Asset Usage",
      menu_e_paramName: "asset-usage",
      menu_link: "13()",
      route: "/report/asset-usage",
    },
    {
      menu_code: 14,
      menu_order: 14,
      menu_group: "report",
      menu_e_name: "Service forecast",
      menu_e_paramName: "service-forecast",
      menu_link: "14()",
      route: "/report/service-forecast",
    },
    {
      menu_code: 15,
      menu_order: 15,
      menu_group: "report",
      menu_e_name: "Inventory tracking",
      menu_e_paramName: "inventory-tracking",
      menu_link: "15()",
      route: "/report/inventory-tracking",
    },
    {
      menu_code: 16,
      menu_order: 16,
      menu_group: "admin",
      menu_e_name: "Global",
      menu_e_paramName: "global",
      menu_link: "16()",
      route: "/admin/global",
    },
    {
      menu_code: 17,
      menu_order: 17,
      menu_group: "admin",
      menu_e_name: "Material Type/Group",
      menu_e_paramName: "material-type-group",
      menu_link: "17()",
      route: "/admin/material-type/group",
    },
    {
      menu_code: 18,
      menu_order: 18,
      menu_group: "admin",
      menu_e_name: "Material Group",
      menu_e_paramName: "material-group",
      menu_link: "18()",
      route: "/admin/material-group",
    },
    {
      menu_code: 19,
      menu_order: 19,
      menu_group: "admin",
      menu_e_name: "Asset Category",
      menu_e_paramName: "asset-category",
      menu_link: "19()",
      route: "/admin/asset-category",
    },
    {
      menu_code: 20,
      menu_order: 20,
      menu_group: "admin",
      menu_e_name: "Asset Group",
      menu_e_paramName: "asset-group",
      menu_link: "20()",
      route: "/admin/asset-group",
    },
    {
      menu_code: 21,
      menu_order: 21,
      menu_group: "admin",
      menu_e_name: "Calendar System",
      menu_e_paramName: "calendar-system",
      menu_link: "21()",
      route: "/admin/calendar-system",
    },
    {
      menu_code: 22,
      menu_order: 22,
      menu_group: "admin",
      menu_e_name: "Manufacture",
      menu_e_paramName: "manufacture",
      menu_link: "22()",
      route: "/admin/manufacture",
    },
    {
      menu_code: 23,
      menu_order: 23,
      menu_group: "admin",
      menu_e_name: "Units",
      menu_e_paramName: "units",
      menu_link: "23()",
      route: "/admin/units",
    },
    {
      menu_code: 24,
      menu_order: 24,
      menu_group: "admin",
      menu_e_name: "Currency",
      menu_e_paramName: "currency",
      menu_link: "24()",
      route: "/admin/currency",
    },
    {
      menu_code: 25,
      menu_order: 25,
      menu_group: "admin",
      menu_e_name: "Checklist Group",
      menu_e_paramName: "checklist-group",
      menu_link: "25()",
      route: "/admin/checklist-group",
    },
    {
      menu_code: 26,
      menu_order: 26,
      menu_group: "admin",
      menu_e_name: "Checklist Template",
      menu_e_paramName: "checklist-template",
      menu_link: "26()",
      route: "/admin/checklist-template",
    },
    {
      menu_code: 27,
      menu_order: 27,
      menu_group: "admin",
      menu_e_name: "Employee",
      menu_e_paramName: "employee",
      menu_link: "27()",
      route: "/admin/employee",
    },
    {
      menu_code: 28,
      menu_order: 28,
      menu_group: "admin",
      menu_e_name: "Dimension",
      menu_e_paramName: "dimension",
      menu_link: "28()",
      route: "/admin/dimension",
    },
    {
      menu_code: 29,
      menu_order: 29,
      menu_group: "admin",
      menu_e_name: "User Groups",
      menu_e_paramName: "user-groups",
      menu_link: "29()",
      route: "/admin/user-groups",
    },
    {
      menu_code: 30,
      menu_order: 30,
      menu_group: "admin",
      menu_e_name: "Users",
      menu_e_paramName: "users",
      menu_link: "30()",
      route: "/admin/users",
    },
    {
      menu_code: 31,
      menu_order: 31,
      menu_group: "settings",
      menu_e_paramName: "app-settings",
      menu_e_name: "App Settings",
      menu_link: "31()",
      route: "/settings/app-settings",
    },
    {
      menu_code: 32,
      menu_order: 32,
      menu_group: "help",
      menu_e_paramName: "help",
      menu_e_name: "Help",
      menu_link: "32()",
      route: "/help/help",
    },
    {
      menu_code: 33,
      menu_order: 33,
      menu_group: "home",
      menu_e_name: "Project",
      menu_e_paramName: "project",
      menu_link: "33()",
      route: "/home/project",
    },
    {
      menu_code: 34,
      menu_order: 34,
      menu_group: "report",
      menu_e_name: "Workorder List",
      menu_e_paramName: "workorder-list",
      menu_link: "34()",
      route: "/report/workorder-list",
    },
    {
      menu_code: 35,
      menu_order: 35,
      menu_group: "report",
      menu_e_name: "Material List",
      menu_e_paramName: "material-list",
      menu_link: "35()",
      route: "/report/material-list",
    },
    {
      menu_code: 36,
      menu_order: 36,
      menu_group: "settings",
      menu_e_paramName: "company-info",
      menu_e_name: "Company Info",
      menu_link: "36()",
      route: "/settings/company-info",
    },
    {
      menu_code: 37,
      menu_order: 37,
      menu_group: "admin",
      menu_e_paramName: "incident-class",
      menu_e_name: "Incident Class",
      menu_link: "37()",
      route: "/admin/incident-class",
    },

    {
      menu_code: 38,
      menu_order: 38,
      menu_group: "admin",
      menu_e_paramName: "incident-item",
      menu_e_name: "Incident Item",
      menu_link: "38()",
      route: "/admin/incident-item",
    },
    {
      menu_code: 39,
      menu_order: 39,
      menu_group: "admin",
      menu_e_paramName: "app-config",
      menu_e_name: "App Config",
      menu_link: "39()",
      route: "/admin/app-config",
    },
  ],
};
