// columnConfigStore.js
import { create } from "zustand";

const useColumnConfigStore = create((set) => ({
  columnConfig: null,

  setColumnConfig: (config) => set({ columnConfig: config }),

  updateColumnConfig: (updatedConfig) =>
    set((state) => {
      // console.log("Updating column config:", updatedConfig);
      return {
        columnConfig: { ...state.columnConfig, ...updatedConfig },
      };
    }),

  transformConfig: (config, allColumns) => {
    if (!config || !config.grid || !config.grid.columns) {
      console.error("Invalid config structure:", config);
      return allColumns;
    }

    let configColumns = config.grid.columns;

    if (configColumns.grid && Array.isArray(configColumns.grid.columns)) {
      configColumns = configColumns.grid.columns;
    }

    if (!Array.isArray(configColumns)) {
      console.error("Invalid columns structure:", configColumns);
      return allColumns;
    }

    const configColumnMap = configColumns.reduce((acc, col) => {
      acc[col.name] = col;
      return acc;
    }, {});

    return allColumns.map((column) => {
      const configColumn = configColumnMap[column.field];
      return {
        ...column,
        headerName: configColumn?.title || column.headerName,
        hide: configColumn ? !configColumn.visible : false,
        width: configColumn?.width
          ? parseInt(configColumn.width, 10)
          : column.width,
      };
    });
  },
  handleRestoreAllColumn: (defaultConfig, allColumns) => {
    const restoredColumns = allColumns.map((col) => ({ ...col, hide: false }));

    const restoredConfig = {
      ...defaultConfig,
      grid: {
        columns: restoredColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: true,
          width: `${col.width}px`,
        })),
      },
    };

    set({ columnConfig: restoredConfig });

    return {
      restoredColumns,
      restoredConfig,
    };
  },
}));

export default useColumnConfigStore;
