import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Key, Lock, Mail } from "@mui/icons-material";
import { Card, CardContent, Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import { makeStyles } from "@mui/styles";
import { Button } from "../common/Button";
import { Dropdown } from "../common/Dropdown";
import { Progressor } from "../common/Progressor";
import { TextInput } from "../common/TextInput";
import { ListGroupPrefix, applicationOption } from "../constants/global";
import useMutationApi from "../hooks/useMutationApi";
import useFetchApi from "../hooks/useFetchApi";
import { FormAutocompleteField } from "../common/FormAutocompleteField";
//import ForgotCredentialsModal from "./ForgotCredentialsModal";

const useStyles = makeStyles((theme) => ({
  root: {
    [`& fieldset`]: {
      borderRadius: 8,
    },
  },
  label: {
    color: "#555555 !important",
    fontSize: "0.9rem !important",
    fontWeight: "500 !important",
  },
  titleLogin: {
    fontWeight: "700 !important",
    fontSize: "1.5rem !important",
    textAlign: "left !important",
    color: "#333333 !important",
    marginBottom: "16px !important",
  },
  buttonRegister: {
    position: "absolute",
    top: "-4vh",
    left: "55vw",
    borderRadius: "12px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    backgroundColor: "#f5f5f5 !important",
    color: "#555555 !important",
    "&:hover": {
      backgroundColor: "#e0e0e0 !important",
    },
  },
  buttonLogin: {
    borderRadius: "12px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
    backgroundColor: "#0073e6 !important",
    color: "#ffffff !important",
    "&:hover": {
      backgroundColor: "#005bb5 !important",
    },
  },
  cardBox: {
    background: "#ffffff !important",
    borderRadius: "12px !important",
    padding: "20px !important",
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1) !important",
    width: "100%",
    maxWidth: "400px",
    margin: "auto",
    marginTop: "auto",
    marginBottom: "auto",
  },
  forgotPassword: {
    marginBottom: "16px !important",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    padding: "16px",
    boxSizing: "border-box",
    marginTop: "5vh",
  },
  forgotPasswordLink: {
    cursor: "pointer",
    color: "#0073e6",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const methods = useForm();
  const [isForgotModalOpen, setIsForgotModalOpen] = useState(false);

  const tenantKeyWatch = methods.watch("userTenantKey");

  const tenantKey = tenantKeyWatch || localStorage.getItem("X-tenant-id");

  const handleNavigate = () => {
    navigate("/sign-up");
  };
  const { mutateAsync: signInUser, isLoading } = useMutationApi({
    endpoint: `auth/signin`,
    method: "post",
    tenantKey,
    showError: false,
    Menu_id: -1,
  });
  const {
    data: applicationData,
    isFetchedAfterMount: isFetch,
    isFetching: isApplicationDataIsFetching,
    refetch: applicationFetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/tenant/apps`,
    retrieveOnMount: true,
    Menu_id: 1,
    isApps: true,
  });

  useEffect(() => {
    if (isFetch) {
      applicationFetch();
    }
  }, [applicationFetch, isFetch]);

  const onSubmit = async (data) => {
    localStorage.setItem("X-tenant-id", data?.userTenantKey);

    const payload = {
      username: data.userEmail,
      password: data.userPassword,
      applicationId: data.application?.id,
      application: "Asset Management",
    };

    try {
      const res = await signInUser({ ...payload });
      if (res.status === 200) {
        localStorage.setItem("token", res.data.jwt);
        navigate("/home/dashboard");
        navigate(0);
      }
    } catch (error) {
      if (
        error.response?.status === 401 &&
        error.response?.data?.jwt === "Error: User account has expired"
      ) {
        toast.error("Your account has expired. Please contact support.");
      } else {
        toast.error(
          "Invalid credentials. Please check email, password, and tenant key combination."
        );
      }
    }
  };
  const inputs = [
    {
      id: 1,
      field: "userEmail",
      name: "userEmail",
      type: "email",
      placeholder: "Email",
      headerName: "Email",
      errorMessage: "Please enter an email address in a valid format",
      required: true,
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      icon: <Mail />,
    },
    {
      id: 2,
      field: "userPassword",
      name: "userPassword",
      type: "password",
      placeholder: "Password",
      headerName: "Password",
      errorMessage: "Please enter your password.",
      required: true,
      pattern:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
      icon: <Lock />,
    },
    {
      id: 3,
      field: "userTenantKey",
      name: "Tenant key",
      type: "number",
      placeholder: "Tenant key",
      headerName: "Tenant key",
      errorMessage: "Please enter a Tenant key",
      required: true,
      icon: <Key />,
    },
    {
      id: 4,
      field: "application",
      headerName: "Application",
      type: "select",
      required: true,
      options: applicationData?.map((o) => ({
        label: o.applicationName,
        id: o.applicationId,
      })),
      textFieldWidth: "100%",
    },
  ];

  // const handleForgotCredentials = (e) => {
  //   e.preventDefault();
  //   setIsForgotModalOpen(true);
  // };

  // const handleCloseForgotModal = () => {
  //   setIsForgotModalOpen(false);
  // };

  return (
    <>
      <ToastContainer />
      <Container component="main" maxWidth="xs" className={classes.container}>
        <CssBaseline />
        <Button
          variant="outlined"
          className={classes.buttonRegister}
          onClick={handleNavigate}
          label={"Register"}
          disabled={isLoading}
        />
        <Box className={classes.cardBox}>
          {isLoading && <Progressor />}
          <CardContent>
            <Typography
              component="h1"
              variant="h5"
              className={classes.titleLogin}
              align="left"
            >
              Welcome Back!
            </Typography>
            <FormProvider {...methods}>
              <form
                onSubmit={methods.handleSubmit(onSubmit)}
                method="post"
                noValidate
              >
                <Stack spacing={2}>
                  {inputs.map((inputItem) => (
                    <React.Fragment key={inputItem.id}>
                      {inputItem.type === "select" ? (
                        <FormAutocompleteField
                          classes={classes}
                          label={inputItem.headerName}
                          value={methods.getValues(inputItem.field)}
                          name={inputItem.field}
                          required={inputItem.required}
                          variant="outlined"
                          options={inputItem.options}
                          width={inputItem.textFieldWidth}
                        />
                      ) : (
                        <TextInput
                          classes={classes}
                          type={inputItem.type}
                          required={inputItem.required}
                          label={inputItem.headerName}
                          value={methods.getValues(inputItem.field)}
                          variant="outlined"
                          name={inputItem.field}
                          InputProps={inputItem.icon}
                          pattern={inputItem.pattern}
                          errorMessage={inputItem.errorMessage}
                        />
                      )}
                    </React.Fragment>
                  ))}

                  <Box className={classes.forgotPassword}>
                    <Link
                      className={classes.forgotPasswordLink}
                      //   onClick={handleForgotCredentials}
                      component="button"
                      variant="body2"
                    >
                      <Typography>Forgot password?</Typography>
                    </Link>
                  </Box>

                  <Button
                    variant="contained"
                    className={classes.buttonLogin}
                    label={"Login"}
                    type="submit"
                    disabled={isLoading}
                  />
                </Stack>
              </form>
            </FormProvider>
          </CardContent>
        </Box>
      </Container>

      {/* <ForgotCredentialsModal
        isOpen={isForgotModalOpen}
        onClose={handleCloseForgotModal}
      /> */}
    </>
  );
};

export default SignIn;
