import DoneIcon from "@mui/icons-material/Done";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import * as Ramda from "ramda";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Tooltip,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCommonData } from "../context/CommonDataProvider";
import { Button } from "./Button";
import { DateInput } from "./DateInput";
import { FileUploadInput } from "./FileUploadInput";
import { FormAutocompleteField } from "./FormAutocompleteField";
import { TextInput } from "./TextInput";
import { TimeInput } from "./TimeInput";
import { TextFieldForNumberInput } from "./TextFieldForNumberInput";
import { TwoColumnAutoCompleteField } from "./TwoColumnAutoCompleteField";

export const AddEditAccordionForm = (props) => {
  const commonData = useCommonData();
  const [accordionError, setAccordionError] = useState({});

  const methods = useForm({
    defaultValues: {
      ...props.defaultValues,
    },
  });
  const updateData = () => {
    const values = methods.getValues();
    const valuesWithId = {
      id: props.editId,
      ...values,
    };

    props.onUpData(valuesWithId);
  };
  useEffect(() => {
    let error = {};
    props.columns.forEach((section) => {
      error[section.label] = false;
    });
    setAccordionError(error);
  }, [props.columns]);

  React.useEffect(() => {
    const temp = Ramda.clone(accordionError);
    props.columns.forEach((section) => {
      const { fields, label } = section;
      let flag = false;
      fields.forEach((item) => {
        flag = Object.keys(methods.formState.errors).includes(item.field);
        if (flag) {
          temp[label] = true;
        }
      });
    });
    setAccordionError(temp);
  }, [props.columns, methods.formState.errors]);

  const saveData = (data) => {
    const savedData = { ...data };

    for (const key in data) {
      switch (key) {
        case "asstExpiryDate":
        case "asstImanufactDate":
        case "asstInstalledDate":
        case "asstPurchasedDate":
        case "asstRetiredDate":
          if (data[key]) {
            savedData[key] = format(new Date(data[key]), "yyyy-MM-dd");
          }
          break;
        default:
          break;
      }
    }

    props.onAdd(savedData);
  };
  return (
    <Dialog
      open
      onClose={props.onClose}
      fullWidth
      scroll="paper"
      maxWidth={false}
      sx={{
        "& .MuiDialog-container": {
          position: "absolute",
          top: "20px",
          left: "15px",
        },
      }}
      PaperProps={{
        sx: {
          height: "100vh",
        },
      }}
    >
      <form
        onSubmit={methods.handleSubmit(!props.showForm ? updateData : saveData)}
      >
        <FormProvider {...methods}>
          <DialogTitle
            sx={{
              backgroundColor: "#1976d2",
              color: "white",
            }}
            style={{ cursor: "move" }}
            id="draggable-dialog-title"
          >
            <Grid container justifyContent="space-between">
              <Grid item>
                {props.showEdit ? `${props.label}` : `${props.label}`}
              </Grid>
              <Grid item>
                <Tooltip title="Submit">
                  <Button
                    sx={{
                      color: "#1976d2",
                      backgroundColor: "white",
                      marginRight: "4px",
                      padding: "4px 16px",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    disabled={props.isLoading}
                    label={<DoneIcon />}
                    type="submit"
                  />
                </Tooltip>
                <Tooltip title="Cancel">
                  <Button
                    sx={{
                      color: "#1976d2",
                      backgroundColor: "white",
                      padding: "4px 16px",
                      "&:hover": {
                        backgroundColor: "white",
                      },
                    }}
                    label={<PhonelinkEraseIcon />}
                    onClick={props.onClose}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent
            dividers
            sx={{
              minHeight: "75.5vh",
              maxHeight: "75.5vh",
              overflowY: "auto",
              border: "none",
            }}
          >
            {props.showForm
              ? `Add  the ${props.label} Information`
              : `Edit the ${props.label} Information`}
            <DialogContentText>
              {props.columns.map((section) => {
                return (
                  <Temp
                    section={section}
                    accordionError={accordionError}
                    methods={methods}
                    commonData={commonData}
                    props={props}
                  />
                );
              })}
            </DialogContentText>
          </DialogContent>
        </FormProvider>
      </form>
    </Dialog>
  );
};

const Temp = ({ section, accordionError, methods, commonData, props }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(accordionError[section.label]);
  }, [accordionError, section.label]);
  return (
    <Accordion
      key={section.label}
      expanded={open}
      onChange={() => {
        setOpen((prev) => !prev);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {section.label}
      </AccordionSummary>
      <AccordionDetails>
        <Grid container columnSpacing={2}>
          {section.fields.map((inputItem) => {
            return (
              <Grid item xs={6} key={inputItem.field}>
                {inputItem.field !== "action" &&
                  (inputItem.type === "select" ? (
                    <FormAutocompleteField
                      label={inputItem.headerName}
                      value={methods.getValues(
                        inputItem.field ? inputItem.field : ""
                      )}
                      name={inputItem.field}
                      required={inputItem.required}
                      variant="standard"
                      options={
                        inputItem.options
                          ? inputItem.options
                          : commonData[inputItem.apiendpoint]
                      }
                      width={inputItem.textFieldWidth}
                    />
                  ) : inputItem.type === "selectTwo" ? (
                    <TwoColumnAutoCompleteField
                      label={inputItem.headerName}
                      value={methods.getValues(
                        inputItem.field ? inputItem.field : ""
                      )}
                      name={inputItem.field}
                      required={inputItem.required}
                      variant="standard"
                      options={
                        inputItem.options
                          ? inputItem.options
                          : commonData[inputItem.apiendpoint]
                      }
                      width={inputItem.textFieldWidth}
                    />
                  ) : inputItem.type === "file" ? (
                    <FileUploadInput
                      required={inputItem.required}
                      label={inputItem.headerName}
                      name={inputItem.field}
                      documentSizeError={props.documentSizeError}
                      handleFileUpload={props.handleFileUpload}
                      selectFile={props.selectFile}
                    />
                  ) : inputItem.type === "Date" ? (
                    <DateInput
                      required={inputItem.required}
                      label={inputItem.headerName}
                      name={inputItem.field}
                      width={inputItem.textFieldWidth}
                      defaultValue={inputItem.value}
                      minDateLabel={inputItem?.minDateLabel}
                      maxDateLabel={inputItem?.maxDateLabel}
                    />
                  ) : inputItem.type === "time" ? (
                    <TimeInput
                      label={inputItem.headerName}
                      name={inputItem.field}
                      value={
                        methods.getValues(inputItem.field)
                          ? methods.getValues(inputItem.field)
                          : null
                      }
                      required={inputItem.required}
                      width={inputItem.textFieldWidth}
                    />
                  ) : (inputItem.type === "text") |
                    (inputItem.type === "email") |
                    (inputItem.type === "float") |
                    (inputItem.type === "tel") ? (
                    <TextInput
                      type={inputItem.type}
                      required={inputItem.required}
                      label={inputItem.headerName}
                      disable={inputItem.disable}
                      maxLength={inputItem.maxLength}
                      value={
                        methods.getValues(inputItem.field)
                          ? methods.getValues(inputItem.field)
                          : null
                      }
                      multiline={inputItem.multiline}
                      variant="outlined"
                      name={inputItem.field}
                      rows={inputItem.row}
                      size={inputItem.size}
                      width={inputItem.textFieldWidth}
                      defaultValue={inputItem.value}
                      section={section.label}
                    />
                  ) : inputItem.type === "number" ? (
                    <TextFieldForNumberInput
                      type={inputItem.type}
                      required={inputItem.required}
                      label={inputItem.headerName}
                      disable={inputItem.disable}
                      maxLength={inputItem.maxLength}
                      value={
                        methods.getValues(inputItem.field)
                          ? methods.getValues(inputItem.field)
                          : null
                      }
                      multiline={inputItem.multiline}
                      variant="outlined"
                      name={inputItem.field}
                      rows={inputItem.row}
                      size={inputItem.size}
                      width={inputItem.textFieldWidth}
                      defaultValue={inputItem.value}
                      pattern={inputItem.pattern}
                      errorMessage={inputItem.errorMessage}
                      onlyPositive={inputItem.onlyPositive}
                    />
                  ) : (
                    ""
                  ))}
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
