import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import DomainIcon from "@mui/icons-material/Domain";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DashboardIcon from "@mui/icons-material/Dashboard";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import TuneIcon from "@mui/icons-material/Tune";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import ReduceCapacityIcon from "@mui/icons-material/ReduceCapacity";
import PeopleIcon from "@mui/icons-material/People";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import FactoryIcon from "@mui/icons-material/Factory";
import RoomPreferencesIcon from "@mui/icons-material/RoomPreferences";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import MenuIcon from "@mui/icons-material/Menu";
import { AccountCircle, Settings } from "@mui/icons-material";
import { useAuthContext } from "../context/AuthContextProvider";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import BusinessIcon from "@mui/icons-material/Business";
import CategoryIcon from "@mui/icons-material/Category";
import GroupsIcon from "@mui/icons-material/Groups";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import ApartmentIcon from "@mui/icons-material/Apartment";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import ChecklistIcon from "@mui/icons-material/Checklist";
import BadgeIcon from "@mui/icons-material/Badge";
import ListAltIcon from "@mui/icons-material/ListAlt";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { useMenuContext } from "../context/MenuContextProvider";
import { menuInitialData } from "../utils/MenuInitialData";
import { mainHeaderHeight, menuTitleHeight } from "../constants/global";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import AssignmentIcon from "@mui/icons-material/Assignment";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import BuildIcon from "@mui/icons-material/Build";

const iconList = {
  home: <MenuIcon />,
  report: <AnalyticsIcon />,
  catalog: <AdminPanelSettingsIcon />,
  user: <AccountCircle />,
  settings: <Settings />,
  help: <HelpCenterIcon />,
  plant: <DomainIcon />,
  "My calendar": <CalendarMonthIcon />,
  Dashboard: <DashboardIcon />,
  Notification: <NotificationsActiveIcon />,
  "Asset Management": <TuneIcon />,
  "Asset List": <ManageSearchIcon />,
  "Asset Usage": <ManageHistoryIcon />,
  "Workorder List": <AssignmentIcon />,
  "Material List": <InventoryIcon />,
  "Service forecast": <OnlinePredictionIcon />,
  "Inventory tracking": <ContentPasteSearchIcon />,
  Company: <FactoryIcon />,
  "Business Unit": <RoomPreferencesIcon />,
  Department: <Diversity3Icon />,
  "User Groups": <ReduceCapacityIcon />,
  Users: <PeopleIcon />,
  "App Settings": <DisplaySettingsIcon />,
  "Company Info": <FactoryIcon />,
  "Incident Class": <ReportProblemIcon />,
  "Incident Item": <ReportProblemIcon />,
  "App Config": <BuildIcon />,
  Help: <HelpCenterIcon />,
  Plant: <PrecisionManufacturingIcon />,
  "Parts Query": <LiveHelpIcon />,
  "Work order": <DeveloperBoardIcon />,
  "Scheduled Maintenance": <EngineeringIcon />,

  Requests: <ConfirmationNumberIcon />,
  Material: <MiscellaneousServicesIcon />,
  Services: <MiscellaneousServicesIcon />,
  "Business Partner": <BusinessIcon />,
  Global: <ListAltIcon />,
  "Asset Category": <CategoryIcon />,
  "Asset Group": <GroupsIcon />,
  "Calendar System": <CalendarMonthIcon />,
  Manufacture: <WarehouseIcon />,
  Units: <ApartmentIcon />,
  Currency: <MonetizationOnIcon />,
  "Checklist Group": <ChecklistIcon />,
  "Checklist Template": <ChecklistIcon />,
  Employee: <BadgeIcon />,
  "Material Type/Group": <MiscellaneousServicesIcon />,
  Dimension: <MiscellaneousServicesIcon />,
  "List Group": <ListAltIcon />,
  Project: <AccountTreeIcon />,
  //"Material Group": <ChecklistIcon />,
};

const initialListState = menuInitialData.members;
const LeftBar = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const ref = useRef(null);
  const { selectedMenuItem, setSelectedMenuItem } = useAuthContext();
  const [selectedListItem, setSelectedListItem] = useState();
  const [showSubmenu, setShowSubmenu] = useState(false);
  const { menuObjectWithUserRights } = useMenuContext();
  let canEnable;
  const urlValue = window.location.pathname.split("/").slice(1)[1];
  const initialData = initialListState
    ?.filter((item) => item.menu_e_paramName === urlValue)
    .map((item) => {
      return item;
    });

  const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.target.closest("#main-menu")
    ) {
      setShowSubmenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    setSelectedMenuItem(initialData[0]);
  }, [setSelectedMenuItem, initialData]);

  const handleSubmenuShowState = () => {
    setShowSubmenu((prevState) => !prevState);
  };

  const getDefaultMenuItems = () => {
    return ["home", "catalog", "report", "settings", "help"];
  };

  const getSubMenuItems = (menuItem) => {
    const subMenuItems = new Array([]);
    menuObjectWithUserRights?.map((item) => {
      const menuGroup =
        item.menu_group === "admin" ? "catalog" : item.menu_group;
      if (menuGroup === menuItem && item.menu_e_name !== "Material Group") {
        subMenuItems.push(item);
      }
      return item;
    });
    subMenuItems.sort((a, b) => a.menu_order - b.menu_order);
    return subMenuItems;
  };

  const handleChangeMenuItem = (listItemName) => {
    if (listItemName !== selectedListItem) {
      setSelectedListItem(listItemName);
    }

    if (!showSubmenu) {
      handleSubmenuShowState();
    } else if (selectedListItem === listItemName && showSubmenu) {
      handleSubmenuShowState();
    }
  };

  // Define which menus should open in new tab
  const newTabMenus = ["Documentation", "Help"];

  const handleSubMenuChange = (menuItem) => {
    // Check if this menu should open in new tab
    if (newTabMenus.includes(menuItem.menu_e_name)) {
      window.open(
        `/${menuItem.menu_group}/${menuItem.menu_e_name
          .replace(" ", "-")
          .toLowerCase()}`,
        "_blank",
        "noopener,noreferrer"
      );
      handleSubmenuShowState();
      return;
    }

    // Normal handling for other menu items
    if (selectedMenuItem?.menu_e_name !== menuItem.menu_e_name) {
      setSelectedMenuItem(menuItem);
    }
    handleSubmenuShowState();
    canEnable = menuItem?.urEnable || menuItem?.urAdmin;

    canEnable &&
      navigate(
        `/${menuItem.menu_group}/${menuItem.menu_e_name
          .replace(" ", "-")
          .toLowerCase()}`
      );
  };

  const mainSideList = () => {
    const items = Array.from(getDefaultMenuItems());
    return (
      <Box component="div">
        <List
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ListItem
            sx={{
              // paddingLeft: "0.6rem",
              display: "flex",
              justifyContent: "center",
            }}
            button
            key={"logo"}
          >
            <ListItemIcon>
              {/* <MenuIcon
                onClick={() => {
                  if (selectedListItem !== undefined) {
                    handleSubmenuShowState();
                  }
                }}
                sx={{ color: "white" }}
              /> */}
            </ListItemIcon>
            <Divider />
          </ListItem>
          {items?.map((listItem, index) => (
            <ListItem
              sx={{
                alignIItem: "center",
                display: "flex",
                justifyContent: "center",
              }}
              button
              key={index}
              onClick={() => {
                handleChangeMenuItem(listItem);
              }}
            >
              <ListItemIcon
                sx={{
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignIItem: "center",
                }}
              >
                {iconList[listItem]}
              </ListItemIcon>

              <Divider />
            </ListItem>
          ))}
        </List>
      </Box>
    );
  };

  const subSideList = () => {
    const subMenuItems = getSubMenuItems(selectedListItem);
    return (
      <div ref={ref}>
        <Collapse orientation="horizontal" in={showSubmenu} timeout={200}>
          <Box component="div">
            <Typography
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                mt: "2rem",
                fontWeight: "bold",
                textTransform: "capitalize",
              }}
            >
              {selectedListItem}
            </Typography>
            <List>
              {subMenuItems.map((listItem, index) => (
                <ListItem
                  sx={{ width: "100%" }}
                  button
                  onClick={() => {
                    handleSubMenuChange(listItem);
                  }}
                  key={index}
                >
                  <ListItemIcon>{iconList[listItem.menu_e_name]}</ListItemIcon>
                  <ListItemText
                    sx={{
                      fontWeight: "600",
                    }}
                    primary={listItem.menu_e_name}
                  />
                  <Divider />
                </ListItem>
              ))}
            </List>
          </Box>
        </Collapse>
      </div>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          color: "white",
          backgroundColor: theme.palette.primary.main,
          height: "95vh",
        }}
        id="main-menu"
      >
        <Box
          sx={{
            width: "5vh",
            padding: "0.3rem",
          }}
        >
          {mainSideList()}
        </Box>

        <Box
          sx={{
            minWidth: "270px",
            width: "fit-content",
            padding: "0.2rem",
            display: !showSubmenu ? "none" : "flex",
            flexDirection: "column",
            gap: ".2rem",
            height: `${100 - mainHeaderHeight - menuTitleHeight}vh`,
            position: "absolute",
            zIndex: "40",
            left: "0",
            backgroundColor: "#1976d2",
            overflowX: "hidden",
            transition: "0.5s",
            marginLeft: "44px",
            color: "gray",
            height: "94vh",
            backgroundColor: "white",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
          }}
          ref={ref}
        >
          {subSideList()}
        </Box>
      </Box>
    </>
  );
};

export default LeftBar;
