import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import dayjs from "dayjs";
import { Typography, Modal, Box } from "@mui/material";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  GridWidthLargeWidth,
  GridWidthMedium,
  AssetPrefix,
  GeneralPrefix,
  dateWidth,
  defaultDateFormate,
  medium,
} from "../../../constants/global";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { getFormattedDate } from "../../../utils/common";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import { useCommonData } from "../../../context/CommonDataProvider";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";

const STORAGE_SERVICE = "S3";

const PhotoPopup = ({ open, handleClose, photoId, assetId }) => {
  const [fullSizeUrl, setFullSizeUrl] = useState("");
  const { rightsArray } = useMenuContext();

  useEffect(() => {
    const fetchFullSizePhoto = async () => {
      try {
        let endpoint;
        if (STORAGE_SERVICE === "ONEDRIVE") {
          endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/get-from-onedrive/${assetId}/${photoId}`;
        } else {
          endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/photos/download/${photoId}`;
        }

        const response = await axios.get(endpoint, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Menu_id: rightsArray[0]?.urMenuIdFk,
          },
        });
        const url = URL.createObjectURL(response.data);
        setFullSizeUrl(url);
      } catch (error) {
        console.error("Error fetching full-size photo:", error);
        toast.error("Error loading full-size photo");
      }
    };

    if (open && photoId) {
      fetchFullSizePhoto();
    }

    return () => {
      if (fullSizeUrl) {
        URL.revokeObjectURL(fullSizeUrl);
      }
    };
  }, [open, photoId, assetId, rightsArray]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="photo-popup"
      aria-describedby="full-size-photo"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          maxWidth: "90%",
          maxHeight: "90%",
          overflow: "auto",
        }}
      >
        <img
          src={fullSizeUrl}
          alt="Full-size"
          style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }}
        />
      </Box>
    </Modal>
  );
};

const ThumbnailImage = ({ photoId, assetId, uniqueId }) => {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [error, setError] = useState(false);
  const { rightsArray } = useMenuContext();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    const fetchThumbnail = async () => {
      try {
        let endpoint;
        if (STORAGE_SERVICE === "ONEDRIVE") {
          endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/get-thumbnail-from-onedrive/${assetId}/${photoId}`;
        } else {
          endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/photos/thumbnail/${photoId}`;
        }

        const response = await axios.get(endpoint, {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Menu_id: rightsArray[0]?.urMenuIdFk,
          },
        });
        const url = URL.createObjectURL(response.data);
        setThumbnailUrl(url);
      } catch (error) {
        console.error("Error fetching thumbnail:", error);
        setError(true);
      }
    };

    fetchThumbnail();

    return () => {
      if (thumbnailUrl) {
        URL.revokeObjectURL(thumbnailUrl);
      }
    };
  }, [photoId, assetId, uniqueId, rightsArray]);

  const handleOpenPopup = () => setOpenPopup(true);
  const handleClosePopup = () => setOpenPopup(false);

  if (error) {
    return (
      <img
        src="/path/to/placeholder.jpg"
        alt="Thumbnail unavailable"
        style={{ width: "50px", height: "50px", objectFit: "cover" }}
      />
    );
  }

  return (
    <>
      <img
        src={thumbnailUrl}
        alt="Thumbnail"
        style={{
          width: "50px",
          height: "50px",
          objectFit: "cover",
          cursor: "pointer",
        }}
        onClick={handleOpenPopup}
      />
      <PhotoPopup
        open={openPopup}
        handleClose={handleClosePopup}
        photoId={photoId}
        assetId={assetId}
      />
    </>
  );
};

export const AssetPhotoUpload = ({
  assetId,
  selectedView,
  photoColumns,
  setPhotoColumns,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const [showPhotoForm, setShowPhotoForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [editDefValues, setEditDefValues] = useState();
  const [showAssetPhotoEditForm, setShowAssetPhotoEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectFile, setSelectFile] = useState(null);
  const [photoSizeError, setPhotoSizeError] = useState(false);
  const [editData, setEditData] = useState();
  const [uniqueIds, setUniqueIds] = useState({});
  const commonData = useCommonData();
  const tenantId = commonData?.userData?.tenant_id;
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [viewLoaded, setViewLoaded] = useState(true);
  const { columnConfig, setColumnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();
  // const handleRestoreButtonClick = () => {
  //   setOpenRestoreDialog(true);
  // };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const handlePhotoEditClick = () => {
    setShowAssetPhotoEditForm(true);
    setId(editData.id);
    setEditDefValues(editData);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const {
    mutateAsync: addAssetPhoto,
    isSuccess: isAssetPhotoSuccess,
    isLoading: isPhotoLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/photos`,
    method: "post",
    contentType: "multipart/form-data",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateAssetPhoto,
    isSuccess: isUpdateSuccess,
    isLoading: updatePhotoLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/photos?photoId=${id}`,
    method: "put",
    contentType: "multipart/form-data",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: deleteAssetPhoto,
    isSuccess: isDeleteSuccess,
    isLoading: deletePhotoLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/photos?photoId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    isSuccess: isPhotoFetchSuccess,
    isLoading: isPhotoFetching,
    refetch,
    data: assetPhotos,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/photos?refId=${assetId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetPhotoSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetPhotoSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    setRows(() => {
      return (
        isPhotoFetchSuccess &&
        canRead &&
        assetPhotos?.map((w) => {
          return { id: w.photoId, ...w };
        })
      );
    });
  }, [assetPhotos, isPhotoFetchSuccess, rightsArray, canRead]);

  const uploadToStorage = async (file, photoId, description) => {
    if (!file) {
      console.error("No file selected for upload");
      throw new Error("No file selected for upload");
    }

    const formData = new FormData();
    formData.append("file", file);
    formData.append("assetId", assetId);
    formData.append("fileName", file.name);
    formData.append("photoId", photoId);
    formData.append("photoDescription", description || "");
    formData.append("tenantId", tenantId);

    if (STORAGE_SERVICE === "S3") {
      formData.append("photoRefIdFk", assetId);
    }

    let endpoint = "";
    if (STORAGE_SERVICE === "ONEDRIVE") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/photo-upload-to-onedrive`;
    } else if (STORAGE_SERVICE === "S3") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/photos`;
    } else {
      throw new Error("Invalid storage service specified");
    }

    try {
      const response = await axios.post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Menu_id: rightsArray[0]?.urMenuIdFk,
        },
      });

      if (response.status === 200) {
        console.log(`Photo uploaded to ${STORAGE_SERVICE} successfully`);
        if (STORAGE_SERVICE === "ONEDRIVE") {
          const uniqueId = response.data.uniqueId;
          setUniqueIds((prevIds) => ({ ...prevIds, [photoId]: uniqueId }));
        }
        return response;
      } else {
        throw new Error(`Failed to upload photo to ${STORAGE_SERVICE}`);
      }
    } catch (error) {
      console.error(`Error in uploadToStorage (${STORAGE_SERVICE}):`, error);
      throw error;
    }
  };

  const submitPhotoData = async (newAssetPhotoData) => {
    try {
      if (!selectFile) {
        toast.error("No file selected. Please select a file to upload.");
        return;
      }
      if (!newAssetPhotoData.photoDescription) {
        toast.error("Photo description is required.");
        return;
      }

      const response = await uploadToStorage(
        selectFile,
        null,
        newAssetPhotoData.photoDescription
      );

      if (response.status === 200) {
        toast.success(`Photo added successfully to ${STORAGE_SERVICE}`);
        setShowPhotoForm(false);
        setShowAssetPhotoEditForm(false);
        setSelectFile(null);
        refetch(); // Refresh the photo list
      } else {
        toast.error(`Failed to upload photo to ${STORAGE_SERVICE}`);
      }
    } catch (error) {
      console.error("Error in submitPhotoData:", error);
      toast.error(error?.response?.data?.message || "Error uploading photo");
    }
  };

  const tenentId = localStorage.getItem("X-tenant-id");

  const saveUpdateData = async (AssetPhotoDataToSave) => {
    updateAssetPhoto({
      dynamicEndpointSuffix: `&tenentId=${tenentId}&photoDescription=${
        AssetPhotoDataToSave?.photoDescription
      }&photoRefIdFk=${assetId}&photoUpDate=${
        dayjs().toISOString().split("Z")[0]
      }`,
      file: selectFile,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Photo updated successfully");
          setShowAssetPhotoEditForm(false);
          setSelectFile(null);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteAssetPhoto({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Photo deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowPhotoForm(false);
    setShowAssetPhotoEditForm(!showAssetPhotoEditForm);
  };

  const fetchPhoto = (photoId, downloadPhotoName) => {
    let endpoint = "";
    if (STORAGE_SERVICE === "ONEDRIVE") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/get-from-onedrive/${assetId}/${photoId}/${uniqueIds[photoId]}`;
    } else if (STORAGE_SERVICE === "S3") {
      endpoint = `${process.env.REACT_APP_BACKEND_URL}${AssetPrefix}/photos/download/${photoId}`;
    }

    axios
      .get(endpoint, {
        responseType: "blob",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Menu_id: rightsArray[0]?.urMenuIdFk,
        },
      })
      .then((response) => {
        if (STORAGE_SERVICE === "ONEDRIVE") {
          handlePhotoInfo(response.data);
        } else {
          const blobObj = new Blob([response.data]);
          const href = URL.createObjectURL(blobObj);

          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", downloadPhotoName.split("/").slice(1));

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      })
      .catch((error) => {
        console.error("Fetch error:", error);
        toast.error(
          error?.response?.data?.message ||
            `Error fetching photo from ${STORAGE_SERVICE}`
        );
      });
  };

  const handlePhotoInfo = (photoInfo) => {
    if (photoInfo.webUrl) {
      window.open(photoInfo.webUrl, "_blank");
    } else if (photoInfo["@microsoft.graph.downloadUrl"]) {
      window.open(photoInfo["@microsoft.graph.downloadUrl"], "_blank");
    } else {
      toast.error("Unable to access the photo");
    }
  };

  const [columns, setColumns] = useState([
    {
      field: "file",
      headerName: "File",
      type: "file",
      maxLength: 40,
      required: true,
      hide: true,
      width: GridWidthMedium,
    },
    {
      field: "photoDescription",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "photoUrl",
      headerName: "URL",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "photoPath",
      headerName: "Path",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      renderCell: (params) => {
        const onClick = () => {
          fetchPhoto(params.row.photoId, params.row.photoPath);
        };
        return (
          <Typography
            mt={1}
            component="a"
            sx={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
              fontSize: "0.875rem",
            }}
            variant="body1"
            onClick={onClick}
          >
            {params.value}
          </Typography>
        );
      },
      width: GridWidthLargeWidth,
    },
    {
      field: "photoUpDate",
      headerName: "Date",
      type: "text",
      textFieldWidth: dateWidth,
      value: dayjs().format(defaultDateFormate),
      valueFormatter: (params) => getFormattedDate(params.value),
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      width: 70,
      renderCell: (params) => (
        <ThumbnailImage
          photoId={params.row.photoId}
          assetId={assetId}
          uniqueId={params.row.uniqueId}
        />
      ),
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }

    try {
      let configColumns = [];

      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.photo_upload) {
        configColumns = updatedConfig.sub_component.photo_upload;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === "object") {
        configColumns = Object.entries(updatedConfig).map(
          ([field, details]) => ({
            name: field,
            title: details?.headerName,
            visible: details?.visible,
            width: details?.width ? `${details.width}px` : undefined,
          })
        );
      }

      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }

      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });

      setColumns(newColumns);

      const configToUpdate = {
        ...columnConfig,
        category: "asset",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          photo_upload: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };

      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.photo_upload
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.photo_upload
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (photoColumns) {
      setColumns(photoColumns);
    } else {
      fetchColumnConfig();
    }
  }, [photoColumns, columnConfig, defaultConfig, assetId]);

  const handleChange = (event) => {
    // Check if event and event.target exist
    if (!event || !event.target) {
      console.error("Invalid event object");
      return;
    }

    // Check if files exist and have length
    const files = event.target.files;
    if (!files || files.length === 0) {
      setSelectFile(null);
      setPhotoSizeError(false);
      return;
    }

    const file = files[0];

    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      toast.error("Please select a valid image file (JPEG, PNG, or GIF)");
      setSelectFile(null);
      return;
    }

    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > 10) {
      setPhotoSizeError(true);
      toast.error("Image size should not exceed 10MB");
      setSelectFile(null);
      return;
    }

    setPhotoSizeError(false);
    setSelectFile(file);
  };

  const onAdd = () => {
    setShowPhotoForm(true);
    setShowAssetPhotoEditForm(true);
    setEditDefValues({});
    setSelectFile([]);
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handlePhotoEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={() => handleOpenColumnPopup()}
        editId={id}
        isLoading={isPhotoFetching}
        columns={columns}
        rows={rows}
        label={"Photo"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"photoDescription"}
      />

      {showAssetPhotoEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showPhotoForm}
          setShowForm={setShowPhotoForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitPhotoData}
          editId={id}
          photoSizeError={photoSizeError}
          handleFileUpload={handleChange}
          defaultValues={editDefValues}
          selectFile={selectFile}
          label={"Photo Upload"}
          isLoading={updatePhotoLoading || isPhotoLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Asset Photo data?"}
        handleApprove={handleDeleteClick}
        isLoading={deletePhotoLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="asset"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
