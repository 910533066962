import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import numeral from "numeral";
import {
  AssetPrefix,
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  dateWidth,
  small,
  defaultNumberFormateAmount,
} from "../../../constants/global";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "./CommonBox";
import { getFormattedDate } from "../../../utils/common";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import { useCommonData } from "../../../context/CommonDataProvider";
import useColumnConfigStore from "../columnConfigStore";

export const Depreciation = ({
  asstId,
  depreciationColumns,
  setDepreciationColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const commonData = useCommonData();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showDepreciationForm, setShowDepreciationForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showDepreciationEditForm, setShowDepreciationEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();
  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addAssetDepreciation,
    isSuccess: isAssetDepreciationSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/depreciation/addDepreciationDetails`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetDepreciation,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/depreciation?depreciationId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteAssetDepreciation,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/depreciation?depreciationId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetDepreciationData,
    isFetchedAfterMount: isFetch,
    isFetching: isDepreciationFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/depreciation?assetId=${asstId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetDepreciationSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetDepreciationSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    if (isFetch && canRead) {
      setRows(() =>
        getAssetDepreciationData?.map((w) => {
          return { id: w.atdepId, ...w };
        })
      );
    }
  }, [isFetch, getAssetDepreciationData, rightsArray, canRead]);

  const handleAssetDepreciationEditClick = () => {
    setShowDepreciationEditForm(true);
    setId(editData.id);
    setEditDefValues(editData);
  };

  const saveUpdateData = async (AssetDepreciationDataToSave) => {
    updateAssetDepreciation({
      id: AssetDepreciationDataToSave.atdepId,
      ...AssetDepreciationDataToSave,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Depreciation updated successfully");
          setShowDepreciationEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAssetDepreciationData = async (newAssetDepreciationData) => {
    addAssetDepreciation({
      atdepAsstIdFk: asstId,
      ...newAssetDepreciationData,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Depreciation Added successfully");
          setShowDepreciationEditForm(!showDepreciationEditForm);
          setShowDepreciationForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteAssetDepreciation()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Depreciation Deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowDepreciationEditForm(!showDepreciationEditForm);
    setShowDepreciationForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "atdepDateEnding",
      headerName: "Date Ending",
      required: true,
      type: "Date",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "atdepPercent",
      headerName: "Percent",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateAmount);
      },
    },
    {
      field: "atdepValue",
      headerName: "Value",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateAmount);
      },
    },
    {
      field: "atdepMonths",
      headerName: "Months",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "atdepRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 40,
      width: GridWidthLargeWidth,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.depreciation || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.depreciation)
    ) {
      configColumns = updatedConfig.sub_component.depreciation;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "asset",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        depreciation: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.depreciation
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.depreciation
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      // Check if there are any changes in the transformed columns
      const hasChanges = transformedColumns.some(
        (col, index) =>
          col.hide !== columns[index].hide ||
          col.headerName !== columns[index].headerName ||
          col.width !== columns[index].width
      );

      if (!hasChanges) {
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        const visibleColumns = transformedColumns.filter((col) => !col.hide);

        if (visibleColumns.length === 0) {
          console.warn(
            "All columns are hidden. Displaying all columns by default."
          );
          setColumns(columns.map((col) => ({ ...col, hide: false })));
        } else {
          setColumns(transformedColumns);
        }
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (depreciationColumns) {
      setColumns(depreciationColumns);
    } else {
      fetchColumnConfig();
    }
  }, [depreciationColumns, columnConfig, defaultConfig, asstId]);

  const onAdd = () => {
    setShowDepreciationEditForm(true);
    setShowDepreciationForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleAssetDepreciationEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        editId={id}
        rows={rows}
        isLoading={isDepreciationFetching}
        label="Depreciation"
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        sortingField={"atdepDateEnding"}
        urAdmin={rightsArray[0]?.urAdmin}
      />

      {showDepreciationEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showDepreciationForm}
          setShowForm={setShowDepreciationForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetDepreciationData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Depreciation"
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        isLoading={isDeleteLoading}
        title={"Are you sure you want to delete Depreciation data?"}
        handleApprove={handleDeleteClick}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="Asset"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
