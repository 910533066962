import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  GridWidthLargeWidth,
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  small,
  workOrderStatusOptions,
  GeneralPrefix,
} from "../../../constants/global";
import { useCommonData } from "../../../context/CommonDataProvider";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../../utils/common";
import useFetchApi from "../../../hooks/useFetchApi";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const Testing = ({ workOrderId, testingColumns, selectedView }) => {
  const { rightsArray } = useMenuContext();
  const [id, setId] = useState(0);
  const { employeDetails, getTestingData } = useCommonData();
  const [editDefValues, setEditDefValues] = useState();
  const [showTestingForm, setShowTestingForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showTestingEditForm, setShowTestingEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addWorkOrderTesting,
    isSuccess: isWorkOrderTestingSuccess,
    isLoading: isTestLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/testing/addtest`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderTesting,
    isSuccess: isUpdateSuccess,
    isLoading: updateTestLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/testing/put?testingId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderTesting,
    isSuccess: isDeleteSuccess,
    isLoading: deleteTestingLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/testing/delete?testingId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    isSuccess: isTestingFetchSuccess,
    isFetching: isTestFetching,
    isFetchedAfterMount: isFetch,
    refetch,
    data: workOrderTesting,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/testing?woId=${workOrderId}`,
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderTestingSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderTestingSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getWoTestingData, setGetWoTestingData] = useState(workOrderTesting);
  useEffect(() => {
    if (workOrderTesting) {
      const testingData = workOrderTesting.map((item) => {
        const employeData = mapDropdownValueToLabelWithId(
          employeDetails,
          item.wotTestBy
        );
        const testData = mapDropdownValueToLabelWithId(
          getTestingData,
          item.wotTestIdFk
        );
        const statusData = mapDropdownValueToLabelWithId(
          workOrderStatusOptions,
          parseInt(item.wotStatus)
        );
        return {
          ...item,
          wotTestBy: employeData,
          wotStatus: statusData,
          wotTestIdFk: testData,
        };
      });
      setGetWoTestingData(testingData);
    }
  }, [workOrderTesting, employeDetails, getTestingData]);

  useEffect(() => {
    if (isFetch && getWoTestingData) {
      setRows(() => {
        return (
          isTestingFetchSuccess &&
          getWoTestingData?.map((w) => {
            return { id: w.wotId, ...w };
          })
        );
      });
    }
  }, [isFetch, getWoTestingData, isTestingFetchSuccess]);

  const handleWorkOrderTestingEditClick = (event, data) => {
    setShowTestingEditForm(true);
    setId(editData.id);
    const employeData = mapDropdownValueToIdWithLabel(
      employeDetails,
      editData.wotTestBy
    );
    const statusData = mapDropdownValueToIdWithLabel(
      workOrderStatusOptions,
      editData.wotStatus
    );
    const testData = mapDropdownValueToIdWithLabel(
      getTestingData,
      editData.wotTestIdFk
    );
    setEditDefValues({
      ...editData,
      wotTestBy: employeData,
      wotStatus: statusData,
      wotTestIdFk: testData,
    });
  };

  const saveUpdateData = async (WorkOrderTestingDataToSave) => {
    updateWorkOrderTesting({
      id: WorkOrderTestingDataToSave.wotId,
      ...WorkOrderTestingDataToSave,
      wotTestIdFk: WorkOrderTestingDataToSave?.wotTestIdFk?.id,
      wotTestBy: WorkOrderTestingDataToSave?.wotTestBy?.id,
      wotStatus: WorkOrderTestingDataToSave?.wotStatus?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Testing updated successfully");
          setShowTestingEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitTestingData = async (newWorkOrderTestingData) => {
    addWorkOrderTesting({
      wotWoIdFk: workOrderId,
      ...newWorkOrderTestingData,
      wotTestIdFk: newWorkOrderTestingData?.wotTestIdFk?.id,
      wotTestBy: newWorkOrderTestingData?.wotTestBy?.id,
      wotStatus: newWorkOrderTestingData?.wotStatus?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Testing added successfully");
          setShowTestingEditForm(!showTestingEditForm);
          setShowTestingForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderTesting({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Testing delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTestingEditForm(!showTestingEditForm);
    setShowTestingForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "wotTestIdFk",
      headerName: "Test",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getTestingData",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "wotRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "wotTestBy",
      headerName: "Completed By",
      type: "select",
      textFieldWidth: medium,
      options: employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "wotStatus",
      headerName: "Status",
      type: "select",
      textFieldWidth: medium,
      options: workOrderStatusOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "wotScore1",
      headerName: "Score 1",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore2",
      headerName: "Score 2",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore3",
      headerName: "Score 3",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore4",
      headerName: "Score 4",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore5",
      headerName: "Score 5",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns =
      updatedConfig.sub_component?.wot_conditional_testing || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.wot_conditional_testing)
    ) {
      configColumns = updatedConfig.sub_component.wot_conditional_testing;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        wot_conditional_testing: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.wot_conditional_testing
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.wot_conditional_testing
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => !col.hide);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (testingColumns) {
      setColumns(testingColumns);
    } else {
      fetchColumnConfig();
    }
  }, [testingColumns, columnConfig, defaultConfig, workOrderId]);

  const onAdd = () => {
    setShowTestingEditForm(true);
    setShowTestingForm(true);
    setEditDefValues({});
  };
  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleWorkOrderTestingEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isTestFetching}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Condition Testing"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"wotTestIdFk"}
      />

      {showTestingEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showTestingForm}
          setShowForm={setShowTestingForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitTestingData}
          editId={id}
          defaultValues={editDefValues}
          label={"Testing"}
          isLoading={updateTestLoading || isTestLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Testing?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteTestingLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
