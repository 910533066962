import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  TextField,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Collapse from "@mui/material/Collapse";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import DeleteDialog from "../../common/DeleteDialog";
import { useCommonData } from "../../context/CommonDataProvider";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import { WorkOrderPrefix } from "../../constants/global";
import { useMenuContext } from "../../context/MenuContextProvider";
import { toast, ToastContainer } from "react-toastify";

export default function MaterialGroup() {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const canWrite = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const [deleteId, setDeleteId] = useState();
  const [branchId, setBranchId] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteChildDialogOpen, setDeleteChildDialogOpen] = useState(false);
  const [branches, setBranches] = useState([
    {
      name: "Branch 1",
      children: [],
      showInput: false,
      showPopUP: false,
      showChildren: false,
    },
  ]);
  const {
    data: materialGroupData,
    refetch: refetchData,
    isFetching: isUserFetching,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/material-group?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && plantId ? true : false,
    retry: 0,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  useEffect(() => {
    if (materialGroupData && canRead) {
      setBranches(
        materialGroupData?.map((item) => ({
          ...item,
          showInput: false,
          showPopUP: false,
          showChildren: item.mtrlgId === branchId ? true : false,
        }))
      );
    }
  }, [branchId, canRead, materialGroupData, rightsArray]);
  const { mutateAsync: addMaterialGroup, isLoading } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-group/AddMaterialGroup`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,

    showError: false,
  });
  const { mutateAsync: deleteMaterialGroup } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-group`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
    showError: false,
  });

  const [isAddingBranch, setIsAddingBranch] = useState(false);
  const [newBranchName, setNewBranchName] = useState("");

  const [popupIndex, setPopupIndex] = useState(-1);

  const [openmenu, setOpen] = React.useState(true);

  const toggleInput = (index) => {
    const newBranches = [...branches];
    newBranches[index].showInput = !newBranches[index].showInput;
    newBranches[index].showChildren = true;
    setBranches(newBranches);
  };

  const togglePopup = (index, event) => {
    event.preventDefault();
    setPopupIndex(index);
  };
  const addChild = async (index, value, parentId) => {
    addMaterialGroup({
      mtrlgParentId: parentId,
      mtrlgDescription: value,
      mtrlgPlantIdFk: plantId,
    })
      .then((response) => {
        if (response.status === 200) {
          refetchData();
          commonData.refetchMaterialGroup();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const removeChild = async (gId, bId) => {
    setBranchId(gId);
    deleteMaterialGroup({
      mtrlgId: bId,
    })
      .then((response) => {
        if (response.status === 200) {
          refetchData();
          commonData.refetchMaterialGroup();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleDeleteConfirm = () => {
    if (deleteId !== undefined) {
      removeBranch(branches[deleteId].mtrlgId);
      setDeleteId(undefined);
      setDeleteDialogOpen(false);
    }
  };

  const handleDeleteChileConfirm = () => {
    removeChild(deleteId, branchId);
    setDeleteChildDialogOpen(false);
  };

  const removeBranch = async (gId) => {
    deleteMaterialGroup({
      mtrlgId: gId,
    })
      .then((response) => {
        if (response.status === 200) {
          refetchData();
          setPopupIndex(-1);
          commonData.refetchMaterialGroup();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.status === 400) {
          toast.error("Cannot delete. This material group has child branches.");
        } else {
          toast.error(error);
        }
      });
  };

  const handleKeyPress = (event, index, parentId) => {
    if (event.key === "Enter") {
      addChild(index, event.target.value, parentId);
      event.target.value = "";
      setBranchId(parentId);
    }
  };
  const handleAddBranch = async () => {
    if (newBranchName.trim() !== "") {
      addMaterialGroup({
        mtrlgPlantIdFk: plantId,
        mtrlgDescription: newBranchName,
      })
        .then((response) => {
          if (response.status === 200) {
            setNewBranchName("");
            refetchData();
            commonData.refetchMaterialGroup();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 404) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ToastContainer />
      <Box
        p={8}
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
      >
        <div>
          {isAddingBranch ? (
            <>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <ClickAwayListener
                    onClickAway={() => {
                      setIsAddingBranch(false);
                      setNewBranchName("");
                    }}
                  >
                    <TextField
                      type="text"
                      autoFocus
                      placeholder="Enter branch name"
                      value={newBranchName}
                      onChange={(e) => setNewBranchName(e.target.value)}
                    />
                  </ClickAwayListener>
                </Grid>
                <Grid item xs={12}>
                  <Button onClick={handleAddBranch} disabled={!canWrite}>
                    Add Branch
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Button onClick={() => setIsAddingBranch(true)}>Add Branch</Button>
          )}
        </div>
        <List>
          {branches?.map(
            (branch, branchIndex) =>
              branch.mtrlgParentId === null && (
                <>
                  <ListItemButton
                    onContextMenu={(event) => {
                      setAnchorEl(event.currentTarget);
                      togglePopup(branchIndex, event);
                    }}
                    onClick={() => {
                      setOpen(!openmenu);
                      const newBranches = [...branches];
                      newBranches[branchIndex].showChildren =
                        !newBranches[branchIndex].showChildren;
                      setBranchId(
                        newBranches[branchIndex].showChildren
                          ? branch.mtrlgId
                          : null
                      );

                      setBranches(newBranches);
                    }}
                  >
                    <ListItemIcon>
                      <DoubleArrowIcon />
                    </ListItemIcon>
                    <ListItemText primary={branch.mtrlgDescription} />
                    {branch?.showChildren ? <ExpandLess /> : <ExpandMore />}
                  </ListItemButton>
                  {popupIndex === branchIndex && (
                    <>
                      {/* <ClickAwayListener onClickAway={handleClose}> */}
                      <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                      >
                        <MenuList>
                          <MenuItem
                            onClick={() => {
                              handleClose();
                              toggleInput(branchIndex);
                            }}
                          >
                            Add Item
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              setDeleteId(branchIndex);

                              setDeleteDialogOpen(true);
                            }}
                          >
                            Remove Branch
                          </MenuItem>
                        </MenuList>
                      </Menu>
                      {/* </ClickAwayListener> */}
                    </>
                  )}
                  {branch.showInput && (
                    <>
                      <ClickAwayListener
                        onClickAway={() => {
                          const newBranches = [...branches];
                          newBranches[branchIndex].showInput = false;
                          setBranches(newBranches);
                        }}
                      >
                        <TextField
                          type="text"
                          focused
                          autoFocus={true}
                          placeholder="Add child..."
                          onKeyPress={(event) =>
                            handleKeyPress(event, branchIndex, branch.mtrlgId)
                          }
                          onClick={(event) => event.stopPropagation()}
                        />
                      </ClickAwayListener>
                    </>
                  )}
                  <Collapse
                    in={branch?.showChildren}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 5 }}>
                        {branch?.showChildren && (
                          <List>
                            {branches
                              .filter(
                                (child) =>
                                  child.mtrlgParentId === branch.mtrlgId
                              )
                              .map((c, childIndex) => (
                                <ListItem
                                  key={childIndex}
                                  onClick={(e) => e.stopPropagation()}
                                  onContextMenu={(e) => e.stopPropagation()}
                                >
                                  <Grid
                                    container
                                    sx={{
                                      width: "300px",
                                    }}
                                  >
                                    <Grid item xs={2}>
                                      <ArrowForwardIcon />
                                    </Grid>
                                    <Grid item xs={9}>
                                      <ListItemText
                                        primary={c.mtrlgDescription}
                                      />
                                    </Grid>{" "}
                                    <Grid item xs={1}>
                                      <IconButton
                                        onClick={(e) => {
                                          // e.stopPropagation();
                                          setDeleteId(branch.mtrlgId);
                                          setBranchId(c.mtrlgId);
                                          setDeleteChildDialogOpen(true);
                                          // handleDeleteChileConfirm(
                                          //   c.mtrlgId,
                                          //   branch.mtrlgId
                                          // );
                                        }}
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                </ListItem>
                              ))}
                          </List>
                        )}
                      </ListItemButton>
                    </List>
                  </Collapse>
                </>
              )
          )}
        </List>
        <DeleteDialog
          open={deleteDialogOpen}
          setOpen={setDeleteDialogOpen}
          title={"Are you sure you want to delete Material Group?"}
          handleApprove={handleDeleteConfirm || handleDeleteChileConfirm}
        />
        <DeleteDialog
          open={deleteChildDialogOpen}
          setOpen={setDeleteChildDialogOpen}
          title={"Are you sure you want to delete Material Group Child?"}
          handleApprove={handleDeleteChileConfirm}
        />
      </Box>
    </>
  );
}
