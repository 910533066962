import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  ListItemIcon,
  MenuItem,
  MenuList,
  Popover,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { Logout, Person, Help } from "@mui/icons-material";
import DoneIcon from "@mui/icons-material/Done";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "./Button";
import { TextInput } from "./TextInput";
import { PaperComponent } from "./PaperComponent";
import useMutationApi from "../hooks/useMutationApi";
import useFetchApi from "../hooks/useFetchApi";
import { ListGroupPrefix } from "../constants/global";
import { useMenuContext } from "../context/MenuContextProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, handleLogout } = props;
  const [openProfileDialog, setOpenProfileDialog] = useState(false);
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [userData, setUserData] = useState(null);
  const { rightsArray } = useMenuContext();

  const { data: fetchedUserData, isFetching } = useFetchApi({
    endpoint: `${ListGroupPrefix}/me`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
    params: { timestamp: Date.now() },
  });

  const { mutateAsync: changePassword } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user/${userData?.id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const profileMethods = useForm({
    defaultValues: {
      userFirstName: "",
      userLastName: "",
      address: "",
      userPhone: "",
      userEmail: "",
    },
  });

  const passwordMethods = useForm({
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if (fetchedUserData) {
      setUserData(fetchedUserData);
      profileMethods.reset({
        userFirstName: fetchedUserData.firstName || "",
        userLastName: fetchedUserData.lastName || "",
        address: fetchedUserData.address || "",
        userPhone: fetchedUserData.userPhone || "",
        userEmail: fetchedUserData.email_id || "",
      });
    }
  }, [fetchedUserData, profileMethods]);

  const handleOpenProfile = () => {
    setOpenProfileDialog(true);
    onClose();
  };

  const handleCloseProfile = () => {
    setOpenProfileDialog(false);
  };

  const handlePasswordChange = () => {
    setOpenPasswordDialog(true);
    handleCloseProfile();
  };

  const handleClosePasswordDialog = () => {
    setOpenPasswordDialog(false);
    passwordMethods.reset();
  };

  const validatePasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasNonalphas = /\W/.test(password);

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    } else if (!(hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas)) {
      return "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.";
    }
    return "";
  };

  const handleSubmitPassword = async (data) => {
    // Check if new password is the same as current password
    if (data.newPassword === data.currentPassword) {
      toast.error("New password must be different from the current password");
      return;
    }

    // Validate password strength
    const strengthError = validatePasswordStrength(data.newPassword);
    if (strengthError) {
      toast.error(strengthError);
      passwordMethods.setError("newPassword", { message: strengthError });
      return;
    }

    // Check if passwords match
    if (data.newPassword !== data.confirmPassword) {
      toast.error("New password and confirm password do not match");
      passwordMethods.setError("confirmPassword", {
        message: "Passwords do not match",
      });
      return;
    }

    try {
      const updatedUserData = {
        ...userData,
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        confirmPassword: data.confirmPassword,
      };
      await changePassword(updatedUserData);
      toast.success("Password changed successfully");
      handleClosePasswordDialog();
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          if (error.response.data.includes("current password is incorrect")) {
            toast.error("Current password is incorrect");
          } else {
            toast.error(error.response.data);
          }
        } else if (error.response.status === 500) {
          toast.error(
            "An error occurred on the server. Please try again later."
          );
        }
      } else {
        toast.error("Failed to change password. Please try again.");
      }
      console.error("Password change error:", error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
        onClose={onClose}
        open={open}
        PaperProps={{ sx: { width: 200 } }}
      >
        {isFetching ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ py: 1.5, px: 2 }}>
              <Typography
                variant="overline"
                sx={{ fontWeight: "bold", textTransform: "capitalize" }}
              >
                {userData?.firstName} {userData?.lastName}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {userData?.email_id}
              </Typography>
            </Box>
            <Divider />
            <MenuList
              disablePadding
              dense
              sx={{
                p: "8px",
                "& > *": {
                  borderRadius: 1,
                },
              }}
            >
              <MenuItem onClick={handleOpenProfile}>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                User Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Sign out
              </MenuItem>
              <MenuItem onClick={() => {}}>
                <ListItemIcon>
                  <Help fontSize="small" />
                </ListItemIcon>
                Help
              </MenuItem>
            </MenuList>
          </>
        )}
      </Popover>

      <Dialog
        open={openProfileDialog}
        onClose={handleCloseProfile}
        fullWidth
        maxWidth="sm"
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          sx={{ backgroundColor: "#1976d2", color: "white" }}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          <Grid container justifyContent="space-between">
            <Grid item>User Profile</Grid>
            <Grid item>
              <Tooltip title="Close">
                <Button
                  sx={{
                    color: "#1976d2",
                    backgroundColor: "white",
                    padding: "4px 16px",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  label={<PhonelinkEraseIcon />}
                  onClick={handleCloseProfile}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <FormProvider {...profileMethods}>
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextInput
                      label="First Name"
                      name="userFirstName"
                      disable={true}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      label="Last Name"
                      name="userLastName"
                      disable={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput label="Address" name="address" disable={true} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput label="Phone" name="userPhone" disable={true} />
                  </Grid>
                  <Grid item xs={6}>
                    <TextInput
                      label="Email"
                      name="userEmail"
                      type="email"
                      disable={true}
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
            <Box sx={{ mt: 3, display: "flex", justifyContent: "flex-end" }}>
              <Button
                variant="contained"
                onClick={handlePasswordChange}
                label="Change Password"
              />
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openPasswordDialog}
        onClose={handleClosePasswordDialog}
        fullWidth
        maxWidth="sm"
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle
          sx={{ backgroundColor: "#1976d2", color: "white" }}
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
        >
          <Grid container justifyContent="space-between">
            <Grid item>Change Password</Grid>
            <Grid item>
              <Tooltip title="Submit">
                <Button
                  sx={{
                    color: "#1976d2",
                    backgroundColor: "white",
                    marginRight: "4px",
                    padding: "4px 16px",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  label={<DoneIcon />}
                  onClick={passwordMethods.handleSubmit(handleSubmitPassword)}
                />
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  sx={{
                    color: "#1976d2",
                    backgroundColor: "white",
                    padding: "4px 16px",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                  label={<PhonelinkEraseIcon />}
                  onClick={handleClosePasswordDialog}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <FormProvider {...passwordMethods}>
              <form>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextInput
                      label="Current Password"
                      name="currentPassword"
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      label="New Password"
                      name="newPassword"
                      type="password"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextInput
                      label="Confirm New Password"
                      name="confirmPassword"
                      type="password"
                      required
                    />
                  </Grid>
                </Grid>
              </form>
            </FormProvider>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  handleLogout: PropTypes.func.isRequired,
  disable: PropTypes.bool,
  currentUsername: PropTypes.string.isRequired,
};
