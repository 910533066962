// Layout constants
export const mainHeaderHeight = 5;
export const subHeaderHeight = 8;
export const menuTitleHeight = 4.5;
export const gridHeight = 25;

// Width constants
export const small = "40%";
export const medium = "50%";
export const dateWidth = "40%";

// Initialize default formats
let defaultDateFormate = "DD-MM-YYYY";
let defaultNumberFormateQty = "#,##,##0.000";
let defaultNumberFormatePrice = "#,##,##0.00";
let defaultNumberFormateAmount = "#,##,##0.00";
let defaultDateTimeFormat = "DD-MM-YYYY HH:mm:ss a";
let defaultTimeFormat = "HH:mm:ss a";

// Function to fetch all app configurations
const fetchAppConfigurations = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      throw new Error("No authentication token found");
    }

    const response = await fetch(
      "http://150.136.208.89:2002/listgroup/app-config",
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Menu_id: "1",
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const configurations = await response.json();

    // Update formats based on API response
    configurations.forEach((config) => {
      switch (config.appConfigP) {
        case "DateFormat":
          defaultDateFormate = config.appValue1 || defaultDateFormate;
          break;
        case "DateTimeFormat":
          defaultDateTimeFormat = config.appValue1 || defaultDateTimeFormat;
          break;
        case "TimeFormat":
          defaultTimeFormat = config.appValue1 || defaultTimeFormat;
          break;
        case "QuantityFormat":
          defaultNumberFormateQty = config.appValue1 || defaultNumberFormateQty;
          break;
        case "PriceFormat":
          defaultNumberFormatePrice =
            config.appValue1 || defaultNumberFormatePrice;
          break;
        case "AmountFormat":
          defaultNumberFormateAmount =
            config.appValue1 || defaultNumberFormateAmount;
          break;
      }
    });
  } catch (error) {
    console.error("Error fetching configurations:", error.message);
  }
};

// Initialize configurations
fetchAppConfigurations();

// Export formats
export {
  defaultDateFormate,
  defaultDateTimeFormat,
  defaultTimeFormat,
  defaultNumberFormateQty,
  defaultNumberFormatePrice,
  defaultNumberFormateAmount,
};

// Options constants
export const deviceTypesOptions = [
  { id: 1, label: "Pressure Monitor" },
  { id: 2, label: "Temperature Monitor" },
  { id: 3, label: "Sound sensor" },
];

export const protocolOptions = [
  { id: 1, label: "MQTT" },
  { id: 2, label: "HTTP" },
];

export const workOrderStatusOptions = [
  { id: 1, label: "Pass" },
  { id: 2, label: "Fail" },
];

export const applicationOption = [{ id: 1, value: "Asset Management" }];

export const uomParentOptions = [
  { id: 1, label: "mtr" },
  { id: 2, label: "km" },
  { id: 3, label: "day" },
  { id: 4, label: "ltr" },
];

export const baseCurrencyOptions = [
  { id: false, label: "false", value: "false" },
  { id: true, label: "true", value: "true" },
];

export const foreignCurrencyOptions = [{ id: 1, label: "USD" }];

export const ticketRequestStatus = [
  { id: 1, label: "Open" },
  { id: 2, label: "Processing" },
  { id: 3, label: "Closed" },
];

export const userStatusOptions = [
  { id: 1, label: "Active" },
  { id: 2, label: "InActive" },
];

// Grid width constants
export const GridWidthSmall = 50;
export const GridWidthMedium = 100;
export const GridWidthLargeWidth = 150;
export const GridWidthExtraLargeWidth = 200;

// Prefix constants
export const ListGroupPrefix = "listgroup";
export const PlantPrefix = "plants";
export const AssetPrefix = "assets";
export const WorkOrderPrefix = "workorder";
export const GeneralPrefix = "general";
export const PreventivePrefix = "pm";
