import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  SwipeableDrawer,
  Typography,
  Tooltip,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import DeleteDialog from "../../common/DeleteDialog";
import { Progressor } from "../../common/Progressor";
import dayjs from "dayjs";
import numeral from "numeral";
import {
  AssetPrefix,
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  GridWidthSmall,
  dateWidth,
  gridHeight,
  medium,
  small,
  deviceTypesOptions,
  protocolOptions,
  defaultNumberFormateAmount,
  defaultNumberFormatePrice,
  defaultNumberFormateQty,
  defaultDateFormate,
} from "../../constants/global";
import { useCommonData } from "../../context/CommonDataProvider";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  getFormattedDate,
  mapDropdownValueToDescWithId,
  mapDropdownValueToIdWithDesc,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
  modifyOptionsWithCodeAndTag,
} from "../../utils/common";
import { AssetManagementMultiSteps } from "./assetManagementSteps/AssetManagementMultiSteps";
import AddEditToolBar from "../../common/AddEditToolBar";
import { AddEditAccordionForm } from "../../common/AddEditAccordionForm";
import { useMenuContext } from "../../context/MenuContextProvider";
import ColumnCustomizationPopup from "./ColumnCustomizationPopup";
import ViewDropdown from "./ViewDropdown";
import useColumnConfigStore from "./columnConfigStore";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";
import CSVImportModal from "../admin/CSVImportModal";

const AssetManagement = () => {
  const [viewLoaded, setViewLoaded] = useState(false);
  const [viewApplied, setViewApplied] = useState(false);
  const commonData = useCommonData();
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [asstId, setAsstId] = useState(null);
  const [editDefValues, setEditDefValues] = useState();
  const [showAssetMasterForm, setShowAssetMasterForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showAssetMasterEditForm, setShowAssetMasterEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const plantId = commonData?.userData?.lastLoginPlantID;
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const userId = commonData.userId;
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const tenantKey = commonData?.userData?.tenant_id;
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const [openReport, setOpenReport] = useState(false);
  const [reportInstance, setReportInstance] = useState(null);
  const [openCSVModal, setOpenCSVModal] = useState(false);

  const handleOpenCSVModal = () => {
    console.log("Opening CSV modal");
    setOpenCSVModal(true);
    console.log("openCSVModal state:", openCSVModal);
  };

  const handleCloseCSVModal = () => {
    setOpenCSVModal(false);
  };

  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const viewerRef = useCallback((instance) => {
    if (instance) {
      setReportInstance(instance);
    }
  }, []);

  async function loadReport() {
    try {
      const module = await import("../report/reports/asset-register.rdlx-json");
      const response = await fetch(module.default);
      const text = await response.text();
      return JSON.parse(text);
    } catch (error) {
      console.error("Error loading report:", error);
      throw error;
    }
  }

  async function loadData() {
    const selectedAsset = rows.find((row) => row.id === asstId);
    if (!selectedAsset) {
      console.error("Selected asset not found");
      return {};
    }

    const transformedRow = {};
    for (const [key, value] of Object.entries(selectedAsset)) {
      transformedRow[key] = value !== null ? String(value) : "";
    }

    return {
      Field1: [transformedRow],
    };
  }

  async function openReports() {
    try {
      const data = await loadData();
      console.log("Data being sent to report:", data);

      const report = await loadReport();
      if (!report || !report.DataSources || report.DataSources.length === 0) {
        console.error("Invalid report structure:", report);
        return;
      }

      report.DataSources[0].ConnectionProperties.ConnectString = `jsondata=${JSON.stringify(
        data
      )}`;
      report.DataSources[0].ConnectionProperties.DataProvider = "JSON";

      const userId = commonData?.userId || "";
      if (reportInstance && reportInstance.Viewer) {
        reportInstance.Viewer.open(report, {
          ReportParams: [
            {
              Name: "User",
              Value: userId,
            },
            {
              Name: "Plant",
              Value: plantId,
            },
          ],
        });
      } else {
        console.error("Report instance or Viewer is not available");
      }
    } catch (error) {
      console.error("Error opening report:", error);
    }
  }

  useEffect(() => {
    if (openReport && reportInstance && asstId) {
      openReports();
    }
  }, [openReport, reportInstance, asstId]);

  const { data: getPlantData } = useFetchApi({
    endpoint: `${GeneralPrefix}/tenant/${tenantKey}/plants`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setAsstId(params.id);
    console.log("Selected asset ID:", params.id);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const showData = () => {
    setOpen(true);
  };

  const {
    mutateAsync: addAssetMaster,
    isSuccess: assetMasterAddDataSuccess,
    isLoading: addingAsset,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/addAsset`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetMaster,
    isSuccess: updateAssetMasterSuccess,
    isLoading: updatingAsset,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/asset?assetId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteAssetMaster,
    isSuccess: deleteAssetMasterSuccess,
  } = useMutationApi({
    endpoint: `${AssetPrefix}?assetId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetMasterDataList,
    isFetchedAfterMount: isFetch,
    isFetching: isAssetLoading,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (
      assetMasterAddDataSuccess ||
      updateAssetMasterSuccess ||
      deleteAssetMasterSuccess
    ) {
      refetch();
    }
  }, [
    assetMasterAddDataSuccess,
    updateAssetMasterSuccess,
    deleteAssetMasterSuccess,
    refetch,
  ]);

  const [getAssetMasterData, setGetAssetMasterData] = useState(
    getAssetMasterDataList
  );
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getAssetDataList,
    "asstTag",
    "asstDesc"
  );
  useEffect(() => {
    if (getAssetMasterDataList) {
      const assetData = getAssetMasterDataList.map((item) => {
        const newAssetStatus = mapDropdownValueToLabelWithListId(
          commonData.assetStatus,
          item.asstStatus
        );
        const newAssetMode = mapDropdownValueToLabelWithListId(
          commonData.assetMode,
          item.asstMode
        );
        const newAssetControlling = mapDropdownValueToLabelWithListId(
          commonData.plantControlling,
          item.plantControlling
        );
        const newAssetCompany = mapDropdownValueToLabelWithListId(
          commonData.plantCompUnit,
          item.plantCompUnit
        );
        const newAssetsBuilding = mapDropdownValueToLabelWithListId(
          commonData.assetsBuilding,
          item.asstBuilding
        );
        const newAssetsZone = mapDropdownValueToLabelWithListId(
          commonData.assetsZone,
          item.asstZone
        );
        const newAssetsDepartment = mapDropdownValueToLabelWithListId(
          commonData.department,
          item.asstDepartment
        );
        const newAssetType = mapDropdownValueToLabelWithListId(
          commonData.assetType,
          item.asstType
        );
        const newAssetMeterType = mapDropdownValueToLabelWithListId(
          commonData.AssetMeterType,
          item.asstMeterType
        );
        const newAssetWarrantyType = mapDropdownValueToLabelWithListId(
          commonData.asst_warrenty_type,
          item.asstWarrentyType
        );
        const newAssetClass = mapDropdownValueToLabelWithListId(
          commonData.assetsClass,
          item.asstClass
        );
        const newParentAsset = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.asstParentId
        );
        const newAssetCategory = mapDropdownValueToLabelWithId(
          commonData.getAssetCategory,
          item.asstAsctCatIdFk
        );
        const newAssetGroup = mapDropdownValueToLabelWithId(
          commonData.getAssetGroupList,
          item.asstAsgpIdFk
        );
        const newAssetManufacture = mapDropdownValueToLabelWithId(
          commonData.getAssetManufacture,
          item.asstManuIdFk
        );
        const newAssetSupplier = mapDropdownValueToLabelWithId(
          commonData.getSupplierList,
          item.asstBusIdFk
        );
        const newAssetUoM = mapDropdownValueToLabelWithId(
          commonData.getUomDataList,
          item.asstRentUomIdFk
        );
        const newCals = mapDropdownValueToLabelWithId(
          commonData.getCalendarSystemData,
          item.asstCalIdFk
        );
        return {
          ...item,
          asstStatus: newAssetStatus,
          asstMode: newAssetMode,
          asstBuilding: newAssetsBuilding,
          asstZone: newAssetsZone,
          asstType: newAssetType,
          asstMeterType: newAssetMeterType,
          assetControlling: newAssetControlling,
          assetCompany: newAssetCompany,
          asstDepartment: newAssetsDepartment,
          asstWarrentyType: newAssetWarrantyType,
          asstClass: newAssetClass,
          asstParentId: newParentAsset,
          asstAsctCatIdFk: newAssetCategory,
          asstAsgpIdFk: newAssetGroup,
          asstManuIdFk: newAssetManufacture,
          asstBusIdFk: newAssetSupplier,
          asstRentUomIdFk: newAssetUoM,
          asstCalIdFk: newCals,
        };
      });
      setGetAssetMasterData(assetData);
    }
  }, [getAssetMasterDataList, commonData]);

  useEffect(() => {
    if (isFetch && getAssetMasterData && canRead) {
      setRows(() =>
        getAssetMasterData?.map((asset) => {
          return { id: asset.asstId, ...asset };
        })
      );
    }
  }, [plantId, isFetch, getAssetMasterData, rightsArray, canRead]);

  const handleAssetEditClick = () => {
    setShowAssetMasterEditForm(true);

    setId(editData.id);
    const newAssetStatus = mapDropdownValueToListIdWithLabel(
      commonData.assetStatus,
      editData.asstStatus
    );
    const newAssetMode = mapDropdownValueToListIdWithLabel(
      commonData.assetMode,
      editData.asstMode
    );
    const newAssetControlling = mapDropdownValueToListIdWithLabel(
      commonData.plantControlling,
      editData.plantControlling
    );
    const newAssetCompany = mapDropdownValueToListIdWithLabel(
      commonData.plantCompUnit,
      editData.plantCompUnit
    );
    const newAssetsBuilding = mapDropdownValueToListIdWithLabel(
      commonData.assetsBuilding,
      editData.asstBuilding
    );
    const newAssetsZone = mapDropdownValueToListIdWithLabel(
      commonData.assetsZone,
      editData.asstZone
    );
    const newAssetsDepartment = mapDropdownValueToListIdWithLabel(
      commonData.department,
      editData.asstDepartment
    );
    const newAssetType = mapDropdownValueToListIdWithLabel(
      commonData.assetType,
      editData.asstType
    );
    const newAssetMeterType = mapDropdownValueToListIdWithLabel(
      commonData.AssetMeterType,
      editData.asstMeterType
    );
    const newAssetWarrantyType = mapDropdownValueToListIdWithLabel(
      commonData.asst_warrenty_type,
      editData.asstWarrentyType
    );
    const newAssetClass = mapDropdownValueToListIdWithLabel(
      commonData.assetsClass,
      editData.asstClass
    );
    const newParentAsset = mapDropdownValueToIdWithDesc(
      modifiedOptions,
      editData.asstParentId
    );
    const newAssetCategory = mapDropdownValueToIdWithLabel(
      commonData.getAssetCategory,
      editData.asstAsctCatIdFk
    );
    const newAssetGroup = mapDropdownValueToIdWithLabel(
      commonData.getAssetGroupList,
      editData.asstAsgpIdFk
    );
    const newAssetManufacture = mapDropdownValueToIdWithLabel(
      commonData.getAssetManufacture,
      editData.asstManuIdFk
    );
    const newAssetSupplier = mapDropdownValueToIdWithLabel(
      commonData.getSupplierList,
      editData.asstBusIdFk
    );
    const newAssetUoM = mapDropdownValueToIdWithLabel(
      commonData.getUomDataList,
      editData.asstRentUomIdFk
    );
    const newCals = mapDropdownValueToIdWithLabel(
      commonData.getCalendarSystemData,
      editData.asstCalIdFk
    );
    setEditDefValues({
      ...editData,
      asstStatus: newAssetStatus,
      asstMode: newAssetMode,
      asstBuilding: newAssetsBuilding,
      asstZone: newAssetsZone,
      asstType: newAssetType,
      asstMeterType: newAssetMeterType,
      assetControlling: newAssetControlling,
      assetCompany: newAssetCompany,
      asstDepartment: newAssetsDepartment,
      asstWarrentyType: newAssetWarrantyType,
      asstClass: newAssetClass,
      asstParentId: newParentAsset,
      asstAsctCatIdFk: newAssetCategory,
      asstAsgpIdFk: newAssetGroup,
      asstManuIdFk: newAssetManufacture,
      asstBusIdFk: newAssetSupplier,
      asstRentUomIdFk: newAssetUoM,
      asstCalIdFk: newCals,
    });
  };

  const saveUpdateData = async (AssetMasterDataToSave) => {
    updateAssetMaster({
      asstPlantIdFk: plantId,
      ...AssetMasterDataToSave,
      units: [],
      asstBusIdFk: AssetMasterDataToSave?.asstBusIdFk?.id
        ? AssetMasterDataToSave?.asstBusIdFk?.id
        : null,
      asstRentUomIdFk: AssetMasterDataToSave?.asstRentUomIdFk?.id
        ? AssetMasterDataToSave?.asstRentUomIdFk?.id
        : null,
      asstCalIdFk: AssetMasterDataToSave?.asstCalIdFk?.id
        ? AssetMasterDataToSave?.asstCalIdFk?.id
        : null,
      asstAsgpIdFk: AssetMasterDataToSave.asstAsgpIdFk?.id,
      asstAsctCatIdFk: AssetMasterDataToSave.asstAsctCatIdFk?.id,
      asstBuilding: AssetMasterDataToSave.asstBuilding?.listId,
      asstClass: AssetMasterDataToSave.asstClass?.listId,
      asstDepartment: AssetMasterDataToSave.asstDepartment?.listId,
      asstManuIdFk: AssetMasterDataToSave.asstManuIdFk?.id,
      asstMeterType: AssetMasterDataToSave.asstMeterType?.listId,
      asstMode: AssetMasterDataToSave.asstMode?.listId,
      asstParentId: AssetMasterDataToSave.asstParentId?.asstId,
      asstStatus: AssetMasterDataToSave.asstStatus?.listId,
      asstType: AssetMasterDataToSave.asstType?.listId,
      asstWarrentyType: AssetMasterDataToSave.asstWarrentyType?.listId,
      asstZone: AssetMasterDataToSave.asstZone?.listId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset Updated successfully");
          setShowAssetMasterEditForm(false);
          setId();
          commonData.refetchAssets();
          setAsstId();
        } else if (response.status === 208) {
          toast.error(response?.data?.message, {
            autoClose: false,
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAddAssetMasterData = async (newWorkOrderAddData) => {
    addAssetMaster({
      ...newWorkOrderAddData,
      units: [],
      asstPlantIdFk: plantId,
      asstBusIdFk: newWorkOrderAddData?.asstBusIdFk?.id
        ? newWorkOrderAddData?.asstBusIdFk?.id
        : null,
      asstRentUomIdFk: newWorkOrderAddData?.asstRentUomIdFk?.id
        ? newWorkOrderAddData?.asstRentUomIdFk?.id
        : null,
      asstCalIdFk: newWorkOrderAddData?.asstCalIdFk?.id
        ? newWorkOrderAddData?.asstCalIdFk?.id
        : null,
      asstAsgpIdFk: newWorkOrderAddData.asstAsgpIdFk?.id,
      asstAsctCatIdFk: newWorkOrderAddData.asstAsctCatIdFk?.id,
      asstBuilding: newWorkOrderAddData.asstBuilding?.listId,
      asstClass: newWorkOrderAddData.asstClass?.listId,
      asstDepartment: newWorkOrderAddData.asstDepartment?.listId,
      asstManuIdFk: newWorkOrderAddData.asstManuIdFk?.id,
      asstMeterType: newWorkOrderAddData.asstMeterType?.listId,
      asstMode: newWorkOrderAddData.asstMode?.listId,
      asstParentId: newWorkOrderAddData.asstParentId?.asstId,
      asstStatus: newWorkOrderAddData.asstStatus?.listId,
      asstType: newWorkOrderAddData.asstType?.listId,
      asstWarrentyType: newWorkOrderAddData.asstWarrentyType?.listId,
      asstZone: newWorkOrderAddData.asstZone?.listId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset Added successfully");
          setShowAssetMasterForm(false);
          setShowAssetMasterEditForm(!showAssetMasterEditForm);
          commonData.refetchAssets();
        } else if (response.status === 208) {
          toast.error(response?.data?.message, {
            autoClose: false,
          });
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteAssetMaster()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset Deleted successfully");
          setOpenDialog(false);
          commonData.refetchAssets();
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          toast.error(error.response.data.message);
          setAsstId();
        } else {
          toast.error("Something went wrong");
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowAssetMasterForm(false);
    setShowAssetMasterEditForm(!showAssetMasterEditForm);
  };

  const GENERAL_FIELDS = [
    {
      field: "asstTag",
      maxLength: 30,
      headerName: "Tag",
      type: "text",
      required: true,
      textFieldWidth: medium,
      section: "General",
      width: GridWidthMedium,
    },
    {
      field: "asstModel",
      headerName: "Model",
      type: "text",
      required: false,
      maxLength: 20,
      apiendpoint: "asset_mode_list",
      textFieldWidth: medium,
      width: GridWidthMedium,
      section: "General",
    },

    {
      field: "asstDesc",
      maxLength: 40,
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstAbbreviation",
      headerName: "Abbreviation",
      type: "text",
      required: false,
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstBarCodeA",
      headerName: "Bar Code",
      type: "text",
      required: false,
      maxLength: 15,
      textFieldWidth: medium,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstPartNo",
      headerName: "Part No",
      type: "text",
      required: false,
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstStatus",
      headerName: "Status",
      type: "select",
      required: true,
      apiendpoint: "assetStatus",
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstParentId",
      headerName: "Parent Asset",
      type: "selectTwo",
      required: false,
      textFieldWidth: small,
      options: modifiedOptions,
      width: GridWidthLargeWidth,
      section: "General",
    },
    {
      field: "asstMode",
      headerName: "Work Mode",
      type: "select",
      required: false,
      apiendpoint: "assetMode",
      maxLength: 20,
      textFieldWidth: small,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstPnId",
      headerName: "P & Id",
      type: "text",
      required: false,
      textFieldWidth: medium,
      maxLength: 20,
      width: GridWidthMedium,
      section: "General",
    },
    {
      field: "asstDepartment",
      headerName: "Department",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "department",
      width: GridWidthLargeWidth,
      section: "General",
    },
  ];

  const LOCATION_FIELDS = [
    {
      field: "asstBuilding",
      headerName: "Building",
      type: "select",
      required: false,
      apiendpoint: "assetsBuilding",
      maxLength: 20,
      textFieldWidth: medium,
      section: "Location",
      width: GridWidthMedium,
    },
    {
      field: "asstZone",
      headerName: "Zone",
      type: "select",
      required: false,
      apiendpoint: "assetsZone",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "asstLocDesc",
      headerName: "Location",
      type: "text",
      required: false,
      maxLength: 40,
      width: GridWidthMedium,
    },
  ];

  const CATEGORY_FIELDS = [
    {
      field: "asstAsctCatIdFk",
      headerName: "Asset Category",
      type: "select",
      required: false,
      textFieldWidth: medium,
      section: "Category",
      apiendpoint: "getAssetCategory",
      width: GridWidthMedium,
    },
    {
      field: "asstAsgpIdFk",
      headerName: "Asset Group",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "getAssetGroupList",
      width: GridWidthMedium,
    },
    {
      field: "asstType",
      headerName: " Asset Type",
      apiendpoint: "assetType",
      type: "select",
      required: true,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "asstManuIdFk",
      headerName: "Manufacture",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "getAssetManufacture",
      width: GridWidthMedium,
    },
    {
      field: "asstClass",
      headerName: "Class",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "assetsClass",
      width: GridWidthMedium,
    },
  ];

  const METER_DETAILS = [
    {
      field: "asstMeterType",
      headerName: "Meter Type",
      type: "select",
      required: false,
      apiendpoint: "AssetMeterType",
      size: "medium",
      textFieldWidth: medium,
      section: "Meter Details",
      width: GridWidthMedium,
    },
    {
      field: "asstCurrentReading",
      headerName: "Current Reading",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "asstCalIdFk",
      headerName: "Calendar",
      type: "select",
      required: false,
      textFieldWidth: medium,
      width: GridWidthMedium,
      apiendpoint: "getCalendarSystemData",
    },
  ];
  const WARRANTY_INFO = [
    {
      field: "asstManufactDate",
      headerName: "Date Manufactured",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      section: "Warranty Info",
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "asstPurchasedDate",
      headerName: "Date Purchased",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "asstExpiryDate",
      headerName: "Expiry Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },

    {
      field: "asstAgreementDetails",
      headerName: "Agreement No",
      type: "text",
      required: false,
      maxLength: 12,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "asstWarrentyType",
      headerName: "Warranty Type",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "asst_warrenty_type",
      width: GridWidthMedium,
    },
    {
      field: "asstSlaDoc",
      headerName: "SLA Doc",
      type: "text",
      required: false,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "asstBusIdFk",
      headerName: "Supplier",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "getSupplierList",
      width: GridWidthMedium,
    },
    {
      field: "asstSupplierDetails",
      headerName: "Support Details",
      type: "text",
      required: false,
      width: GridWidthMedium,
    },
    {
      field: "asstSerialNo",
      headerName: "Serial #",
      type: "text",
      required: false,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "asstBatchNumber",
      headerName: "Batch #",
      type: "text",
      required: false,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
  ];
  const COST = [
    {
      field: "asstBaseCost",
      headerName: "Base Cost",
      type: "number",
      required: false,
      textFieldWidth: small,
      section: "Cost",
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "asstTax",
      headerName: "Tax %",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "asstDepriciationP",
      headerName: "Depreciation %",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "asstInstalledDate",
      headerName: "Date Installed",
      type: "Date",
      required: false,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "asstSalvageValue",
      headerName: "Salvage Value",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "asstLife",
      headerName: "Life(years)",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "asstRecoveryPeriod",
      headerName: "Recovery Period",
      type: "number",
      required: false,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "asstSaleValue",
      headerName: "Sale Value",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "asstRetiredDate",
      headerName: "Retired Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "asstRentalPrice",
      headerName: "Rental Price",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },

    {
      field: "asstRentUomIdFk",
      headerName: "Rent UoM",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "getUomDataList",
      width: GridWidthMedium,
    },
  ];

  const formFields = [
    { label: "General", fields: GENERAL_FIELDS },
    { label: "Location", fields: LOCATION_FIELDS },
    { label: "Category", fields: CATEGORY_FIELDS },
    { label: "Meter Details", fields: METER_DETAILS },
    { label: "Warranty Info", fields: WARRANTY_INFO },
    { label: "Cost", fields: COST },
  ];
  const allcolumns = [
    ...GENERAL_FIELDS,
    ...LOCATION_FIELDS,
    ...CATEGORY_FIELDS,
    ...METER_DETAILS,
    ...WARRANTY_INFO,
    ...COST,
  ];

  const validateEntry = (entry) => {
    let isValid = true;
    let invalidReasons = [];

    allcolumns.forEach((column) => {
      if (column.required && !entry[column.field]) {
        isValid = false;
        invalidReasons.push(`${column.headerName} is required`);
      }
    });

    // Validate Asset Tag (asstTag)
    if (entry.asstTag && entry.asstTag.length > 30) {
      isValid = false;
      invalidReasons.push("Asset Tag must not exceed 30 characters");
    }

    // Validate Asset Description (asstDesc)
    if (entry.asstDesc && entry.asstDesc.length > 255) {
      isValid = false;
      invalidReasons.push("Asset Description must not exceed 255 characters");
    }

    // Validate Asset Category
    if (entry.asstAsctCatIdFk) {
      const validCategories = commonData.getAssetCategory.map((cat) =>
        cat.label.toLowerCase()
      );
      if (!validCategories.includes(entry.asstAsctCatIdFk.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Asset Category: ${entry.asstAsctCatIdFk}`);
      }
    }

    // Validate Asset Group
    if (entry.asstAsgpIdFk) {
      const validGroups = commonData.getAssetGroupList.map((group) =>
        group.label.toLowerCase()
      );
      if (!validGroups.includes(entry.asstAsgpIdFk.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Asset Group: ${entry.asstAsgpIdFk}`);
      }
    }

    // Validate Asset Status
    if (entry.asstStatus) {
      const validStatuses = commonData.assetStatus.map((status) =>
        status.label.toLowerCase()
      );
      if (!validStatuses.includes(entry.asstStatus.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Asset Status: ${entry.asstStatus}`);
      }
    }

    // Validate Asset Type
    if (entry.asstType) {
      const validTypes = commonData.assetType.map((type) =>
        type.label.toLowerCase()
      );
      if (!validTypes.includes(entry.asstType.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Asset Type: ${entry.asstType}`);
      }
    }

    // Validate Department
    if (entry.asstDepartment) {
      const validDepartments = commonData.department.map((dept) =>
        dept.label.toLowerCase()
      );
      if (!validDepartments.includes(entry.asstDepartment.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Department: ${entry.asstDepartment}`);
      }
    }

    //Validate Dates
    const dateFields = [
      "asstManufactDate",
      "asstPurchasedDate",
      "asstExpiryDate",
      "asstInstalledDate",
      "asstRetiredDate",
    ];
    dateFields.forEach((field) => {
      if (
        entry[field] &&
        entry[field].trim() !== "" &&
        !isValidDate(entry[field])
      ) {
        isValid = false;
        invalidReasons.push(`Invalid date for ${field}`);
      }
    });

    // // Validate numeric fields
    // const numericFields = [
    //   "asstBaseCost",
    //   "asstTax",
    //   "asstDepriciationP",
    //   "asstSalvageValue",
    //   "asstLife",
    //   "asstRecoveryPeriod",
    //   "asstSaleValue",
    //   "asstRentalPrice",
    // ];
    // numericFields.forEach((field) => {
    //   if (entry[field] && isNaN(Number(entry[field]))) {
    //     isValid = false;
    //     invalidReasons.push(`${field} must be a number`);
    //   }
    // });

    // Validate Meter Type
    if (entry.asstMeterType) {
      const validMeterTypes = commonData.AssetMeterType.map((type) =>
        type.label.toLowerCase()
      );
      if (!validMeterTypes.includes(entry.asstMeterType.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Meter Type: ${entry.asstMeterType}`);
      }
    }

    // Validate Warranty Type
    if (entry.asstWarrentyType) {
      const validWarrantyTypes = commonData.asst_warrenty_type.map((type) =>
        type.label.toLowerCase()
      );
      if (!validWarrantyTypes.includes(entry.asstWarrentyType.toLowerCase())) {
        isValid = false;
        invalidReasons.push(`Invalid Warranty Type: ${entry.asstWarrentyType}`);
      }
    }

    return { isValid, invalidReason: invalidReasons.join(", ") };
  };

  function isValidDate(dateString) {
    if (!dateString || dateString.trim() === "") return true;
    const date = new Date(dateString);
    return !isNaN(date.getTime());
  }

  const validateAndProcessCSVData = (entries) => {
    const validEntries = [];
    const invalidEntries = [];

    entries.forEach((entry) => {
      const { isValid, invalidReason } = validateEntry(entry);

      if (isValid) {
        validEntries.push(entry);
      } else {
        invalidEntries.push({ ...entry, invalidReason });
      }
    });

    return { validEntries, invalidEntries };
  };

  const handleCSVImport = async (entries) => {
    const { validEntries, invalidEntries } = validateAndProcessCSVData(entries);

    if (invalidEntries.length > 0) {
      console.log("Invalid entries:", invalidEntries);
      toast.warn(
        `${invalidEntries.length} entries are invalid. Please check the invalid entries in the modal.`
      );
    }

    if (validEntries.length === 0) {
      toast.error("No valid entries to import.");
      return;
    }

    const processedEntries = validEntries.map((entry) => ({
      asstPlantIdFk: plantId,
      asstTag: entry.asstTag,
      asstDesc: entry.asstDesc,
      asstModel: entry.asstModel,
      asstAbbreviation: entry.asstAbbreviation,
      asstBarCodeA: entry.asstBarCodeA,
      asstPartNo: entry.asstPartNo,
      asstStatus: commonData.assetStatus.find(
        (status) =>
          status.label.toLowerCase() === entry.asstStatus?.toLowerCase()
      )?.listId,
      asstParentId: commonData.getAssetDataList.find(
        (asset) =>
          asset.asstDesc.toLowerCase() === entry.asstParentId?.toLowerCase()
      )?.asstId,
      asstMode: commonData.assetMode.find(
        (mode) => mode.label.toLowerCase() === entry.asstMode?.toLowerCase()
      )?.listId,
      asstPnId: entry.asstPnId,
      asstDepartment: commonData.department.find(
        (dept) =>
          dept.label.toLowerCase() === entry.asstDepartment?.toLowerCase()
      )?.listId,
      asstBuilding: commonData.assetsBuilding.find(
        (building) =>
          building.label.toLowerCase() === entry.asstBuilding?.toLowerCase()
      )?.listId,
      asstZone: commonData.assetsZone.find(
        (zone) => zone.label.toLowerCase() === entry.asstZone?.toLowerCase()
      )?.listId,
      asstLocDesc: entry.asstLocDesc,
      asstAsctCatIdFk: commonData.getAssetCategory.find(
        (cat) =>
          cat.label.toLowerCase() === entry.asstAsctCatIdFk?.toLowerCase()
      )?.listId,
      asstAsgpIdFk: commonData.getAssetGroupList.find(
        (group) =>
          group.label.toLowerCase() === entry.asstAsgpIdFk?.toLowerCase()
      )?.listId,
      asstType: commonData.assetType.find(
        (type) => type.label.toLowerCase() === entry.asstType?.toLowerCase()
      )?.listId,
      asstManuIdFk: commonData.getAssetManufacture.find(
        (manufacture) =>
          manufacture.label.toLowerCase() === entry.asstManuIdFk?.toLowerCase()
      )?.listId,
      asstClass: commonData.assetsClass.find(
        (cls) => cls.label.toLowerCase() === entry.asstClass?.toLowerCase()
      )?.listId,
      asstMeterType: commonData.AssetMeterType.find(
        (meter) =>
          meter.label.toLowerCase() === entry.asstMeterType?.toLowerCase()
      )?.listId,
      asstCurrentReading: entry.asstCurrentReading,
      asstCalIdFk: commonData.getCalendarSystemData.find(
        (cal) => cal.label.toLowerCase() === entry.asstCalIdFk?.toLowerCase()
      )?.listId,
      asstManufactDate:
        entry.asstManufactDate && entry.asstManufactDate.trim() !== ""
          ? entry.asstManufactDate
          : null,
      asstPurchasedDate:
        entry.asstPurchasedDate && entry.asstPurchasedDate.trim() !== ""
          ? entry.asstPurchasedDate
          : null,
      asstExpiryDate:
        entry.asstExpiryDate && entry.asstExpiryDate.trim() !== ""
          ? entry.asstExpiryDate
          : null,
      asstAgreementDetails: entry.asstAgreementDetails,
      asstWarrentyType: commonData.asst_warrenty_type.find(
        (warranty) =>
          warranty.label.toLowerCase() === entry.asstWarrentyType?.toLowerCase()
      )?.listId,
      asstSlaDoc: entry.asstSlaDoc,
      asstBusIdFk: commonData.getSupplierList.find(
        (supplier) =>
          supplier.label.toLowerCase() === entry.asstBusIdFk?.toLowerCase()
      )?.listId,
      asstSupplierDetails: entry.asstSupplierDetails,
      asstSerialNo: entry.asstSerialNo,
      asstBatchNumber: entry.asstBatchNumber,
      asstBaseCost: entry.asstBaseCost,
      asstTax: entry.asstTax,
      asstDepriciationP: entry.asstDepriciationP,
      asstInstalledDate:
        entry.asstInstalledDate && entry.asstInstalledDate.trim() !== ""
          ? entry.asstInstalledDate
          : null,
      asstSalvageValue: entry.asstSalvageValue,
      asstLife: entry.asstLife,
      asstRecoveryPeriod: entry.asstRecoveryPeriod,
      asstSaleValue: entry.asstSaleValue,
      asstRetiredDate:
        entry.asstRetiredDate && entry.asstRetiredDate.trim() !== ""
          ? entry.asstRetiredDate
          : null,
      asstRentalPrice: entry.asstRentalPrice,
      asstRentUomIdFk:
        commonData.getUomDataList.find(
          (uom) =>
            uom.label.toLowerCase() === entry.asstRentUomIdFk?.toLowerCase()
        )?.listId || null,
    }));

    let successCount = 0;
    let errorCount = 0;
    let alreadyExistsCount = 0;
    const errors = [];

    try {
      console.log("UOM Data List: ", commonData.getUomDataList);

      for (let i = 0; i < processedEntries.length; i++) {
        const newAssetData = processedEntries[i];

        try {
          const response = await addAssetMaster(newAssetData);

          if (response.status === 200) {
            successCount++;
          } else if (response.status === 208) {
            // Handle already exists status
            alreadyExistsCount++;
            errors.push({ entry: newAssetData, error: "Asset already exists" });
          }
        } catch (error) {
          console.error("Error adding asset:", error);
          errorCount++;
          errors.push({ entry: newAssetData, error: error.message });
        }
      }

      if (successCount > 0) {
        toast.success(`Successfully imported ${successCount} Asset(s)`);
      }

      if (alreadyExistsCount > 0) {
        toast.warn(
          `Failed to import ${alreadyExistsCount} Asset(s) because they already exist`
        );
      }

      if (errorCount > 0) {
        toast.error(`Failed to import ${errorCount} Asset(s) due to errors`);
        console.log("Import errors:", errors);
      }

      refetch();
      commonData.refetchAssets();
      setOpenCSVModal(false);
    } catch (error) {
      console.error("Error during CSV import:", error);
      toast.error("An error occurred during the import process");
    }
  };

  const addAssetClick = () => {
    setShowAssetMasterEditForm(true);
    setShowAssetMasterForm(true);
    setEditDefValues({});
  };

  const [componentColumns, setComponentColumns] = useState([
    {
      field: "astcCode",
      headerName: "Component Code",
      required: true,
      type: "text",
      maxLength: 12,
      textFieldWidth: small,
      width: GridWidthLargeWidth,
    },
    {
      field: "astcDesc",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "astcQty",
      headerName: "Quantity",
      type: "number",
      maxLength: 40,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateQty);
      },
    },
    {
      field: "astcAstuIdFk",
      headerName: "Asset",
      type: "selectTwo",
      options: modifiedOptions,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
  ]);

  const [depreciationColumns, setDepreciationColumns] = useState([
    {
      field: "atdepDateEnding",
      headerName: "Date Ending",
      required: true,
      type: "Date",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "atdepPercent",
      headerName: "Percent",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateAmount);
      },
    },
    {
      field: "atdepValue",
      headerName: "Value",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateAmount);
      },
    },
    {
      field: "atdepMonths",
      headerName: "Months",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "atdepRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 40,
      width: GridWidthLargeWidth,
    },
  ]);

  const [warrantyColumns, setWarrantyColumns] = useState([
    {
      field: "astwWarrantyType",
      headerName: "Warranty Type",
      required: true,
      type: "select",
      apiendpoint: "asst_warrenty_type",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
    {
      field: "astwDateStart",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: medium,
      required: true,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "astwDateExpiry",
      headerName: "Date Expiry",
      type: "Date",
      textFieldWidth: medium,
      required: true,
      minDateLabel: "astwDateStart",
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "astwAgrDetails",
      headerName: "Agreement #",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "astwRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 200,
      row: 4,
      multiline: "multiline",
      width: GridWidthLargeWidth,
    },
  ]);

  const [transferColumns, setTransferColumns] = useState([
    {
      field: "astrDate",
      headerName: "Date",
      type: "Date",
      required: true,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "plantId",
      headerName: "Plant",
      type: "select",
      textFieldWidth: small,
      required: true,
      options: getPlantData?.map((o) => ({
        label: o.plantDesc,
        id: o.plantId,
        width: GridWidthMedium,
      })),
    },
    {
      field: "astrBuildIdFk",
      headerName: "Building",
      type: "select",
      required: true,
      apiendpoint: "assetsBuilding",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "astrZoneIdFk",
      headerName: "Zone",
      type: "select",
      apiendpoint: "assetsZone",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "astrRemarks",
      headerName: "Remarks",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
  ]);

  const [adocColumns, setAdocColumns] = useState([
    {
      field: "file",
      headerName: "File",
      type: "file",
      maxLength: 40,
      required: true,
      hide: true,
      width: GridWidthMedium,
    },
    {
      field: "docDescription",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "docUrl",
      headerName: "URL",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "docPath",
      headerName: "Path",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      // renderCell: (params) => {
      //   const onClick = () => {
      //     fetchDocument(params.row.docId, params.row.docPath);
      //   };

      //           return (
      //     <Typography
      //       mt={1}
      //       component="a"
      //       sx={{
      //         color: "blue",
      //         textDecoration: "underline",
      //         cursor: "pointer",
      //         fontSize: "0.875rem",
      //       }}
      //       variant="body1"
      //       onClick={onClick}
      //     >
      //       {params.value}
      //     </Typography>
      //   );
      // },
      width: GridWidthLargeWidth,
    },
    {
      field: "docUpDate",
      headerName: "Date",
      type: "text",
      textFieldWidth: dateWidth,
      value: dayjs().format(defaultDateFormate),
      valueFormatter: (params) => getFormattedDate(params.value),
      disable: true,
      width: GridWidthMedium,
    },
  ]);

  const [photoColumns, setPhotoColumns] = useState([
    {
      field: "file",
      headerName: "File",
      type: "file",
      maxLength: 40,
      required: true,
      hide: true,
      width: GridWidthMedium,
    },
    {
      field: "photoDescription",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "photoUrl",
      headerName: "URL",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "photoPath",
      headerName: "Path",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      // renderCell: (params) => {
      //   const onClick = () => {
      //     fetchPhoto(params.row.photoId, params.row.photoPath);
      //   };
      //   return (
      //     <Typography
      //       mt={1}
      //       component="a"
      //       sx={{
      //         color: "blue",
      //         textDecoration: "underline",
      //         cursor: "pointer",
      //         fontSize: "0.875rem",
      //       }}
      //       variant="body1"
      //       onClick={onClick}
      //     >
      //       {params.value}
      //     </Typography>
      //   );
      // },
      width: GridWidthLargeWidth,
    },
    {
      field: "photoUpDate",
      headerName: "Date",
      type: "text",
      textFieldWidth: dateWidth,
      value: dayjs().format(defaultDateFormate),
      valueFormatter: (params) => getFormattedDate(params.value),
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      width: 70,
      // renderCell: (params) => (
      //   <ThumbnailImage
      //     photoId={params.row.photoId}
      //     assetId={assetId}
      //     uniqueId={params.row.uniqueId}
      //   />
      // ),
    },
  ]);

  const [awoColumns, setAwoColumns] = useState([
    {
      field: "woNumber",
      headerName: "Work Order No",
      type: "text",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "woDesc",
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "woDate",
      headerName: "Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "woIssuedBy",
      headerName: "Assigned To",
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const [scColumns, setScColumns] = useState([
    {
      field: "itemCount",
      headerName: "Id",
      type: "number",
      width: GridWidthSmall,
    },
    {
      field: "caldDesc",
      headerName: "Description",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "caldDate",
      headerName: "Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "caldCtype",
      headerName: "Type",
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const [reqColumns, setReqColumns] = useState([
    {
      field: "maintId",
      headerName: "ID",
      type: "number",
      width: GridWidthSmall,
    },
    {
      field: "maintReqDate",
      headerName: "Request Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "maintReqNote",
      headerName: "Request Note",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintEmail",
      headerName: "Email",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintPhone",
      headerName: "Phone",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintLocDesc",
      headerName: "Location",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintState",
      headerName: "State",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintPriority",
      headerName: "Priority",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintImpact",
      headerName: "Impact",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintCritical",
      headerName: "Critical",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintDesc",
      headerName: "Description",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintDefect",
      headerName: "Defect",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintAddRemarks",
      headerName: "Additional Remarks",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintDate",
      headerName: "Maintenance Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "maintStatus",
      headerName: "Status",
      type: "string",
      width: GridWidthMedium,
    },
  ]);

  const [iotColumns, setIotColumns] = useState([
    {
      field: "astioDescription",
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "astioDeviceId",
      headerName: "Device ID",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "astioDataPoint",
      headerName: "Data Point",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "astioDeviceType",
      headerName: "Type",
      type: "select",
      textFieldWidth: medium,
      options: deviceTypesOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "astioProtocol",
      headerName: "Protocol",
      type: "select",
      textFieldWidth: medium,
      options: protocolOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "astioApi",
      headerName: "API",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "astioValue",
      headerName: "Value",
      type: "number",
      textFieldWidth: small,
      width: GridWidthLargeWidth,
    },

    {
      field: "astioStatus",
      headerName: "Status",
      type: "text",
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
  ]);

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    setColumns(updateColumns(columns, updatedConfig.grid.columns));
    setComponentColumns(
      updateColumns(componentColumns, updatedConfig.sub_component?.component)
    );
    setDepreciationColumns(
      updateColumns(
        depreciationColumns,
        updatedConfig.sub_component?.depreciation
      )
    );
    setWarrantyColumns(
      updateColumns(warrantyColumns, updatedConfig.sub_component?.warranty)
    );
    setTransferColumns(
      updateColumns(transferColumns, updatedConfig.sub_component?.transfer)
    );

    setAdocColumns(
      updateColumns(adocColumns, updatedConfig.sub_component?.adoc_upload)
    );

    setPhotoColumns(
      updateColumns(photoColumns, updatedConfig.sub_component?.photo_upload)
    );

    setAwoColumns(updateColumns(awoColumns, updatedConfig.sub_component?.awo));

    setScColumns(
      updateColumns(scColumns, updatedConfig.sub_component?.asst_sc)
    );

    setReqColumns(
      updateColumns(reqColumns, updatedConfig.sub_component?.asst_req)
    );

    setIotColumns(updateColumns(iotColumns, updatedConfig.sub_component?.iot));

    const configToUpdate = {
      ...updatedConfig,
      sub_component: {
        component: componentColumns,
        depreciation: depreciationColumns,
        warranty: warrantyColumns,
        transfer: transferColumns,
        iot: iotColumns,
        adoc_upload: adocColumns,
        photo_upload: photoColumns,
        awo: awoColumns,
        asst_sc: scColumns,
        asst_req: reqColumns,
      },
    };

    Object.keys(configToUpdate.sub_component).forEach((key) => {
      configToUpdate.sub_component[key] = configToUpdate.sub_component[key].map(
        (col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })
      );
    });

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_asset`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_asset`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  useEffect(() => {
    fetchColumnConfig();
  }, [columnConfig, defaultConfig, asstId]);

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.iot
      ) {
        const updatedIotColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.iot } },
          iotColumns
        );
        setIotColumns(updatedIotColumns);
      } else {
        setIotColumns(iotColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.transfer
      ) {
        const updatedTransferColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.transfer } },
          transferColumns
        );
        setTransferColumns(updatedTransferColumns);
      } else {
        setTransferColumns(
          transferColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.adoc_upload
      ) {
        const updatedAdocColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.adoc_upload } },
          adocColumns
        );
        setAdocColumns(updatedAdocColumns);
      } else {
        setAdocColumns(adocColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.photo_upload
      ) {
        const updatedPhotoColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.photo_upload } },
          photoColumns
        );
        setPhotoColumns(updatedPhotoColumns);
      } else {
        setPhotoColumns(photoColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.awo
      ) {
        const updatedAwoColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.awo } },
          awoColumns
        );
        setAwoColumns(updatedAwoColumns);
      } else {
        setAwoColumns(awoColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.asst_sc
      ) {
        const updatedScColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.asst_sc } },
          scColumns
        );
        setScColumns(updatedScColumns);
      } else {
        setScColumns(scColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.asst_req
      ) {
        const updatedReqColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.asst_req } },
          reqColumns
        );
        setReqColumns(updatedReqColumns);
      } else {
        setReqColumns(reqColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.warranty
      ) {
        const updatedWarrantyColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.warranty } },
          warrantyColumns
        );
        setWarrantyColumns(updatedWarrantyColumns);
      } else {
        setWarrantyColumns(
          warrantyColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.depreciation
      ) {
        const updatedDepreciationColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.depreciation } },
          depreciationColumns
        );
        setDepreciationColumns(updatedDepreciationColumns);
      } else {
        setDepreciationColumns(
          depreciationColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.component
      ) {
        const updatedComponentColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.component } },
          componentColumns
        );
        setComponentColumns(updatedComponentColumns);
      }

      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_asset`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const fetchColumnConfig = async () => {
    try {
      if (columnConfig && Object.keys(columnConfig).length > 0) {
        applyConfiguration(columnConfig);
        if (columnConfig.view_name && columnConfig.view_id) {
          setSelectedView({
            name: columnConfig.view_name,
            id: columnConfig.view_id,
          });
        }
      } else if (defaultConfig) {
        setColumnConfig(defaultConfig);
        applyConfiguration(defaultConfig);
      } else {
        setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
    }
  };

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, allcolumns);
      setColumns(transformedColumns);
    }

    if (config.sub_component) {
      if (config.sub_component.component) {
        const transformedComponentColumns = transformConfig(
          { grid: { columns: config.sub_component.component } },
          componentColumns
        );
        setComponentColumns(transformedComponentColumns);
      }

      if (config.sub_component.depreciation) {
        const transformedDepreciationColumns = transformConfig(
          { grid: { columns: config.sub_component.depreciation } },
          depreciationColumns
        );
        setDepreciationColumns(transformedDepreciationColumns);
      }
      if (config.sub_component.warranty) {
        const transformedWarrantyColumns = transformConfig(
          { grid: { columns: config.sub_component.warranty } },
          warrantyColumns
        );
        setWarrantyColumns(transformedWarrantyColumns);
      }
      if (config.sub_component.transfer) {
        const transformedTransferColumns = transformConfig(
          { grid: { columns: config.sub_component.transfer } },
          transferColumns
        );
        setTransferColumns(transformedTransferColumns);
      }

      if (config.sub_component.adoc_upload) {
        const transformedAdocColumns = transformConfig(
          { grid: { columns: config.sub_component.adoc_upload } },
          adocColumns
        );
        setAdocColumns(transformedAdocColumns);
      }

      if (config.sub_component.photo_upload) {
        const transformedPhotoColumns = transformConfig(
          { grid: { columns: config.sub_component.photo_upload } },
          photoColumns
        );
        setPhotoColumns(transformedPhotoColumns);
      }

      if (config.sub_component.awo) {
        const transformedAwoColumns = transformConfig(
          { grid: { columns: config.sub_component.awo } },
          awoColumns
        );
        setAwoColumns(transformedAwoColumns);
      }

      if (config.sub_component.asst_sc) {
        const transformedScColumns = transformConfig(
          { grid: { columns: config.sub_component.asst_sc } },
          scColumns
        );
        setScColumns(transformedScColumns);
      }

      if (config.sub_component.asst_req) {
        const transformedReqColumns = transformConfig(
          { grid: { columns: config.sub_component.asst_req } },
          reqColumns
        );
        setReqColumns(transformedReqColumns);
      }

      if (config.sub_component.iot) {
        const transformedIotColumns = transformConfig(
          { grid: { columns: config.sub_component.iot } },
          iotColumns
        );
        setIotColumns(transformedIotColumns);
      }
    }

    if (config.view_name) {
      setSelectedView({ name: config.view_name, id: config.view_id });
    }
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isAssetLoading && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={
                viewLoaded
                  ? columns.filter((col) => !col.hide && col.width > 0)
                  : []
              }
              rows={viewLoaded ? rows : []}
              sortingField={"asstTag"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => addAssetClick()}
                        onClickEdit={() => handleAssetEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickProperties={() => showData()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        onClickImportCSV={handleOpenCSVModal}
                        plantId={plantId}
                        propId={asstId}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                        isAsset={true}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="asset"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                      <Tooltip title={"Print"}>
                        {" "}
                        <Button
                          disableRipple
                          onClick={() => setOpenReport(true)}
                          disabled={!asstId}
                          style={{
                            background: "transparent",
                            color: "rgba(0,0,0,0.3)",
                            boxShadow: "none",
                            fontSize: "1px",
                          }}
                          startIcon={
                            <i
                              class="fa-solid fa-print"
                              style={{ fontSize: "15px" }}
                            ></i>
                          }
                        />
                      </Tooltip>
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
              onCellDoubleClick={showData}
            />
          </Box>
        </Box>
      </Box>
      {showAssetMasterEditForm && (
        <AddEditAccordionForm
          onClose={handleClose}
          showForm={showAssetMasterForm}
          setShowForm={setShowAssetMasterForm}
          columns={formFields}
          onUpData={saveUpdateData}
          onAdd={submitAddAssetMasterData}
          isLoading={addingAsset || updatingAsset}
          editId={id}
          defaultValues={editDefValues}
          label={"Asset Master"}
          asset={true}
        />
      )}
      <Box>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ width: "60vw" }}>
            <AssetManagementMultiSteps
              onClose={toggleDrawer(false)}
              asstId={asstId}
              columnConfig={columnConfig}
              updateColumnConfig={updateColumnConfig}
              setColumnConfig={setColumnConfig}
              componentColumns={componentColumns}
              setComponentColumns={setComponentColumns}
              depreciationColumns={depreciationColumns}
              setDepreciationColumns={setDepreciationColumns}
              warrantyColumns={warrantyColumns}
              setWarrantyColumns={setWarrantyColumns}
              transferColumns={transferColumns}
              setTransferColumns={setTransferColumns}
              iotColumns={iotColumns}
              setIotColumns={setIotColumns}
              adocColumns={adocColumns}
              setAdocColumns={setAdocColumns}
              photoColumns={photoColumns}
              setPhotoColumns={setPhotoColumns}
              awoColumns={awoColumns}
              setAwoColumns={setAwoColumns}
              scColumns={scColumns}
              setScColumns={setScColumns}
              reqColumns={reqColumns}
              setReqColumns={setReqColumns}
              selectedView={selectedView}
            />
          </Box>
        </SwipeableDrawer>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Asset?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="asset"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
      <Dialog
        open={openReport}
        onClose={() => setOpenReport(false)}
        fullWidth
        maxWidth="lg"
      >
        <Box sx={{ height: "80vh", width: "100%" }}>
          <Viewer ref={viewerRef} theme="ActiveReports" />
        </Box>
      </Dialog>
      <CSVImportModal
        open={openCSVModal}
        onClose={handleCloseCSVModal}
        onImport={handleCSVImport}
        columns={columns}
        validateEntry={validateEntry}
      />
    </>
  );
};

export default AssetManagement;
