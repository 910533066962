import React, { useState } from "react";
import { Box, StepContent } from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { Button } from "../../../common/Button";
import MenuSelection from "./MenuSelection";

export const UserGroupSteps = ({ onClose, ugId }) => {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: "Menu Selection",
      form: (
        <>
          <MenuSelection
            label="Add Asset Components"
            ugId={ugId}
            onClose={onClose}
          />
        </>
      ),
    },
  ];

  return (
    <div>
      <Box sx={{ maxWidth: "55vw", marginLeft: "4vh" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              {/* <StepLabel>{step.label}</StepLabel> */}
              <StepContent>
                {steps[activeStep].form}
                {/* <Box sx={{ mb: 2 }}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={onClose}
                    sx={{ mt: 0.5, mr: 1 }}
                    label="Save"
                  ></Button>

                  <Button
                    size="small"
                    variant="outlined"
                    onClick={onClose}
                    sx={{ mt: 0.5, mr: 1 }}
                    label="cancel"
                  ></Button>
                </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};
