import React, { useState, useEffect, useCallback } from "react";
import useFetchApi from "../../../hooks/useFetchApi";
import {
  GeneralPrefix,
  GridWidthMedium,
  GridWidthSmall,
  ticketRequestStatus,
} from "../../../constants/global";
import { CommonBox } from "./CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import { useCommonData } from "../../../context/CommonDataProvider";
import {
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToDescWithId,
} from "../../../utils/common";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import { toast } from "react-toastify";

export const AssetRequests = ({
  assetId,
  selectedView,
  reqColumns,
  setReqColumns,
}) => {
  const { rightsArray } = useMenuContext();
  const commonData = useCommonData();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [rows, setRows] = useState([]);
  const [viewLoaded, setViewLoaded] = useState(true);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, setColumnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();
  // const handleRestoreButtonClick = () => {
  //   setOpenRestoreDialog(true);
  // };

  const [columns, setColumns] = useState([
    {
      field: "maintId",
      headerName: "ID",
      type: "number",
      width: GridWidthSmall,
    },
    {
      field: "maintReqDate",
      headerName: "Request Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "maintReqNote",
      headerName: "Request Note",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintEmail",
      headerName: "Email",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintPhone",
      headerName: "Phone",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintLocDesc",
      headerName: "Location",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintState",
      headerName: "State",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintPriority",
      headerName: "Priority",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintImpact",
      headerName: "Impact",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintCritical",
      headerName: "Critical",
      type: "string",
      width: GridWidthSmall,
    },
    {
      field: "maintDesc",
      headerName: "Description",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintDefect",
      headerName: "Defect",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintAddRemarks",
      headerName: "Additional Remarks",
      type: "string",
      width: GridWidthMedium,
    },
    {
      field: "maintDate",
      headerName: "Maintenance Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "maintStatus",
      headerName: "Status",
      type: "string",
      width: GridWidthMedium,
    },
  ]);

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }

    try {
      let configColumns = [];

      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.asst_req) {
        configColumns = updatedConfig.sub_component.asst_req;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === "object") {
        configColumns = Object.entries(updatedConfig).map(
          ([field, details]) => ({
            name: field,
            title: details?.headerName,
            visible: details?.visible,
            width: details?.width ? `${details.width}px` : undefined,
          })
        );
      }

      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }

      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });

      setColumns(newColumns);

      const configToUpdate = {
        ...columnConfig,
        category: "asset",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          asst_req: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };

      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.asst_req) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.asst_req
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (reqColumns) {
      setColumns(reqColumns);
    } else {
      fetchColumnConfig();
    }
  }, [reqColumns, columnConfig, defaultConfig, assetId]);

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const {
    data: getMaintenanceRequestsData,
    isFetchedAfterMount: isFetch,
    isFetching: isRequestsFetching,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/ticketrequests/asset/${assetId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isFetch && getMaintenanceRequestsData && canRead && commonData) {
      const mappedData = getMaintenanceRequestsData.map((request) => ({
        id: request.maintId,
        maintId: request.maintId,
        maintReqDate: request.maintReqDate,
        maintReqNote: request.maintReqNote,
        maintEmail: request.maintEmail,
        maintPhone: request.maintPhone,
        maintLocDesc: request.maintLocDesc,
        maintState: mapDropdownValueToLabelWithListId(
          commonData.assetStatus,
          request.maintState
        ),
        maintPriority: mapDropdownValueToLabelWithListId(
          commonData.workOrderPriority,
          request.maintPriority
        ),
        maintImpact: mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticalityImpact,
          request.maintImpact
        ),
        maintCritical: mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticality,
          request.maintCritical
        ),
        maintDesc: request.maintDesc,
        maintDefect: request.maintDefect,
        maintAddRemarks: request.maintAddRemarks,
        maintDate: request.maintDate,
        maintStatus: mapDropdownValueToLabelWithId(
          ticketRequestStatus,
          parseInt(request.maintStatus)
        ),
        // Including original values in case they're needed elsewhere
        _maintState: request.maintState,
        _maintPriority: request.maintPriority,
        _maintImpact: request.maintImpact,
        _maintCritical: request.maintCritical,
        _maintStatus: request.maintStatus,
      }));

      setRows(mappedData);
    }
  }, [isFetch, getMaintenanceRequestsData, rightsArray, canRead, commonData]);

  return (
    <>
      <CommonBox
        onClickColumnCustom={() => handleOpenColumnPopup()}
        columns={columns}
        rows={rows}
        isLoading={isRequestsFetching}
        label="Asset Maintenance Requests"
        sortingField="maintReqDate"
        getRowId={(row) => row.maintId}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="asset"
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default AssetRequests;
