import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Grid,
  Typography,
  Box,
  Paper,
  IconButton,
  Tooltip,
  Chip,
  Snackbar,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { DataGridComponent } from "../../common/DataGridComponent";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";
import DoneIcon from "@mui/icons-material/Done";
import { toast } from "react-toastify";
import Draggable from "react-draggable";

const CSVImportModal = ({
  open,
  onClose,
  onImport,
  columns,
  validateEntry,
}) => {
  const [file, setFile] = useState(null);
  const [validEntries, setValidEntries] = useState([]);
  const [invalidEntries, setInvalidEntries] = useState([]);
  const [statusMessage, setStatusMessage] = useState("");

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
      setValidEntries([]);
      setInvalidEntries([]);
      parseCSV(selectedFile);
    } else {
      toast.error("Please select a valid CSV file.");
    }
  };

  const parseCSV = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        const { data } = results;
        const headers = data[0].map((header) => header.trim());

        const requiredColumns = columns
          .filter((col) => col.required)
          .map((col) => col.field);
        const missingColumns = requiredColumns.filter(
          (col) => !headers.includes(col)
        );

        if (missingColumns.length > 0) {
          toast.error(`Missing required columns: ${missingColumns.join(", ")}`);
          return;
        }

        const valid = [];
        const invalid = [];

        // Start from index 1 to skip headers, and process all rows including the last one
        for (let i = 1; i < data.length; i++) {
          const row = data[i];

          // Skip empty rows
          if (row.every((cell) => cell.trim() === "")) {
            continue;
          }

          const entry = {};
          headers.forEach((header, index) => {
            entry[header.trim()] = row[index] ? row[index].trim() : "";
          });

          entry.id = `row-${i}-${Math.random().toString(36).substr(2, 9)}`;

          const { isValid, invalidReason } =
            validateEntryWithDateHandling(entry);

          if (isValid) {
            valid.push(entry);
          } else {
            invalid.push({ ...entry, invalidReason });
          }
        }

        setValidEntries(valid);
        setInvalidEntries(invalid);
      },
      // Ensure empty lines are not skipped
      skipEmptyLines: false,
    });
  };

  // Helper function to generate a unique key for each record
  const generateUniqueKey = (entry) => {
    // Implement this based on your data structure
    // For example, if you have a 'name' and 'email' field that together should be unique:
    return `${entry.name}-${entry.email}`.toLowerCase();
  };

  const validateEntryWithDateHandling = (entry) => {
    const dateFields = [
      "asstExpiryDate",
      "asstInstalledDate",
      "asstManufactDate",
      "asstPurchasedDate",
      "asstRetiredDate",
    ];
    const numericFields = [
      "asstBaseCost",
      "asstDepriciationP",
      "asstLife",
      "asstRecoveryPeriod",
      "asstRentalPrice",
      "asstSaleValue",
      "asstSalvageValue",
      "asstTax",
    ];

    dateFields.forEach((field) => {
      if (entry[field]) {
        // Parse the date, assuming DD-MM-YY format
        const parts = entry[field].split("-");
        if (parts.length === 3) {
          const day = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1; // JS months are 0-indexed
          let year = parseInt(parts[2], 10);

          // Adjust for two-digit years
          if (year < 100) {
            year += year < 50 ? 2000 : 1900;
          }

          const dateValue = new Date(year, month, day);
          if (!isNaN(dateValue.getTime())) {
            // If it's a valid date, format it as YYYY-MM-DD
            entry[field] = dateValue.toISOString().split("T")[0];
          } else {
            entry[field] = null;
          }
        } else {
          entry[field] = null;
        }
      } else {
        entry[field] = null;
      }
    });

    numericFields.forEach((field) => {
      if (entry[field] === "") {
        entry[field] = null;
      } else if (entry[field] !== null) {
        const numValue = parseFloat(entry[field]);
        if (isNaN(numValue)) {
          return {
            isValid: false,
            invalidReason: `Invalid numeric value for ${field}: ${entry[field]}`,
          };
        }
        entry[field] = numValue;
      }
    });

    // Call the original validateEntry function
    return validateEntry(entry);
  };

  const generateSampleCSV = () => {
    const csvContent = Papa.unparse({
      fields: columns.map((col) => col.field),
      data: [
        columns
          .map((col) => (col.required ? "*" : ""))
          .map((item) => item.trim()),
      ],
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "sample_import_template.csv");
  };

  const handleImport = () => {
    if (!file) {
      toast.warning("Please select a file to import");
      return;
    }
    if (invalidEntries.length > 0) {
      toast.error(
        `${invalidEntries.length} invalid entries found. Please correct them before importing.`
      );
      return;
    }
    onImport(validEntries);
    setFile(null);
    setValidEntries([]);
    setInvalidEntries([]);
    onClose();
  };

  const invalidColumns = [
    ...columns,
    { field: "invalidReason", headerName: "Invalid Reason", width: 300 },
  ];

  useEffect(() => {
    if (!open) {
      clearAll();
    }
  }, [open]);

  const clearAll = () => {
    setFile(null);
    setValidEntries([]);
    setInvalidEntries([]);
    setStatusMessage("");
  };

  const handleClose = () => {
    clearAll();
    onClose();
  };

  const EntriesGrid = ({ entries, columns, title }) => (
    <Grid
      item
      xs={12}
      sx={{ display: "flex", flexDirection: "column", height: "50%" }}
    >
      <Typography variant="body1" sx={{ mb: 1, color: "inherit" }}>
        {title}
      </Typography>
      <Box elevation={3} sx={{ flexGrow: 1, overflow: "hidden" }}>
        {entries.length > 0 ? (
          <DataGridComponent
            columns={columns}
            rows={entries}
            sortingField={columns[0].field}
            sx={{
              height: "100%",
              width: "100%",
              backgroundColor: "white !important",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid #f0f0f0",
              },
              "& .MuiDataGrid-columnHeaders": {
                borderBottom: "2px solid #f0f0f0",
                backgroundColor: "background.paper",
                color: "text.primary",
              },
              "& .MuiDataGrid-virtualScroller": {
                overflow: "auto",
                "&::-webkit-scrollbar": {},
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#888",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              },
            }}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            headerHeight={56}
            rowHeight={52}
            autoHeight={false}
            components={{
              Toolbar: () => null,
            }}
            hideFooter
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {file
                ? `No ${title.toLowerCase()} found.`
                : "Upload a CSV file to see entries."}
            </Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "93%",
          height: "94vh",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Draggable handle=".draggable-header">
          <Box
            sx={{
              backgroundColor: "white",
              boxShadow: 24,
              borderRadius: 2,
              width: "100%",
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <Box
              className="draggable-header"
              sx={{
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "primary.main",
                pl: 2,
                pr: 2,
                cursor: "move",
              }}
            >
              <Typography variant="h6" color="white">
                CSV Import
              </Typography>
              <Box>
                <Button
                  onClick={handleImport}
                  sx={{
                    color: "#1976d2",
                    backgroundColor: "white",
                    padding: "4px 16px",
                    marginRight: "4px",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <DoneIcon />
                </Button>
                <Button
                  onClick={onClose}
                  sx={{
                    color: "#1976d2",
                    backgroundColor: "white",
                    padding: "4px 16px",
                    "&:hover": {
                      backgroundColor: "white",
                    },
                  }}
                >
                  <PhonelinkEraseIcon />
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: "calc(100% - 64px)",
                overflowY: "auto",
                backgroundColor: "white",
              }}
            >
              <Box sx={{ mb: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    gap: 1,
                    mb: 1,
                  }}
                >
                  <Typography variant="body1" sx={{ mr: 1 }}>
                    Please ensure your CSV file contains the following required
                    columns:
                  </Typography>
                  {columns
                    .filter((col) => col.required)
                    .map((col) => (
                      <Chip
                        key={col.field}
                        label={col.field}
                        color="primary"
                        variant="outlined"
                        size="small"
                      />
                    ))}
                </Box>
                <Box sx={{ display: "flex", gap: 1, mt: 1 }}>
                  <Tooltip title="Download Sample Template">
                    <IconButton
                      onClick={generateSampleCSV}
                      size="small"
                      color="rgba(0,0,0,0.3)"
                    >
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Upload CSV File">
                    <IconButton
                      component="label"
                      size="small"
                      color="rgba(0,0,0,0.3)"
                    >
                      <CloudUploadIcon />
                      <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        hidden
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
              <Grid
                container
                spacing={3}
                sx={{
                  mb: 3,
                  flexGrow: 1,
                  overflow: "hidden",
                  height: "calc(100% - 150px)",
                }}
              >
                <EntriesGrid
                  entries={validEntries}
                  columns={columns}
                  title="Valid Entries"
                />
                <EntriesGrid
                  entries={invalidEntries}
                  columns={invalidColumns}
                  title="Invalid Entries"
                />
              </Grid>
            </Box>
          </Box>
        </Draggable>
        <Snackbar
          open={!!statusMessage}
          autoHideDuration={30000}
          onClose={() => setStatusMessage("")}
          message={statusMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          sx={{
            position: "relative",
            bottom: 16,
            right: 0,
            top: 2.5,
            maxWidth: "fullWidth",
          }}
        />
      </Box>
    </Modal>
  );
};

export default CSVImportModal;
