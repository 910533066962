import React, { useState } from 'react';
import { Bold, Italic, Underline, AlignLeft, AlignCenter, AlignRight, Edit2, Save } from 'lucide-react';

import { GeneralPrefix } from "../../constants/global";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import { useMenuContext } from "../../context/MenuContextProvider";

const Help = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [expandedTopics, setExpandedTopics] = useState({});
  const [editorContent, setEditorContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;

  const {
    data: helpTopicsData,
    isFetchedAfterMount: isFetch,
    isFetching: isHelpTopicsFetching,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/help-topic-hierarchy`,
    retrieveOnMount: !!rightsArray[0]?.urMenuIdFk,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: helpContentData,
    isFetching: isContentFetching,
    error: contentError,
    refetch: fetchHelpContent
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/help-topic/${selectedTopic?.id || ''}/help-content`,
    retrieveOnMount: false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateHelpContent,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/help-content/${helpContentData?.hcId || ''}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const Button = ({ 
    children, 
    variant = 'outline', 
    size = 'default',
    onClick,
    className = '',
    disabled = false
  }) => {
    const baseStyles = 'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-400 disabled:pointer-events-none disabled:opacity-50';
    
    const variants = {
      outline: 'border border-gray-200 bg-white hover:bg-gray-100 hover:text-gray-900',
    };

    const sizes = {
      default: 'h-9 px-4 py-2',
      icon: 'h-8 w-8',
    };

    return (
      <button
        onClick={onClick}
        className={`${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`}
        disabled={disabled}
      >
        {children}
      </button>
    );
  };

  const handleEditClick = () => {
    setIsEditing(true);
    setEditorContent(helpContentData?.hcContent || '');
  };

  const handleSaveClick = async () => {
    try {
      await updateHelpContent({
        hcHtId: selectedTopic.id,
        hcContent: editorContent
      });
      setIsEditing(false);
      fetchHelpContent();
    } catch (error) {
      console.error('Error updating content:', error);
    }
  };

  const handleFormat = (command) => {
    document.execCommand(command, false, null);
  };

  const transformApiDataToHelpContent = (apiData) => {
    if (!apiData) return {};
    
    const processNode = (node) => {
      const result = {
        id: node.htId,
        topic: node.htTopic,
        children: {}
      };
      
      if (node.children && node.children.length > 0) {
        node.children.forEach(child => {
          result.children[child.htTopic] = processNode(child);
        });
      }
      
      return result;
    };

    return apiData.reduce((acc, topic) => {
      acc[topic.htTopic] = processNode(topic);
      return acc;
    }, {});
  };

  const helpContent = transformApiDataToHelpContent(helpTopicsData);

  const toggleTopic = (topic) => {
    setExpandedTopics(prev => ({
      ...prev,
      [topic]: !prev[topic]
    }));
  };

  const findTopicId = (topic, parentTopic = null) => {
    if (parentTopic) {
      return helpContent[parentTopic]?.children[topic]?.id;
    }
    return helpContent[topic]?.id;
  };

  const getFilteredTopics = () => {
    if (!searchQuery) return [];
    
    const results = [];
    const searchRecursive = (topic, content, parentTopic = null) => {
      if (topic.toLowerCase().includes(searchQuery.toLowerCase())) {
        results.push({ 
          main: parentTopic || topic, 
          sub: parentTopic ? topic : null,
          id: content.id 
        });
      }
      
      Object.entries(content.children).forEach(([childTopic, childContent]) => {
        searchRecursive(childTopic, childContent, parentTopic || topic);
      });
    };

    Object.entries(helpContent).forEach(([topic, content]) => {
      searchRecursive(topic, content);
    });
    
    return results;
  };

  const handleTopicSelect = async (topic, parentTopic = null) => {
    const topicId = findTopicId(topic, parentTopic);
    setIsEditing(false); // Reset editing state when switching topics
    
    if (topicId) {
      const newSelectedTopic = { 
        main: parentTopic || topic, 
        sub: parentTopic ? topic : null,
        id: topicId
      };
      
      setSelectedTopic(newSelectedTopic);

      if (parentTopic) {
        setExpandedTopics(prev => ({
          ...prev,
          [parentTopic]: true
        }));
      }
      setSearchQuery('');

      try {
        const endpoint = `${GeneralPrefix}/help-topic/${topicId}/help-content`;
        await fetchHelpContent({
          endpoint,
          Menu_id: rightsArray[0]?.urMenuIdFk
        });
      } catch (error) {
        console.error('Error fetching help content:', error);
      }
    }
  };

  const renderTopicTree = (topics, parentTopic = null) => {
    return Object.entries(topics).map(([topic, content]) => (
      <div key={topic} style={{ marginBottom: '8px' }}>
        <button
          onClick={() => {
            toggleTopic(topic);
            handleTopicSelect(topic, parentTopic);
          }}
          style={{
            width: '100%',
            textAlign: 'left',
            padding: '8px 12px',
            backgroundColor: selectedTopic?.main === topic && !selectedTopic?.sub ? '#EFF6FF' : 'transparent',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            color: selectedTopic?.main === topic && !selectedTopic?.sub ? '#2563EB' : '#374151',
            fontWeight: '500',
            display: 'flex',
            alignItems: 'center'
          }}
          onMouseEnter={(e) => {
            if (!(selectedTopic?.main === topic && !selectedTopic?.sub)) {
              e.currentTarget.style.backgroundColor = '#F3F4F6';
            }
          }}
          onMouseLeave={(e) => {
            if (!(selectedTopic?.main === topic && !selectedTopic?.sub)) {
              e.currentTarget.style.backgroundColor = 'transparent';
            }
          }}
        >
          {Object.keys(content.children).length > 0 && (
            <span style={{ marginRight: '8px' }}>{expandedTopics[topic] ? '▼' : '▶'}</span>
          )}
          {topic}
        </button>

        {expandedTopics[topic] && Object.keys(content.children).length > 0 && (
          <div style={{ marginLeft: '20px' }}>
            {renderTopicTree(content.children, topic)}
          </div>
        )}
      </div>
    ));
  };

  const filteredTopics = getFilteredTopics();

  if (isHelpTopicsFetching) {
    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        Loading...
      </div>
    );
  }

  return (
    <div style={{
      display: 'flex',
      height: '100vh',
      width: '100%',
      overflow: 'hidden',
      position: 'fixed',
      top: 0,
      left: 0,
      backgroundColor: '#F9FAFB'
    }}>
      {/* Sidebar */}
      <div style={{
        width: '256px',
        height: '100%',
        backgroundColor: 'white',
        borderRight: '1px solid #E5E7EB',
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0
      }}>
        {/* Sidebar Header */}
        <div style={{
          padding: '16px',
          borderBottom: '1px solid #E5E7EB'
        }}>
          <h2 style={{
            fontSize: '20px',
            fontWeight: 'bold',
            color: '#1F2937'
          }}>Help Center</h2>
        </div>

        {/* Sidebar Navigation */}
        <div style={{
          overflowY: 'auto',
          flex: 1,
          padding: '16px'
        }}>
          {renderTopicTree(helpContent)}
        </div>
      </div>

      {/* Main Content */}
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden'
      }}>
        {/* Search Bar */}
        <div style={{
          padding: '16px',
          backgroundColor: 'white',
          borderBottom: '1px solid #E5E7EB'
        }}>
          <div style={{ position: 'relative' }}>
            <span style={{
              position: 'absolute',
              left: '12px',
              top: '50%',
              transform: 'translateY(-50%)',
              color: '#9CA3AF'
            }}>🔍</span>
            <input
              type="text"
              placeholder="Search help topics..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: '100%',
                padding: '8px 12px 8px 36px',
                borderRadius: '6px',
                border: '1px solid #D1D5DB',
                fontSize: '14px',
                outline: 'none'
              }}
            />
            {searchQuery && filteredTopics.length > 0 && (
              <div style={{
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                backgroundColor: 'white',
                border: '1px solid #E5E7EB',
                borderRadius: '4px',
                marginTop: '4px',
                maxHeight: '300px',
                overflowY: 'auto',
                zIndex: 10
              }}>
                {filteredTopics.map((topic, index) => (
                  <button
                    key={index}
                    onClick={() => handleTopicSelect(topic.sub || topic.main, topic.sub ? topic.main : null)}
                    style={{
                      display: 'block',
                      width: '100%',
                      textAlign: 'left',
                      padding: '8px 12px',
                      backgroundColor: 'transparent',
                      border: 'none',
                      borderBottom: '1px solid #E5E7EB',
                      color: '#374151',
                      fontWeight: '500',
                      cursor: 'pointer'
                    }}
                  >
                    {topic.sub ? `${topic.main} > ${topic.sub}` : topic.main}
                  </button>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Content Display with RTF Editor */}
        <div style={{
          padding: '16px',
          overflowY: 'auto',
          flex: 1,
          backgroundColor: 'white',
          margin: '16px',
          borderRadius: '8px',
          border: '1px solid #E5E7EB'
        }}>
          {isContentFetching ? (
            <div>Loading content...</div>
          ) : contentError ? (
            <div>Error loading content. Please try again.</div>
          ) : !selectedTopic ? (
            <div>Select a topic to view help content.</div>
          ) : (
            <div>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px'
              }}>
                <h1 style={{ 
                  fontSize: '24px', 
                  fontWeight: 'bold',
                  color: '#1F2937'
                }}>
                  {selectedTopic.sub ? `${selectedTopic.main} > ${selectedTopic.sub}` : selectedTopic.main}
                </h1>
                
                <div style={{ display: 'flex', gap: '8px' }}>
                  {!isEditing ? (
                    <Button
                      variant="outline"
                      size="default"
                      onClick={handleEditClick}
                      className="flex items-center gap-2"
                    >
                      <Edit2 className="h-4 w-4" />
                      Edit
                    </Button>
                  ) : (
                    <Button
                      variant="outline"
                      size="default"
                      onClick={handleSaveClick}
                      className="flex items-center gap-2"
                      disabled={isUpdateLoading}
                    >
                      <Save className="h-4 w-4" />
                      {isUpdateLoading ? 'Saving...' : 'Save'}
                    </Button>
                  )}
                </div>
              </div>
              
              {/* RTF Editor Toolbar - Only show when editing */}
              {isEditing && (
                <div style={{
                  display: 'flex',
                  gap: '8px',
                  marginBottom: '16px',
                  padding: '8px',
                  borderBottom: '1px solid #E5E7EB'
                }}>
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleFormat('bold')}
                    className="h-8 w-8"
                  >
                    <Bold className="h-4 w-4" />
                  </Button>
                  
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleFormat('italic')}
                    className="h-8 w-8"
                  >
                    <Italic className="h-4 w-4" />
                  </Button>
                  
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleFormat('underline')}
                    className="h-8 w-8"
                  >
                    <Underline className="h-4 w-4" />
                  </Button>

                  <div className="w-px h-8 bg-gray-200" />

                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleFormat('justifyLeft')}
                    className="h-8 w-8"
                  >
                    <AlignLeft className="h-4 w-4" />
                  </Button>
                  
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleFormat('justifyCenter')}
                    className="h-8 w-8"
                  >
                    <AlignCenter className="h-4 w-4" />
                  </Button>
                  
                  <Button 
                    variant="outline" 
                    size="icon"
                    onClick={() => handleFormat('justifyRight')}
                    className="h-8 w-8"
                  >
                    <AlignRight className="h-4 w-4" />
                  </Button>
                </div>
              )}

              {/* RTF Editor Content Area */}
              <div
                contentEditable={isEditing}
                dangerouslySetInnerHTML={{ __html: isEditing ? editorContent : (helpContentData?.hcContent || '') }}
                onInput={isEditing ? (e) => setEditorContent(e.currentTarget.innerHTML) : undefined}
                style={{
                  minHeight: '200px',
                  padding: '16px',
                  border: '1px solid #E5E7EB',
                  borderRadius: '4px',
                  outline: 'none',
                  backgroundColor: isEditing ? 'white' : '#F9FAFB',
                  cursor: isEditing ? 'text' : 'default'
                }}
              />

              {/* Show loading indicator while saving */}
              {isUpdateLoading && (
                <div style={{
                  marginTop: '8px',
                  color: '#6B7280',
                  fontSize: '14px'
                }}>
                  Saving changes...
                </div>
              )}

              {/* Show success message after saving */}
              {isUpdateSuccess && !isEditing && (
                <div style={{
                  marginTop: '8px',
                  color: '#059669',
                  fontSize: '14px'
                }}>
                  Changes saved successfully!
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Help;