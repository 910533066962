import { Box, Button, Typography } from "@mui/material";
import { Progressor } from "../../../common/Progressor";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../../common/DataGridComponent";
import { GridToolbar } from "@mui/x-data-grid";
import AddEditToolBar from "../../../common/AddEditToolBar";

export const CommonBox = ({
  columns,
  rows,
  onClickAdd,
  onClickEdit,
  onClickDelete,
  isLoading,
  editId,
  label,
  isCalWorking,
  Gridcolumns,
  onRowClick,
  urWrite,
  urModify,
  urDelete,
  urAdmin,
  sidebar,
  sortingField,
  onClickColumnCustom,
}) => {
  return (
    <Box sx={{ width: "100%" }}>
      {isLoading && <Progressor />}
      <Box sx={{ paddingY: "1rem" }}>
        <Box
          sx={{
            height: "80vh",
            width: "100%",
          }}
        >
          <DataGridComponent
            columns={isCalWorking ? Gridcolumns : columns}
            rows={rows}
            sx={{
              width: "100%",
            }}
            sortingField={sortingField}
            components={{
              Toolbar: (props) => {
                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onClickAdd()}
                        onClickEdit={() => onClickEdit()}
                        onClickDelete={() => onClickDelete()}
                        onClickColumnCustom={() => onClickColumnCustom()}
                        editId={editId}
                        urWrite={urWrite}
                        urModify={urModify}
                        urDelete={urDelete}
                        urAdmin={urAdmin}
                        sidebar
                      />
                    </div>
                  </>
                );
              },
            }}
            onRowClick={onRowClick}
          ></DataGridComponent>
        </Box>
      </Box>
    </Box>
  );
};
