import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  small,
} from "../../../constants/global";
import { useCommonData } from "../../../context/CommonDataProvider";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  getFormattedDate,
  getFormattedTime,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
} from "../../../utils/common";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import dayjs from "dayjs";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const Messages = ({ workOrderId, messagesColumns, selectedView }) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const { employeDetails, workOrderSubStatus } = useCommonData();
  const [editDefValues, setEditDefValues] = useState();
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showMessageEditForm, setShowMessageEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const {
    mutateAsync: addWorkOrderMessage,
    isSuccess: isWorkOrderMessageSuccess,
    isLoading: addMessageLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/message/addmessage`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderMessage,
    isSuccess: isUpdateSuccess,
    isLoading: updateMessageLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/message?messageId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderMessage,
    isSuccess: isDeleteSuccess,
    isLoading: deleteAssetLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/message?messageId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getWorkOrderMessageData,
    isFetchedAfterMount: isFetch,
    isFetching: isMessageFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/message?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getWorkOrderTasksData,
    isFetchedAfterMount: isFetchWorkTask,
    isFetching: isTaskFetching,
    refetch: refetchWorkTask,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/tasks?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderMessageSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
      refetchWorkTask();
    }
  }, [
    isWorkOrderMessageSuccess,
    isUpdateSuccess,
    isDeleteSuccess,
    refetch,
    refetchWorkTask,
  ]);

  const [workMessageData, setWorkMessageData] = useState(
    getWorkOrderMessageData
  );
  useEffect(() => {
    if (getWorkOrderMessageData) {
      const messageData = getWorkOrderMessageData.map((item) => {
        const messageStatusData = mapDropdownValueToLabelWithListId(
          workOrderSubStatus,
          item.womStatus
        );
        const employeData = mapDropdownValueToLabelWithId(
          employeDetails,
          item.womUpdatedById
        );
        const serviceTaskData = mapDropdownValueToLabelWithId(
          getWorkOrderTasksData?.map((o) => ({
            label: o.wosTaskCode,
            id: o.wosId,
          })),
          item.womServId
        );
        return {
          ...item,
          womStatus: messageStatusData,
          womUpdatedById: employeData,
          womServId: serviceTaskData,
        };
      });
      setWorkMessageData(messageData);
    }
  }, [
    getWorkOrderMessageData,
    workOrderSubStatus,
    employeDetails,
    getWorkOrderTasksData,
  ]);
  useEffect(() => {
    if (isFetch && workMessageData && canRead) {
      setRows(() =>
        workMessageData?.map((w) => {
          return { id: w.womId, ...w };
        })
      );
    }
  }, [canRead, isFetch, rightsArray, workMessageData]);

  const handleWorkOrderMessageEditClick = () => {
    setShowMessageEditForm(true);
    setId(editData.id);
    const resourceStatusData = mapDropdownValueToListIdWithLabel(
      workOrderSubStatus,
      editData.womStatus
    );
    const employeData = mapDropdownValueToIdWithLabel(
      employeDetails,
      editData.womUpdatedById
    );
    const serviceTaskData = mapDropdownValueToIdWithLabel(
      getWorkOrderTasksData?.map((o) => ({
        label: o.wosTaskCode,
        id: o.wosId,
      })),
      editData.womServId
    );
    setEditDefValues({
      ...editData,
      womStatus: resourceStatusData,
      womUpdatedById: employeData,
      womServId: serviceTaskData,
    });
  };

  const saveUpdateData = async (WorkOrderMessageDataToSave) => {
    updateWorkOrderMessage({
      id: WorkOrderMessageDataToSave.womId,
      ...WorkOrderMessageDataToSave,
      womUpdateTime: WorkOrderMessageDataToSave?.womUpdateTime
        ? dayjs(WorkOrderMessageDataToSave?.womUpdateTime).format("HH:MM:ss")
        : "",

      womUpdatedById: WorkOrderMessageDataToSave?.womUpdatedById?.id,
      womStatus: WorkOrderMessageDataToSave?.womStatus?.listId,
      womServId: WorkOrderMessageDataToSave?.womServId?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Message updated successfully");
          setShowMessageEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitMessageData = async (newWorkOrderMessageData) => {
    addWorkOrderMessage({
      womWoIdFk: workOrderId,
      ...newWorkOrderMessageData,
      womUpdatedById: newWorkOrderMessageData?.womUpdatedById?.id,
      womStatus: newWorkOrderMessageData?.womStatus?.listId,
      womUpdateTime: newWorkOrderMessageData?.womUpdateTime
        ? dayjs(newWorkOrderMessageData?.womUpdateTime).format("HH:MM:ss")
        : "",
      womServId: newWorkOrderMessageData?.womServId?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Message added successfully");
          setShowMessageEditForm(!showMessageEditForm);
          setShowMessageForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderMessage({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Message delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowMessageEditForm(!showMessageEditForm);
    setShowMessageForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "womMessage",
      headerName: "Messages",
      required: true,
      type: "text",
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "womStatus",
      headerName: "Work Order Sub Status",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "workOrderSubStatus",
      width: GridWidthMedium,
    },
    {
      field: "womUpdatedById",
      headerName: "Updated By",
      type: "select",
      textFieldWidth: small,
      apiendpoint: "employeDetails",
      width: GridWidthMedium,
    },
    {
      field: "womUpdateDate",
      headerName: "Updated Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "womUpdateTime",
      headerName: "Updated Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "womServId",
      headerName: "Service Task",
      type: "select",
      textFieldWidth: medium,
      width: GridWidthMedium,
      options: getWorkOrderTasksData?.map((o) => ({
        label: o.wosTaskCode,
        id: o.wosId,
      })),
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.wom_message || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.wom_message)
    ) {
      configColumns = updatedConfig.sub_component.wom_message;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        wom_message: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.wom_message
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.wom_message
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => !col.hide);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (messagesColumns) {
      setColumns(messagesColumns);
    } else {
      fetchColumnConfig();
    }
  }, [messagesColumns, columnConfig, defaultConfig, workOrderId]);
  const onAdd = () => {
    setShowMessageEditForm(true);
    setShowMessageForm(true);
    setEditDefValues({});
  };
  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleWorkOrderMessageEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isMessageFetching}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"WorkOrder Message"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"womWoIdFk"}
      />

      {showMessageEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showMessageForm}
          setShowForm={setShowMessageForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitMessageData}
          editId={id}
          defaultValues={editDefValues}
          label={"Update"}
          isLoading={updateMessageLoading || addMessageLoading}
          isMessageForm={true}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Update?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteAssetLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
