import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContextProvider";
import { CommonDataProvider } from "./context/CommonDataProvider";
import { ProtectedRouteContent } from "./layout/ProtectedRouteContent";
import HomePage from "./pages/HomePage";
import PageNotFound from "./pages/PageNotFound";
import PageUnderDevelopment from "./pages/PageUnderDevelopment";
// import ForgotCredentialsModal from "./pages/ForgotCredentialsModal";
import SignIn from "./pages/SignIn";
// import ResetPassword from "./pages/ResetPassword";
import SignUp from "./pages/SignUp";
import BusinessUnit from "./pages/admin/BusinessUnit";
import Company from "./pages/admin/Company";
import Department from "./pages/admin/Department";
import AssetManagement from "./pages/home/AssetManagement";
import Dashboard from "./pages/home/Dashboard";
import MyCalendar from "./pages/home/MyCalendar";
import Notification from "./pages/home/Notification";
import { WorkOrder } from "./pages/home/WorkOrder";
import PartsQuery from "./pages/plant/PartsQuery";
import PlantView from "./pages/plant/PlantView";
import AssetList from "./pages/report/AssetList";
import AssetUsage from "./pages/report/AssetUsage";
import WorkorderList from "./pages/report/WorkorderList";
import MaterialList from "./pages/report/MaterialList";
import InventoryTracking from "./pages/report/InventoryTracking";
import ServiceForecast from "./pages/report/ServiceForecast";
import AppSettings from "./pages/settings/AppSettings";
import CompanyInfo from "./pages/settings/CompanyInfo";
import IncidentItem from "./pages/admin/IncidentItem";
import IncidentClass from "./pages/admin/IncidentClass";
import AppConfig from "./pages/admin/AppConfig";
import { WorkOrderMultiStep } from "./pages/home/workordersteps/WorkOrderMultiStep";
import UserGroups from "./pages/admin/UserGroups";
import { AssetCategory } from "./pages/admin/AssetCategory";
import { AssetGroup } from "./pages/admin/AssetGroup";
import { Manufacture } from "./pages/admin/Manufacture";
import { BusinessPartner } from "./pages/home/BusinessPartner";
import { Units } from "./pages/admin/Units";
import { Project } from "./pages/home/Project";
import { Currency } from "./pages/admin/Currency";
import { CheckListGroup } from "./pages/admin/CheckListGroup";
import { CheckListTemplate } from "./pages/admin/CheckListTemplate";
import { Employee } from "./pages/admin/Employee";
import { Dimension } from "./pages/admin/Dimension";
import { Services } from "./pages/home/Services";
import MaterialGroup from "./pages/admin/MaterialGroup";
import { MaterialType } from "./pages/admin/MaterialType";
import { Materials } from "./pages/home/Materials";
import { CalendarSystem } from "./pages/admin/CalendarSystem";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import ScheduleMaintenance from "./pages/home/ScheduleMaintenance";
import { Requests } from "./pages/home/Requests";
import { Users } from "./pages/admin/Users";
import { MenuContextProvider } from "./context/MenuContextProvider";
import Global from "./pages/admin/Global";
import Help from "./pages/help/Help";
import { ToastContainer } from "react-toastify";
export const routes = [
  {
    route: "/home",
    Component: HomePage,
    isProtected: true,
  },
  {
    route: "/login",
    Component: SignIn,
  },
  {
    route: "/sign-up",
    Component: SignUp,
  },

  // { route: "/forgot-password", Component: ForgotCredentialsModal },
  // { route: "/reset-password", Component: ResetPassword },

  { route: "/home/my-calendar", Component: MyCalendar, isProtected: true },

  { route: "/home/work-order", Component: WorkOrder, isProtected: true },
  { route: "/home/dashboard", Component: Dashboard, isProtected: true },
  {
    route: "/home/notification",
    Component: Notification,
    isProtected: true,
  },
  {
    route: "/home/asset-management",
    Component: AssetManagement,
    isProtected: true,
    subMenu: "asset-mgmt",
  },
  {
    route: "/home/work-order/edit/:id",
    Component: WorkOrderMultiStep,
    isProtected: true,
  },
  {
    route: "/home/project",
    Component: Project,
    isProtected: true,
  },
  {
    route: "/plant/parts-query",
    Component: PartsQuery,
    isProtected: true,
  },
  {
    route: "/report/asset-list",
    Component: AssetList,
    isProtected: true,
  },
  {
    route: "/report/material-list",
    Component: MaterialList,
    isProtected: true,
  },
  {
    route: "/report/asset-usage",
    Component: AssetUsage,
    isProtected: true,
  },
  {
    route: "/report/service-forecast",
    Component: ServiceForecast,
    isProtected: true,
  },
  {
    route: "/report/inventory-tracking",
    Component: InventoryTracking,
    isProtected: true,
  },
  {
    route: "/report/workorder-list",
    Component: WorkorderList,
    isProtected: true,
  },
  {
    route: "/admin/company",
    Component: Company,
    isProtected: true,
  },
  {
    route: "/admin/user-groups",
    Component: UserGroups,
    isProtected: true,
  },
  {
    route: "/admin/business-unit",
    Component: BusinessUnit,
    isProtected: true,
  },
  {
    route: "/admin/department",
    Component: Department,
    isProtected: true,
  },
  {
    route: "/settings/plant",
    Component: PlantView,
    isProtected: true,
  },
  {
    route: "/settings/app-settings",
    Component: AppSettings,
    isProtected: true,
  },
  {
    route: "/settings/company-info",
    Component: CompanyInfo,
    isProtected: true,
  },
  {
    route: "/admin/incident-class",
    Component: IncidentClass,
    isProtected: true,
  },
  {
    route: "/admin/incident-item",
    Component: IncidentItem,
    isProtected: true,
  },
  {
    route: "/admin/app-config",
    Component: AppConfig,
    isProtected: true,
  },
  {
    route: "/admin/global",
    Component: Global,
    isProtected: true,
  },
  {
    route: "/admin/asset-category",
    Component: AssetCategory,
    isProtected: true,
  },
  {
    route: "/admin/manufacture",
    Component: Manufacture,
    isProtected: true,
  },
  {
    route: "/home/business-partner",
    Component: BusinessPartner,
    isProtected: true,
  },
  {
    route: "/admin/units",
    Component: Units,
    isProtected: true,
  },
  {
    route: "/admin/asset-group",
    Component: AssetGroup,
    isProtected: true,
  },
  {
    route: "/admin/currency",
    Component: Currency,
    isProtected: true,
  },
  {
    route: "/admin/checklist-group",
    Component: CheckListGroup,
    isProtected: true,
  },
  {
    route: "/admin/checklist-template",
    Component: CheckListTemplate,
    isProtected: true,
  },
  {
    route: "/admin/employee",
    Component: Employee,
    isProtected: true,
  },
  {
    route: "/admin/dimension",
    Component: Dimension,
    isProtected: true,
  },
  {
    route: "/home/services",
    Component: Services,
    isProtected: true,
  },
  {
    route: "/admin/material-group",
    Component: MaterialGroup,
    isProtected: true,
  },
  {
    route: "/admin/material-type/group",
    Component: MaterialType,
    isProtected: true,
  },
  {
    route: "/home/material",
    Component: Materials,
    isProtected: true,
  },
  {
    route: "/admin/calendar-system",
    Component: CalendarSystem,
    isProtected: true,
  },
  {
    route: "/home/scheduled-maintenance",
    Component: ScheduleMaintenance,
    isProtected: true,
  },
  {
    route: "/home/requests",
    Component: Requests,
    isProtected: true,
  },
  {
    route: "/admin/users",
    Component: Users,
    isProtected: true,
  },
  { route: "/help/help", Component: Help, isProtected: true },
  {
    route: "*",
    Component: PageNotFound,
    isProtected: true,
  },
];

function App() {
  const queryClient = new QueryClient();
  return (
    <>
      <ToastContainer />
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <BrowserRouter>
            <Routes>
              {routes.map(({ route, Component, isProtected = false }) => {
                return (
                  <Route
                    key={route}
                    path={route}
                    element={
                      isProtected ? (
                        <AuthContextProvider>
                          <CommonDataProvider>
                            <MenuContextProvider>
                              <ProtectedRouteContent Component={Component} />
                            </MenuContextProvider>
                          </CommonDataProvider>
                        </AuthContextProvider>
                      ) : (
                        <Component />
                      )
                    }
                  />
                );
              })}
            </Routes>
          </BrowserRouter>
        </DndProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
