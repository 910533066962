import React, { useEffect, useState, useCallback } from "react";
import { Box, SwipeableDrawer, Tooltip, Button, Dialog } from "@mui/material";
import { toast } from "react-toastify";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import { WorkOrderMultiStep } from "./workordersteps/WorkOrderMultiStep";
import { Progressor } from "../../common/Progressor";
import numeral from "numeral";
import {
  getFormattedDate,
  getFormattedTime,
  mapDropdownValueToDescWithId,
  mapDropdownValueToIdWithDesc,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
  modifyOptionsWithCodeAndTag,
} from "../../utils/common";
import { useCommonData } from "../../context/CommonDataProvider";
import {
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  WorkOrderPrefix,
  dateWidth,
  gridHeight,
  medium,
  small,
  defaultNumberFormatePrice,
  defaultDateFormate,
} from "../../constants/global";
import DeleteDialog from "../../common/DeleteDialog";
import { AddEditAccordionForm } from "../../common/AddEditAccordionForm";
import AddEditToolBar from "../../common/AddEditToolBar";
import { useMenuContext } from "../../context/MenuContextProvider";
import dayjs from "dayjs";
import ColumnCustomizationPopup from "./ColumnCustomizationPopup";
import ViewDropdown from "./ViewDropdown";
import useColumnConfigStore from "./columnConfigStore";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";

export const WorkOrder = () => {
  const [viewLoaded, setViewLoaded] = useState(false);
  const [viewApplied, setViewApplied] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [woId, setWoId] = useState(null);
  const userId = commonData.userId;
  const plantId = commonData?.userData?.lastLoginPlantID;
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [workOrderId, setWorkOrderId] = useState(null);
  const [editDefValues, setEditDefValues] = useState();
  const [showWorkOrderForm, setShowWorkOrderForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showWorkOrderEditForm, setShowWorkOrderEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const [openReport, setOpenReport] = useState(false);
  const [reportInstance, setReportInstance] = useState(null);
  const viewerRef = useCallback((instance) => {
    if (instance) {
      setReportInstance(instance);
    }
  }, []);

  async function loadReport() {
    try {
      const module = await import(
        "../report/reports/workorder-register.rdlx-json"
      );
      const response = await fetch(module.default);
      const text = await response.text();
      return JSON.parse(text);
    } catch (error) {
      console.error("Error loading report:", error);
      throw error;
    }
  }

  async function loadData() {
    const selectedAsset = rows.find((row) => row.id === workOrderId);
    if (!selectedAsset) {
      console.error("Selected wo not found");
      return {};
    }

    const transformedRow = {};
    for (const [key, value] of Object.entries(selectedAsset)) {
      transformedRow[key] = value !== null ? String(value) : "";
    }

    return {
      Field1: [transformedRow],
    };
  }

  async function openReports() {
    try {
      const data = await loadData();
      console.log("Data being sent to report:", data);

      const report = await loadReport();
      if (!report || !report.DataSources || report.DataSources.length === 0) {
        console.error("Invalid report structure:", report);
        return;
      }

      report.DataSources[0].ConnectionProperties.ConnectString = `jsondata=${JSON.stringify(
        data
      )}`;
      report.DataSources[0].ConnectionProperties.DataProvider = "JSON";

      const userId = commonData?.userId || "";
      if (reportInstance && reportInstance.Viewer) {
        reportInstance.Viewer.open(report, {
          ReportParams: [
            {
              Name: "User",
              Value: userId,
            },
            {
              Name: "Plant",
              Value: plantId,
            },
          ],
        });
      } else {
        console.error("Report instance or Viewer is not available");
      }
    } catch (error) {
      console.error("Error opening report:", error);
    }
  }

  useEffect(() => {
    if (openReport && reportInstance && workOrderId) {
      openReports();
    }
  }, [openReport, reportInstance, workOrderId]);

  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const [editData, setEditData] = useState();
  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setWorkOrderId(params.id);
  };

  const toggleDrawer = (newOpen, params) => () => {
    setOpen(newOpen);
  };
  const getCellValue = () => {
    setOpen(true);
  };
  const { data: getWorkOrderNumber, refetch: isGetWorkOrderNumber } =
    useFetchApi({
      endpoint: `${WorkOrderPrefix}/work-order-number/${plantId}`,
      retrieveOnMount: plantId && rightsArray[0]?.urMenuIdFk ? true : false,
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    mutateAsync: addWorkOrder,
    isSuccess: isWorkOrderAddSuccess,
    isLoading: addingWorkorder,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/addWorkorder`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrder,
    isSuccess: isUpdateSuccess,
    isLoading: updatingWorkorder,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/updateworkorder?workOrderId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteWorkOrder, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${WorkOrderPrefix}/deleteworkorder?workOrderId=`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: workOrderDataList,
    isFetchedAfterMount: isFetch,
    isFetching: isWorkOrderLoading,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}?plantId=${plantId}`,
    retrieveOnMount: plantId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderAddSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
      isGetWorkOrderNumber();
    }
  }, [
    isWorkOrderAddSuccess,
    isUpdateSuccess,
    isDeleteSuccess,
    refetch,
    isGetWorkOrderNumber,
  ]);

  const [workOrderData, setWorkOrderData] = useState(workOrderDataList);
  useEffect(() => {
    if (workOrderDataList) {
      const workOrderDataItem = workOrderDataList.map((item) => {
        const newWorkOrderPriority = mapDropdownValueToLabelWithListId(
          commonData.workOrderPriority,
          item.woPriority
        );
        const newWorkOrderDepartment = mapDropdownValueToLabelWithListId(
          commonData.department,
          item.woDepartment
        );
        const newWorkOrderAccount = mapDropdownValueToLabelWithListId(
          commonData.workOrderAccount,
          item.woAccount
        );
        const newWorkType = mapDropdownValueToLabelWithListId(
          commonData.workType,
          item.woWorkType
        );
        const newWorkOrderType = mapDropdownValueToLabelWithListId(
          commonData.workOrderType,
          item.woWorkOrderType
        );
        const newWorkOrderAssetCriticality = mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticality,
          item.woAssetCrtically
        );
        const newWorkOrderAssetCriticalityImpact =
          mapDropdownValueToLabelWithListId(
            commonData.workOrderAssetCriticalityImpact,
            item.woCriticallyImapact
          );
        const newWorkOrderStatus = mapDropdownValueToLabelWithListId(
          commonData.workOrderStatus,
          item.woStatus
        );
        const newAssetsBuilding = mapDropdownValueToLabelWithListId(
          commonData.assetsBuilding,
          item.woBuilding
        );
        const newAssetsZone = mapDropdownValueToLabelWithListId(
          commonData.assetsZone,
          item.woZone
        );
        const newWorkOrderSubStatus = mapDropdownValueToLabelWithListId(
          commonData.workOrderSubStatus,
          item.woSubStatus
        );
        const newWorkOrderCompletedBy = mapDropdownValueToLabelWithId(
          commonData.employeDetails,
          parseInt(item.woCompletedBy)
        );
        const newWorkOrderIssuedBy = mapDropdownValueToLabelWithId(
          commonData.employeDetails,
          parseInt(item.woIssuedBy)
        );
        const newWorkOrderRequestedBy = mapDropdownValueToLabelWithId(
          commonData.employeDetails,
          parseInt(item.woRequestedby)
        );
        const newWorkOrderApprovedBy = mapDropdownValueToLabelWithId(
          commonData.employeDetails,
          parseInt(item.woApprovedBy)
        );
        const newParty = mapDropdownValueToLabelWithId(
          commonData.getSupplierList,
          item.woBusIdFk
        );
        const newParentAsset = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.woAsstIdFk
        );
        const newAssetGroup = mapDropdownValueToLabelWithId(
          commonData.getAssetGroupList,
          item.woAsgpIdFk
        );
        const newCbs = mapDropdownValueToLabelWithId(
          commonData.getCbsDataList,
          item.woCbsIdFk
        );
        const newProject = mapDropdownValueToLabelWithId(
          commonData.getProjectList,
          item.woProjectIdFk
        );
        return {
          ...item,
          woPriority: newWorkOrderPriority,
          woAccount: newWorkOrderAccount,
          woWorkType: newWorkType,
          woWorkOrderType: newWorkOrderType,
          woAssetCrtically: newWorkOrderAssetCriticality,
          woCriticallyImapact: newWorkOrderAssetCriticalityImpact,
          woStatus: newWorkOrderStatus,
          woSubStatus: newWorkOrderSubStatus,
          woBuilding: newAssetsBuilding,
          woZone: newAssetsZone,
          woCompletedBy: newWorkOrderCompletedBy,
          woIssuedBy: newWorkOrderIssuedBy,
          woRequestedby: newWorkOrderRequestedBy,
          woApprovedBy: newWorkOrderApprovedBy,
          woBusIdFk: newParty,
          woAsstIdFk: newParentAsset,
          woAsgpIdFk: newAssetGroup,
          woCbsIdFk: newCbs,
          woProjectIdFk: newProject,
          woDepartment: newWorkOrderDepartment,
        };
      });
      setWorkOrderData(workOrderDataItem);
    }
  }, [commonData, workOrderDataList]);

  useEffect(() => {
    if (isFetch && workOrderData && canRead) {
      setRows(() =>
        workOrderData?.map((w) => {
          return { id: w.woId, ...w };
        })
      );
    }
  }, [isFetch, workOrderData, rightsArray, canRead]);
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getAssetDataList,
    "asstTag",
    "asstDesc"
  );
  const GENERAL_FIELDS = [
    {
      field: "woNumber",
      headerName: "Work Order No",
      hideable: false,
      type: "number",
      maxLength: 12,
      textFieldWidth: medium,
      section: "General",
      value: getWorkOrderNumber,
      width: GridWidthMedium,
      disable: true,
    },
    {
      field: "woDate",
      headerName: "Work Order Date",
      type: "Date",
      required: true,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woIdate",
      headerName: "Incident Date",
      type: "Date",
      required: true,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woItime",
      headerName: "Incident Time",
      type: "time",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedTime(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woDesc",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthLargeWidth,
    },

    {
      field: "woPriority",
      headerName: "Priority Level",
      type: "select",
      required: false,
      apiendpoint: "workOrderPriority",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woRequestedby",
      headerName: "Requested By",
      type: "select",
      required: false,
      options: commonData?.employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woAccount",
      headerName: "Account",
      type: "select",
      required: false,
      apiendpoint: "workOrderAccount",
      maxLength: 12,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woBusIdFk",
      headerName: "Party",
      type: "select",
      required: false,
      apiendpoint: "getSupplierList",
      maxLength: 12,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woName",
      headerName: "Name",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woEmail",
      headerName: "Email",
      type: "email",
      required: false,
      maxLength: 30,
      width: GridWidthMedium,
    },
    {
      field: "woPhone",
      headerName: "Phone",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woAddress",
      headerName: "Address",
      type: "text",
      maxLength: 20,
      width: GridWidthMedium,
    },
  ];

  const LOCATION_FIELDS = [
    {
      field: "woDepartment",
      headerName: "Department",
      type: "select",
      required: false,
      apiendpoint: "department",
      maxLength: 20,
      textFieldWidth: medium,
      section: "Location",
      width: GridWidthMedium,
    },
    {
      field: "woBuilding",
      headerName: "Building",
      type: "select",
      required: false,
      apiendpoint: "assetsBuilding",
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woZone",
      headerName: "Zone",
      type: "select",
      required: false,
      apiendpoint: "assetsZone",
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woLocDesc",
      headerName: "Location",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
  ];

  const SCOPE_FIELDS = [
    {
      field: "woCbsIdFk",
      headerName: "Structure",
      type: "select",
      required: false,
      maxLength: 20,
      textFieldWidth: medium,
      section: "Scope",
      apiendpoint: "getCbsDataList",
      width: GridWidthMedium,
    },
    {
      field: "woBusinessNeed",
      headerName: "Business Need",
      type: "text",
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "woTerms",
      headerName: "Terms",
      type: "text",
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "woScope",
      headerName: "Scope",
      type: "text",
      required: false,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "woRemarks",
      headerName: "Remarks",
      type: "text",
      required: false,
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "woAsgpIdFk",
      headerName: "Asset Group",
      type: "select",
      required: false,
      textFieldWidth: medium,
      apiendpoint: "getAssetGroupList",
      width: GridWidthMedium,
    },
    {
      field: "woAsstIdFk",
      headerName: "Asset",
      type: "selectTwo",
      required: false,
      options: modifiedOptions,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
  ];

  const STATUS_INFO_FIELDS = [
    {
      field: "woWorkType",
      headerName: "Work Status",
      type: "select",
      required: false,
      apiendpoint: "workType",
      textFieldWidth: medium,
      section: "Status Info",
      width: GridWidthMedium,
    },
    {
      field: "woAsstDownTime",
      headerName: "Asset Down Time(Hrs)",
      type: "number",
      required: false,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woWorkOrderType",
      headerName: "Work Order Type",
      type: "select",
      required: false,
      apiendpoint: "workOrderType",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woAssetCrtically",
      headerName: "Asset Criticality",
      type: "select",
      required: false,
      apiendpoint: "workOrderAssetCriticality",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woCriticallyImapact",
      headerName: "Asset Critically Impact",
      type: "select",
      required: false,
      apiendpoint: "workOrderAssetCriticalityImpact",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woNotes",
      headerName: "Notes",
      type: "text",
      required: false,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
  ];

  const JOB_FIELDS = [
    {
      field: "woProjectIdFk",
      headerName: "Project",
      type: "select",
      required: false,
      apiendpoint: "getProjectList",
      textFieldWidth: dateWidth,
      section: "Job",
      width: GridWidthMedium,
    },
    {
      field: "woPsDate",
      headerName: "Planned Start Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woPeDate",
      headerName: "Planned Finish Date",
      type: "Date",
      required: false,
      minDateLabel: "woPsDate",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woAsDate",
      headerName: "Actual Start Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
      maxDateLabel: "woAeDate",
    },
    {
      field: "woAeDate",
      headerName: "Actual Finish Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
      minDateLabel: "woAsDate",
    },
    {
      field: "woApprovedBy",
      headerName: "Approved By",
      type: "select",
      required: false,
      options: commonData?.employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woIssuedBy",
      headerName: "Issued By",
      type: "select",
      required: false,
      options: commonData?.employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woCompletedBy",
      headerName: "Work Completed By",
      type: "select",
      required: false,
      maxLength: 20,
      textFieldWidth: medium,
      options: commonData?.employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "woStatus",
      headerName: "Work Order Main Status",
      type: "select",
      required: false,
      apiendpoint: "workOrderStatus",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
    {
      field: "woSubStatus",
      headerName: "Work Order Sub Status",
      type: "select",
      required: false,
      apiendpoint: "workOrderSubStatus",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
  ];

  const VALUE_FIELDS = [
    {
      field: "woEstimateValue",
      headerName: "Estimate Cost",
      type: "number",
      required: false,
      textFieldWidth: small,
      section: "Value",
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "woActualValue",
      headerName: "Actual Cost",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "woEhours",
      headerName: "Estimate Hours",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "woAhours",
      headerName: "Actual Hours",
      type: "number",
      required: false,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
  ];

  const formFields = [
    { label: "General", fields: GENERAL_FIELDS },
    { label: "Location", fields: LOCATION_FIELDS },
    { label: "Scope", fields: SCOPE_FIELDS },
    { label: "Status Info", fields: STATUS_INFO_FIELDS },
    { label: "Job", fields: JOB_FIELDS },
    { label: "Value", fields: VALUE_FIELDS },
  ];

  const handleWorkEditClick = () => {
    setShowWorkOrderEditForm(true);
    setId(editData.id);
    const newWorkOrderPriority = mapDropdownValueToListIdWithLabel(
      commonData.workOrderPriority,
      editData.woPriority
    );
    const newWorkOrderDepartment = mapDropdownValueToListIdWithLabel(
      commonData.department,
      editData.woDepartment
    );
    const newWorkOrderAccount = mapDropdownValueToListIdWithLabel(
      commonData.workOrderAccount,
      editData.woAccount
    );
    const newWorkType = mapDropdownValueToListIdWithLabel(
      commonData.workType,
      editData.woWorkType
    );
    const newWorkOrderType = mapDropdownValueToListIdWithLabel(
      commonData.workOrderType,
      editData.woWorkOrderType
    );
    const newWorkOrderAssetCriticality = mapDropdownValueToListIdWithLabel(
      commonData.workOrderAssetCriticality,
      editData.woAssetCrtically
    );
    const newWorkOrderAssetCriticalityImpact =
      mapDropdownValueToListIdWithLabel(
        commonData.workOrderAssetCriticalityImpact,
        editData.woCriticallyImapact
      );
    const newWorkOrderStatus = mapDropdownValueToListIdWithLabel(
      commonData.workOrderStatus,
      editData.woStatus
    );
    const newAssetsBuilding = mapDropdownValueToListIdWithLabel(
      commonData.assetsBuilding,
      editData.woBuilding
    );
    const newAssetsZone = mapDropdownValueToListIdWithLabel(
      commonData.assetsZone,
      editData.woZone
    );
    const newWorkOrderSubStatus = mapDropdownValueToListIdWithLabel(
      commonData.workOrderSubStatus,
      editData.woSubStatus
    );
    const newWorkOrderCompletedBy = mapDropdownValueToIdWithLabel(
      commonData.employeDetails,
      editData.woCompletedBy
    );
    const newWorkOrderIssuedBy = mapDropdownValueToIdWithLabel(
      commonData.employeDetails,
      editData.woIssuedBy
    );
    const newWorkOrderRequestedBy = mapDropdownValueToIdWithLabel(
      commonData.employeDetails,
      editData.woRequestedby
    );
    const newWorkOrderApprovedBy = mapDropdownValueToIdWithLabel(
      commonData.employeDetails,
      editData.woApprovedBy
    );
    const newParty = mapDropdownValueToIdWithLabel(
      commonData.getSupplierList,
      editData.woBusIdFk
    );
    const newParentAsset = mapDropdownValueToIdWithDesc(
      modifiedOptions,
      editData.woAsstIdFk
    );
    const newAssetGroup = mapDropdownValueToIdWithLabel(
      commonData.getAssetGroupList,
      editData.woAsgpIdFk
    );
    const newCbs = mapDropdownValueToIdWithLabel(
      commonData.getCbsDataList,
      editData.woCbsIdFk
    );
    const newProject = mapDropdownValueToIdWithLabel(
      commonData.getProjectList,
      editData.woProjectIdFk
    );
    setEditDefValues({
      ...editData,
      woPriority: newWorkOrderPriority,
      woAccount: newWorkOrderAccount,
      woWorkType: newWorkType,
      woWorkOrderType: newWorkOrderType,
      woAssetCrtically: newWorkOrderAssetCriticality,
      woCriticallyImapact: newWorkOrderAssetCriticalityImpact,
      woStatus: newWorkOrderStatus,
      woSubStatus: newWorkOrderSubStatus,
      woBuilding: newAssetsBuilding,
      woZone: newAssetsZone,
      woCompletedBy: newWorkOrderCompletedBy,
      woIssuedBy: newWorkOrderIssuedBy,
      woRequestedby: newWorkOrderRequestedBy,
      woApprovedBy: newWorkOrderApprovedBy,
      woBusIdFk: newParty,
      woAsstIdFk: newParentAsset,
      woAsgpIdFk: newAssetGroup,
      woCbsIdFk: newCbs,
      woProjectIdFk: newProject,
      woDepartment: newWorkOrderDepartment,
    });
  };

  const saveUpdateData = async (workDataToSave) => {
    if (dayjs(workDataToSave?.woPsDate).isAfter(workDataToSave?.woPeDate)) {
      toast.error(
        "Planned End date should be greater than or equal to Planned Start date"
      );
    } else if (
      dayjs(workDataToSave?.woAsDate).isAfter(workDataToSave?.woAeDate)
    ) {
      toast.error(
        "Actual End date should be greater than or equal to Actual Start date"
      );
    } else {
      updateWorkOrder({
        id: workDataToSave.id,
        woId: workDataToSave.woId,
        woNumber: workDataToSave.woNumber,
        woDate: workDataToSave.woDate,
        woName: workDataToSave.woName,
        woEmail: workDataToSave.woEmail,
        woPhone: workDataToSave.woPhone,
        woPsDate: workDataToSave.woPsDate,
        woPeDate: workDataToSave.woPeDate,
        woAsDate: workDataToSave.woAsDate,
        woAeDate: workDataToSave.woAeDate,
        woBusinessNeed: workDataToSave.woBusinessNeed,
        woDesc: workDataToSave.woDesc,
        woScope: workDataToSave.woScope,
        woRemarks: workDataToSave.woRemarks,
        woPlantIdFk: workDataToSave.woPlantIdFk,
        woLocDesc: workDataToSave.woLocDesc,
        woAddress: workDataToSave.woAddress,
        woNotes: workDataToSave.woNotes,
        woAsstDownTime: workDataToSave.woAsstDownTime,
        woDownTimeUom: workDataToSave.woDownTimeUom,
        woEstimateValue: workDataToSave.woEstimateValue,
        woActualValue: workDataToSave.woActualValue,
        woEhours: workDataToSave.woEhours,
        woAhours: workDataToSave.woAhours,
        woRdate: workDataToSave.woRdate,
        woRtime: workDataToSave.woRtime,
        woIdate: workDataToSave.woIdate,
        woItime: workDataToSave.woItime
          ? dayjs(workDataToSave.woItime).isValid()
            ? dayjs(workDataToSave.woItime).format("HH:mm:ss")
            : workDataToSave.woItime
          : null,
        woTerms: workDataToSave.woTerms,
        woDocUrl: workDataToSave.woDocUrl,
        woDocType: workDataToSave.woDocType,
        woCurIdFk: workDataToSave.woCurIdFk,
        woCurXrate: workDataToSave.woCurXrate,
        woMaintIdFk: workDataToSave.woMaintIdFk,
        woModifiedDate: workDataToSave.woModifiedDate,
        woModifiedBy: workDataToSave.woModifiedBy,
        woAccount: workDataToSave.woAccount?.listId,
        woApprovedBy: workDataToSave.woApprovedBy?.id,
        woAsgpIdFk: workDataToSave.woAsgpIdFk?.id,
        woAssetCrtically: workDataToSave.woAssetCrtically?.listId,
        woAsstIdFk: workDataToSave.woAsstIdFk?.asstId,
        woBuilding: workDataToSave.woBuilding?.listId,
        woCbsIdFk: workDataToSave.woCbsIdFk?.id,
        woCompletedBy: workDataToSave.woCompletedBy?.id,
        woCriticallyImapact: workDataToSave.woCriticallyImapact?.listId,
        woDepartment: workDataToSave.woDepartment?.listId,
        woIssuedBy: workDataToSave.woIssuedBy?.id,
        woPriority: workDataToSave.woPriority?.listId,
        woRequestedby: workDataToSave.woRequestedby?.id,
        woStatus: workDataToSave.woStatus?.listId,
        woSubStatus: workDataToSave.woSubStatus?.listId,
        woWorkOrderType: workDataToSave.woWorkOrderType?.listId,
        woWorkType: workDataToSave.woWorkType?.listId,
        woZone: workDataToSave.woZone?.listId,
        woProjectIdFk: workDataToSave?.woProjectIdFk?.id,
        woBusIdFk: workDataToSave?.woBusIdFk?.id,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Work Order Update successfully");
            setShowWorkOrderEditForm(false);
            setId();
            setWorkOrderId();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const submitAddWorkData = async (newWorkOrderAddData) => {
    if (
      dayjs(newWorkOrderAddData?.woPsDate).isAfter(
        newWorkOrderAddData?.woPeDate
      )
    ) {
      toast.error(
        "Planned End date should be greater than or equal to Planned Start date"
      );
    } else if (
      dayjs(newWorkOrderAddData?.woAsDate).isAfter(
        newWorkOrderAddData?.woAeDate
      )
    ) {
      toast.error(
        "Actual End date should be greater than or equal to Actual Start date"
      );
    } else {
      addWorkOrder({
        ...{
          ...newWorkOrderAddData,
          woPlantIdFk: plantId,
          woCurIdFk: newWorkOrderAddData?.woCurIdFk
            ? newWorkOrderAddData?.woCurIdFk
            : null,
          woAccount: newWorkOrderAddData.woAccount?.listId,
          woApprovedBy: newWorkOrderAddData.woApprovedBy?.id,
          woAsgpIdFk: newWorkOrderAddData.woAsgpIdFk?.id,
          woAssetCrtically: newWorkOrderAddData.woAssetCrtically?.listId,
          woAsstIdFk: newWorkOrderAddData.woAsstIdFk?.asstId,
          woBuilding: newWorkOrderAddData.woBuilding?.listId,
          woCbsIdFk: newWorkOrderAddData.woCbsIdFk?.id,
          woCompletedBy: newWorkOrderAddData.woCompletedBy?.id,
          woCriticallyImapact: newWorkOrderAddData.woCriticallyImapact?.listId,
          woDepartment: newWorkOrderAddData.woDepartment?.listId,
          woPriority: newWorkOrderAddData.woPriority?.listId,
          woRequestedby: newWorkOrderAddData.woRequestedby?.id,
          woStatus: newWorkOrderAddData.woStatus?.listId,
          woSubStatus: newWorkOrderAddData.woSubStatus?.listId,
          woWorkOrderType: newWorkOrderAddData.woWorkOrderType?.listId,
          woWorkType: newWorkOrderAddData.woWorkType?.listId,
          woZone: newWorkOrderAddData.woZone?.listId,
          woIssuedBy: newWorkOrderAddData.woIssuedBy?.id,
          woProjectIdFk: newWorkOrderAddData?.woProjectIdFk?.id,
          woBusIdFk: newWorkOrderAddData?.woBusIdFk?.id,
          woItime: newWorkOrderAddData.woItime
            ? dayjs(newWorkOrderAddData.woItime).isValid()
              ? dayjs(newWorkOrderAddData.woItime).format("HH:mm:ss")
              : newWorkOrderAddData.woItime
            : null,
        },
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Work Order Added successfully");
            setShowWorkOrderEditForm(!showWorkOrderEditForm);
            setShowWorkOrderForm(false);
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const handleDeleteClick = async () => {
    deleteWorkOrder({ dynamicEndpointSuffix: id })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Work Order Delete successfully");
          setOpenDialog(false);
          setId();
          setWorkOrderId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowWorkOrderEditForm(!showWorkOrderEditForm);
    setShowWorkOrderForm(false);
  };
  const allcolumns = [
    ...GENERAL_FIELDS,
    ...LOCATION_FIELDS,
    ...SCOPE_FIELDS,
    ...STATUS_INFO_FIELDS,
    ...JOB_FIELDS,
    ...VALUE_FIELDS,
  ];
  const [employeeColumns, setEmployeeColumns] = useState([
    {
      field: "action",
      headerName: "Action",
      type: "",
      sortable: false,
      hideable: false,
      width: 100,
      required: false,
    },
    {
      field: "woeWoIdFk",
      headerName: "Work Order Id",
    },

    {
      field: "woeEmpIdFk",
      headerName: "Employee id fk ",
      required: true,
      type: "select",
      textFieldWidth: "50%",
    },
    {
      field: "woeStartDate",
      headerName: "WorkOrder start Date",
      type: "Date",
      textFieldWidth: "50%",
      valueFormatter: (params) => getFormattedDate(params.value),
    },
    {
      field: "woeEndDate",
      headerName: " WorkOrder EndDate",
      type: "Date",
      textFieldWidth: "50%",
      minDateLabel: "woeStartDate",
      valueFormatter: (params) => getFormattedDate(params.value),
    },
    {
      field: "woeRemarks",
      headerName: "Remarks",
      type: "text",
    },
    {
      field: "woeNotes",
      headerName: "Notes",
      type: "text",
    },
  ]);
  const [incidentColumns, setIncidentColumns] = useState([
    {
      field: "woiIncItemIdFk",
      headerName: "Incident",
      required: true,
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
    },
    {
      field: "woiDate",
      headerName: "Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woiTime",
      headerName: "Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
  ]);
  const [messagesColumns, setMessagesColumns] = useState([
    {
      field: "womMessage",
      headerName: "Messages",
      required: true,
      type: "text",
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "womStatus",
      headerName: "Work Order Sub Status",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "workOrderSubStatus",
      width: GridWidthMedium,
    },
    {
      field: "womUpdatedById",
      headerName: "Updated By",
      type: "select",
      textFieldWidth: small,
      apiendpoint: "employeDetails",
      width: GridWidthMedium,
    },
    {
      field: "womUpdateDate",
      headerName: "Updated Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "womUpdateTime",
      headerName: "Updated Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "womServId",
      headerName: "Service Task",
      type: "select",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
  ]);
  const [testingColumns, setTestingColumns] = useState([
    {
      field: "wotTestIdFk",
      headerName: "Test",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getTestingData",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "wotRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "wotTestBy",
      headerName: "Completed By",
      type: "select",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "wotStatus",
      headerName: "Status",
      type: "select",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "wotScore1",
      headerName: "Score 1",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore2",
      headerName: "Score 2",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore3",
      headerName: "Score 3",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore4",
      headerName: "Score 4",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wotScore5",
      headerName: "Score 5",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
  ]);
  const [workAssetColumns, setWorkAssetColumns] = useState([
    {
      field: "woaAsstIdFk",
      headerName: "Asset",
      type: "selectTwo",
      options: modifiedOptions,
      textFieldWidth: medium,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "woaIssue",
      headerName: "Issue",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woaRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 200,
      row: 4,
      multiline: "multiline",
      width: GridWidthLargeWidth,
    },
    {
      field: "woaIncItemIdFk",
      headerName: "Incident",
      type: "select",
      maxLength: 20,
      textFieldWidth: medium,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
    },
    {
      field: "woaFailureCode",
      headerName: "Failure Code",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woaIncidentTime",
      headerName: "Incident Time",
      textFieldWidth: medium,
      type: "time",
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "woaAssetCriticality",
      headerName: "Asset Criticality",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "workOrderAssetCriticality",
      width: GridWidthMedium,
    },
  ]);
  const [wostColumns, setWostColumns] = useState([
    {
      field: "wosTaskCode",
      headerName: "Task Code",
      required: true,
      type: "text",
      maxLength: 12,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wosTaskDesc",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      width: GridWidthMedium,
    },
    {
      field: "wosStartDate",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "wosStartTime",
      headerName: "Start Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "wosDuration",
      headerName: "Duration",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "wosUomIdFk",
      headerName: "Unit",
      type: "select",
      apiendpoint: "getUomDataList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "wosEndDate",
      headerName: "End Date",
      type: "Date",
      minDateLabel: "wosStartDate",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "wosEndTime",
      headerName: "End Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "wosStatus",
      headerName: "Status",
      type: "select",
      textFieldWidth: medium,
      maxLength: 20,
      apiendpoint: "workOrderSubStatus",
      width: GridWidthMedium,
    },
  ]);
  const [materialColumns, setMaterialColumns] = useState([
    {
      field: "womMaterialIdFk",
      headerName: "Description",
      type: "selectTwo",
      textFieldWidth: medium,
      options: modifiedOptions,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "womUomIdFk",
      headerName: "UoM",
      type: "select",
      apiendpoint: "getUomDataList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "womQty",
      headerName: "Qty",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
    },
    {
      field: "womCost",
      headerName: "Cost",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
    },
    {
      field: "womCbsIdFk",
      headerName: "Structure",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getCbsDataList",
      width: GridWidthMedium,
    },
  ]);

  const [checklistColumns, setChecklistColumns] = useState([
    {
      field: "chkListCode",
      headerName: "Code",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "chkListId",
      headerName: "Checklist Template Id",
      type: "number",
      hide: true,
    },
    {
      field: "chkListDesc",
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "wocWoIdFk",
      headerName: "Workorder Id",
      type: "number",
      hide: true,
    },
    {
      field: "wocStatus",
      headerName: "Status",
      type: "boolean",
      editable: true,
      width: GridWidthMedium,
      // renderCell: (params) => {
      //   const changeStatus = (event) => {
      //     handleChange(params, "wocStatus", event.target.checked);
      //   };
      //   return (
      //     <>
      //       <Checkbox
      //         size="small"
      //         onChange={changeStatus}
      //         checked={params.row.wocStatus}
      //         color="primary"
      //       />
      //       <p>{params.row.wocStatus}</p>
      //     </>
      //   );
      // },
    },
    {
      field: "wocRemarks",
      headerName: "Remarks",
      type: "text",
      width: GridWidthMedium,
      // renderCell: (params) => {
      //   const editRemarks = (event) => {
      //     handleChange(params, "wocRemarks", event.target.value);
      //   };
      //   return (
      //     <>
      //       <TextField onChange={editRemarks} value={params.row.wocRemarks} />
      //     </>
      //   );
      // },
    },
    {
      field: "wocCheckedBy",
      headerName: "Completed By",
      type: "singleSelect",
      width: GridWidthMedium,
      // renderCell: (params) => {
      //   const changeCheckedBy = (event) => {
      //     handleChange(params, "wocCheckedBy", event.target.value);
      //   };
      //   return (
      //     <>
      //       <Select
      //         size="medium"
      //         sx={{ width: "30vw" }}
      //         onChange={changeCheckedBy}
      //         value={params.row.wocCheckedBy}
      //       >
      //         {availableEmpOpt?.map((item) => {
      //           return (
      //             <MenuItem key={item.id} value={item.id}>
      //               {item.label}
      //             </MenuItem>
      //           );
      //         })}
      //       </Select>
      //     </>
      //   );
      // },
    },
    {
      field: "wocModifiedDate",
      headerName: "Updated Date",
      type: "date",
      hide: true,
    },
    {
      field: "wocModifiedBy",
      headerName: "Updated By",
      type: "number",
      hide: true,
    },
  ]);

  const [woDocColumns, setWoDocColumns] = useState([
    {
      field: "file",
      headerName: "File",
      type: "file",
      maxLength: 40,
      required: true,
      hide: true,
      width: GridWidthMedium,
    },
    {
      field: "docDescription",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "docUrl",
      headerName: "URL",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      width: GridWidthMedium,
    },
    {
      field: "docPath",
      headerName: "Path",
      type: "text",
      maxLength: 20,
      textFieldWidth: medium,
      disable: true,
      // renderCell: (params) => {
      //   const onClick = () => {
      //     fetchDocument(params.row.docId, params.row.docPath);
      //   };
      //   return (
      //     <Typography
      //       mt={1}
      //       component="a"
      //       sx={{
      //         color: "blue",
      //         textDecoration: "underline",
      //         cursor: "pointer",
      //         fontSize: "0.875rem",
      //       }}
      //       variant="body1"
      //       onClick={onClick}
      //     >
      //       {params.value}
      //     </Typography>
      //   );
      // },
      width: GridWidthLargeWidth,
    },
    {
      field: "docUpDate",
      headerName: "Date",
      type: "text",
      textFieldWidth: dateWidth,
      value: dayjs().format(defaultDateFormate),
      valueFormatter: (params) => getFormattedDate(params.value),
      disable: true,
      width: GridWidthMedium,
    },
  ]);

  const [resourceColumns, setResourceColumns] = useState([
    {
      field: "worEmpId",
      headerName: "Resource",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "employeDetails",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "worUomIdFk",
      headerName: "UoM",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getUomDataList",
      width: GridWidthMedium,
    },
    {
      field: "worStartDate",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "worEndDate",
      headerName: "End Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
      minDateLabel: "worStartDate",
    },
    {
      field: "worStatus",
      headerName: "Status",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "resourceStatus",
      width: GridWidthMedium,
    },
  ]);
  const [serviceColumns, setServiceColumns] = useState([
    {
      field: "wosSerIdFk",
      headerName: "Service",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getServiceDataList",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "wosUomIdFk",
      headerName: "UoM",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getUomDataList",
      width: GridWidthMedium,
    },
    {
      field: "wosQty",
      headerName: "Qty",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
    },
    {
      field: "wosCost",
      headerName: "Cost",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
    },
    {
      field: "wosCbsIdFk",
      headerName: "Structure",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getCbsDataList",
      width: GridWidthMedium,
    },
  ]);

  const [columns, setColumns] = useState(allcolumns);
  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    setColumns(updateColumns(columns, updatedConfig.grid.columns));
    setWorkAssetColumns(
      updateColumns(workAssetColumns, updatedConfig.sub_component?.woa_asset)
    );
    setTestingColumns(
      updateColumns(
        testingColumns,
        updatedConfig.sub_component?.wot_conditional_testing
      )
    );
    setIncidentColumns(
      updateColumns(incidentColumns, updatedConfig.sub_component?.woi_incident)
    );
    setMessagesColumns(
      updateColumns(messagesColumns, updatedConfig.sub_component?.wom_message)
    );
    setWostColumns(
      updateColumns(wostColumns, updatedConfig.sub_component?.wos_service_tasks)
    );
    setMaterialColumns(
      updateColumns(materialColumns, updatedConfig.sub_component?.wom_material)
    );
    setChecklistColumns(
      updateColumns(
        checklistColumns,
        updatedConfig.sub_component?.woc_checklist
      )
    );
    setWoDocColumns(
      updateColumns(woDocColumns, updatedConfig.sub_component?.doc_upload)
    );
    setResourceColumns(
      updateColumns(
        resourceColumns,
        updatedConfig.sub_component?.wor_resource_allocation
      )
    );
    setServiceColumns(
      updateColumns(serviceColumns, updatedConfig.sub_component?.services)
    );

    const configToUpdate = {
      ...updatedConfig,
      sub_component: {
        woa_asset: workAssetColumns,
        wot_conditional_testing: testingColumns,
        woi_incident: incidentColumns,
        wom_message: messagesColumns,
        wos_service_tasks: wostColumns,
        wom_material: materialColumns,
        woc_checklist: checklistColumns,
        doc_upload: woDocColumns,
        wor_resource_allocation: resourceColumns,
        services: serviceColumns,
      },
    };

    Object.keys(configToUpdate.sub_component).forEach((key) => {
      configToUpdate.sub_component[key] = configToUpdate.sub_component[key].map(
        (col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })
      );
    });

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_workorder`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_workorder`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  useEffect(() => {
    fetchColumnConfig();
  }, [columnConfig, defaultConfig, workOrderId]);

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.services
      ) {
        const updatedServiceColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.services } },
          serviceColumns
        );
        setServiceColumns(updatedServiceColumns);
      } else {
        setServiceColumns(
          serviceColumns.map((col) => ({ ...col, hide: false }))
        );
      }
      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.wor_resource_allocation
      ) {
        const updatedResourceColumns = transformConfig(
          {
            grid: {
              columns: selectedViewConfig.sub_component.wor_resource_allocation,
            },
          },
          resourceColumns
        );
        setResourceColumns(updatedResourceColumns);
      } else {
        setResourceColumns(
          resourceColumns.map((col) => ({ ...col, hide: false }))
        );
      }
      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.wom_material
      ) {
        const updatedMaterialColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.wom_material } },
          materialColumns
        );
        setMaterialColumns(updatedMaterialColumns);
      } else {
        setMaterialColumns(
          materialColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.woc_checklist
      ) {
        const updatedChecklistColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.woc_checklist } },
          checklistColumns
        );
        setChecklistColumns(updatedChecklistColumns);
      } else {
        setChecklistColumns(
          checklistColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.doc_upload
      ) {
        const updatedWoDocColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.doc_upload } },
          woDocColumns
        );
        setWoDocColumns(updatedWoDocColumns);
      } else {
        setWoDocColumns(woDocColumns.map((col) => ({ ...col, hide: false })));
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.wos_service_tasks
      ) {
        const updatedWostColumns = transformConfig(
          {
            grid: {
              columns: selectedViewConfig.sub_component.wos_service_tasks,
            },
          },
          wostColumns
        );
        setWostColumns(updatedWostColumns);
      } else {
        setWostColumns(wostColumns.map((col) => ({ ...col, hide: false })));
      }
      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.wom_message
      ) {
        const updatedMessagesColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.wom_message } },
          messagesColumns
        );
        setMessagesColumns(updatedMessagesColumns);
      } else {
        setMessagesColumns(
          messagesColumns.map((col) => ({ ...col, hide: false }))
        );
      }
      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.woi_incident
      ) {
        const updatedIncidentColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.woi_incident } },
          incidentColumns
        );
        setIncidentColumns(updatedIncidentColumns);
      } else {
        setIncidentColumns(
          incidentColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.wot_conditional_testing
      ) {
        const updatedTestingColumns = transformConfig(
          {
            grid: {
              columns: selectedViewConfig.sub_component.wot_conditional_testing,
            },
          },
          testingColumns
        );
        setTestingColumns(updatedTestingColumns);
      } else {
        setTestingColumns(
          testingColumns.map((col) => ({ ...col, hide: false }))
        );
      }
      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.woa_asset
      ) {
        const updatedWorkAssetColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.woa_asset } },
          workAssetColumns
        );
        setWorkAssetColumns(updatedWorkAssetColumns);
      } else {
        setWorkAssetColumns(
          workAssetColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_workorder`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const fetchColumnConfig = async () => {
    try {
      if (columnConfig && Object.keys(columnConfig).length > 0) {
        applyConfiguration(columnConfig);
        if (columnConfig.view_name && columnConfig.view_id) {
          setSelectedView({
            name: columnConfig.view_name,
            id: columnConfig.view_id,
          });
        }
      } else if (defaultConfig) {
        setColumnConfig(defaultConfig);
        applyConfiguration(defaultConfig);
      } else {
        setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
    }
  };

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, allcolumns);
      setColumns(transformedColumns);
    }

    if (config.sub_component) {
      if (config.sub_component.woa_asset) {
        const transformedWorkAssetColumns = transformConfig(
          { grid: { columns: config.sub_component.woa_asset } },
          workAssetColumns
        );
        setWorkAssetColumns(transformedWorkAssetColumns);
      }

      if (config.sub_component.wot_conditional_testing) {
        const transformedTestingColumns = transformConfig(
          { grid: { columns: config.sub_component.wot_conditional_testing } },
          testingColumns
        );
        setTestingColumns(transformedTestingColumns);
      }
      if (config.sub_component.woi_incident) {
        const transformedIncidentColumns = transformConfig(
          { grid: { columns: config.sub_component.woi_incident } },
          incidentColumns
        );
        setIncidentColumns(transformedIncidentColumns);
      }
      if (config.sub_component.wom_message) {
        const transformedMessagesColumns = transformConfig(
          { grid: { columns: config.sub_component.wom_message } },
          messagesColumns
        );
        setMessagesColumns(transformedMessagesColumns);
      }
      if (config.sub_component.wos_service_tasks) {
        const transformedWostColumns = transformConfig(
          { grid: { columns: config.sub_component.wos_service_tasks } },
          wostColumns
        );
        setWostColumns(transformedWostColumns);
      }
      if (config.sub_component.wom_material) {
        const transformedMaterialColumns = transformConfig(
          { grid: { columns: config.sub_component.wom_material } },
          materialColumns
        );
        setMaterialColumns(transformedMaterialColumns);
      }

      if (config.sub_component.woc_checklist) {
        const transformedChecklistColumns = transformConfig(
          { grid: { columns: config.sub_component.woc_checklist } },
          checklistColumns
        );
        setChecklistColumns(transformedChecklistColumns);
      }

      if (config.sub_component.doc_upload) {
        const transformedWoDocColumns = transformConfig(
          { grid: { columns: config.sub_component.doc_upload } },
          woDocColumns
        );
        setWoDocColumns(transformedWoDocColumns);
      }

      if (config.sub_component.wor_resource_allocation) {
        const transformedResourceColumns = transformConfig(
          { grid: { columns: config.sub_component.wor_resource_allocation } },
          resourceColumns
        );
        setResourceColumns(transformedResourceColumns);
      }
      if (config.sub_component.services) {
        const transformedServiceColumns = transformConfig(
          { grid: { columns: config.sub_component.services } },
          serviceColumns
        );
        setServiceColumns(transformedServiceColumns);
      }
    }

    if (config.view_name) {
      setSelectedView({ name: config.view_name, id: config.view_id });
    }
  };

  const addWorkOrderClick = () => {
    setShowWorkOrderEditForm(true);
    setShowWorkOrderForm(true);
    setEditDefValues({});
    isGetWorkOrderNumber();
  };

  return (
    <>
      <Box>
        {isWorkOrderLoading && <Progressor />}

        <Box sx={{ padding: "1rem" }}>
          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={
                viewLoaded
                  ? columns.filter((col) => !col.hide && col.width > 0)
                  : []
              }
              rows={viewLoaded ? rows : []}
              sortingField={"woNumber"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => addWorkOrderClick()}
                        onClickEdit={() => handleWorkEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickProperties={() => getCellValue()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        plantId={plantId}
                        propId={workOrderId}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="workorder"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                      <Tooltip title={"Print"}>
                        {" "}
                        <Button
                          disableRipple
                          onClick={() => setOpenReport(true)}
                          disabled={!workOrderId}
                          style={{
                            background: "transparent",
                            color: "rgba(0,0,0,0.3)",
                            boxShadow: "none",
                            fontSize: "0.5px",
                          }}
                          startIcon={
                            <i
                              class="fa-solid fa-print"
                              style={{ fontSize: "17px" }}
                            ></i>
                          }
                        />
                      </Tooltip>
                    </div>
                  </>
                ),
              }}
              onCellDoubleClick={getCellValue}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>

      {showWorkOrderEditForm && (
        <AddEditAccordionForm
          onClose={handleClose}
          showForm={showWorkOrderForm}
          setShowForm={setShowWorkOrderForm}
          columns={formFields}
          onUpData={saveUpdateData}
          onAdd={submitAddWorkData}
          isLoading={addingWorkorder || updatingWorkorder}
          editId={id}
          defaultValues={editDefValues}
          label={"Work Order"}
        />
      )}

      <Box>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ width: "60vw" }}>
            <WorkOrderMultiStep
              onClose={toggleDrawer(false)}
              workOrderId={workOrderId}
              columnConfig={columnConfig}
              updateColumnConfig={updateColumnConfig}
              setColumnConfig={setColumnConfig}
              employeeColumns={employeeColumns}
              setEmployeeColumns={setEmployeeColumns}
              incidentColumns={incidentColumns}
              setIncidentColumns={setIncidentColumns}
              messagesColumns={messagesColumns}
              setMessagesColumns={setMessagesColumns}
              testingColumns={testingColumns}
              setTestingColumns={setTestingColumns}
              workAssetColumns={workAssetColumns}
              setWorkAssetColumns={setWorkAssetColumns}
              wostColumns={wostColumns}
              setWostColumns={setWostColumns}
              materialColumns={materialColumns}
              setMaterialColumns={setMaterialColumns}
              checklistColumns={checklistColumns}
              setChecklistColumns={setChecklistColumns}
              resourceColumns={resourceColumns}
              serviceColumns={serviceColumns}
              selectedView={selectedView}
              woDocColumns={woDocColumns}
              setWoDocColumns={setWoDocColumns}
            />
          </Box>
        </SwipeableDrawer>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
      <Dialog
        open={openReport}
        onClose={() => setOpenReport(false)}
        fullWidth
        maxWidth="lg"
      >
        <Box sx={{ height: "80vh", width: "100%" }}>
          <Viewer ref={viewerRef} theme="ActiveReports" />
        </Box>
      </Dialog>
    </>
  );
};
