import React, { useState, useEffect, useCallback } from "react";
import useFetchApi from "../../../hooks/useFetchApi";
import {
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  AssetPrefix,
} from "../../../constants/global";
import { CommonBox } from "./CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import { useCommonData } from "../../../context/CommonDataProvider";
import { mapDropdownValueToLabelWithId } from "../../../utils/common";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import { toast } from "react-toastify";

export const WorkOrder = ({ assetId, selectedView, awoColumns, setAwoColumns }) => {
  const { rightsArray } = useMenuContext();
  const commonData = useCommonData();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [rows, setRows] = useState([]);
  const [viewLoaded, setViewLoaded] = useState(true);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const [columns, setColumns] = useState([
    {
      field: "woNumber",
      headerName: "Work Order No",
      type: "text",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "woDesc",
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "woDate",
      headerName: "Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "woIssuedBy",
      headerName: "Assigned To",
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });


  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }
   
    try {
      let configColumns = [];
   
      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.awo) {
        configColumns = updatedConfig.sub_component.awo;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === 'object') {
        configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
          name: field,
          title: details?.headerName,
          visible: details?.visible,
          width: details?.width ? `${details.width}px` : undefined,
        }));
      }
   
      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }
   
      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });
   
      setColumns(newColumns);
   
      const configToUpdate = {
        ...columnConfig,
        category: "asset",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          awo: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };
   
      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.awo) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.awo
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (awoColumns) {
      setColumns(awoColumns);
    } else {
      fetchColumnConfig();
    }
  }, [awoColumns, columnConfig, defaultConfig, assetId]);

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const {
    data: getAssetWoData,
    isFetchedAfterMount: isFetch,
    isFetching: isWoFetching,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/workorders?assetId=${assetId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isFetch && getAssetWoData && canRead && commonData) {
      const mappedRows = getAssetWoData.map((item, index) => {
        return {
          id: item.woId || `temp-id-${index}`,
          woNumber: item.woNumber || "N/A",
          woDesc: item.woDesc || "N/A",
          woDate: item.woDate || "N/A",
          woIssuedBy:
            mapDropdownValueToLabelWithId(
              commonData.employeDetails,
              parseInt(item.woIssuedBy)
            ) || "N/A",
          _woIssuedBy: item.woIssuedBy,
          assetId: item.assetId || 0,
        };
      });

      setRows(mappedRows);
    }
  }, [isFetch, getAssetWoData, canRead, commonData]);

  return (
    <>
    <CommonBox
      onClickColumnCustom={() => handleOpenColumnPopup()}
      columns={columns}
      rows={rows}
      isLoading={isWoFetching}
      label="Work Orders"
      sortingField="woNumber"
      getRowId={(row) => row.id}
    />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="asset"
        selectedViewName={selectedView.name}
      />
      </>
  );
};

export default WorkOrder;
