import React from "react";
import { Box, Typography } from "@mui/material";

const PartsQuery = () => {
  return (
    <>
      <Typography>
        <Box sx={{ alignContent: "center", margin: "10px 5vw" }}>
          <h1>Page is in development phase.</h1>
          <h1>Plant - Plant Query.</h1>
          <Typography>
            {" "}
            It seems the page you are trying to reach is under development we
            are also excited to give you new functionality{" "}
          </Typography>
        </Box>
      </Typography>
    </>
  );
};

export default PartsQuery;
