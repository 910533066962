import React, { useState, useRef, useEffect } from "react";
import { Box, IconButton, Paper } from "@mui/material";
import { MessageSquare, X } from "lucide-react";

const AIButton = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState({
    x: window.innerWidth - 100,
    y: window.innerHeight - 100,
  });
  const [isChatOpen, setIsChatOpen] = useState(false);
  const buttonRef = useRef(null);

  const handleMouseDown = (e) => {
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        x: e.clientX,
        y: e.clientY,
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const getChatBoxPosition = () => {
    const buttonRect = buttonRef.current.getBoundingClientRect();
    const isTop = buttonRect.top < window.innerHeight / 2;
    const isLeft = buttonRect.left < window.innerWidth / 2;

    return {
      top: isTop ? buttonRect.bottom + 10 : "auto",
      bottom: !isTop ? window.innerHeight - buttonRect.top + 10 : "auto",
      left: isLeft ? buttonRect.left : "auto",
      right: !isLeft ? window.innerWidth - buttonRect.right : "auto",
    };
  };

  return (
    <>
      <IconButton
        ref={buttonRef}
        onMouseDown={handleMouseDown}
        onClick={toggleChat}
        sx={{
          position: "fixed",
          left: `${position.x}px`,
          top: `${position.y}px`,
          zIndex: 9999,
          backgroundColor: "primary.main",
          color: "white",
          "&:hover": {
            backgroundColor: "primary.dark",
          },
        }}
      >
        <MessageSquare />
      </IconButton>
      {isChatOpen && (
        <Paper
          elevation={3}
          sx={{
            position: "fixed",
            width: "300px",
            height: "400px",
            zIndex: 10000,
            ...getChatBoxPosition(),
          }}
        >
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
            }}
          >
            <iframe
              src="https://copilotstudio.microsoft.com/environments/147ba993-a656-e3a6-b628-55b7f42d2d02/bots/crd58_qACopilot/webchat?__version__=2"
              frameBorder="0"
              style={{ width: "100%", height: "100%", border: "none" }}
            />
          </Box>
        </Paper>
      )}
    </>
  );
};

export default AIButton;
