import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import MIcon from "../assets/svgs/MIcon";
import {
  ListGroupPrefix,
  PlantPrefix,
  GeneralPrefix,
} from "../constants/global";
import { useCommonData } from "../context/CommonDataProvider";
import { useAuthContext } from "../context/AuthContextProvider";
import useFetchApi from "../hooks/useFetchApi";
import { useState, useEffect, useCallback, useRef } from "react";
import useMutationApi from "../hooks/useMutationApi";
import { AccountPopover } from "../common/AccountPopover";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "1px 26px 1px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: `linear-gradient(120deg, white 70%, ${theme.palette.primary.main} 30%)`,
  borderBottom: `4px solid ${theme.palette.primary.main}`,
  boxShadow: "none",
}));

export default function Header() {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const commonData = useCommonData();
  const tenantId = commonData?.userData?.tenant_id;
  const { refetchUser } = useAuthContext();
  const { data: plantDataList } = useFetchApi({
    endpoint: `${GeneralPrefix}/tenant/${tenantId}/plants`,
    retrieveOnMount: Boolean(tenantId),
    Menu_id: 10,
  });

  const [plantMasterData, setPlantMasterData] = useState(plantDataList);
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (plantDataList) {
      setPlantMasterData(plantDataList);
    }
    setValue(commonData.userData?.lastLoginPlantID);
  }, [plantDataList, commonData.userData]);

  const { mutateAsync: updateUser } = useMutationApi({
    endpoint: `${ListGroupPrefix}/update-user`,
    method: "put",
    Menu_id: 29,
  });

  const { mutateAsync: logoutUser } = useMutationApi({
    endpoint: "auth/logout",
    method: "PUT",
  });

  const handleLogout = async () => {
    try {
      const response = await logoutUser({
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        localStorage.clear();
        window.location.pathname = "/login";
      } else {
        console.error("Error logging out:", response.status);
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  const handleChange = async (event) => {
    setValue(event.target.value);
    const res = await updateUser({
      plantId: event.target.value,
    });
    if (res.status === 200) {
      refetchUser();
    }
  };

  const getFullName = (firstName, lastName) => {
    const fullName = firstName + " " + lastName;
    return fullName.length > 20 ? fullName.substring(0, 17) + "..." : fullName;
  };

  return (
    <>
      <StyledAppBar
        position="static"
        sx={{
          height: `5vh`,
        }}
      >
        <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 5, mb: 2 }}
            onClick={() => {
              navigate("/home/dashboard");
            }}
          >
            <MIcon style={{ height: "4vh", width: "4vh" }} />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
            <FormControl
              sx={{
                m: 1,
                minWidth: 110,
                maxWidth: 110,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "pre",
                height: 25,
              }}
              size="small"
            >
              <Select
                labelId="demo-simple-select-label"
                value={value}
                onChange={handleChange}
                input={<BootstrapInput />}
              >
                {plantMasterData?.map((o) => (
                  <MenuItem key={o.plantId} value={o.plantId}>
                    {o.plantCode} {o.plantDesc.slice(0, 4)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Tooltip
              title={`${commonData?.userData?.firstName} ${commonData?.userData?.lastName}`}
            >
              <Typography
                variant="body1"
                component="div"
                sx={{ lineHeight: "2.6", fontSize: 14 }}
              >
                {getFullName(
                  commonData?.userData?.firstName,
                  commonData?.userData?.lastName
                )}
              </Typography>
            </Tooltip>
            <Avatar
              onClick={handleOpen}
              ref={anchorRef}
              sx={{
                cursor: "pointer",
                height: 25,
                width: 25,
                fontSize: "15px",
                ml: 0.3,
              }}
            >
              {`${commonData?.userData?.firstName?.[0] || ""}${
                commonData?.userData?.lastName?.[0] || ""
              }`}
            </Avatar>
          </Box>
        </Toolbar>
      </StyledAppBar>
      <AccountPopover
        anchorEl={anchorRef.current}
        open={open}
        onClose={handleClose}
        handleLogout={handleLogout}
        userData={commonData.userData}
      />
    </>
  );
}
