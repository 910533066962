import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  ListGroupPrefix,
  GridWidthMedium,
  small,
} from "../../constants/global";
import { Box, Typography, Button, Grid, TextField } from "@mui/material";
import { DataGridComponent } from "../../common/DataGridComponent";
import { Progressor } from "../../common/Progressor";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
const CustomTextInput = ({
  type,
  required,
  label,
  disable,
  value,
  variant,
  name,
  onChange,
  multiline,
  rows,
  defaultValue,
  errorMessage,
  width = "100%",
}) => {
  return (
    <Box sx={{ width }}>
      <Typography
        variant="body2"
        sx={{
          marginBottom: "4px",
          color: "text.secondary",
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
      <TextField
        type={type}
        required={required}
        disabled={disable}
        value={value}
        variant={variant}
        name={name}
        size="small"
        onChange={onChange}
        multiline={multiline}
        rows={rows}
        defaultValue={defaultValue}
        error={!!errorMessage}
        helperText={errorMessage}
        InputLabelProps={{ shrink: true }}
        sx={{
          width: "100%", // This ensures the TextField takes up 100% of its parent Box
          "& .MuiOutlinedInput-root": {
            borderRadius: "15px",
          },
          "& .MuiInputBase-input": {
            padding: "8px 12px",
          },
        }}
      />
    </Box>
  );
};

const EmptyToolbar = () => null;
export const CompanyInfo = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [companyData, setCompanyData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [rows, setRows] = useState([]);
  const tenantId = commonData?.userData?.tenant_id;
  const userId = commonData.userId;
  const [selectedView, setSelectedView] = useState({ name: "", id: null });

  const {
    mutateAsync: updateCompanyInfo,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/tenant/${tenantId}/details`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getCompanyInfoData,
    isFetchedAfterMount: isFetch,
    isFetching: isCompanyInfoFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/tenant/${tenantId}/details`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && tenantId ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getUserData,
    isFetchedAfterMount: isUserDataFetch,
    isFetching: isUserDataFetching,
    refetch: refetchUserData,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/me`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isUpdateSuccess) {
      refetch();
      refetchUserData();
    }
  }, [isUpdateSuccess, refetch, refetchUserData]);

  useEffect(() => {
    if (isFetch && isUserDataFetch && canRead) {
      setCompanyData({
        ...getCompanyInfoData,
        firstName: getUserData.firstName,
        lastName: getUserData.lastName,
        address: getUserData.address,
      });
      setRows([
        {
          id: 1,
          dateCreated: getCompanyInfoData.dateCreated,
          plan: getCompanyInfoData.plan,
          dateExpiry: getCompanyInfoData.dateExpiry,
          status: getCompanyInfoData.status,
          registrationNumber: getCompanyInfoData.registrationNumber,
        },
      ]);
    }
  }, [isFetch, isUserDataFetch, getCompanyInfoData, getUserData, canRead]);

  const handleSaveCompanyInfo = async () => {
    try {
      await updateCompanyInfo(companyData);
      toast.success("Company Info updated successfully");
      setEditMode(false);
      refetch();
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to update Company Info"
      );
    }
  };

  const handleInputChange = (e) => {
    setCompanyData({ ...companyData, [e.target.name]: e.target.value });
  };

  const columns = [
    {
      field: "dateCreated",
      headerName: "Date Created",
      width: GridWidthMedium,
    },
    {
      field: "plan",
      headerName: "Plan",
      width: GridWidthMedium,
    },
    {
      field: "dateExpiry",
      headerName: "Date Expiry",
      width: GridWidthMedium,
    },
    {
      field: "status",
      headerName: "Status",
      width: GridWidthMedium,
    },
    {
      field: "registrationNumber",
      headerName: "Registration Number",
      width: GridWidthMedium,
    },
  ];

  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          overflowY: "hidden",
        }}
      >
        {(isCompanyInfoFetching || isUserDataFetching) && <Progressor />}

        {/* Upper portion - Editable Company Info */}
        <Box
          sx={{
            flex: 1,
            padding: "1rem",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={3} sm={6}>
              <CustomTextInput
                type="text"
                required={false}
                label="Company Name"
                disable={!editMode}
                value={companyData.companyName || ""}
                variant="outlined"
                name="companyName"
                size="small"
                width="50%"
                sx={{ borderRadius: "50px !important;" }}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <CustomTextInput
                type="text"
                required={false}
                label="Tenant Key"
                disable={true}
                value={companyData.tenantKey || ""}
                variant="outlined"
                name="tenantKey"
                size="medium"
                width="50%"
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                type="text"
                required={false}
                label="Address"
                disable={!editMode}
                value={companyData.address || ""}
                variant="outlined"
                name="address"
                size="medium"
                width="50%"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <CustomTextInput
                type="text"
                required={false}
                label="First Name"
                disable={!editMode}
                value={companyData.firstName || ""}
                variant="outlined"
                name="firstName"
                size="medium"
                width="50%"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={3} sm={6}>
              <CustomTextInput
                type="text"
                required={false}
                label="Last Name"
                disable={!editMode}
                value={companyData.lastName || ""}
                variant="outlined"
                name="lastName"
                size="medium"
                width="50%"
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomTextInput
                type="email"
                required={false}
                label="Email"
                disable={!editMode}
                value={companyData.email || ""}
                variant="outlined"
                name="email"
                size="medium"
                width="50%"
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            {editMode ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveCompanyInfo}
                  sx={{ mr: 1 }}
                >
                  Save
                </Button>
                <Button variant="outlined" onClick={() => setEditMode(false)}>
                  Cancel
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditMode(true)}
              >
                Edit
              </Button>
            )}
          </Box>
        </Box>

        {/* Lower portion - DataGrid */}
        <Box
          sx={{
            flex: 1,
            padding: "1rem",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="h6" gutterBottom color="text.secondary">
            Plan Information
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              width: "100%",
              height: "calc(100% - 60px)",
              overflow: "hidden",
              padding: "10px 0",
            }}
          >
            <DataGridComponent
              sx={{ height: "35vh", width: "100%" }}
              columns={columns}
              rows={rows}
              components={{
                Toolbar: EmptyToolbar,
              }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CompanyInfo;
