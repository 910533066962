import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { useCommonData, commonData } from "../../../context/CommonDataProvider";
import {
  AssetPrefix,
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  medium,
} from "../../../constants/global";
import {
  getFormattedDate,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
} from "../../../utils/common";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "./CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import dayjs from "dayjs";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const Warranty = ({
  asstId,
  warrantyColumns,
  setWarrantyColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const commonData = useCommonData();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState();
  const { asst_warrenty_type } = useCommonData();
  const [editDefValues, setEditDefValues] = useState();
  const [showWarrantyForm, setShowWarrantyForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showWarrantyEditForm, setShowWarrantyEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const {
    mutateAsync: addAssetWarranty,
    isSuccess: isAssetWarrantySuccess,
    isLoading: addWarrantyLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/warranty/addWarranty`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetWarranty,
    isSuccess: isUpdateSuccess,
    isLoading: updateWarrantyLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/warranty?warrantyId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteAssetWarranty,
    isSuccess: isDeleteSuccess,
    isLoading: deleteAssetLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/warranty?warrantyId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetWarrantyData,
    isFetchedAfterMount: isFetch,
    isFetching: isWarrantyFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/warranty?assetId=${asstId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetWarrantySuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetWarrantySuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getWarrantyData, setGetWarrantyData] = useState(getAssetWarrantyData);
  useEffect(() => {
    if (getAssetWarrantyData) {
      const warrantyData = getAssetWarrantyData.map((item) => {
        const newWarrantyType = mapDropdownValueToLabelWithListId(
          asst_warrenty_type,
          item.astwWarrantyType
        );

        return {
          ...item,
          astwWarrantyType: newWarrantyType,
        };
      });
      setGetWarrantyData(warrantyData);
    }
  }, [getAssetWarrantyData, asst_warrenty_type]);

  useEffect(() => {
    if (isFetch && getWarrantyData && canRead) {
      setRows(() =>
        getWarrantyData?.map((w) => {
          return { id: w.astwId, ...w };
        })
      );
    }
  }, [isFetch, getWarrantyData, rightsArray, canRead]);

  const handleAssetWarrantyEditClick = () => {
    setShowWarrantyEditForm(true);
    setId(editData.id);
    const newWarrantyType = mapDropdownValueToListIdWithLabel(
      asst_warrenty_type,
      editData.astwWarrantyType
    );
    setEditDefValues({ ...editData, astwWarrantyType: newWarrantyType });
  };

  const saveUpdateData = async (AssetWarrantyDataToSave) => {
    if (
      dayjs(AssetWarrantyDataToSave?.astwDateStart).isAfter(
        AssetWarrantyDataToSave?.astwDateExpiry
      )
    ) {
      toast.error("End date should be greater than or equal to start date");
    } else {
      updateAssetWarranty({
        id: AssetWarrantyDataToSave.astwId,
        ...AssetWarrantyDataToSave,
        astwWarrantyType: AssetWarrantyDataToSave.astwWarrantyType?.listId,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Warranty updated successfully");
            setShowWarrantyEditForm(false);
            setId();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const submitAssetWarrantyData = async (newAssetWarrantyData) => {
    if (
      dayjs(newAssetWarrantyData?.astwDateStart).isAfter(
        newAssetWarrantyData?.astwDateExpiry
      )
    ) {
      toast.error("End date should be greater than or equal to start date");
    } else {
      addAssetWarranty({
        asstId,
        ...newAssetWarrantyData,
        astwWarrantyType: newAssetWarrantyData.astwWarrantyType?.listId,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Warranty Added successfully");
            setShowWarrantyEditForm(!showWarrantyEditForm);
            setShowWarrantyForm(false);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const handleDeleteClick = async () => {
    deleteAssetWarranty()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Warranty Deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowWarrantyEditForm(!showWarrantyEditForm);
    setShowWarrantyForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "astwWarrantyType",
      headerName: "Warranty Type",
      required: true,
      type: "select",
      apiendpoint: "asst_warrenty_type",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
    {
      field: "astwDateStart",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: medium,
      required: true,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "astwDateExpiry",
      headerName: "Date Expiry",
      type: "Date",
      textFieldWidth: medium,
      required: true,
      minDateLabel: "astwDateStart",
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "astwAgrDetails",
      headerName: "Agreement #",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "astwRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 200,
      row: 4,
      multiline: "multiline",
      width: GridWidthLargeWidth,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.warranty || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.warranty)
    ) {
      configColumns = updatedConfig.sub_component.warranty;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "asset",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        warranty: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (!config || !config.sub_component || !config.sub_component.warranty) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.warranty
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => !col.hide);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (warrantyColumns) {
      setColumns(warrantyColumns);
    } else {
      fetchColumnConfig();
    }
  }, [warrantyColumns, columnConfig, defaultConfig, asstId]);

  const onAdd = () => {
    setShowWarrantyEditForm(true);
    setShowWarrantyForm(true);
    setEditDefValues({});
  };

  return (
    <React.Fragment key={asstId}>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleAssetWarrantyEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        editId={id}
        isLoading={isWarrantyFetching}
        rows={rows}
        label={"Warranty"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"astwWarrantyType"}
      />

      {showWarrantyEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showWarrantyForm}
          setShowForm={setShowWarrantyForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetWarrantyData}
          editId={id}
          defaultValues={editDefValues}
          label="Warranty"
          isLoading={updateWarrantyLoading || addWarrantyLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Warranty data?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteAssetLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="Asset"
        selectedViewName={selectedView.name}
      />
    </React.Fragment>
  );
};
