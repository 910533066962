import React, { useEffect, useState, useCallback } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import {
  GridWidthMedium,
  ListGroupPrefix,
  gridHeight,
  medium,
  GeneralPrefix,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import { UserGroupSteps } from "./UserGroupSteps/UserGroupSteps";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import {
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
} from "../../utils/common";
import useMutationApi from "../../hooks/useMutationApi";
import useFetchApi from "../../hooks/useFetchApi";
import { toast, ToastContainer } from "react-toastify";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import DeleteDialog from "../../common/DeleteDialog";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

const UserGroups = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [open, setOpen] = useState(false);
  const [editDefValues, setEditDefValues] = useState();
  const [showUserGroupsForm, setShowUserGroupsForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showUserGroupsEditForm, setShowUserGroupsEditForm] = useState(false);
  const [id, setId] = useState(0);
  const [ugId, setUgId] = useState(null);
  const [editData, setEditData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [ugAddId, setUgAddId] = useState(null);
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "user_groups" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const showData = () => {
    setOpen(true);
  };
  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setUgId(params.id);
    setUgAddId(params.id + 1);
  };
  const {
    mutateAsync: addUserGroups,
    isSuccess: addUserGroupDataSuccess,
    isLoading: addingUserGroups,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user-groups`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateUserGroups,
    isSuccess: updateUserGroupsSuccess,
    isLoading: updatingUserGroups,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user-groups/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteUserGroups, isSuccess: deleteUserGroupsSuccess } =
    useMutationApi({
      endpoint: `${ListGroupPrefix}/user-groups/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getUserGroupsDataList,
    isFetchedAfterMount: isFetch,
    isFetching: isUserGroupsLoading,
    refetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/user-groups`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (
      addUserGroupDataSuccess ||
      updateUserGroupsSuccess ||
      deleteUserGroupsSuccess
    ) {
      refetch();
    }
  }, [
    addUserGroupDataSuccess,
    updateUserGroupsSuccess,
    deleteUserGroupsSuccess,
    refetch,
  ]);

  useEffect(() => {
    if (isFetch && canRead) {
      setRows(() =>
        getUserGroupsDataList?.map((userGroup) => {
          // Map the ugType to include both label and listId
          const mappedUgType = mapDropdownValueToLabelWithListId(
            commonData.ugType,
            userGroup.ugType
          );

          return {
            id: userGroup.ugId,
            ...userGroup,
            ugType: mappedUgType, // Override the ugType with mapped value
          };
        })
      );
    }
  }, [isFetch, getUserGroupsDataList, rightsArray, canRead, commonData.ugType]);

  const handleUserGroupEditClick = () => {
    setShowUserGroupsEditForm(true);
    setId(editData.id);

    // Map the ugType to the format expected by the form
    const mappedUgType = mapDropdownValueToListIdWithLabel(
      commonData.ugType,
      editData.ugType
    );

    setEditDefValues({
      ...editData,
      ugType: mappedUgType,
    });
  };
  const submitUserGroupsData = async (newUserGroupAddData) => {
    if (
      newUserGroupAddData["ugGroup"] === "" &&
      newUserGroupAddData["ugType"] === ""
    ) {
      toast.error("Please fill the form");
      return;
    }

    const transformedData = {
      ugGroup: newUserGroupAddData.ugGroup,
      ugType: newUserGroupAddData.ugType.listId, // Extract just the listId
    };

    try {
      const response = await addUserGroups(transformedData);

      if (response.status === 200) {
        toast.success("User Group Added successfully");
        setShowUserGroupsForm(false);
        setShowUserGroupsEditForm(false);
        setUgAddId(null);
        commonData.refetchUserGroup();
      } else if (response.status === 208) {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message || "An error occurred");
      }
    }
  };

  const saveUpdateData = async (UserGroupDataToSave) => {
    const transformedUpdateData = {
      id: UserGroupDataToSave.ugId,
      ugGroup: UserGroupDataToSave.ugGroup,
      ugType: UserGroupDataToSave.ugType.listId, // Extract just the listId
    };

    try {
      const response = await updateUserGroups(transformedUpdateData);

      if (response.status === 200) {
        toast.success("User Group Updated successfully");
        setShowUserGroupsEditForm(false);
        setId(null);
        setUgAddId(null);
        commonData.refetchUserGroup();
      } else if (response.status === 208) {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message || "An error occurred");
      }
    }
  };

  const handleDeleteClick = async () => {
    deleteUserGroups()
      .then((response) => {
        if (response.status === 204) {
          toast.success("User Group Deleted successfully");
          setOpenDialog(false);
          setUgAddId();
          commonData.refetchUserGroup();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowUserGroupsForm(false);
    setShowUserGroupsEditForm(!showUserGroupsEditForm);
  };

  const addUserGroupClick = () => {
    setShowUserGroupsEditForm(true);
    setShowUserGroupsForm(true);
    setEditDefValues({});
  };

  const allcolumns = [
    {
      field: "ugGroup",
      headerName: "User Group",
      type: "text",
      width: GridWidthMedium,
      required: true,
    },
    {
      field: "ugType",
      headerName: "Type",
      type: "select",
      apiendpoint: "ugType",
      textFieldWidth: "medium",
      required: true,
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_user_groups`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_user_groups`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_user_groups`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isUserGroupsLoading && <Progressor />}
          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"ugGroup"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => addUserGroupClick()}
                        onClickEdit={() => handleUserGroupEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        onClickProperties={() => showData()}
                        propId={ugAddId}
                        editId={ugAddId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                        isUserGroup={true}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="user_groups"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
              onCellDoubleClick={showData}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showUserGroupsEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showUserGroupsForm}
          setShowForm={setShowUserGroupsForm}
          columns={columns}
          defaultValues={editDefValues}
          onUpData={saveUpdateData}
          onAdd={submitUserGroupsData}
          label={"User Groups"}
          isLoading={addingUserGroups || updatingUserGroups}
          editId={id}
        />
      )}
      <Box>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ width: "60vw" }}>
            <UserGroupSteps onClose={toggleDrawer(false)} ugId={id} />
          </Box>
        </SwipeableDrawer>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete User Groups?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="user_groups"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default UserGroups;
