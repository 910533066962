import { Box, Container } from "@mui/material";
import Header from "./Header";
import Leftbar from "./Leftbar";
import ScrollToTop from "./ScrollToTop";
import ThemeToggle from "./ThemeToggle";
import Breadcrumb from "../common/BreadCrumb";
import AIButton from "../common/AIButton";

export default function Layout({ children }) {
  return (
    <ThemeToggle>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ px: "0px" }}>
          <Header />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            minWidth: "100vh",
          }}
        >
          <Box>
            <Leftbar />
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: "0px",
              width: "100%",
            }}
          >
            <Container
              sx={{
                px: "0px !important",
                m: "0px !important",
                width: "100%",
                maxWidth: "100% !important",
              }}
            >
              <Breadcrumb />
              <Box>
                <ScrollToTop />
                {children}
              </Box>
            </Container>
          </Box>
        </Box>
        <AIButton />
      </Box>

      {/* <MainFooter /> */}
    </ThemeToggle>
  );
}
