import React, { useState, useEffect } from "react";
import { Box, Grid, Card, CardContent, Typography, FormControl, Select, MenuItem } from "@mui/material";
import { Button } from "../../../common/Button";
import { useCommonData } from "../../../context/CommonDataProvider";
import { useMenuContext } from "../../../context/MenuContextProvider";
import useFetchApi from "../../../hooks/useFetchApi";
import { GeneralPrefix, AssetPrefix } from "../../../constants/global";

const BottomSection = ({ heights, widths, handleMouseDown }) => {
  const commonData = useCommonData();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const { rightsArray } = useMenuContext();

  const [selectedAsset, setSelectedAsset] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Single state for all metrics
  const [metricsValues, setMetricsValues] = useState({
    pmCompliance: 0,
    assetAvailability: 0,
    mtbf: 0,
    mttr: 0,
    failureRate: 0,
    assetUtilization: 0,
    capacityUtilizationRate: 0,
    tco: 0,
    incidentRate: 0,
    energyConsumption: 0
  });

  // Fetch asset data
  const {
    data: getAssetMasterDataList,
    isFetchedAfterMount: isFetch,
    isFetching: isAssetLoading,
    refetch: refetchAssets,
  } = useFetchApi({
    endpoint: `${AssetPrefix}?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Single API call for all metrics
  const {
    data: metricsData,
    isFetching: isMetricsFetching,
    refetch: refetchMetrics,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/all-metrics?assetId=${selectedAsset}`,
    retrieveOnMount: false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleAssetChange = (event) => {
    setSelectedAsset(event.target.value);
  };

  const handleApply = () => {
    if (selectedAsset) {
      refetchMetrics();
    }
  };

  // Format number to 3 decimal places
  const formatNumber = (value, includePercentage = false) => {
    if (isLoading || value === undefined || value === null) return "";
    const formattedValue = Number(value).toFixed(3);
    return includePercentage ? `${formattedValue}%` : formattedValue;
  };

  // Update metrics values when API response changes
  useEffect(() => {
    if (metricsData) {
      setMetricsValues(metricsData);
    }
    setIsLoading(isMetricsFetching);
  }, [metricsData, isMetricsFetching]);

  function getColorForStatus(index) {
    const colors = [
      "#1565C0", "#1E88E5", "#42A5F5", "#64B5F6", "#90CAF9",
      "#1565C0", "#1E88E5", "#42A5F5", "#64B5F6", "#90CAF9"
    ];
    return colors[index];
  }

  const cardData = [
    {
      title: "PM Compliance",
      value: formatNumber(metricsValues.pmCompliance, true),
    },
    {
      title: "Asset Availability",
      value: formatNumber(metricsValues.assetAvailability),
    },
    {
      title: "MTBF",
      value: formatNumber(metricsValues.mtbf),
    },
    {
      title: "Mean Time to Repair",
      value: formatNumber(metricsValues.mttr),
    },
    {
      title: "Failure Rate",
      value: formatNumber(metricsValues.failureRate),
    },
    {
      title: "Asset Utilization",
      value: formatNumber(metricsValues.assetUtilization, true),
    },
    {
      title: "Capacity Util Rate",
      value: formatNumber(metricsValues.capacityUtilizationRate, true),
    },
    {
      title: "TCO",
      value: formatNumber(metricsValues.tco),
    },
    {
      title: "Incident Rate",
      value: formatNumber(metricsValues.incidentRate),
    },
    {
      title: "Energy Consumption",
      value: formatNumber(metricsValues.energyConsumption),
    },
  ];

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: `0 0 ${heights.bottomRow}%`,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        minHeight: "10%",
        overflow: "hidden",
        boxShadow: 3,
      }}
    >
      <Box sx={{ position: "relative", width: "100%" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            height: "2px",
            cursor: "row-resize",
            bgcolor: "grey.300",
            "&:hover": {
              bgcolor: "primary.main",
            },
            zIndex: 10,
          }}
          onMouseDown={(e) => handleMouseDown(e, "verticalBottom")}
        />
      </Box>

      <Box sx={{ pt: 1, flex: 1, display: "flex", flexDirection: "column", gap: 2, p: 2 }}>
        <Box sx={{ display: "flex", gap: 2, alignItems: "center", mb: 2 }}>
          <FormControl sx={{ width: 200 }}>
            <Select
              value={selectedAsset}
              onChange={handleAssetChange}
              displayEmpty
              disabled={isAssetLoading}
              size="small"
              sx={{
                '& .MuiSelect-select': {
                  py: 1,
                }
              }}
            >
              <MenuItem value="">
                <em>Select an Asset</em>
              </MenuItem>
              {getAssetMasterDataList?.map((asset) => (
                <MenuItem key={asset.asstId} value={asset.asstId}>
                  {`${asset.asstTag} - ${asset.asstDesc}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button 
            onClick={handleApply}
            disabled={!selectedAsset || isLoading}
            label="Apply"
          />
        </Box>

        <Grid container spacing={2}>
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={2.4} key={index}>
              <Card
                sx={{
                  bgcolor: getColorForStatus(index),
                  color: "white",
                  boxShadow: 5,
                  width: 230,
                  height: 110,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "left",
                  alignItems: "left",
                }}
              >
                <CardContent>
                  <Typography variant="h6">{card.title}</Typography>
                  <Typography variant="h4">{card.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default BottomSection;