import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const useFetchApi = ({
  endpoint,
  resGetter = (res) => res?.data,
  retrieveOnMount = false,
  storageType = localStorage,
  options = {},
  retry = 2,
  Menu_id,
  isApps,
}) => {
  const token = `Bearer ${localStorage.getItem("token")}`;
  const headers = {
    Authorization: !isApps ? token : null,
    Menu_id,
  };

  const queryFn = async () => {
    const url = `${process.env.REACT_APP_BACKEND_URL}${endpoint}`;
    // console.log('Making API call to:', url);
    // console.log('With params:', options.params);

    try {
      const response = await axios.get(url, {
        ...options,
        headers,
        params: options.params,
      });
      // console.log('API response:', response);
      return response;
    } catch (error) {
      // console.error('API call error:', error);
      throw error;
    }
  };

  const {
    data,
    error,
    failureCount,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isLoading,
    isPreviousData,
    isStale,
    isSuccess,
    refetch,
    remove,
    status,
  } = useQuery({
    queryKey: [
      `${process.env.REACT_APP_BACKEND_URL}${endpoint}`,
      options.params,
    ],
    queryFn,
    enabled: retrieveOnMount,
    refetchOnWindowFocus: false,
    retry,
  });

  useEffect(() => {
    if (error) {
      console.error("Error in useFetchApi:", error);
      if (
        error?.response?.data?.statusCode === 401 ||
        error?.message === "Network Error"
      ) {
        if (localStorage.getItem("token")) {
          localStorage.removeItem("token");
        }
        toast.error("Please login to continue");
        return setTimeout(() => {
          window.location.replace("/login");
        }, 500);
      } else {
        // toast.error(`API Error: ${error.message}`);
      }
    }
  }, [error]);

  return {
    data: resGetter(data),
    error,
    loading: isLoading,
    refetch,
    failureCount,
    isPreviousData,
    isError,
    isFetchedAfterMount,
    isFetching,
    isIdle,
    isStale,
    isSuccess,
    remove,
    status,
    refetchInterval: 1000,
  };
};

export default useFetchApi;
