import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  AssetPrefix,
  GridWidthMedium,
  gridHeight,
  medium,
  GeneralPrefix,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import { useCommonData } from "../../context/CommonDataProvider";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../utils/common";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const AssetGroup = ({ asstId }) => {
  const { rightsArray } = useMenuContext();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showAssetGroupForm, setShowAssetGroupForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showAssetGroupEditForm, setShowAssetGroupEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset_group" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addAssetGroup,
    isSuccess: isAssetGroupSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/asset-group/AddAssetGroup`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetGroup,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/asset-group/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteAssetGroup, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${AssetPrefix}/asset-group/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getAssetGroupData,
    isFetchedAfterMount: isFetch,
    isFetching: isAssetGroupFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/asset-group?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && plantId ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetGroupSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetGroupSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getMainAssetGroupData, setGetMainAssetGroupData] =
    useState(getAssetGroupData);

  useEffect(() => {
    if (getAssetGroupData) {
      const assetGroupData = getAssetGroupData.map((item) => {
        const newAssetCategory = mapDropdownValueToLabelWithId(
          commonData.getAssetCategory,
          item.asgpAstcCatIdFk
        );

        return {
          ...item,
          asgpAstcCatIdFk: newAssetCategory,
        };
      });

      setGetMainAssetGroupData(assetGroupData);
    }
  }, [getAssetGroupData, commonData]);

  useEffect(() => {
    if (isFetch && getMainAssetGroupData && canRead) {
      setRows(() =>
        getMainAssetGroupData?.map((w) => {
          return { id: w.asgpId, ...w };
        })
      );
    }
  }, [isFetch, getMainAssetGroupData, canRead]);

  const handleAssetGroupEditClick = () => {
    setShowAssetGroupEditForm(true);
    setId(editData.id);
    const newAssetCategory = mapDropdownValueToIdWithLabel(
      commonData.getAssetCategory,
      editData.asgpAstcCatIdFk
    );
    setEditDefValues({ ...editData, asgpAstcCatIdFk: newAssetCategory });
  };

  const saveUpdateData = async (AssetGroupDataToSave) => {
    updateAssetGroup({
      id: AssetGroupDataToSave.asctCatId,
      ...AssetGroupDataToSave,
      asgpAstcCatIdFk: AssetGroupDataToSave?.asgpAstcCatIdFk?.id,
      plantId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset Group updated successfully");
          setShowAssetGroupEditForm(false);
          setId();
          commonData.refetchAssGroup();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAssetGroupData = async (newAssetGroupData) => {
    addAssetGroup({
      atdepAsstIdFk: asstId,
      ...newAssetGroupData,
      asgpAstcCatIdFk: newAssetGroupData?.asgpAstcCatIdFk?.id,
      plantId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset Group Added successfully");
          setShowAssetGroupEditForm(!showAssetGroupEditForm);
          setShowAssetGroupForm(false);
          commonData.refetchAssGroup();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteAssetGroup()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset Group Deleted successfully");
          setOpenDialog(false);
          setId();
          commonData.refetchAssGroup();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowAssetGroupEditForm(!showAssetGroupEditForm);
    setShowAssetGroupForm(false);
  };

  const allcolumns = [
    {
      field: "asgpDesc",
      headerName: "Asset Group Description",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "asgpAstcCatIdFk",
      headerName: "Asset Category",
      required: true,
      type: "select",
      width: GridWidthMedium,
      textFieldWidth: medium,
      apiendpoint: "getAssetCategory",
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_asset_group`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_asset_group`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_asset_group`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  const onAdd = () => {
    setShowAssetGroupEditForm(true);
    setShowAssetGroupForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isAssetGroupFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"asgpDesc"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleAssetGroupEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="asset_group"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showAssetGroupEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showAssetGroupForm}
          setShowForm={setShowAssetGroupForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetGroupData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Asset Group"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Asset Group?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="asset_group"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};
