import React, { useState, useEffect, useCallback } from "react";
import useFetchApi from "../../../hooks/useFetchApi";
import {
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  GridWidthSmall,
} from "../../../constants/global";
import { CommonBox } from "../../home/assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import { toast } from "react-toastify";

export const Schedule = ({ id, selectedView, schColumns, setSchColumns }) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [rows, setRows] = useState([]);
  const [viewLoaded, setViewLoaded] = useState(true);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();

  const handleRestoreButtonClick = () => {
    // setOpenRestoreDialog(true);
  };

  const [columns, setColumns] = useState([
    {
      field: "itemCount",
      headerName: "Id",
      type: "number",
      width: GridWidthSmall,
    },
    {
      field: "caldDesc",
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "caldDate",
      headerName: "Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "caldCtype",
      headerName: "Type",
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "schedule_maintenance" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }

    try {
      let configColumns = [];

      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.sch_sc) {
        configColumns = updatedConfig.sub_component.sch_sc;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === "object") {
        configColumns = Object.entries(updatedConfig).map(
          ([field, details]) => ({
            name: field,
            title: details?.headerName,
            visible: details?.visible,
            width: details?.width ? `${details.width}px` : undefined,
          })
        );
      }

      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }

      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });

      setColumns(newColumns);

      const configToUpdate = {
        ...columnConfig,
        category: "preventive_maintenance",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          sch_sc: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };

      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.sch_sc) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.sch_sc
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (schColumns) {
      setColumns(schColumns);
    } else {
      fetchColumnConfig();
    }
  }, [schColumns, columnConfig, defaultConfig, id]);

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const {
    data: getCalendarData,
    isFetchedAfterMount: isFetch,
    isFetching: isCalendarFetching,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/calender-day-today/${id}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && id ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isFetch && getCalendarData && canRead) {
      setRows(() =>
        getCalendarData.map((item, index) => ({
          id: item.caldId || `temp-id-${index}`,
          itemCount: index + 1, // Add this line to include the casual count
          caldDesc: item.caldDesc || "N/A",
          caldDate: item.caldDate || "N/A",
          caldTime: item.caldTime || "N/A",
          caldCtype: item.caldCtype || "N/A",
          caldAsstIdFk: item.caldAsstIdFk || "N/A",
          caldWoIdFk: item.caldWoIdFk || "N/A",
          caldTicketIdFk: item.caldTicketIdFk || "N/A",
          caldPmIdFk: item.caldPmIdFk || "N/A",
          caldUserIdFk: item.caldUserIdFk || "N/A",
          caldPlantIdFk: item.caldPlantIdFk || "N/A",
        }))
      );
    }
  }, [isFetch, getCalendarData, rightsArray, canRead]);

  return (
    <>
      <CommonBox
        onClickColumnCustom={() => handleOpenColumnPopup()}
        columns={columns}
        rows={rows}
        isLoading={isCalendarFetching}
        label={`Schedule for ID: ${id}`}
        sortingField={"caldDate"}
        getRowId={(row) => row.id}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="preventive_maintenance"
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default Schedule;
