import React, { useEffect, useState, useCallback } from "react";
import { Box, SwipeableDrawer } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import DeleteDialog from "../../common/DeleteDialog";
import { Progressor } from "../../common/Progressor";
import {
  GridWidthMedium,
  GridWidthSmall,
  GridWidthLargeWidth,
  gridHeight,
  medium,
  GeneralPrefix,
} from "../../constants/global";
import { useCommonData } from "../../context/CommonDataProvider";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  getFormattedDate,
  mapDropdownValueToDescWithId,
  mapDropdownValueToIdWithDesc,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
  modifyOptionsWithCodeAndTag,
} from "../../utils/common";
import ViewDropdown from "./ViewDropdown";
import useColumnConfigStore from "./columnConfigStore";
import ColumnCustomizationPopup from "./ColumnCustomizationPopup";
import AddEditToolBar from "../../common/AddEditToolBar";
import { AddEditForm } from "../../common/AddEditForm";
import { useMenuContext } from "../../context/MenuContextProvider";
import dayjs from "dayjs";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";
import { ScheduleMaintenanceMultiStep } from "./ScheduleMaintenanceSteps/ScheduleMaintenanceMultiSteps";

const ScheduleMaintenance = () => {
  const commonData = useCommonData();
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [editScheduleValues, setEditScheduleValues] = useState();
  const [showScheduleMaintenanceForm, setShowScheduleMaintenanceForm] =
    useState(false);
  const [rows, setRows] = useState([]);
  const [showScheduleMaintenanceEditForm, setShowScheduleMaintenanceEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const plantId = commonData?.userData?.lastLoginPlantID;
  const [editData, setEditData] = useState();
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setId(params.row.id);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const showData = () => {
    setOpen(true);
  };

  const {
    mutateAsync: addScheduleMaintenance,
    isSuccess: ScheduleMaintenanceAddDataSuccess,
    isLoading: addingAsset,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/preventive-maintenance`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateScheduleMaintenance,
    isSuccess: updateScheduleMaintenanceSuccess,
    isLoading: updatingAsset,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/preventive-maintenance/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteScheduleMaintenance,
    isSuccess: deleteScheduleMaintenanceSuccess,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/preventive-maintenance/${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getScheduleMaintenanceDataList,
    isFetchedAfterMount: isFetch,
    isFetching: isSchLoading,
    refetch,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/preventive-maintenance`,
    retrieveOnMount: plantId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (
      ScheduleMaintenanceAddDataSuccess ||
      updateScheduleMaintenanceSuccess ||
      deleteScheduleMaintenanceSuccess
    ) {
      refetch();
    }
  }, [
    ScheduleMaintenanceAddDataSuccess,
    updateScheduleMaintenanceSuccess,
    deleteScheduleMaintenanceSuccess,
    refetch,
  ]);

  const [getScheduleMaintenanceData, setGetScheduleMaintenanceData] = useState(
    getScheduleMaintenanceDataList
  );
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getAssetDataList,
    "asstTag",
    "asstDesc"
  );
  useEffect(() => {
    if (getScheduleMaintenanceDataList) {
      const assetData = getScheduleMaintenanceDataList.map((item) => {
        const newImpact = mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticalityImpact,
          item.schImpact
        );
        const newPriority = mapDropdownValueToLabelWithListId(
          commonData.workOrderPriority,
          item.schPriority
        );
        const newIncItem = mapDropdownValueToLabelWithId(
          commonData.getIncidentOptions,
          item.schIncItemIdFk
        );

        const newSchCal = mapDropdownValueToLabelWithId(
          commonData.getCalendarSystemData,
          item.schCalIdFk
        );
        const newPmCals = mapDropdownValueToLabelWithId(
          commonData.getCalendarSystemData,
          item.pmCalsId
        );
        const newAsst = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.schAsstIdFk
        );
        const newPmAsst = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.pmAsstIdFk
        );
        return {
          ...item,
          pmAsstIdFk: newPmAsst,
          schAsstIdFk: newAsst,
          pmCalsId: newPmCals,
          schCalIdFk: newSchCal,
          schIncItemIdFk: newIncItem,
          schPriority: newPriority,
          schImpact: newImpact,
        };
      });
      setGetScheduleMaintenanceData(assetData);
    }
  }, [getScheduleMaintenanceDataList, commonData]);

  useEffect(() => {
    if (isFetch && getScheduleMaintenanceData && canRead) {
      setRows(() =>
        getScheduleMaintenanceData?.map((asset) => {
          return { id: asset.pmId, ...asset };
        })
      );
    }
  }, [isFetch, getScheduleMaintenanceData, rightsArray, canRead]);

  const handleAssetEditClick = () => {
    setShowScheduleMaintenanceEditForm(true);

    setId(editData.id);

    const newImpact = mapDropdownValueToListIdWithLabel(
      commonData.workOrderAssetCriticalityImpact,
      editData.schImpact
    );
    const newPriority = mapDropdownValueToListIdWithLabel(
      commonData.workOrderPriority,
      editData.schPriority
    );
    const newIncItem = mapDropdownValueToIdWithLabel(
      commonData.getIncidentOptions,
      editData.schIncItemIdFk
    );

    const newSchCal = mapDropdownValueToIdWithLabel(
      commonData.getCalendarSystemData,
      editData.schCalIdFk
    );
    const newPmCals = mapDropdownValueToIdWithLabel(
      commonData.getCalendarSystemData,
      editData.pmCalsId
    );
    const newAsst = mapDropdownValueToIdWithDesc(
      modifiedOptions,
      editData.schAsstIdFk
    );
    const newPmAsst = mapDropdownValueToIdWithDesc(
      modifiedOptions,
      editData.pmAsstIdFk
    );
    setEditDefValues({
      ...editData,
      pmAsstIdFk: newPmAsst,
      schAsstIdFk: newAsst,
      pmCalsId: newPmCals,
      schCalIdFk: newSchCal,
      schIncItemIdFk: newIncItem,
      schPriority: newPriority,
      schImpact: newImpact,
      time: editData.schType,
      apiTimeKey: editData.schType,
      schSun: editData.schSun,
      schThu: editData.schThu,
      schTue: editData.schTue,
      schWeb: editData.schWeb,
      schMon: editData.schMon,
      schFri: editData.schFri,
      schSat: editData.schSat,
      schJan: editData.schJan,
      schFeb: editData.schFeb,
      schMar: editData.schMar,
      schApr: editData.schApr,
      schMay: editData.schMay,
      schJun: editData.schJun,
      schJul: editData.schJul,
      schAug: editData.schAug,
      schSep: editData.schSep,
      schOct: editData.schOct,
      schNov: editData.schNov,
      schDec: editData.schDec,
    });
  };

  const saveUpdateData = async (ScheduleMaintenanceDataToSave) => {
    if (!ScheduleMaintenanceDataToSave.schType) {
      toast.error("Schedule Type is required");
      return;
    }
    if (
      ScheduleMaintenanceDataToSave.schType === "time" &&
      !ScheduleMaintenanceDataToSave.apiTimeKey
    ) {
      toast.error("Please select a time option");
      return;
    }
    let payload = {
      pmPlantIdFk: plantId,
      schPlantIdFk: plantId,
      plantIdFk: plantId,
      pmMaintenanceType: "Preventive",
      schMaintType: "Scheduled",
      pmDescription: ScheduleMaintenanceDataToSave?.pmDescription,
      schPmIdFk: ScheduleMaintenanceDataToSave.schPmIdFk,
      schCalIdFk: ScheduleMaintenanceDataToSave.pmCalsId?.id,
      pmAsstIdFk: ScheduleMaintenanceDataToSave.pmAsstIdFk?.asstId,
      schAsstIdFk: ScheduleMaintenanceDataToSave.pmAsstIdFk?.asstId,
      pmCalsId: ScheduleMaintenanceDataToSave.pmCalsId?.id,
      schIncItemIdFk: ScheduleMaintenanceDataToSave.schIncItemIdFk?.id,
      schPriority: ScheduleMaintenanceDataToSave.schPriority?.listId,
      schImpact: ScheduleMaintenanceDataToSave.schImpact?.listId,
      schReadingFrequency:
        ScheduleMaintenanceDataToSave.schReadingFrequency?.schReadingFrequency,
    };
    if (ScheduleMaintenanceDataToSave?.schType === "time") {
      if (ScheduleMaintenanceDataToSave?.apiTimeKey === "OneTime") {
        payload = {
          ...payload,
          schStartYear: ScheduleMaintenanceDataToSave?.schStartYear,
          schEndYear: ScheduleMaintenanceDataToSave?.schStartYear
            ? ScheduleMaintenanceDataToSave?.schStartYear
            : ScheduleMaintenanceDataToSave?.schStartYear,
          schType: ScheduleMaintenanceDataToSave?.apiTimeKey,
        };
      } else if (ScheduleMaintenanceDataToSave?.apiTimeKey === "Daily") {
        if (
          dayjs(ScheduleMaintenanceDataToSave?.schStartYear).isAfter(
            ScheduleMaintenanceDataToSave?.schEndYear
          )
        ) {
          toast.error("End date should be greater than or equal to start date");
          return; // Prevent API call
        } else {
          payload = {
            ...payload,
            schStartYear: ScheduleMaintenanceDataToSave?.schStartYear,
            schEndYear: ScheduleMaintenanceDataToSave?.schEndYear
              ? ScheduleMaintenanceDataToSave?.schEndYear
              : ScheduleMaintenanceDataToSave?.schStartYear,
            schType: ScheduleMaintenanceDataToSave?.apiTimeKey,
          };
        }
      } else if (ScheduleMaintenanceDataToSave?.apiTimeKey === "Weekly") {
        if (
          dayjs(ScheduleMaintenanceDataToSave?.schStartYear).isAfter(
            ScheduleMaintenanceDataToSave?.schEndYear
          )
        ) {
          toast.error("End date should be greater than or equal to start date");
          return; // Prevent API call
        } else {
          payload = {
            ...payload,
            schStartYear: ScheduleMaintenanceDataToSave?.schStartYear,
            schEndYear: ScheduleMaintenanceDataToSave?.schEndYear
              ? ScheduleMaintenanceDataToSave?.schEndYear
              : ScheduleMaintenanceDataToSave?.schStartYear,
            schType: ScheduleMaintenanceDataToSave?.apiTimeKey,
            schSun: ScheduleMaintenanceDataToSave?.schSun === "schSun",
            schThu: ScheduleMaintenanceDataToSave?.schThu === "schThu",
            schTue: ScheduleMaintenanceDataToSave?.schTue === "schTue",
            schWeb: ScheduleMaintenanceDataToSave?.schWeb === "schWeb",
            schMon: ScheduleMaintenanceDataToSave?.schMon === "schMon",
            schFri: ScheduleMaintenanceDataToSave?.schFri === "schFri",
            schSat: ScheduleMaintenanceDataToSave?.schSat === "schSat",
          };
        }
      } else if (ScheduleMaintenanceDataToSave?.apiTimeKey === "Monthly") {
        if (
          dayjs(ScheduleMaintenanceDataToSave?.schStartYear).isAfter(
            ScheduleMaintenanceDataToSave?.schEndYear
          )
        ) {
          toast.error("End date should be greater than or equal to start date");
          return; // Prevent API call
        } else {
          payload = {
            ...payload,
            schStartYear: ScheduleMaintenanceDataToSave?.schStartYear,
            schEndYear: ScheduleMaintenanceDataToSave?.schEndYear
              ? ScheduleMaintenanceDataToSave?.schEndYear
              : ScheduleMaintenanceDataToSave?.schStartYear,
            schType: ScheduleMaintenanceDataToSave?.apiTimeKey,
            schMonthDay: ScheduleMaintenanceDataToSave?.schMonthDay,
            schJan: ScheduleMaintenanceDataToSave?.schJan === "schJan",
            schFeb: ScheduleMaintenanceDataToSave?.schFeb === "schFeb",
            schMar: ScheduleMaintenanceDataToSave?.schMar === "schMar",
            schApr: ScheduleMaintenanceDataToSave?.schApr === "schApr",
            schMay: ScheduleMaintenanceDataToSave?.schMay === "schMay",
            schJun: ScheduleMaintenanceDataToSave?.schJun === "schJun",
            schJul: ScheduleMaintenanceDataToSave?.schJul === "schJul",
            schAug: ScheduleMaintenanceDataToSave?.schAug === "schAug",
            schSep: ScheduleMaintenanceDataToSave?.schSep === "schSep",
            schOct: ScheduleMaintenanceDataToSave?.schOct === "schOct",
            schNov: ScheduleMaintenanceDataToSave?.schNov === "schNov",
            schDec: ScheduleMaintenanceDataToSave?.schDec === "schDec",
          };
        }
      }
    } else if (ScheduleMaintenanceDataToSave?.schType === "meter") {
      payload = {
        ...payload,
        schType: ScheduleMaintenanceDataToSave?.schType,
        schReadingFrequency: ScheduleMaintenanceDataToSave?.schReadingFrequency,
      };
    } else if (ScheduleMaintenanceDataToSave?.schType === "event") {
      payload = {
        ...payload,
        schType: ScheduleMaintenanceDataToSave?.schType,
      };
    } else if (ScheduleMaintenanceDataToSave?.schType === "preventive") {
      payload = {
        ...payload,
        schType: ScheduleMaintenanceDataToSave?.schType,
      };
    }
    if (
      ScheduleMaintenanceDataToSave?.apiTimeKey === "Weekly" &&
      !(
        ScheduleMaintenanceDataToSave?.schMon ||
        ScheduleMaintenanceDataToSave?.schTue ||
        ScheduleMaintenanceDataToSave?.schWeb ||
        ScheduleMaintenanceDataToSave?.schThu ||
        ScheduleMaintenanceDataToSave?.schFri ||
        ScheduleMaintenanceDataToSave?.schSat ||
        ScheduleMaintenanceDataToSave?.schSun
      )
    ) {
      toast.error("Atleast one day should be selected!");
    } else if (
      ScheduleMaintenanceDataToSave?.apiTimeKey === "Monthly" &&
      !(
        ScheduleMaintenanceDataToSave?.schJan ||
        ScheduleMaintenanceDataToSave?.schFeb ||
        ScheduleMaintenanceDataToSave?.schMar ||
        ScheduleMaintenanceDataToSave?.schApr ||
        ScheduleMaintenanceDataToSave?.schMay ||
        ScheduleMaintenanceDataToSave?.schJun ||
        ScheduleMaintenanceDataToSave?.schJul ||
        ScheduleMaintenanceDataToSave?.schAug ||
        ScheduleMaintenanceDataToSave?.schSep ||
        ScheduleMaintenanceDataToSave?.schOct ||
        ScheduleMaintenanceDataToSave?.schNov ||
        ScheduleMaintenanceDataToSave?.schDec
      )
    ) {
      toast.error("Atleast one month should be selected!");
    } else {
      updateScheduleMaintenance({
        pmId: ScheduleMaintenanceDataToSave?.pmId,
        schId: ScheduleMaintenanceDataToSave?.schId,
        ...payload,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Schedule Maintenance Updated successfully");
            setShowScheduleMaintenanceEditForm(false);
            setId();
          } else if (response.status === 208) {
            toast.error(
              response?.data?.editData ||
                "An error occurred while updating the schedule maintenance"
            );
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(
              error?.response?.data?.message ||
                "Bad request. Please check your input."
            );
          } else if (error?.message === "Schedule Type is required") {
            toast.error("Schedule Type is required");
          } else if (error?.message === "Please select a time option") {
            toast.error("Please select a time option");
          } else {
            toast.error(
              error?.message ||
                "An error occurred while updating the schedule maintenance"
            );
          }
        });
    }
  };

  const submitAddScheduleMaintenanceData = async (newSchMaintenanceAddData) => {
    if (!newSchMaintenanceAddData.schType) {
      toast.error("Schedule Type is required");
      return;
    }
    if (
      newSchMaintenanceAddData.schType === "time" &&
      !newSchMaintenanceAddData.apiTimeKey
    ) {
      toast.error("Please select a time option");
      return;
    }
    if (
      newSchMaintenanceAddData?.schStartYear &&
      newSchMaintenanceAddData?.schEndYear &&
      dayjs(newSchMaintenanceAddData?.schStartYear).isAfter(
        newSchMaintenanceAddData?.schEndYear
      )
    ) {
      toast.error("End date should be greater than or equal to start date");
    } else {
      let payload = {
        pmPlantIdFk: plantId,
        schPlantIdFk: plantId,
        plantIdFk: plantId,
        pmMaintenanceType: "Preventive",
        schMaintType: "Scheduled",
        pmDescription: newSchMaintenanceAddData?.pmDescription,
        schPmIdFk: newSchMaintenanceAddData.schPmIdFk,
        schCalIdFk: newSchMaintenanceAddData.pmCalsId?.id,
        pmAsstIdFk: newSchMaintenanceAddData.pmAsstIdFk?.asstId,
        schAsstIdFk: newSchMaintenanceAddData.pmAsstIdFk?.asstId,
        pmCalsId: newSchMaintenanceAddData.pmCalsId?.id,
        schIncItemIdFk: newSchMaintenanceAddData.schIncItemIdFk?.id,
        schPriority: newSchMaintenanceAddData.schPriority?.listId,
        schImpact: newSchMaintenanceAddData.schImpact?.listId,
        schReadingFrequency:
          newSchMaintenanceAddData.schReadingFrequency?.schReadingFrequency,
      };
      if (newSchMaintenanceAddData?.schType === "time") {
        if (newSchMaintenanceAddData?.apiTimeKey === "OneTime") {
          payload = {
            ...payload,
            schStartYear: newSchMaintenanceAddData?.schStartYear,
            schEndYear: newSchMaintenanceAddData?.schEndYear
              ? newSchMaintenanceAddData?.schEndYear
              : newSchMaintenanceAddData?.schStartYear,
            schType: newSchMaintenanceAddData?.apiTimeKey,
          };
        } else if (newSchMaintenanceAddData?.apiTimeKey === "Daily") {
          payload = {
            ...payload,
            schStartYear: newSchMaintenanceAddData?.schStartYear,
            schEndYear: newSchMaintenanceAddData?.schEndYear
              ? newSchMaintenanceAddData?.schEndYear
              : newSchMaintenanceAddData?.schStartYear,
            schType: newSchMaintenanceAddData?.apiTimeKey,
          };
        } else if (newSchMaintenanceAddData?.apiTimeKey === "Weekly") {
          payload = {
            ...payload,
            schStartYear: newSchMaintenanceAddData?.schStartYear,
            schEndYear: newSchMaintenanceAddData?.schEndYear
              ? newSchMaintenanceAddData?.schEndYear
              : newSchMaintenanceAddData?.schStartYear,
            schType: newSchMaintenanceAddData?.apiTimeKey,
            schSun: newSchMaintenanceAddData?.schSun === "schSun",
            schThu: newSchMaintenanceAddData?.schThu === "schThu",
            schTue: newSchMaintenanceAddData?.schTue === "schTue",
            schWeb: newSchMaintenanceAddData?.schWeb === "schWeb",
            schMon: newSchMaintenanceAddData?.schMon === "schMon",
            schFri: newSchMaintenanceAddData?.schFri === "schFri",
            schSat: newSchMaintenanceAddData?.schSat === "schSat",
          };
        } else if (newSchMaintenanceAddData?.apiTimeKey === "Monthly") {
          payload = {
            ...payload,
            schStartYear: newSchMaintenanceAddData?.schStartYear,
            schEndYear: newSchMaintenanceAddData?.schEndYear
              ? newSchMaintenanceAddData?.schEndYear
              : newSchMaintenanceAddData?.schStartYear,
            schType: newSchMaintenanceAddData?.apiTimeKey,
            schMonthDay: newSchMaintenanceAddData?.schMonthDay,
            schJan: newSchMaintenanceAddData?.schJan === "schJan",
            schFeb: newSchMaintenanceAddData?.schFeb === "schFeb",
            schMar: newSchMaintenanceAddData?.schMar === "schMar",
            schApr: newSchMaintenanceAddData?.schApr === "schApr",
            schMay: newSchMaintenanceAddData?.schMay === "schMay",
            schJun: newSchMaintenanceAddData?.schJun === "schJun",
            schJul: newSchMaintenanceAddData?.schJul === "schJul",
            schAug: newSchMaintenanceAddData?.schAug === "schAug",
            schSep: newSchMaintenanceAddData?.schSep === "schSep",
            schOct: newSchMaintenanceAddData?.schOct === "schOct",
            schNov: newSchMaintenanceAddData?.schNov === "schNov",
            schDec: newSchMaintenanceAddData?.schDec === "schDec",
          };
        }
      } else if (newSchMaintenanceAddData?.schType === "meter") {
        payload = {
          ...payload,
          schType: newSchMaintenanceAddData?.schType,
          schReadingFrequency: newSchMaintenanceAddData?.schReadingFrequency,
        };
      } else if (newSchMaintenanceAddData?.schType === "event") {
        payload = {
          ...payload,
          schType: newSchMaintenanceAddData?.schType,
        };
      } else if (newSchMaintenanceAddData?.schType === "preventive") {
        payload = {
          ...payload,
          schType: newSchMaintenanceAddData?.schType,
        };
      }
      if (
        newSchMaintenanceAddData?.apiTimeKey === "Weekly" &&
        !(
          newSchMaintenanceAddData?.schMon ||
          newSchMaintenanceAddData?.schTue ||
          newSchMaintenanceAddData?.schWeb ||
          newSchMaintenanceAddData?.schThu ||
          newSchMaintenanceAddData?.schFri ||
          newSchMaintenanceAddData?.schSat ||
          newSchMaintenanceAddData?.schSun
        )
      ) {
        toast.error("Atleast one day should be selected!");
      } else if (
        newSchMaintenanceAddData?.apiTimeKey === "Monthly" &&
        !(
          newSchMaintenanceAddData?.schJan ||
          newSchMaintenanceAddData?.schFeb ||
          newSchMaintenanceAddData?.schMar ||
          newSchMaintenanceAddData?.schApr ||
          newSchMaintenanceAddData?.schMay ||
          newSchMaintenanceAddData?.schJun ||
          newSchMaintenanceAddData?.schJul ||
          newSchMaintenanceAddData?.schAug ||
          newSchMaintenanceAddData?.schSep ||
          newSchMaintenanceAddData?.schOct ||
          newSchMaintenanceAddData?.schNov ||
          newSchMaintenanceAddData?.schDec
        )
      ) {
        toast.error("Atleast one month should be selected!");
      } else {
        addScheduleMaintenance({
          ...payload,
        })
          .then((res) => {
            if (res.status === 200) {
              toast.success("Schedule Maintenance Added successfully");
              setShowScheduleMaintenanceForm(false);
              setShowScheduleMaintenanceEditForm(
                !showScheduleMaintenanceEditForm
              );
            } else if (res.status === 208) {
              toast.error(
                res?.data?.editData ||
                  "An error occurred while adding the schedule maintenance"
              );
            }
          })
          .catch((error) => {
            if (error?.response?.status === 400) {
              toast.error(
                error?.response?.data?.message ||
                  "Bad request. Please check your input."
              );
            } else if (error?.message === "Schedule Type is required") {
              toast.error("Schedule Type is required");
            } else if (error?.message === "Please select a time option") {
              toast.error("Please select a time option");
            } else {
              toast.error(
                error?.message ||
                  "An error occurred while adding the schedule maintenance"
              );
            }
          });
      }
    }
  };
  const handleDeleteClick = async () => {
    deleteScheduleMaintenance()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Schedule Maintenance  Deleted successfully");
          setOpenDialog(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowScheduleMaintenanceForm(false);
    setShowScheduleMaintenanceEditForm(!showScheduleMaintenanceEditForm);
  };

  const allcolumns = [
    {
      field: "pmAsstIdFk",
      maxLength: 30,
      headerName: "Asset",
      required: true,
      type: "select",
      textFieldWidth: medium,
      width: GridWidthMedium,
      apiendpoint: "getAssetDataList",
    },
    {
      field: "pmDescription",
      maxLength: 40,
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
    },

    {
      field: "pmCalsId",
      maxLength: 30,
      headerName: "Calendar",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getCalendarSystemData",
      width: GridWidthMedium,
      required: true,
    },
    {
      field: "schIncItemIdFk",
      headerName: "Incident",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
      section: "Schedule",
    },
    {
      field: "schPriority",
      headerName: "Priority Level",
      type: "select",
      required: false,
      apiendpoint: "workOrderPriority",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "schImpact",
      headerName: "Impact",
      type: "select",
      required: false,
      apiendpoint: "workOrderAssetCriticalityImpact",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },

    {
      field: "schType",
      headerName: "Schedule Type",
      type: "radio",
      required: true,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "schReadingFrequency",
      headerName: "Reading Frequency",
      type: "text",
      required: true,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },

    {
      field: "schStartYear",
      headerName: "Start Date",
      type: "date",
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "schEndYear",
      headerName: "End Date",
      type: "date",
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
  ];
  const editColumns = [
    {
      field: "pmAsstIdFk",
      maxLength: 30,
      headerName: "Asset",
      required: true,
      type: "selectTwo",
      width: GridWidthMedium,
      options: modifiedOptions,
    },
    {
      field: "pmDescription",
      maxLength: 40,
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "pmMaintenanceType",
      headerName: "Maintenance Type",
      width: GridWidthMedium,
    },
    {
      field: "pmCalsId",
      maxLength: 30,
      headerName: "Calendar",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getCalendarSystemData",
      width: GridWidthMedium,
      required: true,
    },
    {
      field: "schAsstIdFk",
      maxLength: 30,
      headerName: "Schedule Asset",
    },
    {
      field: "schIncItemIdFk",
      headerName: "Incident",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
      section: "Schedule",
    },
    {
      field: "schPriority",
      headerName: "Priority Level",
      type: "select",
      required: false,
      apiendpoint: "workOrderPriority",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "schImpact",
      headerName: "Impact",
      type: "select",
      required: false,
      apiendpoint: "workOrderAssetCriticalityImpact",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "schMaintType",
      headerName: "Maintenance Type",
      width: GridWidthMedium,
    },
    {
      field: "schType",
      headerName: "Schedule Type",
      type: "radio",
      required: true,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "schReadingFrequency",
      headerName: "Reading Frequency",
      width: GridWidthMedium,
      required: true,
    },
    {
      field: "schSun",
      headerName: "Sunday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schMon",
      headerName: "Monday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schTue",
      headerName: "Tuesday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schWeb",
      headerName: "Wednesday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schThu",
      headerName: "Thursday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schFri",
      headerName: "Friday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schSat",
      headerName: "Saturday",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schMonthDay",
      headerName: "Month Day",
      width: GridWidthMedium,
    },
    {
      field: "schJan",
      headerName: "Jan",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schFeb",
      headerName: "Feb",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schMar",
      headerName: "Mar",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schApr",
      headerName: "Apr",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schMay",
      headerName: "May",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schJun",
      headerName: "Jun",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schJul",
      headerName: "Jul",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schAug",
      headerName: "Aug",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schSep",
      headerName: "Sep",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schOct",
      headerName: "Oct",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schNov",
      headerName: "Nov",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schDec",
      headerName: "Dec",
      type: "boolean",
      width: GridWidthMedium,
    },
    {
      field: "schStartYear",
      headerName: "Start Year",
      width: GridWidthMedium,
      required: true,
    },
    {
      field: "schEndYear",
      headerName: "End Year",
      width: GridWidthMedium,
      required: true,
    },
    {
      field: "apiTimeKey",
      headerName: "apiTimeKey",
      hideable: true,
    },
  ];

  const [schColumns, setSchColumns] = useState([
    {
      field: "itemCount",
      headerName: "Id",
      type: "number",
      width: GridWidthSmall,
    },
    {
      field: "caldDesc",
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "caldDate",
      headerName: "Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "caldCtype",
      headerName: "Type",
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    setColumns(updateColumns(columns, updatedConfig.grid.columns));
    setSchColumns(
      updateColumns(schColumns, updatedConfig.sub_component?.sch_sc)
    );

    const configToUpdate = {
      ...updatedConfig,
      sub_component: {
        sch_sc: schColumns,
      },
    };

    Object.keys(configToUpdate.sub_component).forEach((key) => {
      configToUpdate.sub_component[key] = configToUpdate.sub_component[key].map(
        (col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })
      );
    });

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_preventive_maintenance`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "preventive_maintenance" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_preventive_maintenance`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  useEffect(() => {
    fetchColumnConfig();
  }, [columnConfig, defaultConfig, id]);

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.sch_sc
      ) {
        const updatedSchColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.sch_sc } },
          schColumns
        );
        setSchColumns(updatedSchColumns);
      } else {
        setSchColumns(schColumns.map((col) => ({ ...col, hide: false })));
      }

      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    const storedView = localStorage.getItem(
      `selectedView_preventive_maintenance`
    );
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const fetchColumnConfig = async () => {
    try {
      if (columnConfig && Object.keys(columnConfig).length > 0) {
        applyConfiguration(columnConfig);
        if (columnConfig.view_name && columnConfig.view_id) {
          setSelectedView({
            name: columnConfig.view_name,
            id: columnConfig.view_id,
          });
        }
      } else if (defaultConfig) {
        setColumnConfig(defaultConfig);
        applyConfiguration(defaultConfig);
      } else {
        setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
    }
  };

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, allcolumns);
      setColumns(transformedColumns);
    }

    if (config.sub_component) {
      if (config.sub_component.sch_sc) {
        const transformedSchColumns = transformConfig(
          { grid: { columns: config.sub_component.sch_sc } },
          schColumns
        );
        setSchColumns(transformedSchColumns);
      }
    }

    if (config.view_name) {
      setSelectedView({ name: config.view_name, id: config.view_id });
    }
  };

  const addAssetClick = () => {
    setShowScheduleMaintenanceEditForm(true);
    setShowScheduleMaintenanceForm(true);
    setEditDefValues({});
  };
  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isSchLoading && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"pmAsstIdFk"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => addAssetClick()}
                        onClickEdit={() => handleAssetEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickProperties={() => toggleDrawer(true)()}
                        propId={id}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        plantId={plantId}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="preventive_maintenance"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showScheduleMaintenanceEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showScheduleMaintenanceForm}
          setShowForm={setShowScheduleMaintenanceForm}
          columns={editColumns}
          onUpData={saveUpdateData}
          onAdd={submitAddScheduleMaintenanceData}
          isLoading={addingAsset || updatingAsset}
          editId={id}
          defaultValues={editDefValues}
          label={"Preventive Maintenance"}
          schedule={true}
        />
      )}
      <Box>
        <Box>
          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={toggleDrawer(false)}
            disableSwipeToOpen={false}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Box sx={{ width: "60vw" }}>
              <ScheduleMaintenanceMultiStep
                onClose={toggleDrawer(false)}
                id={id}
                open={open}
                setOpen={setOpen}
                setEditScheduleValues={setEditScheduleValues}
                editScheduleValues={editScheduleValues}
                selectedView={selectedView}
                schColumns={schColumns}
                setSchColumns={setSchColumns}
              />
            </Box>
          </SwipeableDrawer>
        </Box>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Schedule Maintenance?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="preventive_maintenance"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default ScheduleMaintenance;
