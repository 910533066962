import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  gridHeight,
  small,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box, SwipeableDrawer } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import { useCommonData } from "../../context/CommonDataProvider";
import { CountryCode } from "../../utils/Country";
import { CalendarSystemMultiSteps } from "./CalendarSystemSteps/CalendarSystemMultiSteps";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../utils/common";
import { useMenuContext } from "../../context/MenuContextProvider";

export const CalendarSystem = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [editDefWorkingValues, setEditDefWorkingValues] = useState();
  const [calsId, setCalsId] = useState(null);
  const [showCalendarSystemForm, setShowCalendarSystemForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showCalendarSystemEditForm, setShowCalendarSystemEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const plantId = commonData?.userData?.lastLoginPlantID;

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const showData = () => {
    setOpen(true);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setCalsId(params.row.id);
  };
  const {
    mutateAsync: addCalendarSystem,
    isSuccess: isCalendarSystemSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/calendar-system`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateCalendarSystem,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/calendar-system/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteCalendarSystem, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${WorkOrderPrefix}/calendar-system/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getCalendarSystemData,
    isFetchedAfterMount: isFetch,
    isFetching: isCalendarSystemFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/calendar-system/plant`,
    retrieveOnMount:
      plantId !== null && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  useEffect(() => {
    if (isCalendarSystemSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isCalendarSystemSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getMainCalendarSystemData, setGetMainCalendarSystemData] = useState(
    getCalendarSystemData
  );

  useEffect(() => {
    if (getCalendarSystemData) {
      const calsSData = getCalendarSystemData.map((item) => {
        const newCountryCode = mapDropdownValueToLabelWithId(
          CountryCode,
          item.calsCountryCode
        );
        return {
          ...item,
          calsCountryCode: newCountryCode,
        };
      });

      setGetMainCalendarSystemData(calsSData);
    }
  }, [getCalendarSystemData]);

  useEffect(() => {
    if (isFetch && getMainCalendarSystemData && canRead) {
      setRows(() =>
        getMainCalendarSystemData?.map((w) => {
          return { id: w.calsId, ...w };
        })
      );
    }
  }, [isFetch, getMainCalendarSystemData, rightsArray, canRead]);

  const handleCalendarSystemEditClick = () => {
    setShowCalendarSystemEditForm(true);
    setId(editData.id);
    const newCountryCode = mapDropdownValueToIdWithLabel(
      CountryCode,
      editData.calsCountryCode
    );
    setEditDefValues({ ...editData, calsCountryCode: newCountryCode });
  };
  const saveUpdateData = async (CalendarSystemDataToSave) => {
    updateCalendarSystem({
      calsId: CalendarSystemDataToSave.calsId,
      calsPlantIdFk: plantId,
      calsDesc: CalendarSystemDataToSave?.calsDesc,
      calsCountryCode: CalendarSystemDataToSave?.calsCountryCode?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Calendar updated successfully");
          setShowCalendarSystemEditForm(false);
          setId();
          commonData.refetchCalsSystem();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitCalendarSystemData = async (newCalendarSystemData) => {
    addCalendarSystem({
      calsPlantIdFk: plantId,
      ...newCalendarSystemData,
      calsCountryCode: newCalendarSystemData?.calsCountryCode?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Calendar Added successfully");
          setShowCalendarSystemEditForm(!showCalendarSystemEditForm);
          setShowCalendarSystemForm(false);
          commonData.refetchCalsSystem();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  const handleDeleteClick = async () => {
    deleteCalendarSystem()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Calendar Deleted successfully");
          setOpenDialog(false);
          setId();
          commonData.refetchCalsSystem();
        } else if (response.status === 208) {
          toast.error(response?.data.message);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.status === 208) {
          toast.error(error?.response?.data.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowCalendarSystemEditForm(!showCalendarSystemEditForm);
    setShowCalendarSystemForm(false);
  };

  const columns = [
    {
      field: "calsDesc",
      headerName: "Calendar Name",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "calsCountryCode",
      headerName: "Country",
      required: true,
      type: "select",
      width: GridWidthMedium,
      options: CountryCode?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      textFieldWidth: small,
    },
  ];

  const onAdd = () => {
    setShowCalendarSystemEditForm(true);
    setShowCalendarSystemForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isCalendarSystemFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={columns}
              rows={rows}
              sortingField={"calsDesc"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleCalendarSystemEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickProperties={() => showData()}
                        editId={id}
                        propId={calsId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
              onCellDoubleClick={showData}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showCalendarSystemEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showCalendarSystemForm}
          setShowForm={setShowCalendarSystemForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitCalendarSystemData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Calendar System"
        />
      )}
      <Box>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ width: "60vw" }}>
            <CalendarSystemMultiSteps
              onClose={toggleDrawer(false)}
              calsId={calsId}
              open={open}
              setOpen={setOpen}
              setEditDefWorkingValues={setEditDefWorkingValues}
              editDefWorkingValues={editDefWorkingValues}
            />
          </Box>
        </SwipeableDrawer>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Calendar System?"}
        handleApprove={handleDeleteClick}
      />
    </>
  );
};
