import React, { useState, useEffect } from "react";
import { Box, Typography, Tabs, Tab } from "@mui/material";
import { DataGridComponent } from "../../../common/DataGridComponent";
import {
  WorkOrderPrefix,
  PlantPrefix,
  GeneralPrefix
} from "../../../constants/global";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { useMenuContext } from "../../../context/MenuContextProvider";
import { useCommonData } from "../../../context/CommonDataProvider";
 
const MiddleSection = ({ heights, widths, handleMouseDown }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { rightsArray } = useMenuContext();
  const commonData = useCommonData();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const [workOrderList, setWorkOrderList] = useState([]);
  const [requestListWithId, setRequestListWithId] = useState([]);
  const [shouldFetchRequests, setShouldFetchRequests] = useState(true);
  const [error, setError] = useState(null);
 
  const { data: assetData, isFetching: isAssetLoading } = useFetchApi({
    endpoint: `${GeneralPrefix}/upcoming-schedules`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
 
  const { data: workOrderData, isFetching: isWorkOrderLoading } = useFetchApi({
    endpoint: `${WorkOrderPrefix}?plantId=${plantId}`,
    retrieveOnMount: plantId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
 
  const {
    mutateAsync: requestData,
    isSuccess: isFetchSuccess,
    isLoading: isFilterDataLoading,
  } = useMutationApi({
    endpoint: `${PlantPrefix}/ticketrequestsFilterData`,
    method: "post",
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: 6,
  });
 
  useEffect(() => {
    if (workOrderData && !isWorkOrderLoading) {
      setWorkOrderList(workOrderData);
    }
  }, [workOrderData, isWorkOrderLoading]);
 
  useEffect(() => {
    const fetchRequestData = async () => {
      if (!shouldFetchRequests) return;
 
      try {
        setShouldFetchRequests(false);
        const result = await requestData();
        const updatedRequestList = result.map((request, index) => ({
          ...request,
          id: index + 1,
        }));
        setRequestListWithId(updatedRequestList);
      } catch (err) {
        console.error("Error fetching request data:", err);
        setError("An error occurred while fetching request data.");
      }
    };
 
    fetchRequestData();
  }, [shouldFetchRequests]);
 
  const requestColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "maintReqNote", headerName: "Title", flex: 1 },
    { field: "maintState", headerName: "Status", width: 130 },
    { field: "maintReqDate", headerName: "Date", width: 130 },
  ];
 
  const workOrderColumns = [
    { field: "woNumber", headerName: "ID", width: 70 },
    { field: "woDesc", headerName: "Title", flex: 1 },
    { field: "woStatus", headerName: "Status", width: 130 },
    { field: "woDate", headerName: "Date", width: 130 },
  ];
 
  const scheduledActivitiesColumns = [
    {
      field: "caldAsstIdFk",
      headerName: "Asset",
      width: 100
    },
    {
      field: "caldDesc",
      headerName: "Description",
      flex: 1
    },
    {
      field: "caldCtype",
      headerName: "Type",
      width: 120
    },
    {
      field: "caldDate",
      headerName: "Date",
      width: 130,
      valueFormatter: (params) => {
        if (!params.value) return '';
        return new Date(params.value).toLocaleDateString();
      }
    }
  ];
 
 
  return (
    <Box sx={{ display: "flex", flex: heights.bottomRow, minHeight: "10%" }}>
      <Box
        sx={{
          flex: widths.bottomLeftColumn,
          position: "relative",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflow: "auto",
            borderRadius: 2,
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Typography variant="h6" sx={{ mb: 1, color: "#0D47A1" }}>
            Assigned to Me
          </Typography>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab label="Request" />
            <Tab label="Work Order" />
          </Tabs>
          {selectedTab === 0 && (
            <DataGridComponent
              columns={requestColumns}
              rows={requestListWithId}
              sx={{ height: 300, mt: 2, width: 650 }}
              sortingField="date"
              components={{
                Toolbar: () => null,
              }}
            />
          )}
          {selectedTab === 1 && (
            <DataGridComponent
              columns={workOrderColumns}
              rows={workOrderList.map((workOrder) => ({
                ...workOrder,
                id: workOrder.woNumber || `wo-${workOrder.woId}`,
              }))}
              sx={{ height: 300, mt: 2, width: 650 }}
              sortingField="woDate"
              components={{
                Toolbar: () => null,
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: 0,
            width: "1px",
            cursor: "col-resize",
            bgcolor: "grey.400",
          }}
          onMouseDown={(e) => handleMouseDown(e, "horizontalBottom")}
        />
      </Box>
      <Box
        sx={{
          flex: widths.bottomRightColumn,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 1,
            p: 2,
            overflow: "auto",
            borderRadius: 2,
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Typography variant="h6" sx={{ mb: 1, color: "#0D47A1" }}>
            Scheduled Activities (Next 3 Days)
          </Typography>
          <Typography sx={{ color: "#0D47A1", mb: 2 }}>
            {assetData?.length || 0} activities scheduled
          </Typography>
          <DataGridComponent
            columns={scheduledActivitiesColumns}
            rows={(assetData || []).map(row => ({
              ...row,
              id: row.caldId  // Add explicit id property
            }))}
            sx={{ height: 300, width: "100%" }}
            sortingField="caldDate"
            loading={isAssetLoading}
            getRowId={(row) => row.caldId}
            components={{
              Toolbar: () => null,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
 
export default MiddleSection;