import React, { useEffect, useState } from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../../common/DataGridComponent";
import { GridToolbar } from "@mui/x-data-grid";
import {
  GridWidthLargeWidth,
  ListGroupPrefix,
  medium,
} from "../../../constants/global";
import { Button } from "../../../common/Button";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import useMutationApi from "../../../hooks/useMutationApi";
import useFetchApi from "../../../hooks/useFetchApi";
import { toast } from "react-toastify";
import { useCommonData } from "../../../context/CommonDataProvider";
import { useMenuContext } from "../../../context/MenuContextProvider";

const MenuSelection = ({ ugId, onClose }) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [rows, setRows] = useState([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const methods = useForm({});

  useEffect(() => {
    setSelectedCheckboxes({});
  }, [ugId]);

  const {
    mutateAsync: addUserRights,
    isSuccess: isAddSuccess,
    isLoading: isAddLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user-group-rights?username=${commonData?.userData?.email_id}`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateUserRights,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user-group-rights-update?userGroupId=${ugId}&username=${commonData?.userData?.email_id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getUserGroupRightsData,
    isFetchedAfterMount: isFetch,
    isFetching: isUsersFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/user-group-rights-json-menu?groupId=${ugId}`,
    retrieveOnMount:
      ugId !== undefined && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getMenuData,
    isFetchedAfterMount: isMenuFetch,
    isFetching: isMenuFetching,
    refetch: menuDataFetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/user-group-menu`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  useEffect(() => {
    if (isUpdateSuccess || isAddSuccess) {
      refetch();
      menuDataFetch();
    }
  }, [isUpdateSuccess, isAddSuccess, refetch, menuDataFetch]);

  useEffect(() => {
    if (isFetch && canRead) {
      let newRows;
      if (getUserGroupRightsData?.length === 0) {
        if (isMenuFetch) {
          newRows = getMenuData?.map((menu) => ({
            id: menu.menuId,
            ...menu,
          }));
        }
      } else {
        newRows = getUserGroupRightsData?.map((menu) => ({
          id: menu.urMenuIdFk,
          ...menu,
        }));
      }

      if (newRows) {
        setRows(newRows);
        const newSelectedCheckboxes = {};
        newRows.forEach((row) => {
          newSelectedCheckboxes[row.id] = {
            urEnable: row.urEnable || false,
            urRead: row.urRead || false,
            urWrite: row.urWrite || false,
            urModify: row.urModify || false,
            urView: row.urView || false,
            urDelete: row.urDelete || false,
            urAdmin: row.urAdmin || false,
            urExecute: row.urExecute || false,
          };
        });
        setSelectedCheckboxes(newSelectedCheckboxes);
      }
    }
  }, [
    isFetch,
    getUserGroupRightsData,
    isMenuFetch,
    getMenuData,
    canRead,
    ugId,
  ]);

  const handleCheckboxChange = (event, rowId, params, name, value) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [rowId]: {
        ...prevState[rowId],
        [name]: event.target.checked,
      },
    }));
    setRows((prevRows) =>
      prevRows.map((item) =>
        item.id === params?.row?.id ? { ...item, [name]: value } : item
      )
    );
  };

  const saveData = async () => {
    const payload = {};
    rows.forEach((row, index) => {
      payload[index] = {
        urAppIdFk: 1,
        urUgIdFk: ugId,
        urMenuIdFk: row.menuId ? row.menuId : row?.urMenuIdFk,
        urEnable: row.urEnable,
        urRead: row.urRead,
        urView: row.urView,
        urWrite: row.urWrite,
        urDelete: row.urDelete,
        urAdmin: row.urAdmin,
        urExecute: row.urExecute,
        urModify: row.urModify,
      };
    });

    if (getUserGroupRightsData?.length === 0) {
      addUserRights({ permissions: payload })
        .then((res) => {
          if (res.status === 200) {
            toast.success("User Rights added successfully");
            methods.reset();
            onClose();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    } else {
      updateUserRights({ permissions: payload })
        .then((res) => {
          if (res.status === 200) {
            toast.success("User Rights updated successfully");
            methods.reset();
            onClose();
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };
  const columns = [
    {
      field: "menuGroup",
      headerName: "Menu Group",
      textFieldWidth: medium,
      width: 100,
    },
    {
      field: "menuName",
      headerName: "Menu Name",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
      width: 120,
    },
    {
      field: "urEnable",
      headerName: "Enabled",
      textFieldWidth: medium,
      width: 60,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              value={true}
              color="primary"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urEnable",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urEnable ||
                params?.row?.urEnable ||
                false
              }
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "urRead",
      headerName: "Read",
      textFieldWidth: medium,
      width: 60,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              color="primary"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urRead",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urRead ||
                params?.row?.urRead ||
                false
              }
            />
          </>
        );
      },
    },

    {
      field: "urWrite",
      headerName: "Write",
      textFieldWidth: medium,
      width: 60,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urWrite",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urWrite ||
                params?.row?.urWrite ||
                false
              }
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "urModify",
      headerName: "Modify",
      textFieldWidth: medium,
      width: 60,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urModify",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urModify ||
                params?.row?.urModify ||
                false
              }
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "urView",
      headerName: "View",
      width: 60,
      textFieldWidth: medium,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urView",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urView ||
                params?.row?.urView ||
                false
              }
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "urDelete",
      headerName: "Delete",
      width: 60,
      textFieldWidth: medium,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urDelete",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urDelete ||
                params?.row?.urDelete ||
                false
              }
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "urAdmin",
      headerName: "Admin",
      width: 60,
      textFieldWidth: medium,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urAdmin",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urAdmin ||
                params?.row?.urAdmin ||
                false
              }
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
    {
      field: "urExecute",
      headerName: "Execute",
      width: 60,
      textFieldWidth: medium,
      renderCell: (params) => {
        return (
          <>
            <Checkbox
              size="small"
              onChange={(event) => {
                handleCheckboxChange(
                  event,
                  params.row.id,
                  params,
                  "urExecute",
                  event.target.checked
                );
              }}
              checked={
                selectedCheckboxes[params.row.id]?.urExecute ||
                params?.row?.urExecute ||
                false
              }
              color="primary"
            />
            <p>{params.row.wocStatus}</p>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Box sx={{ padding: "1rem" }}>
        <Typography fontWeight={"bold"}>User Rights</Typography>

        <Box
          sx={{
            height: "80vh",
            width: "30vw",
          }}
        >
          <DataGridComponent
            columns={columns}
            rows={rows}
            sortingField={"menuGroup"}
            components={{
              Toolbar: (props) => (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <CustomToolbar {...props} />
                  </div>
                </>
              ),
            }}
          ></DataGridComponent>
          <form onSubmit={methods.handleSubmit(saveData)}>
            <FormProvider {...methods}>
              <Box
                sx={{
                  justifyContent: "flex-start",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <Button
                  type="submit"
                  label={
                    getUserGroupRightsData?.length === 0 ? "Save" : "Update"
                  }
                />
                <Button
                  label="Cancel"
                  onClick={onClose}
                  variant="outlined"
                  sx={{ marginLeft: "2rem" }}
                />
              </Box>
            </FormProvider>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default MenuSelection;
