import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { useCommonData } from "../../../context/CommonDataProvider";
import {
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
} from "../../../constants/global";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  getFormattedTime,
  mapDropdownValueToDescWithId,
  mapDropdownValueToIdWithDesc,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
  modifyOptionsWithCodeAndTag,
} from "../../../utils/common";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import dayjs from "dayjs";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";

export const WorkAsset = ({
  workOrderId,
  workAssetColumns,
  setWorkAssetColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const commonData = useCommonData();
  const [showAssetForm, setShowAssetForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showAssetEditForm, setShowAssetEditForm] = useState(false);
  const [editDefValues, setEditDefValues] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };
  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addWorkOrderAsset,
    isSuccess: isWorkOrderAssetSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/asset/addassets`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderAsset,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/asset?woaId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderAsset,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/asset?woaId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    isSuccess: isAssetFetchSuccess,
    isFetchedAfterMount: isFetch,
    isLoading: isAssetFetching,
    refetch,
    data: workOrderAsset,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/asset?workOrderId=${workOrderId}`,
    retrieveOnMount: workOrderId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderAssetSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderAssetSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getWoAssetTaskData, setGetWoAssetTaskData] = useState(workOrderAsset);

  useEffect(() => {
    if (workOrderAsset) {
      const assetData = workOrderAsset.map((item) => {
        const newCriticality = mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticality,
          item.woaAssetCriticality
        );
        const newParentAsset = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.woaAsstIdFk
        );
        const newIncident = mapDropdownValueToLabelWithId(
          commonData.getIncidentOptions,
          item.woaIncItemIdFk
        );
        return {
          ...item,
          woaAssetCriticality: newCriticality,
          woaIncItemIdFk: newIncident,
          woaAsstIdFk: newParentAsset,
        };
      });
      setGetWoAssetTaskData(assetData);
    }
  }, [workOrderAsset, commonData]);

  useEffect(() => {
    if (isFetch && getWoAssetTaskData && canRead) {
      setRows(() => {
        return (
          isAssetFetchSuccess &&
          getWoAssetTaskData?.map((w) => {
            return { id: w.woaId, ...w };
          })
        );
      });
    }
  }, [isFetch, getWoAssetTaskData, isAssetFetchSuccess, rightsArray, canRead]);
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getAssetDataList,
    "asstTag",
    "asstDesc"
  );
  const handleWorkOrderAssetEditClick = () => {
    setShowAssetEditForm(true);
    setId(editData.id);
    const { woaAssetCriticality, woaAsstIdFk, woaIncItemIdFk, ...rest } =
      editData;
    setEditDefValues({
      ...rest,
      woaAssetCriticality: mapDropdownValueToListIdWithLabel(
        commonData.workOrderAssetCriticality,
        woaAssetCriticality
      ),
      woaAsstIdFk: mapDropdownValueToIdWithDesc(modifiedOptions, woaAsstIdFk),
      woaIncItemIdFk: mapDropdownValueToIdWithLabel(
        commonData.getIncidentOptions,
        woaIncItemIdFk
      ),
    });
  };

  const saveUpdateData = async (WorkOrderAssetDataToSave) => {
    console.log({ WorkOrderAssetDataToSave });
    updateWorkOrderAsset({
      id: WorkOrderAssetDataToSave.woaId,
      ...WorkOrderAssetDataToSave,
      woaAsstIdFk: WorkOrderAssetDataToSave?.woaAsstIdFk?.asstId,
      woaIncItemIdFk: WorkOrderAssetDataToSave?.woaIncItemIdFk?.id,
      woaAssetCriticality:
        WorkOrderAssetDataToSave?.woaAssetCriticality?.listId,
      woaIncidentTime: WorkOrderAssetDataToSave?.woaIncidentTime
        ? dayjs(WorkOrderAssetDataToSave.woaIncidentTime).isValid()
          ? dayjs(WorkOrderAssetDataToSave.woaIncidentTime).format("HH:mm:ss")
          : WorkOrderAssetDataToSave.woaIncidentTime
        : null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset updated successfully");
          setShowAssetEditForm(!showAssetEditForm);
          setShowAssetForm(false);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAssetData = async (newWorkOrderAssetData) => {
    console.log({ newWorkOrderAssetData });
    addWorkOrderAsset({
      woaWoIdFk: workOrderId,
      ...newWorkOrderAssetData,
      woaAsstIdFk: newWorkOrderAssetData?.woaAsstIdFk?.asstId,
      woaIncItemIdFk: newWorkOrderAssetData?.woaIncItemIdFk?.id,
      woaAssetCriticality: newWorkOrderAssetData?.woaAssetCriticality?.listId,
      woaIncidentTime: newWorkOrderAssetData?.woaIncidentTime
        ? dayjs(newWorkOrderAssetData?.woaIncidentTime).isValid()
          ? dayjs(newWorkOrderAssetData?.woaIncidentTime).format("HH:MM:ss")
          : newWorkOrderAssetData.woaIncidentTime
        : null,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset added successfully");
          setShowAssetEditForm(!showAssetEditForm);
          setShowAssetForm(false);
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderAsset({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Asset delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something Went Wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowAssetEditForm(!showAssetEditForm);
    setShowAssetForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "woaAsstIdFk",
      headerName: "Asset",
      type: "selectTwo",
      options: modifiedOptions,
      textFieldWidth: medium,
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "woaIssue",
      headerName: "Issue",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woaRemarks",
      headerName: "Remarks",
      type: "text",
      maxLength: 200,
      row: 4,
      multiline: "multiline",
      width: GridWidthLargeWidth,
    },
    {
      field: "woaIncItemIdFk",
      headerName: "Incident",
      type: "select",
      maxLength: 20,
      textFieldWidth: medium,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
    },
    {
      field: "woaFailureCode",
      headerName: "Failure Code",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "woaIncidentTime",
      headerName: "Incident Time",
      textFieldWidth: medium,
      type: "time",
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
    {
      field: "woaAssetCriticality",
      headerName: "Asset Criticality",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "workOrderAssetCriticality",
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.woa_asset || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.woa_asset)
    ) {
      configColumns = updatedConfig.sub_component.woa_asset;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: details.visible,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          title: updatedColumn.title,
          visible: updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        woa_asset: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: col.visible,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.woa_asset) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.woa_asset
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (workAssetColumns) {
      setColumns(workAssetColumns);
    } else {
      fetchColumnConfig();
    }
  }, [workAssetColumns, columnConfig, defaultConfig, workOrderId]);

  const onAdd = () => {
    setShowAssetEditForm(true);
    setShowAssetForm(true);
    setEditDefValues({});
  };
  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleWorkOrderAssetEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={handleOpenColumnPopup}
        editId={id}
        isLoading={isAssetFetching}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Asset"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"woaAsstIdFk"}
      />

      {showAssetEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showAssetForm}
          setShowForm={setShowAssetForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetData}
          editId={id}
          defaultValues={editDefValues}
          label={"Asset"}
          isLoading={isUpdateLoading || isPostLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Asset data?"}
        handleApprove={handleDeleteClick}
        isLoading={isDeleteLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
