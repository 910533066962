import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useCommonData } from "../../../context/CommonDataProvider";
import useMutationApi from "../../../hooks/useMutationApi";
import useFetchApi from "../../../hooks/useFetchApi";
import { GridWidthMedium, GeneralPrefix } from "../../../constants/global";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "../../home/assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ViewDropdown from "../../home/ViewDropdown";
import useColumnConfigStore from "../../home/columnConfigStore";
import ColumnCustomizationPopup from "../../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../../common/RestoreColumnsDialog";

export const FailMode = ({
  incItemId,
  failColumns,
  setFailColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showFailModeForm, setShowFailModeForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showFailModeEditForm, setShowFailModeEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "incident_item" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    if (params.row) {
      setId(params.row.id);
      setEditData(params.row);
    }
  };

  const {
    mutateAsync: addFailMode,
    isSuccess: isFailModeSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/fail-mode`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateFailMode,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/fail-mode/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const { mutateAsync: deleteFailMode, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${GeneralPrefix}/fail-mode/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getFailModeData,
    isFetchedAfterMount: isFetch,
    isFetching: isFailModeFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/incident-item/${incItemId}/fail-mode`,
    retrieveOnMount:
      plantId && rightsArray[0]?.urMenuIdFk && incItemId ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isFailModeSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isFailModeSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    if (isFetch && canRead && getFailModeData) {
      if (Array.isArray(getFailModeData)) {
        setRows(() =>
          getFailModeData.map((w) => {
            return { id: w.failId, ...w };
          })
        );
      } else {
        console.error("getFailModeData is not an array:", getFailModeData);
        toast.error("Error: Unexpected data format received from the server");
        setRows([]);
      }
    }
  }, [isFetch, getFailModeData, canRead]);

  const handleFailModeEditClick = () => {
    setShowFailModeEditForm(true);
    setId(editData?.id);
    setEditDefValues(editData);
  };

  const saveUpdateData = async (failModeDataToSave) => {
    updateFailMode({
      id: failModeDataToSave.failId,
      ...failModeDataToSave,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Failure Mode updated successfully");
          setShowFailModeEditForm(false);
          setId(undefined);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error.toString());
        }
      });
  };

  const submitFailModeData = async (newFailModeData) => {
    try {
      const response = await addFailMode({
        failIncItemIdFk: incItemId,
        failModifiedBy: commonData?.userData?.userId,
        ...newFailModeData,
      });

      if (response.status === 200) {
        toast.success("Failure Mode Added successfully");
        setShowFailModeEditForm(false);
        setShowFailModeForm(false);
        refetch();
      } else {
        toast.warning(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.toString());
      }
    }
  };

  const handleDeleteClick = async () => {
    deleteFailMode()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Failure Mode Deleted successfully");
          setOpenDialog(false);
          setId(undefined);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error.toString());
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowFailModeEditForm(false);
    setShowFailModeForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "failFailureCause",
      headerName: "Failure Cause",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    // Add initial null check
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }

    try {
      let configColumns = [];

      // Multiple fallback paths for getting columns
      if (updatedConfig.sub_component?.fail_mode) {
        configColumns = updatedConfig.sub_component.fail_mode;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === "object") {
        configColumns = Object.entries(updatedConfig).map(
          ([field, details]) => ({
            name: field,
            title: details?.headerName,
            visible: details?.visible,
            width: details?.width ? `${details.width}px` : undefined,
          })
        );
      }

      // Validate grid configuration
      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }

      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });

      setColumns(newColumns);

      const configToUpdate = {
        ...columnConfig,
        category: "incident_item",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          fail_mode: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };

      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.fail_mode) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.fail_mode
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (failColumns) {
      setColumns(failColumns);
    } else {
      fetchColumnConfig();
    }
  }, [failColumns, columnConfig, defaultConfig, incItemId]);

  const onAdd = () => {
    setShowFailModeEditForm(true);
    setShowFailModeForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleFailModeEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={() => handleOpenColumnPopup()}
        onClickRestore={handleRestoreButtonClick}
        editId={id}
        isLoading={isFailModeFetching}
        columns={columns}
        rows={rows}
        label={"Failure Mode"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"failFailureCause"}
      />

      {showFailModeEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showFailModeForm}
          setShowForm={setShowFailModeForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitFailModeData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Failure Mode"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Fail Mode?"}
        handleApprove={handleDeleteClick}
      />

      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="incident_item"
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default FailMode;
