import React, { useEffect, useState } from "react";
import { AccountCircle, Close, Key, Mail } from "@mui/icons-material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  Card,
  CardContent,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { makeStyles } from "@mui/styles";
import { toast } from "react-toastify";
import { Progressor } from "../common/Progressor";
import { SignInUpForm } from "../common/SignInUpForm";
import useMutationApi from "../hooks/useMutationApi";
import { ListGroupPrefix, applicationOption } from "../constants/global";
import { GeneralPrefix } from "../constants/global";
import useFetchApi from "../hooks/useFetchApi";
import { CountryCode } from "../utils/Country";

const useStyles = makeStyles((theme) => ({
  root: {
    [`& fieldset`]: {
      borderRadius: 15,
    },
    "&  .css-1wc848c-MuiFormHelperText-root ": {
      color: "red",
    },
  },
  titleLogin: {
    fontWeight: "700 !important",
    fontSize: "1.8rem !important",
    textAlign: "left !important ",
  },
  label: {
    color: "#212121 !important",
    fontSize: "1.0rem !important",
    fontWeight: "600 !important",
  },
  labelPassword: {
    color: "#212121 !important",
    fontSize: "1.0rem !important",
    fontWeight: "600 !important",
  },
  buttonRegister: {
    width: "190px !important",
    borderRadius: "18px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
  },
  buttonLogin: {
    width: "190px !important",
    borderRadius: "18px !important",
    fontWeight: "600 !important",
    textTransform: "capitalize !important",
  },
  item: {
    paddingLeft: "0 !important",
    textAlign: "center",
  },
  errorMessage: {
    color: "red",
  },
}));

const SignUp = () => {
  const classes = useStyles();
  const [state, setState] = useState({
    open: false,
    close: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const [formKey, setFormKey] = useState(0);
  const { mutateAsync: addUser, isLoading } = useMutationApi({
    endpoint: `${ListGroupPrefix}/tenant/post`,
    method: "post",
    Menu_id: -1,
  });

  const {
    data: applicationData,
    isFetchedAfterMount: isApplicationDataFetched,
    isFetching: isApplicationDataFetching,
    refetch: fetchApplications,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/tenant/apps`,
    retrieveOnMount: true,
    Menu_id: 1,
    isApps: true,
  });

  useEffect(() => {
    if (isApplicationDataFetched) {
      fetchApplications();
    }
  }, [fetchApplications, isApplicationDataFetched]);

  const {
    data: plansData,
    isFetchedAfterMount: isPlansFetched,
    isFetching: isPlansDataFetching,
    refetch: fetchPlans,
    error: plansError,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/plans`,
    retrieveOnMount: true,
    Menu_id: 1,
    isApps: true,
  });

  // Similar effect for plans data fetching
  useEffect(() => {
    if (isPlansFetched) {
      fetchPlans();
    }
  }, [fetchPlans, isPlansFetched]);

  // Error handling for plans
  useEffect(() => {
    if (plansError) {
      console.error("Error fetching plans:", plansError);
      toast.error("Failed to load plans. Please try again later.");
    }
  }, [plansError]);

  // Log fetched plans data
  useEffect(() => {
    if (plansData) {
      console.log("Fetched plans:", plansData);
    }
  }, [plansData]);

  const filteredCountries = CountryCode.filter(
    (country) => country.id === "US"
  );

  const inputs = [
    {
      id: 1,
      field: "companyName",
      name: "companyName",
      type: "text",
      placeholder: "companyName",
      headerName: "Company Name",
      errorMessage: "Company Name should be atleast 3 characters",
      required: true,
      pattern: /^[A-Za-z\s]{3,16}$/i,
      icon: <ApartmentIcon />,
    },
    {
      id: 2,
      field: "userEmail",
      name: "userEmail",
      type: "email",
      placeholder: "Email",
      headerName: "Email",
      errorMessage: "Please enter a valid email address",
      required: true,
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      icon: <Mail />,
    },
    {
      id: 3,
      field: "userPassword",
      name: "userPassword",
      type: "password",
      placeholder: "Password",
      headerName: "Password",
      errorMessage:
        "Password must contain at least one capital letter, one lowercase letter, one number, one special character, and be at least 8 characters long.",
      required: true,
      pattern: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,}$/,
      icon: <Key />,
    },
    ,
    {
      id: 4,
      field: "confirmPassword",
      name: "confirmPassword",
      type: "password",
      placeholder: "Confirm Password",
      headerName: "Confirm Password",
      errorMessage: "Passwords do not match",
      required: true,
      pattern:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
      icon: <Key />,
    },
    {
      id: 5,
      field: "userFirstName",
      name: "userFirstName",
      type: "text",
      placeholder: "First name",
      headerName: "First Name",
      errorMessage: "Please enter a first name between 3-40 characters",
      required: true,
      pattern: /^[A-Za-z\s]{3,16}$/i,
      icon: <AccountCircle />,
    },
    {
      id: 6,
      field: "userLastName",
      name: "userLastName",
      type: "text",
      placeholder: "Last name",
      headerName: "Last Name",
      errorMessage: "Please enter a last name with at least 1 character",
      required: true,
      pattern: /^[A-Za-z\s]{1,16}$/i,
      icon: <AccountCircle />,
    },
    {
      id: 7,
      field: "userAddress",
      name: "userAddress",
      type: "text",
      placeholder: "Address",
      headerName: "Address",
      errorMessage: "Please enter an address between 3-40 characters",
      required: true,
      pattern: /^[a-zA-Z0-9\s]{3,16}$/i,
      icon: <HomeIcon />,
    },
    {
      id: 8,
      field: "country",
      name: "country",
      type: "select",
      placeholder: "Country ",
      headerName: "Country ",
      errorMessage: "Please select a Country ",
      required: true,
      options: filteredCountries.map((country) => ({
        label: country.label,
        id: country.id,
      })),
      icon: <PhoneIcon />,
    },
    {
      id: 9,
      field: "userPhone",
      name: "userPhone",
      type: "text",
      placeholder: "Phone",
      headerName: "Phone",
      errorMessage: "Please enter a phone number with at least 5 characters",
      required: true,
      pattern: /.{5,}/,
      icon: <PhoneIcon />,
    },

    {
      id: 10,
      field: "applicationId",
      name: "applicationId",
      type: "select",
      placeholder: "Application",
      headerName: "Application",
      errorMessage: "Please select Application",
      required: true,
      options: applicationData?.map((o) => ({
        label: o.applicationName,
        id: o.applicationId,
      })),
      icon: <PhoneIcon />,
    },
    {
      id: 11,
      field: "planName",
      name: "planName",
      type: "select",
      placeholder: "Plan",
      headerName: "Plan",
      errorMessage: "Please select a plan",
      required: true,
      options:
        plansData?.map((plan) => ({
          label: plan.planName,
          id: plan.planId,
        })) || [],
      icon: <ListAltIcon />,
    },
    {
      id: 12,
      field: "subscription",
      name: "subscription",
      type: "select",
      placeholder: "Subscription",
      headerName: "Subscription",
      errorMessage: "Please select a subscription type",
      required: true,
      options: [
        { label: "Monthly", id: "monthly" },
        { label: "Yearly", id: "yearly" },
      ],
      icon: <CalendarTodayIcon />,
    },
    {
      id: 13,
      field: "numberOfUsers",
      name: "numberOfUsers",
      type: "number",
      placeholder: "Number of Users",
      headerName: "Number of Users",
      errorMessage: "Please enter a number greater than 0",
      required: true,
      min: 1,
      step: 1,
    },
  ];

  const handleClose = () => {
    setState({ ...state, open: false, close: false });
  };

  const handleSubmit = async (data) => {
    console.log("Form data before submission:", data);

    const validateNumberOfUsers = (value) => {
      const users = parseInt(value, 10);
      if (isNaN(users) || users < 1) {
        return "Number of Users should be at least 1";
      }
      return true;
    };

    const numberOfUsers = validateNumberOfUsers(data.numberOfUsers);
    if (numberOfUsers !== true) {
      toast.error(numberOfUsers);
      return;
    }

    if (data.userPassword !== data.confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      // Try to register the user with the provided data
      const response = await addUser({
        ...data,
        applicationId: data?.applicationId?.id,
        planName: data?.planName?.label,
        subscription: data?.subscription?.id,
        numberOfUsers: parseInt(data.numberOfUsers),
        country: data?.country?.id,
      });

      // If registration is successful, show success message and clear form
      if (response.status === 200) {
        toast.success("Registered successfully!");
        setState({
          ...state,
          open: true,
          close: true,
          message: (
            <>
              <h1>{response.data.tenantKey}</h1>
              <p>
                Kindly save your unique identifier. You will not be allowed to
                login without this id.
              </p>
            </>
          ),
        });
        localStorage.setItem("X-tenant-id", response.data.tenantKey);
        setFormKey((prevKey) => prevKey + 1); // Increment the formKey to reset the form
      }
    } catch (error) {
      // On registration failure, do not clear the form data
      console.error("Registration error:", error);
      toast.error("Registration failed.");
    }
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
        style={{ position: "absolute", top: 0, right: 0 }}
      >
        <Close fontSize="large" />
      </IconButton>
    </React.Fragment>
  );

  const snackbarStyles = {
    root: {
      padding: "25px 50px 50px 50px",
      height: "200px",
      width: "400px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },
  };

  return (
    <>
      {isLoading && <Progressor />}

      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Box open={open}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={open}
            message={state?.message}
            key={vertical + horizontal}
            action={action}
            ContentProps={{ style: snackbarStyles.root }}
          />
        </Box>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            filter: open ? "blur(5px)" : "none",
            transition: "filter 0.3s ease-in-out",
            pointerEvents: open ? "none" : "auto",
          }}
        >
          <Card sx={{ width: "500px" }}>
            <CardContent>
              <Stack spacing={2}>
                <Typography
                  component="h1"
                  variant="h5"
                  className={classes.titleLogin}
                  align="left"
                >
                  Register
                </Typography>
                <Box noValidate sx={{ mt: 1 }}>
                  <Stack spacing={2}>
                    <SignInUpForm
                      columns={inputs}
                      key={formKey}
                      onSubmit={handleSubmit}
                      classes={classes}
                      isLoading={isLoading}
                    />
                    <Grid container>
                      Already have an account?&nbsp;
                      <Link href="/login" variant="body2">
                        <Typography>Login Here</Typography>
                      </Link>
                    </Grid>
                  </Stack>
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
};

export default SignUp;
