import React, { useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FailMode } from "./FailMode";
import { IncidentRemedy } from "./IncidentRemedy";

export const IncidentItemMultiStep = ({
  onClose,
  incItemId,
  open,
  setEditDefWorkingValues,
  editDefWorkingValues,
  setOpen,
  selectedView,
  failColumns,
  setFailColumns,
  remedyColumns,
  setRemedyColumns,
}) => {
  // Initialize the expanded state with 'failMode' to open it by default
  const [expanded, setExpanded] = useState('failMode');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionItems = [
    {
      id: "failMode",
      label: "Failure Mode",
      content: (
        <FailMode
          label="Add Fail Mode"
          incItemId={incItemId}
          open={open}
          setEditDefWorkingValues={setEditDefWorkingValues}
          editDefWorkingValues={editDefWorkingValues}
          setOpen={setOpen}
          selectedView={selectedView}
          failColumns={failColumns}
          setFailColumns={setFailColumns}
          set
        />
      ),
    },
    {
      id: "incidentRemedy",
      label: "Incident Remedy",
      content: (
        <IncidentRemedy
          label="Add Incident Remedy"
          incItemId={incItemId}
          open={open}
          setEditDefWorkingValues={setEditDefWorkingValues}
          editDefWorkingValues={editDefWorkingValues}
          setOpen={setOpen}
          selectedView={selectedView}
          remedyColumns={remedyColumns}
          setRemedyColumns={setRemedyColumns}
        />
      ),
    },
    // Add more accordion items here as needed
  ];

  return (
    <div style={{ maxWidth: "55vw", marginLeft: "4vh" }}>
      {accordionItems.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <Typography>{item.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>{item.content}</AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};