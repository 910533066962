import React, { useState, useEffect, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import useMutationApi from "../../hooks/useMutationApi";
import useFetchApi from "../../hooks/useFetchApi";
import numeral from "numeral";
import {
  GridWidthLargeWidth,
  GridWidthMedium,
  dateWidth,
  gridHeight,
  medium,
  ticketRequestStatus,
  small,
  GeneralPrefix,
  defaultNumberFormatePrice,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";

import {
  Autocomplete,
  Box,
  FormControl,
  InputLabel,
  Tab,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import {
  getFormattedDate,
  mapDropdownValueToDescWithId,
  mapDropdownValueToIdWithDesc,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
  modifyOptionsWithCodeAndTag,
} from "../../utils/common";
import { useCommonData } from "../../context/CommonDataProvider";
import { TabContext, TabList } from "@mui/lab";
import { ClearIcon } from "@mui/x-date-pickers";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "./ViewDropdown";
import useColumnConfigStore from "./columnConfigStore";
import ColumnCustomizationPopup from "./ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

const TestAutoCompleteComponent = ({
  optionsData,
  value,
  setValue,
  tabValue,
  loading,
}) => {
  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          marginBottom: "2px",
          color: "text.secondary",
          fontWeight: 500,
          marginLeft: "10px",
        }}
      >
        Select the {tabValue}:
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControl
          sx={{
            m: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "pre",
          }}
          size="small"
        >
          <Autocomplete
            sx={{
              mb: 2,
              fontSize: "0.9rem",
              width: 300,
            }}
            loading={loading}
            clearIcon={<ClearIcon fontSize={"small"} />}
            options={optionsData}
            getOptionLabel={(option) =>
              tabValue === "Asset"
                ? `${option.code} - ${option.desc}`
                : option.label || ""
            }
            isOptionEqualToValue={(option, currentOption) => {
              return option.id === currentOption.id;
            }}
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                FormHelperTextProps={{
                  sx: {
                    ml: 0,
                    borderRadius: "15px",
                    fontSize: "0.9rem",
                  },
                }}
              />
            )}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {tabValue === "Asset" ? (
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography variant="body2">{option.asstTag}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography variant="body2">{option.asstDesc}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Typography variant="body2">{option.label}</Typography>
                )}
              </Box>
            )}
          />
        </FormControl>
      </Box>
    </Box>
  );
};

export const Requests = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showTicketsForm, setShowTicketsForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showTicketsEditForm, setShowTicketsEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [tabValue, setTabValue] = useState("user");
  const [value, setValue] = React.useState(null);
  const [assetMasterData, setAssetMasterData] = useState(
    commonData?.getAssetDataList
  );
  const [employeeData, setEmployeeData] = useState(commonData?.employeDetails);
  const [loading, setLoading] = useState(false);
  const [getTicketsData, setGetTicketsData] = useState([]);
  const plant_Id = commonData?.userData?.lastLoginPlantID;
  const userId = commonData?.userData?.id;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [idDisable, setIdDisable] = useState(false);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openConvertDialog, setOpenConvertDialog] = useState(false);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();

  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "requests" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const { data: getRequestNumber, refetch: isGetRequestNumber } = useFetchApi({
    endpoint: `${GeneralPrefix}/request-number/${plant_Id}`,
    retrieveOnMount: plant_Id && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: addTickets,
    isSuccess: isTicketsSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/ticketrequests`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateTickets,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/ticketrequests/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteTickets, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${GeneralPrefix}/ticketrequests/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    mutateAsync: getFilterTicketsData,
    isSuccess: isFetchSuccess,
    isLoading: isFilterDataLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/ticketrequestsFilterData?plantId=${plant_Id}`,
    method: "post",
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && plant_Id ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: convertToWorkOrder,
    isLoading: isConvertingToWorkOrder,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/ticketrequests/${id}/convert-to-workorder`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (commonData) {
      setAssetMasterData(commonData?.getAssetDataList);
      setEmployeeData(commonData?.employeDetails);
    }
  }, [commonData]);

  useEffect(() => {
    if (commonData) {
      setAssetMasterData(
        modifyOptionsWithCodeAndTag(
          commonData?.getAssetDataList || [],
          "asstTag",
          "asstDesc"
        )
      );
      setEmployeeData(commonData?.employeDetails || []);
    }
  }, [commonData]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setValue(null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!canRead) return;

      setLoading(true);
      const id = {};
      switch (tabValue) {
        case "Asset":
          id["assetId"] = value?.asstId;
          break;
        case "plant":
          id["plantId"] = plant_Id;
          break;
        case "Employee":
          id["empId"] = value?.id;
          break;
        default:
          id["userId"] = userId;
          break;
      }
      console.log("Fetching with id:", id);
      try {
        const res = await getFilterTicketsData(id);
        if (res.status === 200) {
          setGetTicketsData(res?.data);
        }
      } catch (error) {
        console.error("Error fetching ticket data:", error);
        toast.error("Failed to fetch ticket data");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    plant_Id,
    rightsArray,
    tabValue,
    userId,
    value,
    isUpdateSuccess,
    isTicketsSuccess,
    isDeleteSuccess,
    canRead,
  ]);

  const getTicketFilterData = async (id) => {
    getFilterTicketsData(id).then((res) => {
      if (res.status === 200) {
        setGetTicketsData(res?.data);
      }
    });
  };
  const [getMainTicketsData, setGetMainTicketsData] = useState(getTicketsData);
  useEffect(() => {
    if (getTicketsData) {
      const TicketsData = getTicketsData?.map((item) => {
        const newAssetData = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.maintAsstIdFk
        );
        const newCritical = mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticality,
          item.maintCritical
        );
        const newCurrency = mapDropdownValueToLabelWithId(
          commonData.getCurrencyData,
          item.maintCurIdFk
        );
        const newEmpData = mapDropdownValueToLabelWithId(
          commonData.employeDetails,
          item.maintEmpIdFk
        );
        const newCriticality = mapDropdownValueToLabelWithListId(
          commonData.workOrderAssetCriticalityImpact,
          item.maintImpact
        );
        const newIncItem = mapDropdownValueToLabelWithId(
          commonData.getIncidentOptions,
          item.maintIncItemIdFk
        );
        const newPriority = mapDropdownValueToLabelWithListId(
          commonData.workOrderPriority,
          item.maintPriority
        );
        const newReqBy = mapDropdownValueToLabelWithId(
          commonData.employeDetails,
          item.maintReqByIdFk
        );
        const newState = mapDropdownValueToLabelWithListId(
          commonData.assetStatus,
          item.maintState
        );
        const newStatus = mapDropdownValueToLabelWithId(
          ticketRequestStatus,
          parseInt(item.maintStatus)
        );
        const newZone = mapDropdownValueToLabelWithListId(
          commonData.assetsZone,
          item.maintZone
        );

        return {
          ...item,
          maintAsstIdFk: newAssetData,
          maintCritical: newCritical,
          maintCurIdFk: newCurrency,
          maintEmpIdFk: newEmpData,
          maintImpact: newCriticality,
          maintIncItemIdFk: newIncItem,
          maintPriority: newPriority,
          maintReqByIdFk: newReqBy,
          maintState: newState,
          maintStatus: newStatus,
          maintZone: newZone,
        };
      });
      setGetMainTicketsData(TicketsData);
    }
  }, [commonData, getTicketsData]);

  useEffect(() => {
    if (isFetchSuccess) {
      setRows(() =>
        getMainTicketsData?.map((w) => {
          return { id: w.maintId, ...w };
        })
      );
    }
  }, [isFilterDataLoading, getMainTicketsData, isFetchSuccess]);
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getAssetDataList,
    "asstTag",
    "asstDesc"
  );
  const handleTicketsEditClick = () => {
    setShowTicketsEditForm(true);
    setId(editData.id);
    const newAssetData = mapDropdownValueToIdWithDesc(
      modifiedOptions,
      editData.maintAsstIdFk
    );
    const newCritical = mapDropdownValueToListIdWithLabel(
      commonData.workOrderAssetCriticality,
      editData.maintCritical
    );
    const newCurrency = mapDropdownValueToIdWithLabel(
      commonData.getCurrencyData,
      editData.maintCurIdFk
    );
    const newEmpData = mapDropdownValueToIdWithLabel(
      commonData.employeDetails,
      editData.maintEmpIdFk
    );
    const newCriticality = mapDropdownValueToIdWithLabel(
      commonData.workOrderAssetCriticalityImpact,
      editData.maintImpact
    );
    const newIncItem = mapDropdownValueToIdWithLabel(
      commonData.getIncidentOptions,
      editData.maintIncItemIdFk
    );
    const newPriority = mapDropdownValueToIdWithLabel(
      commonData.workOrderPriority,
      editData.maintPriority
    );
    const newReqBy = mapDropdownValueToIdWithLabel(
      commonData.employeDetails,
      editData.maintReqByIdFk
    );
    const newState = mapDropdownValueToIdWithLabel(
      commonData.assetStatus,
      editData.maintState
    );
    const newStatus = mapDropdownValueToIdWithLabel(
      ticketRequestStatus,
      editData.maintStatus
    );
    const newZone = mapDropdownValueToIdWithLabel(
      commonData.assetsZone,
      editData.maintZone
    );
    setEditDefValues({
      ...editData,
      maintAsstIdFk: newAssetData,
      maintCritical: newCritical,
      maintCurIdFk: newCurrency,
      maintEmpIdFk: newEmpData,
      maintImpact: newCriticality,
      maintIncItemIdFk: newIncItem,
      maintPriority: newPriority,
      maintReqByIdFk: newReqBy,
      maintState: newState,
      maintStatus: newStatus,
      maintZone: newZone,
    });
  };

  const saveUpdateData = async (TicketsDataToSave) => {
    updateTickets({
      ...TicketsDataToSave,
      maintAsstIdFk: TicketsDataToSave?.maintAsstIdFk?.asstId,
      maintCritical: TicketsDataToSave?.maintCritical?.listId,
      maintCurIdFk: TicketsDataToSave?.maintCurIdFk?.id,
      maintEmpIdFk: TicketsDataToSave?.maintEmpIdFk?.id,
      maintImpact: TicketsDataToSave?.maintImpact?.listId,
      maintIncItemIdFk: TicketsDataToSave?.maintIncItemIdFk?.id,
      maintPriority: TicketsDataToSave?.maintPriority?.listId,
      maintReqByIdFk: TicketsDataToSave?.maintReqByIdFk?.id,
      maintState: TicketsDataToSave?.maintState?.listId,
      maintStatus: TicketsDataToSave?.maintStatus?.id,
      maintZone: TicketsDataToSave?.maintZone?.listId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Requests updated successfully");
          setShowTicketsEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitTicketsData = async (newTicketsData) => {
    addTickets({
      maintPlantIdFk: plant_Id,
      ...newTicketsData,
      maintAsstIdFk: newTicketsData?.maintAsstIdFk?.asstId,
      maintCritical: newTicketsData?.maintCritical?.listId,
      maintCurIdFk: newTicketsData?.maintCurIdFk?.id,
      maintEmpIdFk: newTicketsData?.maintEmpIdFk?.id,
      maintImpact: newTicketsData?.maintImpact?.listId,
      maintIncItemIdFk: newTicketsData?.maintIncItemIdFk?.id,
      maintPriority: newTicketsData?.maintPriority?.listId,
      maintReqByIdFk: newTicketsData?.maintReqByIdFk?.id,
      maintState: newTicketsData?.maintState?.listId,
      maintStatus: newTicketsData?.maintStatus?.id,
      maintZone: newTicketsData?.maintZone?.listId,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Requests Added successfully");
          setShowTicketsEditForm(!showTicketsEditForm);
          setShowTicketsForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteTickets()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Requests Deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleConvertToWorkOrder = () => {
    if (!id) {
      toast.error("Please select a request to convert");
      return;
    }

    const selectedTicket = rows.find((row) => row.id === id);
    if (!selectedTicket) {
      toast.error("Selected request not found");
      return;
    }

    if (selectedTicket.isConverted) {
      toast.warning("This request has already been converted to a work order");
      return;
    }

    setOpenConvertDialog(true);
  };

  const handleConfirmConvert = async () => {
    try {
      const response = await convertToWorkOrder();
      if (response.status === 201) {
        toast.success(`Request ID ${id} converted to Work Order successfully`);

        // Update the local state to reflect the conversion
        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, isConverted: true } : row
        );
        setRows(updatedRows);

        // Optionally, refresh the ticket list
        getTicketFilterData({ plantId: plant_Id });
      } else {
        toast.error(`Failed to convert Request ID ${id} to Work Order`);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          const errorMessage =
            error.response.data.message || error.response.data;
          if (
            typeof errorMessage === "string" &&
            errorMessage.includes("already been converted")
          ) {
            toast.error(
              `Request ID ${id} has already been converted to a work order`
            );
          } else {
            toast.error(
              `Request ID ${id}: ${errorMessage || "Bad request error"}`
            );
          }
        } else if (error.response.status === 500) {
          const errorMessage =
            error.response.data.message || error.response.data;
          toast.error(
            `Request ID ${id}: ${
              errorMessage ||
              "Server error occurred while converting the ticket"
            }`
          );
        } else {
          toast.error(`An error occurred while converting Request ID ${id}`);
        }
      } else {
        toast.error(`An error occurred while converting Request ID ${id}`);
      }
      console.error("Error converting request to work order:", error);
    } finally {
      setOpenConvertDialog(false);
    }
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTicketsEditForm(!showTicketsEditForm);
    setShowTicketsForm(false);
  };

  const allcolumns = [
    {
      field: "maintNumber",
      headerName: "Request No",
      hideable: false,
      type: "number",
      maxLength: 12,
      textFieldWidth: medium,
      value: getRequestNumber,
      width: GridWidthMedium,
      disable: true,
    },
    {
      field: "maintReqDate",
      headerName: "Requested Date",
      required: true,
      type: "Date",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "maintReqByIdFk",
      headerName: "Requested By",
      type: "select",
      required: false,
      options: commonData?.employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "maintDesc",
      headerName: "Description",
      required: true,
      type: "text",
      width: GridWidthLargeWidth,
      multiline: "multiline",
      row: 4,
    },
    {
      field: "maintEmail",
      headerName: "Email Id",
      type: "email",
      width: GridWidthMedium,
      textFieldWidth: medium,
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    },
    {
      field: "maintPhone",
      headerName: "Phone",
      type: "text",
      errorMessage: "Please enter a phone number with at least 5 characters",
      required: false,
      pattern: /.{5,}/,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "maintAsstIdFk",
      headerName: "Asset",
      required: true,
      type: "selectTwo",
      options: modifiedOptions,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "maintZone",
      headerName: "Zone",
      required: false,
      type: "select",
      apiendpoint: "assetsZone",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "maintLocDesc",
      headerName: "Location Description",
      type: "text",
      width: GridWidthMedium,
      multiline: "multiline",
      row: 4,
    },
    {
      field: "maintState",
      headerName: "Current State",
      required: true,
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "assetStatus",
      width: GridWidthLargeWidth,
    },
    {
      field: "maintPriority",
      headerName: "Priority",
      required: false,
      type: "select",
      apiendpoint: "workOrderPriority",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
    {
      field: "maintImpact",
      headerName: "Impact",
      required: true,
      type: "select",
      apiendpoint: "workOrderAssetCriticalityImpact",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
    {
      field: "maintCritical",
      headerName: "Criticality",
      required: false,
      type: "select",
      apiendpoint: "workOrderAssetCriticality",
      width: GridWidthLargeWidth,
      textFieldWidth: medium,
    },
    {
      field: "maintReqNote",
      headerName: "Note",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "maintDefect",
      headerName: "Defect",
      type: "text",
      width: GridWidthLargeWidth,
      textFieldWidth: medium,
    },
    {
      field: "maintAddRemarks",
      headerName: "Remarks",
      multiline: "multiline",
      type: "text",
      row: 4,
      width: GridWidthLargeWidth,
    },
    {
      field: "maintDate",
      headerName: "Maintenance Date",
      type: "Date",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "maintEmpIdFk",
      headerName: "Assigned To",
      type: "select",
      options: commonData?.employeDetails?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: "maintIncItemIdFk",
      headerName: "Incident Item",
      type: "select",
      required: true,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: "maintCurIdFk",
      headerName: "Currency",
      type: "select",
      required: true,
      apiendpoint: "getCurrencyData",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: "maintCurXrate",
      headerName: "Exchange Rate",
      type: "number",
      width: GridWidthMedium,
      textFieldWidth: medium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "maintStatus",
      headerName: "Status",
      type: "select",
      required: true,
      options: ticketRequestStatus?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_requests`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_requests`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_requests`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);
  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  const onAdd = () => {
    setShowTicketsEditForm(true);
    setShowTicketsForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />

      <Box sx={{ padding: "1rem" }}>
        {isFilterDataLoading && <Progressor />}
        <Box>
          <TabContext value={tabValue}>
            <Box>
              <TabList
                onChange={handleTabChange}
                aria-label="lab API tabs example"
                sx={{
                  ".MuiTab-root": {
                    textTransform: "none",
                    borderBottom: "none",
                    borderRadius: 0,
                    backgroundColor: "transparent",
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "#f0f0f0",
                    },
                  },
                }}
              >
                <Tab label="User" value="user" />
                <Tab label="Plant" value="plant" />
                <Tab label="Asset" value="Asset" />
                <Tab label="Employee" value="Employee" />
              </TabList>
            </Box>
          </TabContext>
          {tabValue === "Asset" ? (
            <TestAutoCompleteComponent
              optionsData={modifiedOptions}
              value={value}
              setValue={setValue}
              tabValue={tabValue}
            />
          ) : tabValue === "Employee" ? (
            <TestAutoCompleteComponent
              optionsData={employeeData}
              value={value}
              setValue={setValue}
              tabValue={tabValue}
            />
          ) : null}
          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              sx={{ height: "80vh" }}
              rows={viewLoaded ? rows : []}
              sortingField={"maintReqDate"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleTicketsEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        onClickConvertToWorkOrder={handleConvertToWorkOrder}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                        disableConvertToWorkOrder={
                          !id ||
                          isConvertingToWorkOrder ||
                          (editData && editData.isConverted)
                        }
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="requests"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showTicketsEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showTicketsForm}
          setShowForm={setShowTicketsForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitTicketsData}
          onClickConvertToWorkOrder={handleConvertToWorkOrder}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Requests"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Requests?"}
        handleApprove={handleDeleteClick}
      />
      <DeleteDialog
        open={openConvertDialog}
        setOpen={setOpenConvertDialog}
        title={"Are you sure you want to convert this request to a work order?"}
        handleApprove={handleConfirmConvert}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="requests"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};
