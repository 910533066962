import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  GridWidthMedium,
  ListGroupPrefix,
  dateWidth,
  gridHeight,
  medium,
  userStatusOptions,
  GeneralPrefix,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import {
  getFormattedDate,
  mapDropdownValueToFindIdWithLabel,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../utils/common";
import { useCommonData } from "../../context/CommonDataProvider";
import dayjs from "dayjs";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const Users = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showUsersForm, setShowUsersForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showUsersEditForm, setShowUsersEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [isChangePed, setIsChangePwd] = useState(false);
  const [ugAddId, setUgAddId] = useState(null);
  const plantId = commonData?.userData?.lastLoginPlantID;
  const tenatId = commonData?.userData?.tenant_id;
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "users" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setUgAddId(params.id + 1);
  };
  const {
    mutateAsync: addUsers,
    isSuccess: isUsersSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateUsers,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${ListGroupPrefix}/user/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteUsers, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${ListGroupPrefix}/user/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getUsersData,
    isFetchedAfterMount: isFetch,
    isFetching: isUsersFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${ListGroupPrefix}/user`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isUsersSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isUsersSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getMainUsersData, setGetMainUsersData] = useState(getUsersData);

  useEffect(() => {
    if (getUsersData) {
      const UsersData = getUsersData?.map((item) => {
        const newUserUGId = mapDropdownValueToLabelWithId(
          commonData.getUserGroupData,
          item.userUgIdFk
        );
        const newStatusId = mapDropdownValueToFindIdWithLabel(
          userStatusOptions,
          item.status
        );
        return {
          ...item,
          userUgIdFk: newUserUGId,
          status: newStatusId,
        };
      });

      setGetMainUsersData(UsersData);
    }
  }, [getUsersData, commonData]);
  useEffect(() => {
    if (isFetch && getMainUsersData && canRead) {
      setRows(() =>
        getMainUsersData?.map((w) => {
          return { id: w.userId, ...w };
        })
      );
    }
  }, [isFetch, getMainUsersData, rightsArray, canRead]);

  const handleUsersEditClick = () => {
    setShowUsersEditForm(true);
    setId(editData.id);
    const newUserUGId = mapDropdownValueToIdWithLabel(
      commonData.getUserGroupData,
      editData.userUgIdFk
    );
    const newStatusId = mapDropdownValueToIdWithLabel(
      userStatusOptions,
      editData.status
    );

    setEditDefValues({
      ...editData,
      userUgIdFk: newUserUGId,
      status: newStatusId,
    });
  };

  const saveUpdateData = async (UsersDataToSave) => {
    if (dayjs(UsersDataToSave?.startDate).isAfter(UsersDataToSave?.endDate)) {
      toast.error(
        "End date should not be greater than or equal to the start date."
      );
    } else {
      updateUsers({
        plantMasterEntity: plantId,
        tenant: tenatId,
        userId: UsersDataToSave?.userId,
        userUgIdFk: UsersDataToSave?.userUgIdFk?.id,
        status: UsersDataToSave?.status?.label,
        startDate: UsersDataToSave?.startDate
          ? dayjs(UsersDataToSave?.startDate).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        endDate: UsersDataToSave?.endDate
          ? dayjs(UsersDataToSave?.endDate).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        password: UsersDataToSave?.password,
        address: UsersDataToSave?.address,
        userEmail: UsersDataToSave?.userEmail,
        userFirstName: UsersDataToSave?.userFirstName,
        userLastName: UsersDataToSave?.userLastName,
        userPhone: UsersDataToSave?.userPhone,
        username: UsersDataToSave?.username,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Users updated successfully");
            setShowUsersEditForm(false);
            setId();
            setUgAddId();
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const submitUsersData = async (newUsersData) => {
    if (dayjs(newUsersData?.startDate).isAfter(newUsersData?.endDate)) {
      toast.error("End date should be greater than or equal to start date");
      return;
    }

    try {
      const response = await addUsers({
        plantMasterEntity: plantId,
        ...newUsersData,
        userUgIdFk: newUsersData?.userUgIdFk?.id,
        status: newUsersData?.status?.label,
        startDate: newUsersData?.startDate
          ? dayjs(newUsersData?.startDate).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        endDate: newUsersData?.endDate
          ? dayjs(newUsersData?.endDate).format("YYYY-MM-DDTHH:mm:ss")
          : "",
        tenant: tenatId,
      });

      toast.success("User added successfully");
      setShowUsersEditForm(false);
      setShowUsersForm(false);
      setUgAddId(null);
    } catch (error) {
      console.error("Error adding user:", error);

      // Log the entire error object for debugging
      console.log("Full error object:", error);

      if (error.response) {
        console.log("Error response:", error.response);
        console.log("Error status:", error.response.status);
        console.log("Error data:", error.response.data);

        const errorMessage =
          error.response.data?.message || error.response.data;
        if (
          typeof errorMessage === "string" &&
          errorMessage.includes("User limit")
        ) {
          toast.error("User limit for this tenant has been reached");
        } else if (errorMessage) {
          toast.error(errorMessage);
        } else {
          toast.error(
            "An error occurred while adding the user. Please try again."
          );
        }
      } else if (error.request) {
        toast.error(
          "No response received from the server. Please check your network connection."
        );
      } else {
        toast.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const handleDeleteClick = async () => {
    deleteUsers()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Users Deleted successfully");
          setOpenDialog(false);
          setId();
          setUgAddId();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowUsersEditForm(!showUsersEditForm);
    setShowUsersForm(false);
  };

  const allcolumns = [
    {
      field: "userUgIdFk",
      headerName: "User Group",
      required: true,
      type: "select",
      width: GridWidthMedium,
      apiendpoint: "getUserGroupData",
    },
    {
      field: "username",
      headerName: "User Name",
      required: true,
      type: "text",
      width: GridWidthMedium,
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
    },
    {
      field: "userFirstName",
      headerName: "First Name",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "userLastName",
      headerName: "Last Name",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "address",
      headerName: "Address",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "userPhone",
      headerName: "Phone",
      type: "number",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "userEmail",
      headerName: "Email Id",
      type: "email",
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      width: GridWidthMedium,
    },
    {
      field: "status",
      headerName: "Status",
      required: true,
      type: "select",
      width: GridWidthMedium,
      options: userStatusOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "Date",
      required: false,
      minDateLabel: "startDate",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
  ];

  const editColumns = [
    {
      field: "userUgIdFk",
      headerName: "User Group",
      required: true,
      type: "select",
      width: GridWidthMedium,
      apiendpoint: "getUserGroupData",
    },
    {
      field: "userEmail",
      headerName: "Email Id",
      type: "email",
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      width: GridWidthMedium,
      required: true,
    },

    {
      field: "userFirstName",
      headerName: "First Name",
      type: "text",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "userLastName",
      headerName: "Last Name",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "address",
      headerName: "Address",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "userPhone",
      headerName: "Phone",
      type: "number",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "username",
      headerName: "User Name",
      required: true,
      type: "text",
      width: GridWidthMedium,
      pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
      errorMessage: "Please enter username in a valid format",
    },
    {
      field: "password",
      headerName: "Password",
      required: true,
      type: "password",
      width: GridWidthMedium,
      errorMessage:
        "A strong 8-character password should contain letters, numbers, and special characters.",
      pattern:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/i,
    },
    {
      field: "startDate",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "endDate",
      headerName: "End Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "status",
      headerName: "Status",
      required: true,
      type: "select",
      width: GridWidthMedium,
      textFieldWidth: medium,
      options: userStatusOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_users`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_users`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_users`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  const onAdd = () => {
    setShowUsersEditForm(true);
    setShowUsersForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isUsersFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"userUgIdFk"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleUsersEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={ugAddId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="users"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showUsersEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showUsersForm}
          setShowForm={setShowUsersForm}
          columns={editColumns}
          onUpData={saveUpdateData}
          onAdd={submitUsersData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Users"
          isUsers={true}
          setIsChangePwd={setIsChangePwd}
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Users?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="users"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};
