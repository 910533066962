import React, { useState, useEffect } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  ListItemIcon,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useCommonData } from "../../context/CommonDataProvider";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SaveViewPopup from "./SaveViewPopup";
import useFetchApi from "../../hooks/useFetchApi";
import { GeneralPrefix } from "../../constants/global";
import CheckIcon from "@mui/icons-material/Check";
const ViewDropdown = ({
  columns,
  setColumns,
  defaultColumnConfig,
  columnConfig,
  category,
  userId,
  onApplySelectedView,
  selectedView,
  setSelectedView,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openSaveViewPopup, setOpenSaveViewPopup] = useState(false);
  const [views, setViews] = useState([]);

  const commonData = useCommonData();
  const open = Boolean(anchorEl);

  const { data: globalViewConfigurations } = useFetchApi({
    endpoint: `${GeneralPrefix}/viewconfigurations`,
    options: {
      params: { category, scope: "global" },
    },
    retrieveOnMount: true,
    Menu_id: 1,
  });

  const { data: userViewConfigurations } = useFetchApi({
    endpoint: `${GeneralPrefix}/viewconfigurations`,
    options: {
      params: { category, userId, scope: "user" },
    },
    retrieveOnMount: true,
    Menu_id: 1,
  });

  useEffect(() => {
    if (globalViewConfigurations && userViewConfigurations) {
      const defaultView = {
        viewId: "default",
        viewName: "Default View",
        scope: "Global",
        columnConfigJson: JSON.stringify(defaultColumnConfig),
      };

      const combinedViews = [
        defaultView,
        ...globalViewConfigurations.map((view) => ({
          ...view,
          scope: "Global",
        })),
        ...userViewConfigurations.map((view) => ({ ...view, scope: "User" })),
      ];

      const sortedViews = combinedViews.sort((a, b) =>
        a.viewName.localeCompare(b.viewName)
      );

      setViews(sortedViews);

      if (!selectedView.id) {
        setSelectedView({ name: "Default View", id: "default" });
      }
    }
  }, [
    globalViewConfigurations,
    userViewConfigurations,
    defaultColumnConfig,
    selectedView,
    setSelectedView,
  ]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenSaveViewPopup = () => {
    setOpenSaveViewPopup(true);
    handleClose();
  };

  const handleCloseSaveViewPopup = () => {
    setOpenSaveViewPopup(false);
  };

  const handleApplyView = (selectedViewConfig, viewName, viewId) => {
    console.log("Applying view from dropdown:", selectedViewConfig);
    if (viewId === "default") {
      onApplySelectedView(defaultColumnConfig, viewName, viewId, false);
    } else {
      onApplySelectedView(selectedViewConfig, viewName, viewId, false);
    }
    setSelectedView({ name: viewName, id: viewId });
    localStorage.setItem(
      `selectedView_${category}`,
      JSON.stringify({ name: viewName, id: viewId, config: selectedViewConfig })
    );
    handleClose();
    toast.success("View applied successfully");
  };

  return (
    <div>
      <ToastContainer />
      <Tooltip title="View Menu">
        <IconButton
          aria-label="view options"
          aria-controls="view-menu"
          aria-haspopup="true"
          onClick={handleClick}
          disableRipple
        >
          <MoreVertIcon
            sx={{
              color: "rgba(0,0,0,0.4)",
              fontSize: "1.2rem",
            }}
          />
        </IconButton>
      </Tooltip>
      <Menu
        id="view-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem sx={{ fontSize: "12px" }} onClick={handleOpenSaveViewPopup}>
          Save View
        </MenuItem>
        <hr />
        {views.map((view) => (
          <MenuItem
            sx={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "space-between",
            }}
            key={view.viewId}
            onClick={() =>
              handleApplyView(
                JSON.parse(view.columnConfigJson),
                view.viewName,
                view.viewId
              )
            }
          >
            {`${view.viewName} (${view.scope})`}
            {selectedView && selectedView.id === view.viewId && (
              <ListItemIcon>
                <CheckIcon color="primary" />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Menu>
      <SaveViewPopup
        open={openSaveViewPopup}
        onClose={handleCloseSaveViewPopup}
        columns={columns}
        onSave={(updatedColumns) => setColumns(updatedColumns)}
        defaultColumnConfig={defaultColumnConfig}
        columnConfig={columnConfig}
        onApplyViewConfig={(updatedColumns) => setColumns(updatedColumns)}
        category={category}
        userId={userId}
        selectedView={selectedView}
      />
    </div>
  );
};

export default ViewDropdown;
