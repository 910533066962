import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  AssetPrefix,
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  deviceTypesOptions,
  medium,
  protocolOptions,
  small,
} from "../../../constants/global";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../../utils/common";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "./CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import { useCommonData } from "../../../context/CommonDataProvider";
import useColumnConfigStore from "../columnConfigStore";

export const Iot = ({ asstId, iotColumns, setIotColumns, selectedView }) => {
  const { rightsArray } = useMenuContext();
  const commonData = useCommonData();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showIotForm, setShowIotForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showIotEditForm, setShowIotEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const [openColumnPopup, setOpenColumnPopup] = useState(false);

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addAssetIot,
    isSuccess: isAssetIotSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/iot/addIoTDetails`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetIot,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/iot?iotId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteAssetIot,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/${asstId}/iot/${id}?id=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetIotData,
    isFetchedAfterMount: isFetch,
    isFetching: isIotFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/iot?assetId=${asstId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetIotSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetIotSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getIotData, setGetIotData] = useState(getAssetIotData);

  useEffect(() => {
    if (getAssetIotData) {
      const iotData = getAssetIotData.map((item) => {
        const newDeviceType = mapDropdownValueToLabelWithId(
          deviceTypesOptions,
          item.astioDeviceType
        );
        const newProtocolType = mapDropdownValueToLabelWithId(
          protocolOptions,
          item.astioProtocol
        );
        return {
          ...item,
          astioProtocol: newProtocolType,
          astioDeviceType: newDeviceType,
        };
      });
      setGetIotData(iotData);
    }
  }, [getAssetIotData]);

  useEffect(() => {
    if (isFetch && getIotData && canRead) {
      setRows(() =>
        getIotData?.map((w) => {
          return { id: w.astioId, ...w };
        })
      );
    }
  }, [isFetch, getIotData, rightsArray, canRead]);

  const handleAssetIotEditClick = () => {
    setShowIotEditForm(true);
    setId(editData.id);
    const newDeviceType = mapDropdownValueToIdWithLabel(
      deviceTypesOptions,
      editData.astioDeviceType
    );
    const newProtocolType = mapDropdownValueToIdWithLabel(
      protocolOptions,
      editData.astioProtocol
    );
    setEditDefValues({
      ...editData,
      astioProtocol: newProtocolType,
      astioDeviceType: newDeviceType,
    });
  };

  const saveUpdateData = async (AssetIotDataToSave) => {
    updateAssetIot({
      id: AssetIotDataToSave.astioId,
      ...AssetIotDataToSave,
      astioDeviceType: AssetIotDataToSave.astioDeviceType?.id,
      astioProtocol: AssetIotDataToSave.astioProtocol?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Iot updated successfully");
          setShowIotEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAssetIotData = async (newAssetIotData) => {
    addAssetIot({
      astioAsstIdFk: asstId,
      ...newAssetIotData,
      astioDeviceType: newAssetIotData.astioDeviceType?.id,
      astioProtocol: newAssetIotData.astioProtocol?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Iot Added successfully");
          setShowIotEditForm(!showIotEditForm);
          setShowIotForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteAssetIot()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Iot Deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowIotEditForm(!showIotEditForm);
    setShowIotForm(false);
  };
  const [columns, setColumns] = useState([
    {
      field: "astioDescription",
      headerName: "Description",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "astioDeviceId",
      headerName: "Device ID",
      type: "text",
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "astioDataPoint",
      headerName: "Data Point",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "astioDeviceType",
      headerName: "Type",
      type: "select",
      textFieldWidth: medium,
      options: deviceTypesOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "astioProtocol",
      headerName: "Protocol",
      type: "select",
      textFieldWidth: medium,
      options: protocolOptions?.map((o) => ({
        label: o.label,
        id: o.id,
      })),
      width: GridWidthMedium,
    },
    {
      field: "astioApi",
      headerName: "API",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "astioValue",
      headerName: "Value",
      type: "number",
      textFieldWidth: small,
      width: GridWidthLargeWidth,
    },

    {
      field: "astioStatus",
      headerName: "Status",
      type: "text",
      maxLength: 200,
      multiline: "multiline",
      row: 4,
      width: GridWidthLargeWidth,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.iot || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.iot)
    ) {
      configColumns = updatedConfig.sub_component.iot;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "asset",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        iot: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (!config || !config.sub_component || !config.sub_component.iot) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.iot
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      // Check if there are any changes in the transformed columns
      const hasChanges = transformedColumns.some(
        (col, index) =>
          col.hide !== columns[index].hide ||
          col.headerName !== columns[index].headerName ||
          col.width !== columns[index].width
      );

      if (!hasChanges) {
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        const visibleColumns = transformedColumns.filter((col) => !col.hide);

        if (visibleColumns.length === 0) {
          console.warn(
            "All columns are hidden. Displaying all columns by default."
          );
          setColumns(columns.map((col) => ({ ...col, hide: false })));
        } else {
          setColumns(transformedColumns);
        }
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (iotColumns) {
      setColumns(iotColumns);
    } else {
      fetchColumnConfig();
    }
  }, [iotColumns, columnConfig, defaultConfig, asstId]);

  const onAdd = () => {
    setShowIotEditForm(true);
    setShowIotForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleAssetIotEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        isLoading={isIotFetching}
        label="Iot"
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"astioDescription"}
      />

      {showIotEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showIotForm}
          setShowForm={setShowIotForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetIotData}
          editId={id}
          defaultValues={editDefValues}
          label="Iot"
          isLoading={isUpdateLoading || isPostLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete IoT data?"}
        handleApprove={handleDeleteClick}
        isLoading={isDeleteLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="Asset"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
