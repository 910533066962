import { useState } from "react";
import { StepContent } from "@mui/material";
import { Box, StepLabel } from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { Button } from "../../../common/Button";
import { WorkOrderTasks } from "./WorkOrderTasks";
import { Messages } from "./Messages";
import { Testing } from "./Testing";
import { Checklist } from "./CheckList";
import { Incident } from "./Incident";
import { WorkLabor } from "./WorkLabor";
import { WorkTools } from "./WorkTools";
import { WorkAsset } from "./WorkAsset";
import { WorkMaterials } from "./WorkMaterials";
import { WorkOrderServices } from "./WorkOrderServices";
import { WorkDocumentUpload } from "./WorkDocumentUpload";
import { WorkResource } from "./WorkResource";

export const WorkOrderMultiStep = ({
  onClose,
  workOrderId,
  columnConfig,
  checklistColumns,
  setChecklistColumns,
  setEmployeeColumns,
  employeeColumns,
  setIncidentColumns,
  incidentColumns,
  messagesColumns,
  setMessagesColumns,
  testingColumns,
  setTestingColumns,
  selectedView,
  workAssetColumns,
  setWorkAssetColumns,
  setWostColumns,
  wostColumns,
  materialColumns,
  setMaterialColumns,
  resourceColumns,
  serviceColumns,
  woDocColumns,
  setWoDocColumns,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (index) => () => {
    setActiveStep(index);
  };

  const steps = [
    {
      label: "Asset",
      form: (
        <>
          <WorkAsset
            label="Add Asset"
            workOrderId={workOrderId}
            workAssetColumns={workAssetColumns}
            setWorkAssetColumns={setWorkAssetColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Service Tasks",
      form: (
        <>
          <WorkOrderTasks
            label="Add Service Tasks"
            workOrderId={workOrderId}
            wostColumns={wostColumns}
            setWostColumns={setWostColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Materials",
      form: (
        <>
          <WorkMaterials
            label="Add Materials"
            workOrderId={workOrderId}
            materialColumns={materialColumns}
            setMaterialColumns={setMaterialColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Services",
      form: (
        <>
          <WorkOrderServices
            label="Add Services"
            workOrderId={workOrderId}
            serviceColumns={serviceColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Labor",
      form: (
        <>
          <WorkLabor label="Add Labor" workOrderId={workOrderId} />
        </>
      ),
    },
    {
      label: "Tools",
      form: (
        <>
          <WorkTools label="Add Tools" workOrderId={workOrderId} />
        </>
      ),
    },
    {
      label: "Incident(history)",
      form: (
        <>
          <Incident
            label="Add Incident"
            workOrderId={workOrderId}
            incidentColumns={incidentColumns}
            setIncidentColumns={setIncidentColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Resource Allocation",
      form: (
        <>
          <WorkResource
            label=" Add Resource Allocation"
            workOrderId={workOrderId}
            resourceColumns={resourceColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Update",
      form: (
        <>
          <Messages
            label="Add Update"
            workOrderId={workOrderId}
            messagesColumns={messagesColumns}
            setMessagesColumns={setMessagesColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Check List",
      form: (
        <Checklist
          label="Add Checklist"
          workOrderId={workOrderId}
          checklistColumns={checklistColumns}
          setChecklistColumns={setChecklistColumns}
          selectedView={selectedView}
        />
      ),
    },
    {
      label: "Condition Testing",
      form: (
        <>
          <Testing
            label="Add Condition Testing"
            workOrderId={workOrderId}
            testingColumns={testingColumns}
            setTestingColumns={setTestingColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Document/Photo Upload",
      form: (
        <>
          <WorkDocumentUpload
            label="Add Document/Photo Upload"
            workOrderId={workOrderId}
            woDocColumns={woDocColumns}
            setWoDocColumns={setWoDocColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ maxWidth: "55vw", marginLeft: "4vh" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={handleStep(index)}>{step.label}</StepLabel>
              <StepContent>
                {steps[activeStep].form}
                {/* <Box sx={{ mb: 2 }}>
                  {index !== steps.length - 1 && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 0.5, mr: 1 }}
                      label="Continue"
                    ></Button>
                  )}

                  {index === steps.length - 1 && (
                    <Button
                      size="small"
                      disabled={index === 0}
                      onClick={onClose}
                      sx={{ mt: 0.5, mr: 1 }}
                      label="finish"
                    ></Button>
                  )}
                  <Button
                    size="small"
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 0.5, mr: 1 }}
                    label="back"
                    variant="outlined"
                  ></Button>
                </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};
