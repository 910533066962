import React, { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IncidentSubClass } from "./IncidentSubClass";

export const IncidentMultiStep = ({
  onClose,
  ingId,
  open,
  setEditDefWorkingValues,
  editDefWorkingValues,
  setOpen,
  selectedView,
  incidentSubColumns,
  setIncidentSubColumns,
}) => {
  // Initialize the expanded state with 'incidentSubclass' to open it by default
  const [expanded, setExpanded] = useState("incidentSubclass");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const accordionItems = [
    {
      id: "incidentSubclass",
      label: "Incident Subclass",
      content: (
        <IncidentSubClass
          label="Add Incident Subclass"
          ingId={ingId}
          open={open}
          setEditDefWorkingValues={setEditDefWorkingValues}
          editDefWorkingValues={editDefWorkingValues}
          incidentSubColumns={incidentSubColumns}
          setIncidentSubColumns={setIncidentSubColumns}
          setOpen={setOpen}
          selectedView={selectedView}
        />
      ),
    },
    // Add more accordion items here as needed
  ];

  return (
    <Box sx={{ maxWidth: "55vw", marginLeft: "4vh" }}>
      {accordionItems.map((item) => (
        <Accordion
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
          sx={{
            "&.MuiAccordion-root": {
              minHeight: 0,
            },
            "& .MuiAccordionSummary-root": {
              minHeight: 48,
              maxHeight: 48,
            },
            "& .MuiAccordionDetails-root": {
              padding: 1,
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${item.id}-content`}
            id={`${item.id}-header`}
          >
            <Typography variant="body2">{item.label}</Typography>
          </AccordionSummary>
          <AccordionDetails>{item.content}</AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};
