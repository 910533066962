import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";

export const TextFieldForNumberInput = ({
  label,
  muiProps,
  InputProps,
  required,
  type,
  name,
  classes,
  disable,
  login,
  maxLength,
  rows,
  multiline,
  size,
  width,
  defaultValue,
  onlyPositive,
}) => {
  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const chkListMnvalue = watch("chkListMnvalue");
  const chkListMxvalue = watch("chkListMxvalue");

  const isGreaterThanZero = (value) => {
    const numericValue = parseFloat(value);
    return value && (isNaN(numericValue) || numericValue <= 0)
      ? `${label} must be greater than 0`
      : true;
  };
  return (
    <>
      <Grid container sx={{ margin: "4px" }}>
        {!login && (
          <Grid item xs={12} sx={{ display: "flex", alignItems: "center" }}>
            <InputLabel className={classes?.label} sx={{ fontSize: "0.9rem" }}>
              {label}
              {required && (
                <span style={{ color: "red", margin: "5px" }}>*</span>
              )}
            </InputLabel>
          </Grid>
        )}

        <Grid item xs={12} sx={{ alignItems: "center" }}>
          <Controller
            control={control}
            name={name}
            rules={{
              required,
              ...(name === "chkListMnvalue" && onlyPositive
                ? {
                    validate: {
                      isMinLessThanMax: (value) => {
                        const numericValue = parseFloat(value);
                        const minValue = parseFloat(chkListMnvalue);
                        const maxValue = parseFloat(chkListMxvalue);
                        if (!isNaN(minValue) && !isNaN(maxValue)) {
                          return (
                            minValue <= maxValue ||
                            `The ${label} must be less than or equal to Max value`
                          );
                        }
                        return true;
                      },
                    },
                  }
                : {}), // Add validation only if both minValue and maxValue are present
              ...(!onlyPositive && { validate: isGreaterThanZero }), // If onlyPositive is true, add this validation
            }}
            render={({ field }) => {
              return (
                <>
                  <TextField
                    className={classes?.root}
                    disabled={disable}
                    fullWidth
                    {...muiProps}
                    variant="outlined"
                    name={name}
                    type={type}
                    size={size || "small"}
                    defaultValue={defaultValue}
                    autoComplete="off"
                    color={name in errors ? "error" : null}
                    {...register(name)}
                    value={
                      parseFloat(field.value)
                        ? parseFloat(field.value)
                        : defaultValue
                    }
                    inputProps={{
                      maxLength,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {InputProps}
                        </InputAdornment>
                      ),
                      style: {
                        borderRadius: "15px",
                        fontSize: "0.9rem",
                      },
                      inputProps: { step: "any" },
                    }}
                    multiline={multiline}
                    rows={rows}
                    sx={{ width: { width } }}
                  />
                </>
              );
            }}
          />
          <Typography
            variant="inherit"
            sx={{ fontSize: "0.9rem", color: "#d32f2f" }}
          >
            {errors[name] && errors[name].message}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

TextField.defaultProps = {
  variant: "outlined",
};
