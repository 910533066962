import React, { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useCommonData } from "../../../context/CommonDataProvider";
import useMutationApi from "../../../hooks/useMutationApi";
import useFetchApi from "../../../hooks/useFetchApi";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
} from "../../../constants/global";
import { useMenuContext } from "../../../context/MenuContextProvider";
import { CalForm } from "../../../common/CalForm";
import dayjs from "dayjs";
import { formatTime } from "../../../utils/common";

export const CalendarWorkingDays = ({
  calsId,
  open,
  editDefWorkingValues,
  setEditDefWorkingValues,
  setOpen,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showCalendarWorkingDaysForm, setShowCalendarWorkingDaysForm] =
    useState(false);
  const [rows, setRows] = useState([]);
  const [showCalendarWorkingDaysEditForm, setShowCalendarWorkingDaysEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addCalendarWorkingDays,
    isSuccess: isCalendarWorkingDaysSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/add`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateCalendarWorkingDays,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/edit/${calsId}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteCalendarWorkingDays, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${WorkOrderPrefix}/delete/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getCalendarWorkingDaysData,
    isFetchedAfterMount: isFetch,
    isFetching: isCalendarWorkingDaysFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/${calsId}`,
    retrieveOnMount: calsId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isCalendarWorkingDaysSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isCalendarWorkingDaysSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    if (canRead && getCalendarWorkingDaysData && open) {
      setEditDefWorkingValues(getCalendarWorkingDaysData);
    } else {
      setEditDefWorkingValues([]);
    }
  }, [
    canRead,
    getCalendarWorkingDaysData,
    open,
    rightsArray,
    setEditDefWorkingValues,
  ]);

  const submitCalendarWorkingDaysData = async (newCalendarWorkingDaysData) => {
    if (getCalendarWorkingDaysData?.length === 0) {
      let flag = false;
      for (let i = 0; i < 7; i++) {
        const dayKey = `calw${
          ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][i]
        }`;
        const startTimeKey = `${dayKey}StartTime`;
        const endTimeKey = `${dayKey}EndTime`;
        if (
          newCalendarWorkingDaysData[startTimeKey] &&
          newCalendarWorkingDaysData[endTimeKey]
        ) {
          flag = true;
          break;
        }
      }

      if (flag) {
        addCalendarWorkingDays({
          calwCalsIdFk: calsId,
          calwPlantIdFk: plantId,
          calwDesc: newCalendarWorkingDaysData?.calwDesc
            ? newCalendarWorkingDaysData?.calwDesc
            : "",
          calwFriEndTime:
            newCalendarWorkingDaysData?.calwFriEndTime &&
            typeof newCalendarWorkingDaysData.calwFriEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwFriEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwFriStartTime:
            newCalendarWorkingDaysData?.calwFriStartTime &&
            typeof newCalendarWorkingDaysData.calwFriStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwFriStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwMonEndTime:
            newCalendarWorkingDaysData?.calwMonEndTime &&
            typeof newCalendarWorkingDaysData.calwMonEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwMonEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwMonStartTime:
            newCalendarWorkingDaysData?.calwMonStartTime &&
            typeof newCalendarWorkingDaysData.calwMonStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwMonStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwSatEndTime:
            newCalendarWorkingDaysData?.calwSatEndTime &&
            typeof newCalendarWorkingDaysData.calwSatEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwSatEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwSatStartTime:
            newCalendarWorkingDaysData?.calwSatStartTime &&
            typeof newCalendarWorkingDaysData.calwSatStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwSatStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwSunEndTime:
            newCalendarWorkingDaysData?.calwSunEndTime &&
            typeof newCalendarWorkingDaysData.calwSunEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwSunEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwSunStartTime:
            newCalendarWorkingDaysData?.calwSunStartTime &&
            typeof newCalendarWorkingDaysData.calwSunStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwSunStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwThuEndTime:
            newCalendarWorkingDaysData?.calwThuEndTime &&
            typeof newCalendarWorkingDaysData.calwThuEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwThuEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwThuStartTime:
            newCalendarWorkingDaysData?.calwThuStartTime &&
            typeof newCalendarWorkingDaysData.calwThuStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwThuStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwTueEndTime:
            newCalendarWorkingDaysData?.calwTueEndTime &&
            typeof newCalendarWorkingDaysData.calwTueEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwTueEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwTueStartTime:
            newCalendarWorkingDaysData?.calwTueStartTime &&
            typeof newCalendarWorkingDaysData.calwTueStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwTueStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwWedEndTime:
            newCalendarWorkingDaysData?.calwWedEndTime &&
            typeof newCalendarWorkingDaysData.calwWedEndTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwWedEndTime).format(
                  "HH:mm:ss"
                )
              : " ",
          calwWedStartTime:
            newCalendarWorkingDaysData?.calwWedStartTime &&
            typeof newCalendarWorkingDaysData.calwWedStartTime === "object"
              ? dayjs(newCalendarWorkingDaysData.calwWedStartTime).format(
                  "HH:mm:ss"
                )
              : " ",
        })
          .then((response) => {
            if (response.status === 200) {
              toast.success("Calendar Working Days Added successfully");
              setShowCalendarWorkingDaysEditForm(
                !showCalendarWorkingDaysEditForm
              );
              setShowCalendarWorkingDaysForm(false);
            }
          })
          .catch((error) => {
            if (error?.response?.status === 400) {
              toast.error(error?.response?.data?.message);
            } else {
              toast.error(error);
            }
          });
      } else {
        toast.error("please provide data for atleast one set");
      }
    } else {
      updateCalendarWorkingDays({
        calwCalsIdFk: calsId,
        calwPlantIdFk: plantId,
        calwDesc: newCalendarWorkingDaysData?.calwDesc
          ? newCalendarWorkingDaysData?.calwDesc
          : "",
        calwFriEndTime: formatTime(newCalendarWorkingDaysData?.calwFriEndTime),
        calwFriStartTime: formatTime(
          newCalendarWorkingDaysData?.calwFriStartTime
        ),
        calwMonEndTime: formatTime(newCalendarWorkingDaysData?.calwMonEndTime),
        calwMonStartTime: formatTime(
          newCalendarWorkingDaysData?.calwMonStartTime
        ),
        calwSatEndTime: formatTime(newCalendarWorkingDaysData?.calwSatEndTime),
        calwSatStartTime: formatTime(
          newCalendarWorkingDaysData?.calwSatStartTime
        ),
        calwSunEndTime: formatTime(newCalendarWorkingDaysData?.calwSunEndTime),
        calwSunStartTime: formatTime(
          newCalendarWorkingDaysData?.calwSunStartTime
        ),
        calwThuEndTime: formatTime(newCalendarWorkingDaysData?.calwThuEndTime),
        calwThuStartTime: formatTime(
          newCalendarWorkingDaysData?.calwThuStartTime
        ),
        calwTueEndTime: formatTime(newCalendarWorkingDaysData?.calwTueEndTime),
        calwTueStartTime: formatTime(
          newCalendarWorkingDaysData?.calwTueStartTime
        ),
        calwWedEndTime: formatTime(newCalendarWorkingDaysData?.calwWedEndTime),
        calwWedStartTime: formatTime(
          newCalendarWorkingDaysData?.calwWedStartTime
        ),
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("CalendarWorkingDays updated successfully");
            setShowCalendarWorkingDaysEditForm(false);
            setId();
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const handleDeleteClick = async () => {
    const res = deleteCalendarWorkingDays();
    if (res.status === 200) {
      toast.success("Calendar Working Days Deleted successfully");
      setOpenDialog(false);
      setId();
    } else {
      toast.error("Something went wrong");
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowCalendarWorkingDaysEditForm(!showCalendarWorkingDaysEditForm);
    setShowCalendarWorkingDaysForm(false);
    setOpen(false);
  };

  const columns = [
    {
      field: "calwDesc",
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: ["calwSunStartTime", "calwSunEndTime"],
      headerName: "Sunday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
      showLabel: true,
    },
    {
      field: ["calwMonStartTime", "calwMonEndTime"],
      headerName: "Monday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: ["calwTueStartTime", "calwTueEndTime"],
      headerName: "Tuesday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: ["calwWedStartTime", "calwWedEndTime"],
      headerName: "Wednesday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: ["calwThuStartTime", "calwThuEndTime"],
      headerName: "Thursday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: ["calwFriStartTime", "calwFriEndTime"],
      headerName: "Friday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
    {
      field: ["calwSatStartTime", "calwSatEndTime"],
      headerName: "Saturday",
      type: "calendarTime",
      width: GridWidthMedium,
      textFieldWidth: medium,
    },
  ];

  return (
    <>
      <ToastContainer />
      {open && (
        <CalForm
          onClose={handleClose}
          showForm={showCalendarWorkingDaysForm}
          setShowForm={setShowCalendarWorkingDaysForm}
          columns={columns}
          onUpData={submitCalendarWorkingDaysData}
          onAdd={submitCalendarWorkingDaysData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefWorkingValues}
          label={"Calendar Working Days"}
        />
      )}
    </>
  );
};
