import React from "react";
import { Button, Tooltip } from "@mui/material";
import "./CommonButton.css";
import { useTheme } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import WorkOrderIcon from "@mui/icons-material/Assignment";
import RequestIcon from "@mui/icons-material/NoteAdd"; // Import a suitable icon for "Convert to Request"

export default function AddEditToolBar(props) {
  const {
    onClickAdd,
    onClickEdit,
    onClickDelete,
    onClickProperties,
    propId,
    editId,
    plantId = 0,
    checkList,
    plant,
    urWrite,
    urModify,
    urDelete,
    isAsset,
    urAdmin,
    onClickColumnCustom,
    onClickRestore,
    onClickImportCSV,
    onClickConvertToWorkOrder,
    onClickConvertToRequest, // Add this new prop
  } = props;

  const buttonStyle = {
    minWidth: 0,
    minHeight: 0,
    width: "auto",
    height: "auto",
    fontSize: "1px",
    backgroundColor: "transparent",
    color: "rgba(0,0,0,0.3)",
    border: "none",
    boxShadow: "none",
    textTransform: "capitalize",
    "&:hover": {
      color: "primary.main",
      backgroundColor: "transparent",
      boxShadow: "none",
    },
    "& .MuiButton-startIcon": {
      margin: "0 1px 0 0",
    },
    "& .MuiButton-endIcon": {
      margin: 0,
    },
  };

  const access = {
    canDelete: urAdmin || urDelete,
    canModify: urAdmin || urModify,
    canWrite: urAdmin || urWrite,
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "0px",
        padding: "0px",
        border: "none",
      }}
    >
      {!checkList && (
        <>
          <Tooltip title={"Add"}>
            <span>
              <Button
                disableRipple
                sx={buttonStyle}
                style={{ background: "transparent" }}
                startIcon={
                  <i
                    class="fa-solid fa-plus fa-xs"
                    style={{ fontSize: "17px" }}
                  ></i>
                }
                onClick={onClickAdd}
                disabled={
                  isAsset ? !plantId || !access.canWrite : !access.canWrite
                }
              />
            </span>
          </Tooltip>
          <Tooltip title={"Edit"}>
            <span>
              <Button
                disableRipple
                sx={buttonStyle}
                style={{ background: "transparent" }}
                startIcon={
                  <i
                    class="fa-solid fa-pen-to-square fa-xs"
                    style={{ fontSize: "15px" }}
                  ></i>
                }
                onClick={onClickEdit}
                disabled={!editId || !access.canModify}
              ></Button>
            </span>
          </Tooltip>
        </>
      )}
      {!plant && (
        <Tooltip title={"Delete"}>
          <span>
            <Button
              disableRipple
              sx={buttonStyle}
              style={{ background: "transparent" }}
              startIcon={
                <i
                  class="fa-regular fa-trash-can fa-xs"
                  style={{ fontSize: "14.5px" }}
                ></i>
              }
              onClick={onClickDelete}
              disabled={!editId || !access.canDelete}
            ></Button>
          </span>
        </Tooltip>
      )}

      {(!checkList || !plant) && (
        <>
          <Tooltip title={"Properties"}>
            <span>
              <Button
                disableRipple
                sx={buttonStyle}
                style={{ background: "transparent" }}
                startIcon={
                  <i
                    class="fa-solid fa-toolbox fa-xs"
                    style={{ fontSize: "14px" }}
                  ></i>
                }
                onClick={onClickProperties}
                disabled={!propId}
              ></Button>
            </span>
          </Tooltip>
          <Tooltip title={"Customize"}>
            <span>
              <Button
                disableRipple
                onClick={onClickColumnCustom}
                sx={buttonStyle}
                style={{ background: "transparent" }}
                startIcon={
                  <i
                    class="fa-solid fa-sliders fa-xs"
                    style={{ fontSize: "15px" }}
                  ></i>
                }
              ></Button>
            </span>
          </Tooltip>

          <Tooltip title={"Restore Columns"}>
            <span>
              <Button
                disableRipple
                onClick={onClickRestore}
                sx={buttonStyle}
                style={{ background: "transparent" }}
                startIcon={
                  <i
                    class="fa-solid fa-reply fa-xs"
                    style={{ fontSize: "15px" }}
                  ></i>
                }
              ></Button>
            </span>
          </Tooltip>
          <Tooltip title={"Import CSV"}>
            <span>
              <Button
                disableRipple
                sx={buttonStyle}
                style={{ background: "transparent" }}
                variant="contained"
                startIcon={
                  <i
                    class="fa-solid fa-cloud-arrow-up fa-xs"
                    style={{ fontSize: "15px" }}
                  ></i>
                }
                onClick={onClickImportCSV}
              />
            </span>
          </Tooltip>
          <Tooltip title="Convert to Work Order">
            <span>
              <Button
                disableRipple
                sx={buttonStyle}
                style={{ background: "transparent" }}
                variant="contained"
                onClick={onClickConvertToWorkOrder}
              >
                <WorkOrderIcon fontSize="small" />
              </Button>
            </span>
          </Tooltip>

          {/* New "Convert to Request" button */}
          <Tooltip title="Convert to Request">
            <span>
              <Button
                disableRipple
                sx={buttonStyle}
                style={{ background: "transparent" }}
                variant="contained"
                onClick={onClickConvertToRequest}
              >
                <RequestIcon fontSize="small" />
              </Button>
            </span>
          </Tooltip>
        </>
      )}
    </div>
  );
}
