import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useCommonData } from "../../../context/CommonDataProvider";
import useMutationApi from "../../../hooks/useMutationApi";
import useFetchApi from "../../../hooks/useFetchApi";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  GeneralPrefix,
  dateWidth,
} from "../../../constants/global";
import { AddEditForm } from "../../../common/AddEditForm";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "../../home/assetManagementSteps/CommonBox";
import { getFormattedDate } from "../../../utils/common";
import { useMenuContext } from "../../../context/MenuContextProvider";
import useColumnConfigStore from "../../home/columnConfigStore";
import ColumnCustomizationPopup from "../../home/ColumnCustomizationPopup";

export const CalendarHoliday = ({ calsId }) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showCalendarHolidayForm, setShowCalendarHolidayForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [showCalendarHolidayEditForm, setShowCalendarHolidayEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "calendar_system" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const {
    mutateAsync: addCalendarHoliday,
    isSuccess: isCalendarHolidaySuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/calendar-holiday`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateCalendarHoliday,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/calendar-holiday/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteCalendarHoliday, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${WorkOrderPrefix}/calendar-holiday/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getCalendarHolidayData,
    isFetchedAfterMount: isFetch,
    isFetching: isCalendarHolidayFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/calendar-holiday/${calsId}`,
    retrieveOnMount: plantId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isCalendarHolidaySuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isCalendarHolidaySuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    if (isFetch && canRead) {
      setRows(() =>
        getCalendarHolidayData?.map((w) => {
          return { id: w.calhId, ...w };
        })
      );
    }
  }, [isFetch, getCalendarHolidayData, rightsArray, canRead]);

  const handleCalendarHolidayEditClick = () => {
    setShowCalendarHolidayEditForm(true);
    setId(editData.id);
    setEditDefValues(editData);
  };

  const saveUpdateData = async (CalendarHolidayDataToSave) => {
    updateCalendarHoliday({
      id: CalendarHolidayDataToSave.calhId,
      ...CalendarHolidayDataToSave,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Calendar Holiday updated successfully");
          setShowCalendarHolidayEditForm(false);
          setId();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitCalendarHolidayData = async (newCalendarHolidayData) => {
    addCalendarHoliday({
      calhCalsId: calsId,
      calhPlantIdFk: plantId,
      ...newCalendarHolidayData,
    })
      .then((response) => {
        if (response.status === 201) {
          toast.success("Calendar Holiday Added successfully");
          setShowCalendarHolidayEditForm(!showCalendarHolidayEditForm);
          setShowCalendarHolidayForm(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteCalendarHoliday()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Calendar Holiday Deleted successfully");
          setOpenDialog(false);
          setId();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowCalendarHolidayEditForm(!showCalendarHolidayEditForm);
    setShowCalendarHolidayForm(false);
  };

  const allcolumns = [
    {
      field: "calhDesc",
      headerName: "Holiday Description",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "calhDayoffDate",
      headerName: "Date",
      required: true,
      type: "Date",
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
      textFieldWidth: dateWidth,
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_calendar_system`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  // const handleRestoreAllColumns = async () => {
  //   const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
  //     defaultConfig,
  //     allcolumns
  //   );
  //   setColumns(restoredColumns);

  //   try {
  //     const updatedConfig = {
  //       ...restoredConfig,
  //       view_name: selectedView.name,
  //       view_id: selectedView.id,
  //     };
  //     updateColumnConfig(updatedConfig);
  //     setColumnConfig(updatedConfig);

  //     localStorage.setItem(
  //       `selectedView_calendar_system`,
  //       JSON.stringify({
  //         name: selectedView.name,
  //         id: selectedView.id,
  //         config: updatedConfig,
  //       })
  //     );

  //     toast.success("All columns restored for the current view");
  //   } catch (error) {
  //     console.error("Error restoring columns for the current view:", error);
  //     toast.error("Failed to restore columns for the current view");
  //   }
  //   setOpenRestoreDialog(false);
  // };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  const onAdd = () => {
    setShowCalendarHolidayEditForm(true);
    setShowCalendarHolidayForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleCalendarHolidayEditClick}
        onClickColumnCustom={() => handleOpenColumnPopup()}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isCalendarHolidayFetching}
        columns={columns}
        rows={rows}
        label={"Calendar Holiday"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"calhDayoffDate"}
      />
      {showCalendarHolidayEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showCalendarHolidayForm}
          setShowForm={setShowCalendarHolidayForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitCalendarHolidayData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Calendar Holiday"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Calendar Holiday?"}
        handleApprove={handleDeleteClick}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="calendar_system"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
