import React, { useState, useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import gantt from 'dhtmlx-gantt';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import { Maximize2, X } from 'lucide-react';
import minMax from 'dayjs/plugin/minMax';
dayjs.extend(minMax);

const GanttChart = ({ tasks }) => {
  const ganttContainerRef = useRef(null);
  const fullscreenGanttRef = useRef(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [currentZoom, setCurrentZoom] = useState('months');
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Define zoom configurations with time-specific formats
  const zoomConfig = {
    hours: {
      scales: [
        { unit: "day", step: 1, format: "%d %M" },
        { unit: "hour", step: 1, format: "%H:00" }
      ],
      min_column_width: 30,
      scale_height: 60
    },
    days: {
      scales: [
        { unit: "month", step: 1, format: "%F %Y" },
        { unit: "day", step: 1, format: "%d %M" },
        { unit: "hour", step: 6, format: "%H:00" }
      ],
      min_column_width: 50,
      scale_height: 90
    },
    weeks: {
      scales: [
        { unit: "month", step: 1, format: "%F %Y" },
        { unit: "week", step: 1, format: "Week #%W" },
        { unit: "day", step: 1, format: "%d %M" }
      ],
      min_column_width: 80,
      scale_height: 90
    },
    months: {
      scales: [
        { unit: "year", step: 1, format: "%Y" },
        { unit: "month", step: 1, format: "%F" },
        { unit: "week", step: 1, format: "Week #%W" }
      ],
      min_column_width: 100,
      scale_height: 90
    }
  };

  const processGanttTasks = (tasks) => {
    return tasks.map(task => {
      // Handle start date/time
      let startDateTime;
      if (task.wosStartDate) {
        if (task.wosStartTime) {
          startDateTime = dayjs(`${task.wosStartDate} ${task.wosStartTime}`);
        } else {
          // If start time is null, set to 00:00
          startDateTime = dayjs(`${task.wosStartDate} 00:00`);
        }
      } else {
        startDateTime = dayjs(); // Default to current date/time if no start date
      }

      // Handle end date/time
      let endDateTime;
      if (task.wosEndDate) {
        if (task.wosEndTime) {
          endDateTime = dayjs(`${task.wosEndDate} ${task.wosEndTime}`);
        } else {
          // If end time is null, set to 23:59
          endDateTime = dayjs(`${task.wosEndDate} 23:59`);
        }
      } else {
        endDateTime = startDateTime.add(1, 'hour'); // Default to start + 1 hour if no end date
      }

      return {
        id: task.wosId,
        text: task.wosTaskDesc || task.wosTaskCode,
        start_date: startDateTime.format('YYYY-MM-DD HH:mm'),
        end_date: endDateTime.format('YYYY-MM-DD HH:mm'),
        status: task.wosStatus,
        progress: task.wosStatus?.label === 'Completed' ? 1 : 
                 task.wosStatus?.label === 'In Progress' ? 0.5 : 0,
        duration: task.wosDuration || 1
      };
    });
  };

  // Function to set zoom level
  const setZoom = (level) => {
    setCurrentZoom(level);
    gantt.config.scales = zoomConfig[level].scales;
    gantt.config.min_column_width = zoomConfig[level].min_column_width;
    gantt.config.scale_height = zoomConfig[level].scale_height;
    gantt.render();
  };

  // Function to handle zoom in/out
  const handleZoom = (direction) => {
    const zoomLevels = ['hours', 'days', 'weeks', 'months'];
    const currentIndex = zoomLevels.indexOf(currentZoom);
    
    if (direction === 'in' && currentIndex > 0) {
      setZoom(zoomLevels[currentIndex - 1]);
    } else if (direction === 'out' && currentIndex < zoomLevels.length - 1) {
      setZoom(zoomLevels[currentIndex + 1]);
    }
  };

  // Handle fullscreen toggle
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
    setTimeout(() => {
      const targetRef = !isFullscreen ? fullscreenGanttRef : ganttContainerRef;
      gantt.init(targetRef.current);
      const processedTasks = processGanttTasks(tasks);
      gantt.parse({ data: processedTasks });

      if (processedTasks.length > 0) {
        const startDates = processedTasks.map(task => dayjs(task.start_date));
        const endDates = processedTasks.map(task => dayjs(task.end_date));
        
        const minDate = dayjs.min(startDates);
        const maxDate = dayjs.max(endDates);
        
        gantt.config.start_date = minDate.subtract(1, 'hour').toDate();
        gantt.config.end_date = maxDate.add(1, 'hour').toDate();
        gantt.render();
      }
    }, 100);
  };

  useEffect(() => {
    // Configure Gantt chart settings
    gantt.config.date_format = "%Y-%m-%d %H:%i";
    gantt.config.work_time = true;
    gantt.config.scale_height = 90;
    gantt.config.row_height = 40;
    
    // Disable task dragging and resizing
    gantt.config.drag_move = false;
    gantt.config.drag_resize = false;

    // Configure columns in the left-side grid
    gantt.config.columns = [
      { name: "text", label: "Task Description", tree: true, width: '*', resize: true },
      { 
        name: "start_date", 
        label: "Start Date & Time", 
        align: "center", 
        width: 140,
        resize: true,
        template: (task) => dayjs(task.start_date).format('MMM D, YYYY HH:mm')
      },
      { 
        name: "end_date", 
        label: "End Date & Time", 
        align: "center", 
        width: 140,
        resize: true,
        template: (task) => dayjs(task.end_date).format('MMM D, YYYY HH:mm')
      },
      {
        name: "status",
        label: "Status",
        align: "center",
        width: 100,
        resize: true,
        template: (task) => task.status?.label || task.status || 'Not Set'
      }
    ];

    // Set initial zoom level
    gantt.config.scales = zoomConfig[currentZoom].scales;
    gantt.config.min_column_width = zoomConfig[currentZoom].min_column_width;

    // Custom task styling based on status
    gantt.templates.task_class = (start, end, task) => {
      const statusClasses = {
        'Not Started': 'task-not-started',
        'In Progress': 'task-in-progress',
        'Completed': 'task-completed',
        'Delayed': 'task-delayed',
        'On Hold': 'task-on-hold'
      };

      const status = task.status?.label || task.status;
      return statusClasses[status] || 'task-default';
    };

    // Add custom CSS for Gantt chart styling
    const styleElement = document.createElement('style');
    styleElement.textContent = `
      .gantt_container {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
        border: none !important;
      }

      .gantt_grid_head_cell {
        color: #374151;
        font-weight: 600;
        background-color: #F3F4F6;
        border-color: #E5E7EB;
        padding: 8px 12px !important;
        height: 40px !important;
        line-height: 24px !important;
        box-sizing: border-box !important;
        display: inline-block !important;
        vertical-align: middle !important;
        text-align: center !important;
      }

      .gantt_grid_head_cell[data-column-name="text"] {
        text-align: left !important;
      }

      .gantt_grid_data {
        background-color: #FFFFFF;
      }

      .gantt_cell {
        border-color: #E5E7EB;
        padding: 8px 12px !important;
        height: 40px !important;
        line-height: 24px !important;
        display: flex !important;
        align-items: center !important;
      }

      .gantt_cell[data-column-name="start_date"],
      .gantt_cell[data-column-name="end_date"],
      .gantt_cell[data-column-name="status"] {
        justify-content: center !important;
      }

      .gantt_tree_content {
        line-height: 24px !important;
        padding: 0 !important;
      }

      .gantt_task_line {
        border-radius: 4px;
        border: none;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      }

      .gantt_task_content {
        color: white;
        font-weight: 500;
        padding: 0 8px;
        line-height: 40px !important;
      }

      .gantt_scale_cell {
        color: #4B5563;
        border-color: #E5E7EB;
        background-color: #F9FAFB;
        line-height: 20px !important;
      }

      .task-not-started .gantt_task_content,
      .task-not-started .gantt_task_progress {
        background: #3B82F6;
      }

      .task-in-progress .gantt_task_content,
      .task-in-progress .gantt_task_progress {
        background: #D2122E;
      }

      .task-completed .gantt_task_content,
      .task-completed .gantt_task_progress {
        background: #10B981;
      }

      .task-delayed .gantt_task_content,
      .task-delayed .gantt_task_progress {
        background: #F87171;
      }

      .task-on-hold .gantt_task_content,
      .task-on-hold .gantt_task_progress {
        background: #FBBF24;
      }

      .task-not-started .gantt_task_progress_drag {
        display: none !important;
      }

      .gantt_task_progress {
        opacity: 0.3;
      }

      .custom-gantt-container {
        background: white;
        border: 1px solid #E5E7EB;
        border-radius: 0.5rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        margin: 1rem 0;
      }

      .gantt_grid_scale .gantt_grid_head_cell {
        font-size: 14px;
      }

      .gantt_grid_data .gantt_cell {
        font-size: 14px;
      }

      .gantt_task {
        font-size: 14px;
      }

      .zoom-controls {
        display: flex;
        gap: 8px;
        margin-bottom: 16px;
      }

      .zoom-button {
        background-color: white;
        border: 1px solid #E5E7EB;
        border-radius: 4px;
        padding: 8px 16px;
        font-size: 14px;
        font-weight: 500;
        color: #374151;
        cursor: pointer;
        transition: all 0.2s;
      }

      .zoom-button:hover {
        background-color: #F3F4F6;
      }

      .zoom-button:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      .fullscreen-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .fullscreen-content {
        background: white;
        width: 95%;
        height: 90%;
        border-radius: 8px;
        padding: 20px;
        position: relative;
      }

      .fullscreen-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
      }

      .fullscreen-title {
        font-size: 20px;
        font-weight: 600;
        color: #374151;
      }

      .close-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        color: #374151;
        transition: all 0.2s;
      }

      .close-button:hover {
        background-color: #F3F4F6;
      }

      .fullscreen-button {
        background: none;
        border: none;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        color: #374151;
        transition: all 0.2s;
        }

      .fullscreen-button:hover {
        background-color: #F3F4F6;
      }
    `;
    document.head.appendChild(styleElement);

    gantt.init(ganttContainerRef.current);
    setIsInitialized(true);

    return () => {
      gantt.clearAll();
    };
  }, []);

  useEffect(() => {
    if (isInitialized && tasks && tasks.length > 0) {
      const processedTasks = processGanttTasks(tasks);
      gantt.clearAll();
      gantt.parse({ data: processedTasks });

      if (processedTasks.length > 0) {
        const startDates = processedTasks.map(task => dayjs(task.start_date));
        const endDates = processedTasks.map(task => dayjs(task.end_date));
        
        const minDate = dayjs.min(startDates);
        const maxDate = dayjs.max(endDates);
        
        gantt.config.start_date = minDate.subtract(1, 'hour').toDate();
        gantt.config.end_date = maxDate.add(1, 'hour').toDate();
        gantt.render();
      }
    }
  }, [tasks, isInitialized]);

  return (
    <>
      <div className="custom-gantt-container">
        <div className="zoom-controls">
          <button
            className="zoom-button"
            onClick={() => handleZoom('in')}
            disabled={currentZoom === 'hours'}
          >
            Zoom In
          </button>
          <button
            className="zoom-button"
            onClick={() => handleZoom('out')}
            disabled={currentZoom === 'months'}
          >
            Zoom Out
          </button>
          <button
            className="fullscreen-button"
            onClick={toggleFullscreen}
            title="Fullscreen"
          >
            <Maximize2 size={20} />
          </button>
        </div>
        <div 
          ref={ganttContainerRef}
          className="gantt-chart-container"
          style={{ 
            height: '500px',
            width: '100%'
          }}
        />
      </div>

      {isFullscreen && (
        <div className="fullscreen-modal">
          <div className="fullscreen-content">
            <div className="fullscreen-header">
              <div className="fullscreen-title">Gantt Chart</div>
              <button className="close-button" onClick={toggleFullscreen}>
                <X size={20} />
              </button>
            </div>
            <div className="zoom-controls">
              <button
                className="zoom-button"
                onClick={() => handleZoom('in')}
                disabled={currentZoom === 'hours'}
              >
                Zoom In
              </button>
              <button
                className="zoom-button"
                onClick={() => handleZoom('out')}
                disabled={currentZoom === 'months'}
              >
                Zoom Out
              </button>
            </div>
            <div 
              ref={fullscreenGanttRef}
              className="gantt-chart-container"
              style={{ 
                height: 'calc(100% - 100px)',
                width: '100%'
              }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GanttChart;