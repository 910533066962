import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Box, Grid, Stack } from "@mui/material";
import { TextInput } from "./TextInput";
import { Button } from "./Button";
import { FormAutocompleteField } from "./FormAutocompleteField";
import { toast } from "react-toastify";

export const SignInUpForm = (props) => {
  const methods = useForm({
    defaultValues: {
      ...props.defaultValues,
    },
    mode: "onChange", // This will run validation on every change
  });

  const saveData = async (data) => {
    try {
      await props.onSubmit(data);
      // Reset the form on successful submission
      methods.reset();
    } catch (error) {
      console.error("Submission error:", error);
      // Show only one error message for failed registration
      toast.error("Registration failed.");
    }
  };

  // Custom validation for confirm password
  const validatePasswordMatch = (value) => {
    const password = methods.getValues("userPassword");
    return password === value || "Passwords do not match";
  };

  return (
    <div>
      <Box noValidate sx={{ mt: 1 }}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(saveData)} method="post">
            <Stack spacing={2}>
              {props.columns.map((inputItem) => {
                return (
                  <React.Fragment key={inputItem.id}>
                    {inputItem.field !== "action" && (
                      <>
                        {["text", "email", "number", "password"].includes(
                          inputItem.type
                        ) ? (
                          <TextInput
                            classes={props.classes}
                            type={inputItem.type}
                            required={inputItem.required}
                            label={inputItem?.headerName}
                            variant="outlined"
                            name={inputItem.field}
                            InputProps={inputItem.icon}
                            errorMessage={inputItem.errorMessage}
                            rules={{
                              required:
                                inputItem.required && "This field is required",
                              pattern: {
                                value: inputItem.pattern,
                                message: inputItem.errorMessage,
                              },
                              validate:
                                inputItem.field === "confirmPassword"
                                  ? validatePasswordMatch
                                  : undefined,
                              ...(inputItem.type === "number" && {
                                min: {
                                  value: 1,
                                  message: "Value must be greater than 0",
                                },
                              }),
                            }}
                          />
                        ) : inputItem.type === "select" ? (
                          <FormAutocompleteField
                            classes={props.classes}
                            label={inputItem.headerName}
                            name={inputItem.field}
                            required={inputItem.required}
                            variant="outlined"
                            options={inputItem.options}
                            width={inputItem.textFieldWidth}
                            rules={{
                              required:
                                inputItem.required && "This field is required",
                            }}
                          />
                        ) : null}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
              <Grid container spacing={2}>
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={24}
                  xs={24}
                  className={props.classes.item}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    className={props.classes.buttonRegister}
                    label={"Register"}
                    disabled={props.isLoading}
                  />
                </Grid>
              </Grid>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </div>
  );
};
