import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  GridWidthLargeWidth,
  GridWidthMedium,
  WorkOrderPrefix,
  dateWidth,
  gridHeight,
  medium,
  small,
  GeneralPrefix,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import {
  getFormattedDate,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../utils/common";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import dayjs from "dayjs";
import ViewDropdown from "./ViewDropdown";
import useColumnConfigStore from "./columnConfigStore";
import ColumnCustomizationPopup from "./ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const Project = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const plant_Id = commonData?.userData?.lastLoginPlantID;
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showProjectForm, setShowProjectForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showProjectEditForm, setShowProjectEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const userId = commonData.userId;
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "project" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addProject,
    isSuccess: isProjectSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/project/addProject`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateProject,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/project?prjId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const { mutateAsync: deleteProject, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${WorkOrderPrefix}/project?prjId=${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getProjectData,
    isFetchedAfterMount: isFetch,
    isFetching: isProjectFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/project/plant/${plant_Id}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && plant_Id ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isProjectSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isProjectSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getMainProjectData, setGetMainProjectData] = useState(getProjectData);

  useEffect(() => {
    if (getProjectData) {
      const projectData = getProjectData.map((item) => {
        const newProjectSupplier = mapDropdownValueToLabelWithId(
          commonData.getSupplierList,
          item.prjBusIdFk
        );
        const newProjectUoM = mapDropdownValueToLabelWithId(
          commonData.getUomDataList,
          item.prjBunitIdFk
        );
        const newProjectComp = mapDropdownValueToLabelWithId(
          commonData.getSupplierList,
          item.prjCompIdFk
        );
        return {
          ...item,
          prjBusIdFk: newProjectSupplier,
          prjBunitIdFk: newProjectUoM,
          prjCompIdFk: newProjectComp,
        };
      });

      setGetMainProjectData(projectData);
    }
  }, [getProjectData, commonData]);

  useEffect(() => {
    if (isFetch && getMainProjectData && canRead) {
      setRows(() =>
        getMainProjectData?.map((w) => {
          return { id: w.prjId, ...w };
        })
      );
    }
  }, [isFetch, getMainProjectData, rightsArray, canRead]);

  const handleProjectEditClick = () => {
    setShowProjectEditForm(true);
    setId(editData.id);
    const newSupplierId = mapDropdownValueToIdWithLabel(
      commonData.getSupplierList,
      editData.prjBusIdFk
    );
    const newUnitId = mapDropdownValueToIdWithLabel(
      commonData.getUomDataList,
      editData.prjBunitIdFk
    );
    const newCompId = mapDropdownValueToIdWithLabel(
      commonData.getSupplierList,
      editData.prjCompIdFk
    );
    setEditDefValues({
      ...editData,
      prjBusIdFk: newSupplierId,
      prjBunitIdFk: newUnitId,
      prjCompIdFk: newCompId,
    });
  };

  const saveUpdateData = async (ProjectDataToSave) => {
    if (
      dayjs(ProjectDataToSave?.prjPsDate).isAfter(ProjectDataToSave?.prjPeDate)
    ) {
      toast.error(
        "End date should not be greater than or equal to the start date."
      );
    } else {
      updateProject({
        plantMasterEntity: plant_Id,
        prjId: ProjectDataToSave.prjId,
        prjDesc: ProjectDataToSave.prjDesc,
        prjCode: ProjectDataToSave.prjCode,
        prjScope: ProjectDataToSave.prjScope,
        prjPsDate: ProjectDataToSave.prjPsDate,
        prjPeDate: ProjectDataToSave.prjPeDate,
        prjProjectManager: ProjectDataToSave.prjProjectManager,
        prjProjectGroup: ProjectDataToSave.prjProjectGroup,
        prjContact: ProjectDataToSave.prjContact,
        prjAddress: ProjectDataToSave.prjAddress,
        prjBunitIdFk: ProjectDataToSave?.prjBunitIdFk?.id,
        prjBusIdFk: ProjectDataToSave?.prjBusIdFk?.id,
        prjCompIdFk: ProjectDataToSave?.prjCompIdFk?.id,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Project updated successfully");
            setShowProjectEditForm(false);
            setId();
            commonData.refetchProject();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const submitProjectData = async (newProjectData) => {
    if (dayjs(newProjectData?.prjPsDate).isAfter(newProjectData?.prjPeDate)) {
      toast.error(
        "End date should not be greater than or equal to the start date."
      );
    } else {
      addProject({
        plantMasterEntity: plant_Id,
        ...newProjectData,
        prjBunitIdFk: newProjectData?.prjBunitIdFk?.id,
        prjBusIdFk: newProjectData?.prjBusIdFk?.id,
        prjCompIdFk: newProjectData?.prjCompIdFk?.id,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Project Added successfully");
            setShowProjectEditForm(!showProjectEditForm);
            setShowProjectForm(false);
            commonData.refetchProject();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const handleDeleteClick = async () => {
    deleteProject()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Project Deleted successfully");
          setOpenDialog(false);
          setId();
          commonData.refetchProject();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowProjectEditForm(!showProjectEditForm);
    setShowProjectForm(false);
  };

  const allcolumns = [
    {
      field: "prjDesc",
      headerName: "Project Description",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "prjCode",
      headerName: "Project code",
      required: true,
      type: "text",
      width: GridWidthMedium,
      textFieldWidth: small,
    },
    {
      field: "prjScope",
      headerName: "Project Scope",
      type: "text",
      width: GridWidthLargeWidth,
      multiline: "multiline",
      row: 4,
    },
    {
      field: "prjPsDate",
      headerName: "Start Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "prjPeDate",
      headerName: "End Date",
      type: "Date",
      required: false,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
      minDateLabel: "prjPsDate",
    },
    {
      field: "prjProjectManager",
      headerName: "Manager",
      type: "text",
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "prjProjectGroup",
      headerName: "Group",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "prjCompIdFk",
      headerName: "Company",
      required: true,
      type: "select",
      apiendpoint: "getSupplierList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "prjBunitIdFk",
      headerName: "Unit",

      type: "select",
      apiendpoint: "getUomDataList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "prjBusIdFk",
      headerName: "Business Partner",
      required: true,
      type: "select",
      apiendpoint: "getSupplierList",
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "prjContact",
      headerName: "Contract",
      type: "text",
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
    {
      field: "prjAddress",
      headerName: "Address",
      type: "text",
      maxLength: 25,
      textFieldWidth: medium,
      width: GridWidthMedium,
    },
  ];
  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_project`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_project`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_project`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  const onAdd = () => {
    setShowProjectEditForm(true);
    setShowProjectForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isProjectFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              rows={viewLoaded ? rows : []}
              sortingField={"prjDesc"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        // justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleProjectEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={id}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="project"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showProjectEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showProjectForm}
          setShowForm={setShowProjectForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitProjectData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Project"
        />
      )}

      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Project?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="project"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};
