import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { AddEditForm } from "../../../common/AddEditForm";
import { medium } from "../../../constants/global";
import { CommonBox } from "../assetManagementSteps/CommonBox";

export const WorkTools = ({ workOrderId }) => {
  const [showToolsForm, setShowToolsForm] = useState(false);
  const [rows, setRows] = useState([]);

  const saveUpdateData = async () => {
    setShowToolsForm(false);
  };

  const columns = [
    {
      field: "action",
      headerName: "Action",
      type: "",
      sortable: false,
      hideable: false,
      width: 100,
      required: false,
      renderCell: (params) => {
        return (
          <>
            <IconButton>
              <EditIcon />
            </IconButton>

            <IconButton>
              <DeleteIcon />
            </IconButton>
          </>
        );
      },
    },

    {
      field: "tools",
      headerName: "Tools",
      type: "select",
      textFieldWidth: medium,
    },

    {
      field: "qty",
      headerName: "Qty",
      type: "number",
      maxLength: 20,
      textFieldWidth: medium,
    },
    {
      field: "unit",
      headerName: "Unit",
      type: "text",
      textFieldWidth: medium,
    },
  ];
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowToolsForm(false);
  };

  const onAdd = () => {
    setShowToolsForm(true);
  };

  return (
    <>
      <CommonBox
        onClick={onAdd}
        isLoading={false}
        columns={columns}
        rows={rows}
        label={"Tools"}
      />

      {showToolsForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showToolsForm}
          setShowForm={setShowToolsForm}
          columns={columns}
          onAdd={saveUpdateData}
          label={"Tools"}
        />
      )}
    </>
  );
};
