import React, { useState } from "react";
import { Box, StepLabel, StepContent, Typography } from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import { Component } from "./Component";
import { Button } from "../../../common/Button";
import { Transfer } from "./Transfer";
import { Warranty } from "./Warranty";
import { Iot } from "./Iot";
import { Depreciation } from "./Depreciation";
import { AssetDocumentUpload } from "./AssetDocumentUpload";
import { AssetPhotoUpload } from "./AssetPhotoUpload";
import { WorkOrder } from "./WorkOrder";
import { AssetSchedules } from "./AssetSchedules";
import { AssetRequests } from "./Request";
import { Suspense } from "react";
export const AssetManagementMultiSteps = ({
  onClose,
  asstId,
  columnConfig,
  componentColumns,
  setComponentColumns,
  setDepreciationColumns,
  depreciationColumns,
  setWarrantyColumns,
  warrantyColumns,
  iotColumns,
  setIotColumns,
  transferColumns,
  setTransferColumns,
  workorderColumns,
  setWorkorderColumns,
  adocColumns,
  setAdocColumns,
  photoColumns,
  setPhotoColumns,
  selectedView,
  awoColumns,
  setAwoColumns,
  scColumns,
  setScColumns,
  reqColumns,
  setReqColumns,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleStep = (index) => () => {
    setActiveStep(index);
  };
  const steps = [
    {
      label: "Component",
      form: (
        <>
          <Component
            label="Add Asset Components"
            asstId={asstId}
            columnConfig={columnConfig}
            componentColumns={componentColumns}
            setComponentColumns={setComponentColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Depreciation",
      form: (
        <>
          <Depreciation
            label="Add Depreciation"
            asstId={asstId}
            columnConfig={columnConfig}
            depreciationColumns={depreciationColumns}
            setDepreciationColumns={setDepreciationColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Transfer",
      form: (
        <>
          <Transfer
            label="Add Asset Transfer"
            asstId={asstId}
            transferColumns={transferColumns}
            setTransferColumns={setTransferColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Warranty",
      form: (
        <>
          <Warranty
            label="Add Asset Warranty"
            asstId={asstId}
            columnConfig={columnConfig}
            warrantyColumns={warrantyColumns}
            setWarrantyColumns={setWarrantyColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "IoT",
      form: (
        <>
          <Iot
            label="Add Asset IoT"
            asstId={asstId}
            iotColumns={iotColumns}
            setIotColumns={setIotColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Document Upload",
      form: (
        <>
          <AssetDocumentUpload label="Add Document Upload" 
          assetId={asstId}
          selectedView={selectedView}
          adocColumns={adocColumns}
          setAdocColumns={setAdocColumns}
          />
        </>
      ),
    },
    {
      label: "Photo Upload",
      form: (
        <>
          <Suspense fallback={<div>Loading...</div>}>
            {asstId && <AssetPhotoUpload 
            assetId={asstId}
            selectedView={selectedView}
            photoColumns={photoColumns}
            setPhotoColumns={setPhotoColumns}
            />}
          </Suspense>
        </>
      ),
    },
    {
      label: "Workorder",
      form: (
        <>
          <WorkOrder
            label="Add Workorder"
            assetId={asstId}
            awoColumns={awoColumns}
            setAwoColumns={setAwoColumns}
            selectedView={selectedView}
          />
        </>
      ),
    },
    {
      label: "Schedule",
      form: 
      <AssetSchedules 
      label="Add Schedule" 
      assetId={asstId} 
      selectedView={selectedView}
      scColumns={scColumns}
      setScColumns={setScColumns}
      />,
    },
    {
      label: "Request",
      form: 
      <AssetRequests 
      label="Add Request" 
      assetId={asstId}
      selectedView={selectedView}
      reqColumns={reqColumns}
      setReqColumns={setReqColumns}
      />,
    },
  ];

  return (
    <div>
      <Box sx={{ maxWidth: "55vw", marginLeft: "4vh" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={handleStep(index)}>{step.label}</StepLabel>
              <StepContent>
                {steps[activeStep].form}
                {/* <Box sx={{ mb: 2 }}>
                  {index !== steps.length - 1 && (
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleNext}
                      sx={{ mt: 0.5, mr: 1 }}
                      label="Continue"
                    ></Button>
                  )}

                  {index === steps.length - 1 && (
                    <Button
                      size="small"
                      disabled={index === 0}
                      onClick={onClose}
                      sx={{ mt: 0.5, mr: 1 }}
                      label="finish"
                    ></Button>
                  )}
                  <Button
                    size="small"
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 0.5, mr: 1 }}
                    label="back"
                    variant="outlined"
                  ></Button>
                </Box> */}
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};
