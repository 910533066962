import React, { useState, useEffect, useCallback } from "react";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  GeneralPrefix,
  GridWidthMedium,
  gridHeight,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import { Box, SwipeableDrawer } from "@mui/material";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { Progressor } from "../../common/Progressor";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import { IncidentMultiStep } from "./IncidentSteps/IncidentMultiStep";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const IncidentClass = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [editDefWorkingValues, setEditDefWorkingValues] = useState();
  const [ingId, setIngId] = useState(null);
  const [showIncidentClassForm, setShowIncidentClassForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showIncidentClassEditForm, setShowIncidentClassEditForm] =
    useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const userId = commonData?.userData?.userId;
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [viewLoaded, setViewLoaded] = useState(true);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);
  const handleRestoreButtonClick = () => {
    setOpenRestoreDialog(true);
  };

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const showData = () => {
    setOpen(true);
  };

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
    setIngId(params.row.id);
  };

  const {
    mutateAsync: addIncidentClass,
    isSuccess: isIncidentClassSuccess,
    isLoading: isPostLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/incident-class`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateIncidentClass,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/incident-class/${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const { mutateAsync: deleteIncidentClass, isSuccess: isDeleteSuccess } =
    useMutationApi({
      endpoint: `${GeneralPrefix}/incident-class/${id}`,
      method: "delete",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const {
    data: getIncidentClassData,
    isFetchedAfterMount: isFetch,
    isFetching: isIncidentClassFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/incident-class`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isIncidentClassSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isIncidentClassSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  useEffect(() => {
    if (isFetch && getIncidentClassData && canRead) {
      setRows(() =>
        getIncidentClassData?.map((w) => {
          return { id: w.ingId, ...w };
        })
      );
    }
  }, [isFetch, getIncidentClassData, canRead]);

  const handleIncidentClassEditClick = () => {
    setShowIncidentClassEditForm(true);
    setId(editData.id);
    setEditDefValues({ ...editData });
  };

  const saveUpdateData = async (IncidentClassDataToSave) => {
    updateIncidentClass({
      ingId: IncidentClassDataToSave.ingId,
      ...IncidentClassDataToSave,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident Class updated successfully");
          setShowIncidentClassEditForm(false);
          setId();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitIncidentClassData = async (newIncidentClassData) => {
    addIncidentClass({
      ...newIncidentClassData,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident Class Added successfully");
          setShowIncidentClassEditForm(!showIncidentClassEditForm);
          setShowIncidentClassForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const handleDeleteClick = async () => {
    deleteIncidentClass()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident Class Deleted successfully");
          setOpenDialog(false);
          setId();
        } else if (response.status === 208) {
          toast.error(response?.data.message);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else if (error?.response?.status === 208) {
          toast.error(error?.response?.data.message);
        } else {
          toast.error(error);
        }
      });
  };

  const [incidentSubColumns, setIncidentSubColumns] = useState([
    {
      field: "insSubDesc",
      headerName: "Subclass Description",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "insCode",
      headerName: "Subclass Code",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowIncidentClassEditForm(!showIncidentClassEditForm);
    setShowIncidentClassForm(false);
  };

  const allcolumns = [
    {
      field: "ingDesc",
      headerName: "Description",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
    {
      field: "ingCode",
      headerName: "Code",
      required: true,
      type: "text",
      width: GridWidthMedium,
    },
  ];

  const [columns, setColumns] = useState(allcolumns);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    setColumns(updateColumns(columns, updatedConfig.grid.columns));
    setIncidentSubColumns(
      updateColumns(
        incidentSubColumns,
        updatedConfig.sub_component?.ins_sub_class
      )
    );

    const configToUpdate = {
      ...updatedConfig,
      sub_component: {
        ins_sub_class: incidentSubColumns,
      },
    };

    Object.keys(configToUpdate.sub_component).forEach((key) => {
      configToUpdate.sub_component[key] = configToUpdate.sub_component[key].map(
        (col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })
      );
    });

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_incident_class`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "incident_class" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_incident_class`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  useEffect(() => {
    fetchColumnConfig();
  }, [columnConfig, defaultConfig, ingId]);

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      console.log("View name is:", viewName);
      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }

      if (
        selectedViewConfig.sub_component &&
        selectedViewConfig.sub_component.ins_sub_class
      ) {
        const updatedincidentSubColumns = transformConfig(
          { grid: { columns: selectedViewConfig.sub_component.ins_sub_class } },
          incidentSubColumns
        );
        setIncidentSubColumns(updatedincidentSubColumns);
      } else {
        setIncidentSubColumns(
          incidentSubColumns.map((col) => ({ ...col, hide: false }))
        );
      }

      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_incident_class`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  useEffect(() => {
    if (defaultConfig && !viewLoaded) {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
    }
  }, [defaultConfig, viewLoaded]);

  const fetchColumnConfig = async () => {
    try {
      if (columnConfig && Object.keys(columnConfig).length > 0) {
        applyConfiguration(columnConfig);
        if (columnConfig.view_name && columnConfig.view_id) {
          setSelectedView({
            name: columnConfig.view_name,
            id: columnConfig.view_id,
          });
        }
      } else if (defaultConfig) {
        setColumnConfig(defaultConfig);
        applyConfiguration(defaultConfig);
      } else {
        setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(allcolumns.map((col) => ({ ...col, hide: false })));
    }
  };

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, allcolumns);
      setColumns(transformedColumns);
    }

    if (config.sub_component) {
      if (config.sub_component.ins_sub_class) {
        const transformedincidentSubColumns = transformConfig(
          { grid: { columns: config.sub_component.ins_sub_class } },
          incidentSubColumns
        );
        setIncidentSubColumns(transformedincidentSubColumns);
      }
    }

    if (config.view_name) {
      setSelectedView({ name: config.view_name, id: config.view_id });
    }
  };

  const onAdd = () => {
    setShowIncidentClassEditForm(true);
    setShowIncidentClassForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <ToastContainer />
      <Box>
        <Box sx={{ padding: "1rem" }}>
          {isIncidentClassFetching && <Progressor />}

          <Box
            sx={{
              height: `${100 - gridHeight}vh`,
              width: "90vw",
            }}
          >
            <DataGridComponent
              columns={columns}
              rows={rows}
              sortingField={"ingDesc"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAdd()}
                        onClickEdit={() => handleIncidentClassEditClick()}
                        onClickDelete={() => handleClickOpen()}
                        onClickProperties={() => showData()}
                        onClickColumnCustom={() => handleOpenColumnPopup()}
                        onClickRestore={handleRestoreButtonClick}
                        editId={id}
                        propId={ingId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />

                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="incident_class"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleRowClick}
              onCellDoubleClick={toggleDrawer(true)}
            ></DataGridComponent>
          </Box>
        </Box>
      </Box>
      {showIncidentClassEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showIncidentClassForm}
          setShowForm={setShowIncidentClassForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitIncidentClassData}
          editId={id}
          isLoading={isUpdateLoading || isPostLoading}
          defaultValues={editDefValues}
          label="Incident Class"
        />
      )}
      <Box>
        <SwipeableDrawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          disableSwipeToOpen={false}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <Box sx={{ width: "60vw" }}>
            <IncidentMultiStep
              onClose={toggleDrawer(false)}
              ingId={ingId}
              open={open}
              setOpen={setOpen}
              columnConfig={columnConfig}
              updateColumnConfig={updateColumnConfig}
              setColumnConfig={setColumnConfig}
              incidentSubColumns={incidentSubColumns}
              setIncidentSubColumns={setIncidentSubColumns}
              setEditDefWorkingValues={setEditDefWorkingValues}
              editDefWorkingValues={editDefWorkingValues}
              selectedView={selectedView}
            />
          </Box>
        </SwipeableDrawer>
      </Box>
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Incident Class?"}
        handleApprove={handleDeleteClick}
      />
      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="incident_class"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default IncidentClass;
