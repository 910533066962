import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {
  Box,
  Tooltip,
  styled,
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { ClearIcon } from "@mui/x-date-pickers";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import useFetchApi from "../../hooks/useFetchApi";
import events from "../../assets/mockData/events";
import { GeneralPrefix } from "../../constants/global";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const StyledCalendar = styled(Calendar)(({ theme }) => ({
  "& .rbc-event": {
    backgroundColor: "transparent",
    border: "none",
  },
  "& .has-events": {
    position: "relative",
    "&::after": {
      content: "attr(data-event-count)",
      position: "absolute",
      top: "0",
      right: "0",
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      borderRadius: "50%",
      padding: "2px 5px",
      fontSize: "0.7rem",
    },
  },
}));

const MyCalendar = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [highlightDate, setHighlightDate] = useState(false);
  const [eventsData, setEventsData] = useState(events);
  const [tabValue, setTabValue] = useState("user");
  const [value, setValue] = useState(null);

  const {
    data: calendarData,
    isFetchedAfterMount: isFetch,
    isFetching: isCalendarFetching,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/calender-day-today${
      tabValue === "tenant"
        ? ""
        : `?caldUserIdFk=${commonData?.userData?.id}${
            value ? `&caldAsstIdFk=${value?.asstId}` : ""
          }`
    }`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const [assetMasterData, setAssetMasterData] = useState(
    commonData?.getAssetDataList
  );

  useEffect(() => {
    const tempCalData = [];
    calendarData?.forEach((cal) => {
      tempCalData.push({
        start: new Date(cal?.caldDate),
        end: new Date(cal?.caldDate),
        title: cal?.caldDesc,
      });
    });
    setEventsData(tempCalData);
  }, [calendarData]);

  useEffect(() => {
    if (commonData) {
      setAssetMasterData(commonData?.getAssetDataList);
    }
  }, [commonData]);

  const onDateSelect = (event) => {
    setSelectedDate(event.start);
    setHighlightDate(true);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue !== "asset") {
      setValue(null);
    }
  };

  const eventStyleGetter = (event) => ({
    style: {
      backgroundColor: "transparent",
    },
  });

  const dayPropGetter = (date) => {
    const eventsForDay = eventsData.filter((event) =>
      moment(event.start).isSame(date, "day")
    );

    return {
      className: eventsForDay.length > 0 ? "has-events" : "",
      style: {
        position: "relative",
      },
      "data-event-count": eventsForDay.length > 0 ? eventsForDay.length : "",
    };
  };

  return (
    <Box sx={{ p: "1rem" }}>
      <TabContext value={tabValue}>
        <Box>
          <TabList
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            sx={{
              ".MuiTab-root": {
                textTransform: "none",
                borderBottom: "none",
                borderRadius: 0,
                backgroundColor: "transparent",
                "&:hover": {
                  backgroundColor: "#f0f0f0",
                },
                "&.Mui-selected": {
                  backgroundColor: "#f0f0f0",
                },
              },
            }}
          >
            <Tab label="User" value="user" />
            <Tab label="Tenant" value="tenant" />
            <Tab label="Asset" value="asset" />
          </TabList>
        </Box>
      </TabContext>
      {tabValue === "asset" && (
        <Box>
          <Typography
            variant="body2"
            sx={{
              marginBottom: "2px",
              color: "text.secondary",
              fontWeight: 500,
              marginLeft: "10px",
            }}
          >
            Select the Asset:
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl
              sx={{
                m: 1,
                minWidth: 220,
                maxWidth: 120,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "pre",
              }}
              size="small"
            >
              <Autocomplete
                sx={{ mb: 2, fontSize: "0.9rem" }}
                clearIcon={<ClearIcon size={"small"} />}
                options={assetMasterData}
                getOptionLabel={(option) => option.asstDesc || ""}
                isOptionEqualToValue={(option, currentOption) => {
                  return option === currentOption;
                }}
                value={value}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    <Grid container>
                      <Grid item xs={3}>
                        <Typography variant="body2">
                          {option.asstTag}
                        </Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <Typography variant="body2">
                          {option.asstDesc}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="small"
                    FormHelperTextProps={{
                      sx: {
                        ml: 0,
                        borderRadius: "15px",
                        fontSize: "0.9rem",
                      },
                    }}
                  />
                )}
                PaperComponent={(props) => (
                  <Paper {...props} sx={{ marginTop: "4px", width: "500px" }} />
                )}
              />
            </FormControl>
            {/* {value === null ? (
              <InputLabel sx={{ color: "red" }}>Please Select Asset</InputLabel>
            ) : (
              ""
            )} */}
          </Box>
        </Box>
      )}
      <Box sx={{ pt: "1rem" }}>
        <Calendar
          views={["day", "agenda", "work_week", "month"]}
          selectable={false}
          localizer={localizer}
          defaultDate={new Date()}
          defaultView="month"
          events={eventsData}
          style={{ height: "70vh" }}
          onSelectSlot={onDateSelect}
          onNavigate={() => {
            // Do nothing to prevent navigation
          }}
          components={{
            dateCellWrapper: ({ children, value }) =>
              highlightDate && moment(value).isSame(selectedDate, "day") ? (
                <div style={{ backgroundColor: "lightblue" }}>{children}</div>
              ) : (
                children
              ),
          }}
        />
      </Box>
    </Box>
  );
};

export default MyCalendar;
