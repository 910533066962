import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import numeral from "numeral";
import {
  AssetPrefix,
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  small,
  defaultNumberFormateQty,
} from "../../../constants/global";
import DeleteDialog from "../../../common/DeleteDialog";
import { useCommonData } from "../../../context/CommonDataProvider";
import { CommonBox } from "./CommonBox";
import {
  mapDropdownValueToDescWithId,
  mapDropdownValueToIdWithDesc,
  modifyOptionsWithCodeAndTag,
} from "../../../utils/common";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const Component = ({
  asstId,
  componentColumns,
  setComponentColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showComponentForm, setShowComponentForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showComponentEditForm, setShowComponentEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addAssetComponent,
    isSuccess: isAssetComponentSuccess,
    isLoading: addAssetLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/components/addComponents`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetComponent,
    isSuccess: isUpdateSuccess,
    isLoading: updateAssetLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/components?componentId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteAssetComponent,
    isSuccess: isDeleteSuccess,
    isLoading: deleteAssetLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/components?componentId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetComponentData,
    isFetchedAfterMount: isFetch,
    isFetching: isComponentFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/components?assetId=${asstId}`,
    retrieveOnMount:
      asstId !== null && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetComponentSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetComponentSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getComponentData, setGetComponentData] = useState(
    getAssetComponentData
  );

  useEffect(() => {
    if (getAssetComponentData) {
      const assetComponentData = getAssetComponentData.map((item) => {
        const newParentAsset = mapDropdownValueToDescWithId(
          commonData.getAssetDataList,
          item.astcAstuIdFk
        );
        return {
          ...item,
          astcAstuIdFk: newParentAsset,
        };
      });
      setGetComponentData(assetComponentData);
    }
  }, [getAssetComponentData, commonData]);

  useEffect(() => {
    if (isFetch && getComponentData && canRead) {
      setRows(() =>
        getComponentData?.map((w) => {
          return { id: w.astcId, ...w };
        })
      );
    }
  }, [isFetch, getComponentData, rightsArray, canRead]);
  const modifiedOptions = modifyOptionsWithCodeAndTag(
    commonData?.getAssetDataList,
    "asstTag",
    "asstDesc"
  );
  const handleAssetComponentEditClick = () => {
    setShowComponentEditForm(true);
    setId(editData.id);
    const newParentAsset = mapDropdownValueToIdWithDesc(
      modifiedOptions,
      editData.astcAstuIdFk
    );
    setEditDefValues({ ...editData, astcAstuIdFk: newParentAsset });
  };

  const saveUpdateData = async (AssetComponentDataToSave) => {
    updateAssetComponent({
      id: AssetComponentDataToSave.astcId,
      ...AssetComponentDataToSave,
      astcAstuIdFk: AssetComponentDataToSave?.astcAstuIdFk?.asstId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Component Updated successfully");
          setShowComponentEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAssetComponentData = async (newAssetComponentData) => {
    addAssetComponent({
      asstId,
      ...newAssetComponentData,
      astcAstuIdFk: newAssetComponentData?.astcAstuIdFk?.asstId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("component added successfully");
          setShowComponentEditForm(!showComponentEditForm);
          setShowComponentForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteAssetComponent()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Component delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowComponentEditForm(!showComponentEditForm);
    setShowComponentForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "astcCode",
      headerName: "Component Code",
      required: true,
      type: "text",
      maxLength: 12,
      textFieldWidth: small,
      width: GridWidthLargeWidth,
    },
    {
      field: "astcDesc",
      headerName: "Description",
      type: "text",
      maxLength: 40,
      required: true,
      width: GridWidthLargeWidth,
    },
    {
      field: "astcQty",
      headerName: "Quantity",
      type: "number",
      maxLength: 40,
      textFieldWidth: small,
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateQty);
      },
    },
    {
      field: "astcAstuIdFk",
      headerName: "Asset",
      type: "selectTwo",
      options: modifiedOptions,
      textFieldWidth: small,
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.component || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.component)
    ) {
      configColumns = updatedConfig.sub_component.component;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: details.visible,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          title: updatedColumn.title,
          visible: updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "asset",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        component: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: col.visible,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.component) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.component
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (componentColumns) {
      setColumns(componentColumns);
    } else {
      fetchColumnConfig();
    }
  }, [componentColumns, columnConfig, defaultConfig, asstId]);

  const onAdd = () => {
    setShowComponentEditForm(true);
    setShowComponentForm(true);
    setEditDefValues({});
  };

  return (
    <React.Fragment key={asstId}>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleAssetComponentEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        onClickColumnCustom={handleOpenColumnPopup}
        editId={id}
        isLoading={isComponentFetching}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Component"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sidebar
        sortingField={"astcCode"}
      />
      {showComponentEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showComponentForm}
          setShowForm={setShowComponentForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetComponentData}
          editId={id}
          defaultValues={editDefValues}
          label={"Component"}
          isLoading={updateAssetLoading || addAssetLoading}
        />
      )}

      <DeleteDialog
        open={openDialog}
        isLoading={deleteAssetLoading}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Component data?"}
        handleApprove={handleDeleteClick}
      />

      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="Asset"
        selectedViewName={selectedView.name}
      />
    </React.Fragment>
  );
};
