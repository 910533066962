import React, { useState, useEffect } from "react";
import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import {
  AssetPrefix,
  ListGroupPrefix,
} from "../../../constants/global";
import useFetchApi from "../../../hooks/useFetchApi";
import { useCommonData } from "../../../context/CommonDataProvider";
import { useMenuContext } from "../../../context/MenuContextProvider";

const TopSection = ({ height, handleMouseDown }) => {
  const commonData = useCommonData();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const { rightsArray } = useMenuContext();
  
  const [assetStatusList, setAssetStatusList] = useState([]);
  const [assetCounts, setAssetCounts] = useState({});
  const [warrantyExpiringCount, setWarrantyExpiringCount] = useState(0);
  const [criticalAssetsCount, setCriticalAssetsCount] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch asset status list
  const { data: assetStatus } = useFetchApi({
    endpoint: `${ListGroupPrefix}/assets-status`,
    retrieveOnMount: true,
    Menu_id: 16,
  });

  // Fetch asset data
  const { data: assetData, isFetching: isAssetLoading } = useFetchApi({
    endpoint: `${AssetPrefix}?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Handle asset status data
  useEffect(() => {
    if (assetStatus) {
      console.log("Asset status data:", assetStatus);
      setAssetStatusList(assetStatus);
    }
  }, [assetStatus]);

  // Process asset data and calculate counts
  useEffect(() => {
    if (assetData && !isAssetLoading && assetStatusList.length > 0) {
      console.log("Asset data:", assetData);
      console.log("Asset status list:", assetStatusList);

      setIsLoading(true);
      setError(null);

      try {
        const counts = {};
        const statusCodeMap = {};

        // Create mapping of status codes to labels
        assetStatusList.forEach((status) => {
          statusCodeMap[status.listId] = status.label;
        });

        let expiringCount = 0;
        let criticalCount = 0;
        const today = new Date();
        const thirtyDaysFromNow = new Date(
          today.getTime() + 30 * 24 * 60 * 60 * 1000
        );

        // Calculate counts for each asset
        assetData.forEach((asset) => {
          const statusCode = asset.asstStatus;
          const statusLabel = statusCodeMap[statusCode] || "Unknown";
          counts[statusLabel] = (counts[statusLabel] || 0) + 1;

          // Check warranty expiry
          if (asset.asstExpiryDate) {
            const expiryDate = new Date(asset.asstExpiryDate);
            if (expiryDate > today && expiryDate <= thirtyDaysFromNow) {
              expiringCount++;
            }
          }

          // Check critical assets
          if (asset.isCritical) {
            criticalCount++;
          }
        });

        console.log("Calculated asset counts:", counts);
        setAssetCounts(counts);
        setWarrantyExpiringCount(expiringCount);
        setCriticalAssetsCount(criticalCount);
        setTotalAssets(assetData.length);
      } catch (err) {
        console.error("Error calculating asset counts:", err);
        setError("An error occurred while calculating asset counts.");
      } finally {
        setIsLoading(false);
      }
    }
  }, [assetData, isAssetLoading, assetStatusList]);

  // Prepare card data from processed assets
  const cardData = [
    ...assetStatusList.map((status) => ({
      title: status.label,
      value: assetCounts[status.label] || 0,
      color: getColorForStatus(status.label),
    })),
    {
      title: "Warranty Expiring",
      value: warrantyExpiringCount,
      color: getColorForStatus("Warranty Expiring"),
    },
    {
      title: "Critical Assets",
      value: criticalAssetsCount,
      color: getColorForStatus("Critical Assets"),
    },
  ];

  // Helper function to get colors for different statuses
  function getColorForStatus(status) {
    const colors = {
      Working: "#1565C0",
      Paused: "#1E88E5",
      Faulty: "#42A5F5",
      "Warranty Expiring": "#64B5F6",
      "Critical Assets": "#90CAF9",
    };
    return colors[status] || "#90CAF9";
  }

  if (error) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        flex: `0 0 ${height}%`,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        minHeight: "10%",
        overflow: "hidden",
        boxShadow: 3,
      }}
    >
     
        <Grid container spacing={1} justifyContent="center">
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={2} key={index}>
              <Card sx={{ bgcolor: card.color, color: "white", boxShadow: 5 }}>
                <CardContent>
                  <Typography variant="h6">{card.title}</Typography>
                  <Typography variant="h4">{card.value}</Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
          height: "1px",
          cursor: "row-resize",
          bgcolor: "grey.400",
        }}
        onMouseDown={(e) => handleMouseDown(e, "verticalTop")}
      />
    </Box>
  );
};

export default TopSection;