import React, { useState } from "react";
import { Box, StepLabel, StepContent } from "@mui/material";
import { Stepper, Step } from "@mui/material";

import { Schedule } from "./Schedule";

export const ScheduleMaintenanceMultiStep = ({
  onClose,
  id,
  open,
  setEditScheduleValues,
  editScheduleValues,
  setOpen,
  columnConfig,
  selectedView,
  schColumns,
  setSchColumns,
}) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (index) => () => {
    setActiveStep(index);
  };

  const steps = [
    {
      label: "Schedules",
      form: (
        <Schedule
          label="Add Schedule"
          id={id}
          open={open}
          setEditScheduleValues={setEditScheduleValues}
          editScheduleValues={editScheduleValues}
          setOpen={setOpen}
          selectedView={selectedView}
          schColumns={schColumns}
          setSchColumns={setSchColumns}
        />
      ),
    },
    // Add more steps here if needed
  ];

  return (
    <div>
      <Box className="max-w-[55vw] ml-[4vh]">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={handleStep(index)}>{step.label}</StepLabel>
              <StepContent>{step.form}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </div>
  );
};

export default ScheduleMaintenanceMultiStep;
