import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { useCommonData } from "../../../context/CommonDataProvider";
import {
  AssetPrefix,
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  dateWidth,
  medium,
  small,
} from "../../../constants/global";
import {
  getFormattedDate,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToLabelWithPlantId,
  mapDropdownValueToListIdWithLabel,
  mapDropdownValueToPlantIdWithLabel,
} from "../../../utils/common";
import DeleteDialog from "../../../common/DeleteDialog";
import { CommonBox } from "./CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const Transfer = ({
  asstId,
  transferColumns,
  setTransferColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [id, setId] = useState(0);
  const commonData = useCommonData();
  const [editDefValues, setEditDefValues] = useState();
  const [showTransferForm, setShowTransferForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showTransferEditForm, setShowTransferEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const tenantKey = commonData?.userData?.tenant_id;
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addAssetTransfer,
    isSuccess: isAssetTransferSuccess,
    isLoading: addTransferLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/${asstId}/transfer`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateAssetTransfer,
    isSuccess: isUpdateSuccess,
    isLoading: updateTransferLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/transfer?transferId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteAssetTransfer,
    isSuccess: isDeleteSuccess,
    isLoading: deleteTransferLoading,
  } = useMutationApi({
    endpoint: `${AssetPrefix}/transfer?transferId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetTransferData,
    isFetchedAfterMount: isFetch,
    isFetching: isTransferFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${AssetPrefix}/transfer?assetId=${asstId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const { data: getPlantData } = useFetchApi({
    endpoint: `${GeneralPrefix}/tenant/${tenantKey}/plants`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isAssetTransferSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isAssetTransferSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getTransferData, setGetTransferData] = useState(getAssetTransferData);
  useEffect(() => {
    if (getAssetTransferData) {
      const assetData = getAssetTransferData.map((item) => {
        const newAssetsBuilding = mapDropdownValueToLabelWithListId(
          commonData.assetsBuilding,
          item.astrBuildIdFk
        );
        const newAssetsZone = mapDropdownValueToLabelWithListId(
          commonData.assetsZone,
          item.astrZoneIdFk
        );
        const newPlant = mapDropdownValueToPlantIdWithLabel(
          getPlantData,
          item.plantId
        );
        return {
          ...item,
          astrBuildIdFk: newAssetsBuilding,
          astrZoneIdFk: newAssetsZone,
          plantId: newPlant,
        };
      });
      setGetTransferData(assetData);
    }
  }, [getAssetTransferData, commonData, getPlantData]);

  useEffect(() => {
    if (isFetch && getTransferData && canRead) {
      setRows(() =>
        getTransferData?.map((w) => {
          return { id: w.astrId, ...w };
        })
      );
    }
  }, [isFetch, getTransferData, rightsArray, canRead]);
  const handleAssetTransferEditClick = () => {
    toast.error("Editing transfers is not allowed.");
  };

  const saveUpdateData = async (AssetTransferDataToSave) => {
    updateAssetTransfer({
      id: AssetTransferDataToSave.astrId,
      ...AssetTransferDataToSave,
      plantId: AssetTransferDataToSave.plantId?.plantId,
      astrBuildIdFk: AssetTransferDataToSave.astrBuildIdFk?.listId,
      astrZoneIdFk: AssetTransferDataToSave.astrZoneIdFk?.listId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Transfer Updated successfully");
          setShowTransferEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitAssetTransferData = async (newAssetTransferData) => {
    addAssetTransfer({
      asstId,
      ...newAssetTransferData,
      plantId: newAssetTransferData.plantId?.id,
      astrBuildIdFk: newAssetTransferData.astrBuildIdFk?.listId,
      astrZoneIdFk: newAssetTransferData.astrZoneIdFk?.listId,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Transfer added successfully");
          setShowTransferEditForm(!showTransferEditForm);
          setShowTransferForm(false);
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    toast.error("Deleting transfers is not allowed.");
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowTransferEditForm(!showTransferEditForm);
    setShowTransferForm(false);
  };
  const [columns, setColumns] = useState([
    {
      field: "astrDate",
      headerName: "Date",
      type: "Date",
      required: true,
      textFieldWidth: dateWidth,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "plantId",
      headerName: "Plant",
      type: "select",
      textFieldWidth: small,
      required: true,
      options: getPlantData?.map((o) => ({
        label: o.plantCode,
        id: o.plantId,
        width: GridWidthMedium,
      })),
      valueGetter: (params) => params.value?.plantCode || params.value,
    },
    {
      field: "astrPlantIdFk",
      headerName: "Plant ID",
      type: "number",
      textFieldWidth: small,
      width: GridWidthMedium,
      valueGetter: (params) =>
        params.row.plantId?.plantId || params.row.plantId,
    },
    {
      field: "astrBuildIdFk",
      headerName: "Building",
      type: "select",
      required: true,
      apiendpoint: "assetsBuilding",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "astrZoneIdFk",
      headerName: "Zone",
      type: "select",
      apiendpoint: "assetsZone",
      textFieldWidth: small,
      width: GridWidthMedium,
    },
    {
      field: "astrRemarks",
      headerName: "Remarks",
      type: "text",
      textFieldWidth: medium,
      width: GridWidthLargeWidth,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.transfer || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.transfer)
    ) {
      configColumns = updatedConfig.sub_component.transfer;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "asset",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        transfer: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (!config || !config.sub_component || !config.sub_component.transfer) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.transfer
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      // Check if there are any changes in the transformed columns
      const hasChanges = transformedColumns.some(
        (col, index) =>
          col.hide !== columns[index].hide ||
          col.headerName !== columns[index].headerName ||
          col.width !== columns[index].width
      );

      if (!hasChanges) {
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        const visibleColumns = transformedColumns.filter((col) => !col.hide);

        if (visibleColumns.length === 0) {
          console.warn(
            "All columns are hidden. Displaying all columns by default."
          );
          setColumns(columns.map((col) => ({ ...col, hide: false })));
        } else {
          setColumns(transformedColumns);
        }
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (transferColumns) {
      setColumns(transferColumns);
    } else {
      fetchColumnConfig();
    }
  }, [transferColumns, columnConfig, defaultConfig, asstId]);

  const onAdd = () => {
    setShowTransferEditForm(true);
    setShowTransferForm(true);
    setEditDefValues({});
  };
  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleAssetTransferEditClick}
        onClickDelete={handleDeleteClick}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isTransferFetching}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Transfer"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"astrDate"}
      />

      {showTransferEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showTransferForm}
          setShowForm={setShowTransferForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitAssetTransferData}
          editId={id}
          defaultValues={editDefValues}
          isLoading={updateTransferLoading || addTransferLoading}
          label={"Transfer"}
        />
      )}
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="Asset"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
