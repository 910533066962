import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";

const ColumnCustomizationPopup = ({
  open,
  onClose,
  columns,
  onApplyViewConfig,
  onRestoreDefaultConfig,
  selectedViewName,
}) => {
  const [localColumns, setLocalColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentConfig, setCurrentConfig] = useState({});

  const fieldStyle = {
    "& .MuiInputBase-root": {
      height: "1.6rem",
      fontSize: "0.7rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.7rem",
      transform: "translate(14px, 0.7rem) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -0.5rem) scale(0.75)",
    },
  };

  useEffect(() => {
    setLocalColumns(columns);
    const initialConfig = columns.reduce((acc, column) => {
      acc[column.field] = {
        headerName: column.headerName,
        width: column.width,
        hide: column.hide || false,
      };
      return acc;
    }, {});
    setCurrentConfig(initialConfig);
  }, [columns]);

  const handleColumnChange = (index, key, value) => {
    const updatedColumns = [...localColumns];
    updatedColumns[index] = { ...updatedColumns[index], [key]: value };
    setLocalColumns(updatedColumns);

    const updatedConfig = { ...currentConfig };
    if (!updatedConfig.grid) updatedConfig.grid = { columns: [] };
    let columnConfig = updatedConfig.grid.columns.find(
      (col) => col.name === updatedColumns[index].field
    );
    if (!columnConfig) {
      columnConfig = {
        name: updatedColumns[index].field,
        title: updatedColumns[index].headerName,
        visible: true,
        width: `${updatedColumns[index].width}px`,
      };
      updatedConfig.grid.columns.push(columnConfig);
    }

    if (key === "headerName") columnConfig.title = value;
    else if (key === "hide") columnConfig.visible = !value;
    else if (key === "width") columnConfig.width = `${value}px`;

    setCurrentConfig(updatedConfig);
  };

  const handleApply = () => {
    const updatedConfig = {
      grid: {
        columns: localColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };
    onApplyViewConfig(updatedConfig);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "primary.main",
          color: "white",
          variant: "h6",
        }}
      >
        {selectedViewName
          ? `Customize - ${selectedViewName}`
          : "Customize Columns"}
        <Button
          onClick={onClose}
          sx={{
            color: "#1976d2",
            backgroundColor: "white",
            padding: "4px 16px",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <PhonelinkEraseIcon />
        </Button>
      </DialogTitle>
      <DialogContent sx={{ overflowX: "hidden", marginTop: "1.5vh" }}>
        {localColumns.map((column, index) => (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            key={column.field}
            spacing={0.1}
            margin={0.2}
          >
            <Grid item xs={5}>
              <Switch
                checked={!column.hide}
                onChange={(e) =>
                  handleColumnChange(index, "hide", !e.target.checked)
                }
              />
              <Typography sx={{ fontSize: "0.7rem" }} display="inline">
                {column.headerName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <TextField
                sx={fieldStyle}
                label="Header Name"
                value={column.headerName}
                onChange={(e) =>
                  handleColumnChange(index, "headerName", e.target.value)
                }
                style={{ marginRight: 16 }}
                disabled={column.hide}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={fieldStyle}
                label="Width"
                type="number"
                value={column.width}
                onChange={(e) =>
                  handleColumnChange(
                    index,
                    "width",
                    parseInt(e.target.value, 10)
                  )
                }
                style={{ width: 80 }}
                disabled={column.hide}
              />
            </Grid>
          </Grid>
        ))}
      </DialogContent>
      <DialogActions style={{ height: "4vh" }}>
        <Button
          style={{ fontSize: "0.8rem", height: "4vh", textTransform: "none" }}
          onClick={handleApply}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnCustomizationPopup;
