import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  Checkbox,
} from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import { GeneralPrefix } from "../../constants/global";
import { useCommonData } from "../../context/CommonDataProvider";
import CloseIcon from "@mui/icons-material/Close";
import PhonelinkEraseIcon from "@mui/icons-material/PhonelinkErase";

const SaveViewPopup = ({
  open,
  onClose,
  columns,
  onSave,
  defaultColumnConfig,
  columnConfig,
  onApplyViewConfig,
  onRestoreDefaultConfig,
  category,
  userId,
  selectedView,
}) => {
  const [localColumns, setLocalColumns] = useState([]);
  const [globalViews, setGlobalViews] = useState([]);
  const [userViews, setUserViews] = useState([]);
  const [saveScope, setSaveScope] = useState("global");
  const commonData = useCommonData();
  const [viewName, setViewName] = useState(selectedView.name || "");
  const [newViewName, setNewViewName] = useState("");
  const [viewId, setViewId] = useState(selectedView.id || null);
  const [saveAsNew, setSaveAsNew] = useState(false);

  const {
    data: globalViewConfigurations,
    refetch: refetchGlobalViewConfigurations,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/viewconfigurations`,
    options: {
      params: { category, scope: "global" },
    },
    retrieveOnMount: true,
    Menu_id: 1,
  });

  const {
    data: userViewConfigurations,
    refetch: refetchUserViewConfigurations,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/viewconfigurations`,
    options: {
      params: { category, userId, scope: "user" },
    },
    retrieveOnMount: true,
    Menu_id: 1,
  });

  const { mutateAsync: saveViewConfiguration } = useMutationApi({
    endpoint: `${GeneralPrefix}/viewconfigurations/save-view-config`,
    method: "post",
    Menu_id: 1,
  });

  const { mutateAsync: updateViewConfiguration } = useMutationApi({
    endpoint: `${GeneralPrefix}/viewconfigurations/update-view-config`,
    method: "put",
    Menu_id: 1,
  });

  const fieldStyle = {
    "& .MuiInputBase-root": {
      height: "2rem",
      fontSize: "0.8rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.7rem",
      transform: "translate(14px, 0.7rem) scale(1)",
    },
    "& .MuiInputLabel-shrink": {
      transform: "translate(14px, -0.5rem) scale(0.75)",
    },
  };

  useEffect(() => {
    if (globalViewConfigurations) {
      setGlobalViews(globalViewConfigurations);
    }
  }, [globalViewConfigurations]);

  useEffect(() => {
    if (userViewConfigurations) {
      setUserViews(userViewConfigurations);
    }
  }, [userViewConfigurations]);

  useEffect(() => {
    setLocalColumns(columns);
    if (category) {
      refetchGlobalViewConfigurations();
      if (userId) {
        refetchUserViewConfigurations();
      }
    }
  }, [columns, category, userId]);

  useEffect(() => {
    if (selectedView) {
      setViewName(selectedView.name || "");
      setViewId((prevId) => selectedView.id || prevId);
    }
  }, [selectedView]);

  const updateLocalColumns = (newColumns) => {
    setLocalColumns(newColumns);
  };

  const handleSave = async () => {
    if (viewId === "default" && !saveAsNew) {
      toast.error("Cannot modify the default view. Please save as a new view.");
      return;
    }
    const isUpdate = !saveAsNew && viewId;

    const columnConfigJson = JSON.stringify({
      grid: {
        columns: localColumns.map((column) => ({
          name: column.field,
          title: column.headerName,
          visible: !column.hide,
          width: `${column.width}px`,
        })),
      },
      sub_component: {
        component: columnConfig?.sub_component?.component || [],
        warranty: columnConfig?.sub_component?.warranty || [],
        depreciation: columnConfig?.sub_component?.depreciation || [],
        transfer: columnConfig?.sub_component?.transfer || [],
        iot: columnConfig?.sub_component?.iot || [],
        adoc_upload: columnConfig?.sub_component?.adoc_upload || [],
        photo_upload: columnConfig?.sub_component?.photo_upload || [],
        awo: columnConfig?.sub_component?.awo || [],
        asst_sc: columnConfig?.sub_component?.asst_sc || [],
        asst_req: columnConfig?.sub_component?.asst_req || [],
        checklist: columnConfig?.sub_component?.checklist || [],
        woa_asset: columnConfig?.sub_component?.woa_asset || [],
        wos_service_tasks: columnConfig?.sub_component?.wos_service_tasks || [],
        wom_message: columnConfig?.sub_component?.wom_message || [],
        wom_material: columnConfig?.sub_component?.wom_material || [],
        wot_conditional_testing:
          columnConfig?.sub_component?.wot_conditional_testing || [],
        woi_incident: columnConfig?.sub_component?.woi_incident || [],
        wor_resource_allocation:
          columnConfig?.sub_component?.wor_resource_allocation || [],
        services: columnConfig?.sub_component?.services || [],
        ins_sub_class: columnConfig?.sub_component?.ins_sub_class || [],
        fail_mode: columnConfig?.sub_component?.fail_mode || [],
        incr_remedy: columnConfig?.sub_component?.incr_remedy || [],
        woc_checklist: columnConfig?.sub_component?.woc_checklist || [],
        doc_upload: columnConfig?.sub_component?.doc_upload || [],
        sch_sc: columnConfig?.sub_component?.sch_sc || [],
      },
    });
    try {
      const payload = {
        columnConfigJson,
        defaultColumnConfigJson: JSON.stringify(defaultColumnConfig),
        viewName: saveAsNew ? newViewName : viewName,
        category,
        userId,
        scope: saveScope,
      };

      if (isUpdate) {
        await updateViewConfiguration({
          viewId,
          ...payload,
        });
        toast.success("View configuration updated successfully");
      } else {
        const result = await saveViewConfiguration(payload);
        if (saveAsNew) {
          setViewId(result.viewId);
        }
        toast.success("View configuration saved successfully");
      }
      onSave(localColumns);
      onClose();
      refetchGlobalViewConfigurations();
      refetchUserViewConfigurations();
    } catch (error) {
      console.error("Error saving view configuration:", error);
      toast.error("Failed to save view configuration");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <ToastContainer />
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "primary.main",
          color: "white",
          variant: "h6",
        }}
      >
        Save View
        <Button
          onClick={onClose}
          sx={{
            color: "#1976d2",
            backgroundColor: "white",
            padding: "4px 16px",
            "&:hover": {
              backgroundColor: "white",
            },
          }}
        >
          <PhonelinkEraseIcon />
        </Button>
      </DialogTitle>
      <DialogContent>
        <TextField
          sx={fieldStyle}
          label="View Name"
          value={viewName}
          onChange={(e) => setViewName(e.target.value)}
          fullWidth
          disabled={!saveAsNew && viewId !== null}
          margin="normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={saveAsNew}
              onChange={(e) => {
                setSaveAsNew(e.target.checked);
                if (e.target.checked) {
                  setNewViewName("");
                }
              }}
            />
          }
          label="Save as new view"
        />
        {saveAsNew && (
          <TextField
            sx={fieldStyle}
            label="New View Name"
            value={newViewName}
            onChange={(e) => setNewViewName(e.target.value)}
            fullWidth
            margin="normal"
          />
        )}
        <FormControl component="fieldset" margin="normal">
          <RadioGroup
            row
            aria-label="save-scope"
            name="save-scope"
            value={saveScope}
            onChange={(e) => setSaveScope(e.target.value)}
          >
            <FormControlLabel
              value="global"
              control={<Radio />}
              label="Global"
              disabled={!saveAsNew}
            />
            <FormControlLabel
              value="user"
              control={<Radio />}
              label="User-specific"
              disabled={!saveAsNew}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ height: "4vh" }}>
        <Button
          style={{ fontSize: "0.8rem", height: "4vh", textTransform: "none" }}
          onClick={handleSave}
          color="primary"
          disabled={saveAsNew ? !newViewName : !viewName}
        >
          {saveAsNew ? "Save as New" : viewId ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveViewPopup;
