import React, { useState, useEffect, useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import useFetchApi from "../../hooks/useFetchApi";
import useMutationApi from "../../hooks/useMutationApi";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  GeneralPrefix,
} from "../../constants/global";
import { AddEditForm } from "../../common/AddEditForm";
import DeleteDialog from "../../common/DeleteDialog";
import {
  CustomToolbar,
  DataGridComponent,
} from "../../common/DataGridComponent";
import AddEditToolBar from "../../common/AddEditToolBar";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import ViewDropdown from "../home/ViewDropdown";
import useColumnConfigStore from "../home/columnConfigStore";
import ColumnCustomizationPopup from "../home/ColumnCustomizationPopup";
import RestoreColumnsDialog from "../../common/RestoreColumnsDialog";

export const MaterialType = () => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const canWrite = rightsArray[0]?.urAdmin || rightsArray[0]?.urWrite;
  const commonData = useCommonData();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const userId = commonData.userId;

  // Material Type state
  const [materialTypeId, setMaterialTypeId] = useState(0);
  const [materialTypeEditDefValues, setMaterialTypeEditDefValues] = useState();
  const [showMaterialTypeForm, setShowMaterialTypeForm] = useState(false);
  const [materialTypeRows, setMaterialTypeRows] = useState([]);
  const [showMaterialTypeEditForm, setShowMaterialTypeEditForm] =
    useState(false);
  const [openMaterialTypeDialog, setOpenMaterialTypeDialog] = useState(false);
  const [materialTypeEditData, setMaterialTypeEditData] = useState();

  // Material Group state
  const [materialGroupId, setMaterialGroupId] = useState(0);
  const [materialGroupEditDefValues, setMaterialGroupEditDefValues] =
    useState();
  const [showMaterialGroupForm, setShowMaterialGroupForm] = useState(false);
  const [materialGroupRows, setMaterialGroupRows] = useState([]);
  const [showMaterialGroupEditForm, setShowMaterialGroupEditForm] =
    useState(false);
  const [openMaterialGroupDialog, setOpenMaterialGroupDialog] = useState(false);
  const [materialGroupEditData, setMaterialGroupEditData] = useState();
  const [materialGroupViewLoaded, setMaterialGroupViewLoaded] = useState(false);
  const [selectedMaterialGroupView, setSelectedMaterialGroupView] = useState({
    name: "",
    id: null,
  });
  const [openMaterialGroupColumnPopup, setOpenMaterialGroupColumnPopup] =
    useState(false);
  const [openMaterialGroupRestoreDialog, setOpenMaterialGroupRestoreDialog] =
    useState(false);

  // Shared state
  const [viewLoaded, setViewLoaded] = useState(true);
  const [selectedView, setSelectedView] = useState({ name: "", id: null });
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false);

  const {
    columnConfig,
    setColumnConfig,
    updateColumnConfig,
    transformConfig,
    handleRestoreAllColumn,
  } = useColumnConfigStore();

  const [sectionWidths, setSectionWidths] = useState({
    left: 50,
    right: 50,
  });

  // API calls
  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "material_type" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Add Material Group configuration fetch
  const {
    data: materialGroupDefaultConfig,
    refetch: refetchMaterialGroupConfig,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "material_group" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Material Type API calls
  const {
    mutateAsync: addMaterialType,
    isSuccess: isMaterialTypeSuccess,
    isLoading: isPostMaterialTypeLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-type/AddMaterialType`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateMaterialType,
    isSuccess: isUpdateMaterialTypeSuccess,
    isLoading: isUpdateMaterialTypeLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-type/${materialTypeId}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getMaterialTypeData,
    isFetchedAfterMount: isFetchMaterialType,
    isFetching: isMaterialTypeFetching,
    refetch: refetchMaterialType,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/material-type?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && plantId ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: deleteMaterialType,
    isSuccess: isDeleteMaterialTypeSuccess,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-type/${materialTypeId}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Material Group API calls with fixed delete endpoint
  const {
    mutateAsync: addMaterialGroup,
    isSuccess: isMaterialGroupSuccess,
    isLoading: isPostMaterialGroupLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-group/AddMaterialGroup`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: updateMaterialGroup,
    isSuccess: isUpdateMaterialGroupSuccess,
    isLoading: isUpdateMaterialGroupLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-group/${materialGroupId}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Updated Material Group Delete Mutation
  const {
    mutateAsync: deleteMaterialGroup,
    isSuccess: isDeleteMaterialGroupSuccess,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/material-group/${materialGroupId}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
    showError: false,
  });

  const {
    data: getMaterialGroupData,
    isFetchedAfterMount: isFetchMaterialGroup,
    isFetching: isMaterialGroupFetching,
    refetch: refetchMaterialGroup,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/material-group?plantId=${plantId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk && plantId ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  // Material Type functions
  const handleMaterialTypeRowClick = (params) => {
    setMaterialTypeId(params.row.id);
    setMaterialTypeEditData(params.row);
  };

  const handleMaterialTypeEditClick = () => {
    setShowMaterialTypeEditForm(true);
    setMaterialTypeId(materialTypeEditData.id);
    setMaterialTypeEditDefValues(materialTypeEditData);
  };

  const saveMaterialTypeUpdateData = async (materialTypeDataToSave) => {
    updateMaterialType({
      mtrltId: materialTypeDataToSave?.mtrltId,
      mtrltDescription: materialTypeDataToSave?.mtrltDescription,
      mtrltPlantIdFk: materialTypeDataToSave?.mtrltPlantIdFk,
      mtrltModifiedDate: materialTypeDataToSave?.mtrltModifiedDate,
      mtrltModifiedBy: materialTypeDataToSave?.mtrltModifiedBy,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material Type updated successfully");
          setShowMaterialTypeEditForm(false);
          setMaterialTypeId();
          commonData.refetchMaterialType();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitMaterialTypeData = async (newMaterialTypeData) => {
    addMaterialType({ mtrltPlantIdFk: plantId, ...newMaterialTypeData })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Material Type Added successfully");
          setShowMaterialTypeEditForm(false);
          setShowMaterialTypeForm(false);
          commonData.refetchMaterialType();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleMaterialTypeDeleteClick = async () => {
    deleteMaterialType()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material Type Deleted successfully");
          setOpenMaterialTypeDialog(false);
          setMaterialTypeId();
          commonData.refetchMaterialType();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  // Material Group functions
  const handleMaterialGroupRowClick = (params) => {
    setMaterialGroupId(params.row.id);
    setMaterialGroupEditData(params.row);
  };

  const handleMaterialGroupEditClick = () => {
    setShowMaterialGroupEditForm(true);
    setMaterialGroupId(materialGroupEditData.id);
    setMaterialGroupEditDefValues(materialGroupEditData);
  };

  const saveMaterialGroupUpdateData = async (materialGroupDataToSave) => {
    updateMaterialGroup({
      mtrlgId: materialGroupDataToSave?.mtrlgId,
      mtrlgDescription: materialGroupDataToSave?.mtrlgDescription,
      mtrlgPlantIdFk: materialGroupDataToSave?.mtrlgPlantIdFk,
      mtrlgModifiedDate: materialGroupDataToSave?.mtrlgModifiedDate,
      mtrlgModifiedBy: materialGroupDataToSave?.mtrlgModifiedBy,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material Group updated successfully");
          setShowMaterialGroupEditForm(false);
          setMaterialGroupId();
          commonData.refetchMaterialGroup();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitMaterialGroupData = async (newMaterialGroupData) => {
    addMaterialGroup({ mtrlgPlantIdFk: plantId, ...newMaterialGroupData })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Material Group Added successfully");
          setShowMaterialGroupEditForm(false);
          setShowMaterialGroupForm(false);
          commonData.refetchMaterialGroup();
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  // Material Group functions with updated delete handler
  const handleMaterialGroupDeleteClick = async () => {
    try {
      const response = await deleteMaterialGroup({
        mtrlgId: setMaterialGroupId,
      });
      if (response.status === 200) {
        toast.success("Material Group Deleted successfully");
        setOpenMaterialGroupDialog(false);
        setMaterialGroupId();
        commonData.refetchMaterialGroup();
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      if (error?.response?.status === 400) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error.message || "An error occurred while deleting");
      }
    }
  };

  // Shared functions and effects
  useEffect(() => {
    if (
      isMaterialTypeSuccess ||
      isUpdateMaterialTypeSuccess ||
      isDeleteMaterialTypeSuccess
    ) {
      refetchMaterialType();
    }
  }, [
    isMaterialTypeSuccess,
    isUpdateMaterialTypeSuccess,
    isDeleteMaterialTypeSuccess,
    refetchMaterialType,
  ]);

  useEffect(() => {
    if (isFetchMaterialGroup && canRead) {
      setMaterialGroupRows(() =>
        getMaterialGroupData?.map((w) => ({
          id: w.mtrlgId,
          ...w,
        }))
      );
    }
  }, [isFetchMaterialGroup, getMaterialGroupData, canRead]);

  useEffect(() => {
    if (isFetchMaterialType && canRead) {
      setMaterialTypeRows(() =>
        getMaterialTypeData?.map((w) => ({
          id: w.mtrltId,
          ...w,
        }))
      );
    }
  }, [isFetchMaterialType, getMaterialTypeData, canRead]);

  useEffect(() => {
    if (isFetchMaterialGroup && canRead) {
      setMaterialGroupRows(() =>
        getMaterialGroupData?.map((w) => {
          return { id: w.mtrlgId, ...w };
        })
      );
    }
  }, [isFetchMaterialGroup, getMaterialGroupData, rightsArray, canRead]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowMaterialTypeEditForm(false);
    setShowMaterialTypeForm(false);
    setShowMaterialGroupEditForm(false);
    setShowMaterialGroupForm(false);
  };

  const materialTypeColumns = [
    {
      field: "mtrltDescription",
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
      required: true,
    },
  ];

  const [materialGroupColumns, setMaterialGroupColumns] = useState([
    {
      field: "mtrlgDescription",
      headerName: "Description",
      type: "text",
      width: GridWidthMedium,
      required: true,
    },
  ]);

  const [columns, setColumns] = useState(materialTypeColumns);

  const handleMaterialGroupApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(
      materialGroupColumns,
      updatedConfig.grid.columns
    );
    setMaterialGroupColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      const currentViewName = selectedMaterialGroupView.name || "Custom View";
      const currentViewId = selectedMaterialGroupView.id || "custom";
      localStorage.setItem(
        `selectedView_material_group`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedMaterialGroupView({
        name: currentViewName,
        id: currentViewId,
      });
      toast.success("Material Group column configuration applied successfully");
    } catch (error) {
      console.error("Error applying Material Group view config:", error);
      toast.error("Failed to apply Material Group view configuration");
    }
  };

  const handleMaterialGroupRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      materialGroupDefaultConfig,
      materialGroupColumns
    );
    setMaterialGroupColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedMaterialGroupView.name,
        view_id: selectedMaterialGroupView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_material_group`,
        JSON.stringify({
          name: selectedMaterialGroupView.name,
          id: selectedMaterialGroupView.id,
          config: updatedConfig,
        })
      );

      toast.success("All Material Group columns restored");
    } catch (error) {
      console.error("Error restoring Material Group columns:", error);
      toast.error("Failed to restore Material Group columns");
    }
    setOpenMaterialGroupRestoreDialog(false);
  };

  const handleMaterialGroupApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error(
          "Invalid Material Group view configuration:",
          selectedViewConfig
        );
        return;
      }

      setSelectedMaterialGroupView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView_material_group",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (selectedViewConfig.grid?.columns) {
        const updatedColumns = transformConfig(
          selectedViewConfig,
          materialGroupColumns
        );
        setMaterialGroupColumns(updatedColumns);
      }

      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedMaterialGroupView({ name: viewName, id: viewId });
        setMaterialGroupViewLoaded(true);

        if (showToast) {
          toast.success("Material Group view applied successfully");
        }
      } catch (error) {
        console.error("Error applying Material Group view:", error);
      }
    },
    [setMaterialGroupColumns, setColumnConfig]
  );

  // Add effect for Material Group view initialization
  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_material_group`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleMaterialGroupApplySelectedView(config, name, id, false);
          setMaterialGroupViewLoaded(true);
          setSelectedMaterialGroupView({ name, id });
        } else {
          applyMaterialGroupDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored Material Group view:", error);
        applyMaterialGroupDefaultView();
      }
    } else if (materialGroupDefaultConfig && !materialGroupViewLoaded) {
      applyMaterialGroupDefaultView();
    }

    function applyMaterialGroupDefaultView() {
      handleMaterialGroupApplySelectedView(
        materialGroupDefaultConfig,
        "Default View",
        "default",
        false
      );
      setMaterialGroupViewLoaded(true);
      setSelectedMaterialGroupView({ name: "Default View", id: "default" });
    }
  }, [
    materialGroupDefaultConfig,
    materialGroupViewLoaded,
    handleMaterialGroupApplySelectedView,
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig.grid?.columns?.length) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const updateColumns = (currentColumns, configColumns) => {
      return currentColumns.map((column) => {
        const updatedColumn = configColumns?.find(
          (col) => col.name === column.field
        );
        return updatedColumn
          ? {
              ...column,
              headerName: updatedColumn.title,
              hide: !updatedColumn.visible,
              width: parseInt(updatedColumn.width, 10),
            }
          : column;
      });
    };

    const updatedColumns = updateColumns(columns, updatedConfig.grid.columns);
    setColumns(updatedColumns);

    const configToUpdate = {
      ...updatedConfig,
    };

    try {
      await updateColumnConfig(configToUpdate);

      // Update localStorage with the new configuration
      const currentViewName = selectedView.name || "Custom View";
      const currentViewId = selectedView.id || "custom";
      localStorage.setItem(
        `selectedView_material_type`,
        JSON.stringify({
          name: currentViewName,
          id: currentViewId,
          config: configToUpdate,
        })
      );

      setSelectedView({ name: currentViewName, id: currentViewId });

      toast.success("Column configuration applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const handleRestoreAllColumns = async () => {
    const { restoredColumns, restoredConfig } = handleRestoreAllColumn(
      defaultConfig,
      materialTypeColumns // Use materialTypeColumns instead of allcolumns
    );
    setColumns(restoredColumns);

    try {
      const updatedConfig = {
        ...restoredConfig,
        view_name: selectedView.name,
        view_id: selectedView.id,
      };
      updateColumnConfig(updatedConfig);
      setColumnConfig(updatedConfig);

      localStorage.setItem(
        `selectedView_material_type`,
        JSON.stringify({
          name: selectedView.name,
          id: selectedView.id,
          config: updatedConfig,
        })
      );

      toast.success("All columns restored for the current view");
    } catch (error) {
      console.error("Error restoring columns for the current view:", error);
      toast.error("Failed to restore columns for the current view");
    }
    setOpenRestoreDialog(false);
  };

  const handleApplySelectedView = useCallback(
    async (selectedViewConfig, viewName, viewId, showToast = true) => {
      if (!selectedViewConfig || !selectedViewConfig.grid) {
        console.error("Invalid view configuration:", selectedViewConfig);
        return;
      }

      setSelectedView({ name: viewName, id: viewId });

      localStorage.setItem(
        "selectedView",
        JSON.stringify({
          config: selectedViewConfig,
          name: viewName,
          id: viewId,
        })
      );

      if (
        selectedViewConfig.grid &&
        Array.isArray(selectedViewConfig.grid.columns)
      ) {
        const updatedColumns = transformConfig(selectedViewConfig, columns);
        setColumns(updatedColumns);
      }
      try {
        const configToUpdate = {
          ...selectedViewConfig,
          view_name: viewName,
          view_id: viewId,
        };
        setColumnConfig(configToUpdate);
        setSelectedView({ name: viewName, id: viewId });
        setViewLoaded(true);

        if (showToast) {
          toast.success("View applied successfully");
        }
      } catch (error) {
        console.error("Error applying view:", error);
      }
    },
    [setColumns, setColumnConfig, setSelectedView]
  );

  useEffect(() => {
    if (columnConfig) {
      applyConfiguration(columnConfig);
    }
  }, [columnConfig]);

  useEffect(() => {
    const storedView = localStorage.getItem(`selectedView_material_type`);
    if (storedView) {
      try {
        const { name, id, config } = JSON.parse(storedView);
        if (config) {
          handleApplySelectedView(config, name, id, false);
          setViewLoaded(true);
          setSelectedView({ name, id });
        } else {
          console.warn("Stored view configuration not found:", id);
          applyDefaultView();
        }
      } catch (error) {
        console.error("Failed to parse stored view:", error);
        applyDefaultView();
      }
    } else if (defaultConfig && !viewLoaded) {
      applyDefaultView();
    }

    function applyDefaultView() {
      handleApplySelectedView(defaultConfig, "Default View", "default", false);
      setViewLoaded(true);
      setSelectedView({ name: "Default View", id: "default" });
    }
  }, [defaultConfig, viewLoaded, handleApplySelectedView]);

  const applyConfiguration = (config) => {
    if (config.grid && config.grid.columns) {
      const transformedColumns = transformConfig(config, columns);
      setColumns(transformedColumns);
    }
  };

  const onAddMaterialType = () => {
    setShowMaterialTypeEditForm(true);
    setShowMaterialTypeForm(true);
    setMaterialTypeEditDefValues({});
  };

  const onAddMaterialGroup = () => {
    setShowMaterialGroupEditForm(true);
    setShowMaterialGroupForm(true);
    setMaterialGroupEditDefValues({});
  };

  const [sectionHeights, setSectionHeights] = useState({
    top: 100.1,
    middle: 0.0,
    bottom: 0.0,
  });

  const handleMouseDown = (e) => {
    e.preventDefault();
    const startX = e.clientX;
    const startWidths = { ...sectionWidths };

    const handleMouseMove = (e) => {
      const deltaX = ((e.clientX - startX) / window.innerWidth) * 100;
      setSectionWidths({
        left: Math.max(10, Math.min(90, startWidths.left + deltaX)),
        right: Math.max(10, Math.min(90, startWidths.right - deltaX)),
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  return (
    <>
      <ToastContainer />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          overflow: "hidden",
        }}
      >
        {/* Single Row Layout */}
        <Box sx={{ display: "flex", height: "100%" }}>
          {/* Material Type Section */}
          <Box
            sx={{
              width: `${sectionWidths.left}%`,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              p: 2,
            }}
          >
            <Typography variant="h6" sx={{ color: "#0D47A1", mb: 2 }}>
              Material Type
            </Typography>
            <DataGridComponent
              columns={viewLoaded ? columns.filter((col) => !col.hide) : []}
              sx={{ width: "65hw" }}
              rows={viewLoaded ? materialTypeRows : []}
              sortingField={"mtrltDescription"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAddMaterialType()}
                        onClickEdit={() => handleMaterialTypeEditClick()}
                        onClickDelete={() => setOpenMaterialTypeDialog(true)}
                        onClickColumnCustom={() => setOpenColumnPopup(true)}
                        onClickRestore={() => setOpenRestoreDialog(true)}
                        editId={materialTypeId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={columns}
                        setColumns={setColumns}
                        defaultColumnConfig={defaultConfig}
                        category="material_type"
                        userId={userId}
                        onApplySelectedView={handleApplySelectedView}
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleMaterialTypeRowClick}
            />
          </Box>

          {/* Vertical Resizer */}
          <Box
            sx={{
              width: "5px",
              cursor: "col-resize",
              bgcolor: "grey.300",
            }}
            onMouseDown={handleMouseDown}
          />

          {/* Material Group Section */}
          <Box
            sx={{
              width: `${sectionWidths.right}%`,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
              p: 2,
            }}
          >
            <Typography variant="h6" sx={{ color: "#0D47A1", mb: 2 }}>
              Material Group
            </Typography>
            <DataGridComponent
              sx={{ width: "65hw" }}
              columns={
                materialGroupViewLoaded
                  ? materialGroupColumns.filter((col) => !col.hide)
                  : []
              }
              rows={materialGroupRows}
              sortingField={"mtrlgDescription"}
              components={{
                Toolbar: (props) => (
                  <>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <CustomToolbar {...props} />
                      <AddEditToolBar
                        onClickAdd={() => onAddMaterialGroup()}
                        onClickEdit={() => handleMaterialGroupEditClick()}
                        onClickDelete={() => setOpenMaterialGroupDialog(true)}
                        onClickColumnCustom={() =>
                          setOpenMaterialGroupColumnPopup(true)
                        }
                        onClickRestore={() =>
                          setOpenMaterialGroupRestoreDialog(true)
                        }
                        editId={materialGroupId}
                        urWrite={rightsArray[0]?.urWrite}
                        urModify={rightsArray[0]?.urModify}
                        urDelete={rightsArray[0]?.urDelete}
                        urAdmin={rightsArray[0]?.urAdmin}
                      />
                      <ViewDropdown
                        columns={materialGroupColumns}
                        setColumns={setMaterialGroupColumns}
                        defaultColumnConfig={materialGroupDefaultConfig}
                        category="material_group"
                        userId={userId}
                        onApplySelectedView={
                          handleMaterialGroupApplySelectedView
                        }
                        selectedView={selectedMaterialGroupView}
                        setSelectedView={setSelectedMaterialGroupView}
                        columnConfig={columnConfig}
                      />
                    </div>
                  </>
                ),
              }}
              onRowClick={handleMaterialGroupRowClick}
            />
          </Box>
        </Box>
      </Box>

      {/* Add Material Group column customization and restore dialogs */}
      <>
        <RestoreColumnsDialog
          open={openMaterialGroupRestoreDialog}
          setOpen={setOpenMaterialGroupRestoreDialog}
          title="Restore All Material Group Columns"
          handleApprove={handleMaterialGroupRestoreAllColumns}
          isLoading={false}
        />

        <ColumnCustomizationPopup
          open={openMaterialGroupColumnPopup}
          onClose={() => setOpenMaterialGroupColumnPopup(false)}
          columns={materialGroupColumns}
          defaultColumnConfig={materialGroupDefaultConfig}
          defaultConfig={materialGroupDefaultConfig}
          onApplyViewConfig={handleMaterialGroupApplyViewConfig}
          category="material_group"
          onRestoreDefaultConfig={handleMaterialGroupRestoreAllColumns}
          selectedViewName={selectedMaterialGroupView.name}
        />
      </>

      {/* Material Type Dialogs and Forms */}
      {showMaterialTypeEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showMaterialTypeForm}
          setShowForm={setShowMaterialTypeForm}
          columns={columns}
          onUpData={saveMaterialTypeUpdateData}
          onAdd={submitMaterialTypeData}
          editId={materialTypeId}
          isLoading={isUpdateMaterialTypeLoading || isPostMaterialTypeLoading}
          defaultValues={materialTypeEditDefValues}
          label="Material Type"
        />
      )}

      <DeleteDialog
        open={openMaterialTypeDialog}
        setOpen={setOpenMaterialTypeDialog}
        title={"Are you sure you want to delete Material Type?"}
        handleApprove={handleMaterialTypeDeleteClick}
      />

      {showMaterialGroupEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showMaterialGroupForm}
          setShowForm={setShowMaterialGroupForm}
          columns={materialGroupColumns}
          onUpData={saveMaterialGroupUpdateData}
          onAdd={submitMaterialGroupData}
          editId={materialGroupId}
          isLoading={isUpdateMaterialGroupLoading || isPostMaterialGroupLoading}
          defaultValues={materialGroupEditDefValues}
          label="Material Group"
        />
      )}

      <DeleteDialog
        open={openMaterialGroupDialog}
        setOpen={setOpenMaterialGroupDialog}
        title={"Are you sure you want to delete Material Group?"}
        handleApprove={handleMaterialGroupDeleteClick}
      />

      <RestoreColumnsDialog
        open={openRestoreDialog}
        setOpen={setOpenRestoreDialog}
        title="Restore All Columns"
        handleApprove={handleRestoreAllColumns}
        isLoading={false}
      />

      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={() => setOpenColumnPopup(false)}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="material_type"
        onRestoreDefaultConfig={handleRestoreAllColumns}
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default MaterialType;
