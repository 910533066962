import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  GeneralPrefix,
} from "../../../constants/global";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  getFormattedDate,
  getFormattedTime,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../../utils/common";
import { useCommonData } from "../../../context/CommonDataProvider";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import dayjs from "dayjs";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const Incident = ({ workOrderId, incidentColumns, selectedView }) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [editDefValues, setEditDefValues] = useState();
  const [showIncidentForm, setShowIncidentForm] = useState(false);
  const [rows, setRows] = useState([]);
  const [showIncidentEditForm, setShowIncidentEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const {
    mutateAsync: addWorkOrderIncident,
    isSuccess: isWorkOrderIncidentSuccess,
    isLoading: addIncidentLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/incident/addincident`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderIncident,
    isSuccess: isUpdateSuccess,
    isLoading: updateIncidentLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/incident?incidentId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderIncident,
    isSuccess: isDeleteSuccess,
    isLoading: deleteAssetLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/incident?incidentId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getWorkOrderIncidentData,
    isFetchedAfterMount: isFetch,
    isFetching: isIncidentFetching,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/incident?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderIncidentSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderIncidentSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getWoIncidentData, setGetWoIncidentData] = useState(
    getWorkOrderIncidentData
  );

  useEffect(() => {
    if (getWorkOrderIncidentData) {
      const incidentData = getWorkOrderIncidentData.map((item) => {
        const newIncident = mapDropdownValueToLabelWithId(
          commonData.getIncidentOptions,
          item.woiIncItemIdFk
        );
        return {
          ...item,
          woiIncItemIdFk: newIncident,
        };
      });
      setGetWoIncidentData(incidentData);
    }
  }, [getWorkOrderIncidentData, commonData]);

  useEffect(() => {
    if (isFetch && getWoIncidentData && canRead) {
      setRows(() =>
        getWoIncidentData?.map((w) => {
          return { id: w.woiId, ...w };
        })
      );
    }
  }, [isFetch, getWoIncidentData, rightsArray, canRead]);

  const handleWorkOrderIncidentEditClick = () => {
    setShowIncidentEditForm(true);
    setId(editData.id);
    const newIncident = mapDropdownValueToIdWithLabel(
      commonData.getIncidentOptions,
      editData.woiIncItemIdFk
    );
    setEditDefValues({
      ...editData,
      woiIncItemIdFk: newIncident,
      woiDate: editData.woiDate
        ? dayjs(editData.woiDate).format("YYYY-MM-DD")
        : null,
    });
  };
  const saveUpdateData = async (WorkOrderIncidentDataToSave) => {
    const updatedData = {
      id: WorkOrderIncidentDataToSave.woiId,
      ...WorkOrderIncidentDataToSave,
      woiIncItemIdFk: WorkOrderIncidentDataToSave?.woiIncItemIdFk?.id,
    };

    // Handle date
    if (WorkOrderIncidentDataToSave.woiDate) {
      updatedData.woiDate = dayjs(WorkOrderIncidentDataToSave.woiDate).format(
        "YYYY-MM-DD"
      );
    }

    // Handle time only if it's changed
    if (
      WorkOrderIncidentDataToSave.woiTime &&
      WorkOrderIncidentDataToSave.woiTime !== editData.woiTime
    ) {
      updatedData.woiTime = dayjs(WorkOrderIncidentDataToSave.woiTime).format(
        "HH:mm:ss"
      );
    } else {
      // If time hasn't changed, use the original time
      updatedData.woiTime = editData.woiTime;
    }

    console.log("Updating with data:", updatedData); // Add this line for debugging

    updateWorkOrderIncident(updatedData)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident updated successfully");
          setShowIncidentEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        console.error("Update error:", error); // Add this line for debugging
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const submitIncidentData = async (newWorkOrderIncidentData) => {
    const dataToSubmit = {
      workOrderId,
      ...newWorkOrderIncidentData,
      woiIncItemIdFk: newWorkOrderIncidentData?.woiIncItemIdFk?.id,
    };

    if (newWorkOrderIncidentData.woiDate) {
      dataToSubmit.woiDate = dayjs(newWorkOrderIncidentData.woiDate).format(
        "YYYY-MM-DD"
      );
    }

    if (newWorkOrderIncidentData.woiTime) {
      dataToSubmit.woiTime = dayjs(newWorkOrderIncidentData.woiTime).format(
        "HH:mm:ss"
      );
    }

    addWorkOrderIncident(dataToSubmit)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident added successfully");
          setShowIncidentEditForm(!showIncidentEditForm);
          setShowIncidentForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderIncident()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Incident deleted successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowIncidentEditForm(!showIncidentEditForm);
    setShowIncidentForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "woiIncItemIdFk",
      headerName: "Incident",
      required: true,
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getIncidentOptions",
      width: GridWidthMedium,
    },
    {
      field: "woiDate",
      headerName: "Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "woiTime",
      headerName: "Time",
      type: "time",
      textFieldWidth: medium,
      width: GridWidthMedium,
      valueFormatter: (params) => getFormattedTime(params.value),
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.woi_incident || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.woi_incident)
    ) {
      configColumns = updatedConfig.sub_component.woi_incident;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        woi_incident: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.woi_incident
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.woi_incident
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => !col.hide);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (incidentColumns) {
      setColumns(incidentColumns);
    } else {
      fetchColumnConfig();
    }
  }, [incidentColumns, columnConfig, defaultConfig, workOrderId]);

  const onAdd = () => {
    setShowIncidentEditForm(true);
    setShowIncidentForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleWorkOrderIncidentEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isIncidentFetching}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Incident"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"woiIncItemIdFk"}
      />

      {showIncidentEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showIncidentForm}
          setShowForm={setShowIncidentForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitIncidentData}
          editId={id}
          defaultValues={editDefValues}
          label={"Incident"}
          isLoading={updateIncidentLoading || addIncidentLoading}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Incident?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteAssetLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
