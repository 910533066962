import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import { useCommonData } from "../../../context/CommonDataProvider";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  GeneralPrefix,
} from "../../../constants/global";
import DeleteDialog from "../../../common/DeleteDialog";
import {
  getFormattedDate,
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
  mapDropdownValueToLabelWithListId,
  mapDropdownValueToListIdWithLabel,
} from "../../../utils/common";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import dayjs from "dayjs";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const WorkResource = ({
  workOrderId,
  selectedView,
  resourceColumns,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const { employeDetails, resourceStatus, getUomDataList } = useCommonData();
  const [id, setId] = useState(0);
  const [showResourceForm, setShowResourceForm] = useState(false);
  const [editDefValues, setEditDefValues] = useState();
  const [rows, setRows] = useState([]);
  const [showResourceEditForm, setShowResourceEditForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();
  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };
  const handleResourceTaskEditClick = () => {
    setShowResourceEditForm(true);
    setId(editData.id);
    const resourceStatusData = mapDropdownValueToListIdWithLabel(
      resourceStatus,
      editData.worStatus
    );
    const employeData = mapDropdownValueToIdWithLabel(
      employeDetails,
      editData.worEmpId
    );
    const UoMData = mapDropdownValueToIdWithLabel(
      getUomDataList,
      editData.worUomIdFk
    );
    setEditDefValues({
      ...editData,
      worStatus: resourceStatusData,
      worEmpId: employeData,
      worUomIdFk: UoMData,
    });
  };

  const {
    mutateAsync: addWorkOrderResource,
    isSuccess: isWorkOrderResourceSuccess,
    isLoading: isResourceLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/resource-allocation/addresourceallocation`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderResource,
    isSuccess: isUpdateSuccess,
    isLoading: updateResourceLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/resource-allocation?worId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderResource,
    isSuccess: isDeleteSuccess,
    isLoading: deleteResourceLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/resource-allocation?worId=${id}`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    isSuccess: isResourceFetchSuccess,
    isFetchedAfterMount: isFetch,
    isLoading: isResourceFetching,
    refetch,
    data: workOrderResource,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/resource-allocation?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderResourceSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderResourceSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getResourceData, setGetResourceData] = useState(workOrderResource);
  useEffect(() => {
    if (workOrderResource) {
      const resourceData = workOrderResource.map((item) => {
        const resourceStatusData = mapDropdownValueToLabelWithListId(
          resourceStatus,
          item.worStatus
        );
        const employeData = mapDropdownValueToLabelWithId(
          employeDetails,
          item.worEmpId
        );
        const UoMData = mapDropdownValueToLabelWithId(
          getUomDataList,
          item.worUomIdFk
        );
        return {
          ...item,
          worStatus: resourceStatusData,
          worEmpId: employeData,
          worUomIdFk: UoMData,
        };
      });
      setGetResourceData(resourceData);
    }
  }, [workOrderResource, resourceStatus, employeDetails, getUomDataList]);

  useEffect(() => {
    if (isFetch && getResourceData && canRead) {
      setRows(() => {
        return (
          isResourceFetchSuccess &&
          getResourceData?.map((w) => {
            return { id: w.worId, ...w };
          })
        );
      });
    }
  }, [canRead, getResourceData, isFetch, isResourceFetchSuccess, rightsArray]);

  const saveUpdateData = async (WorkOrderResourceDataToSave) => {
    if (
      dayjs(WorkOrderResourceDataToSave?.worStartDate).isAfter(
        WorkOrderResourceDataToSave?.worEndDate
      )
    ) {
      toast.error("End date should be greater than or equal to start date");
    } else {
      updateWorkOrderResource({
        id: WorkOrderResourceDataToSave.worId,
        ...WorkOrderResourceDataToSave,
        worEmpId: WorkOrderResourceDataToSave?.worEmpId?.id,
        worUomIdFk: WorkOrderResourceDataToSave?.worUomIdFk?.id,
        worStatus: WorkOrderResourceDataToSave?.worStatus?.listId,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Resource updated  successfully");
            setShowResourceEditForm(false);
            setId();
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const submitResourceData = async (newWorkOrderResourceData) => {
    if (
      dayjs(newWorkOrderResourceData?.worStartDate).isAfter(
        newWorkOrderResourceData?.worEndDate
      )
    ) {
      toast.error("End date should be greater than or equal to start date");
    } else {
      addWorkOrderResource({
        worWoIdFk: workOrderId,
        ...newWorkOrderResourceData,
        worEmpId: newWorkOrderResourceData?.worEmpId?.id,
        worUomIdFk: newWorkOrderResourceData?.worUomIdFk?.id,
        worStatus: newWorkOrderResourceData?.worStatus?.listId,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success("Resource added successfully");
            setShowResourceEditForm(!showResourceEditForm);
            setShowResourceForm(false);
          } else {
            toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message);
          } else {
            toast.error(error);
          }
        });
    }
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderResource()
      .then((response) => {
        if (response.status === 200) {
          toast.success("Material delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowResourceEditForm(!showResourceEditForm);
    setShowResourceForm(false);
  };
  const [columns, setColumns] = useState([
    {
      field: "worEmpId",
      headerName: "Resource",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "employeDetails",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "worUomIdFk",
      headerName: "UoM",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getUomDataList",
      width: GridWidthMedium,
    },
    {
      field: "worStartDate",
      headerName: "Start Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
    },
    {
      field: "worEndDate",
      headerName: "End Date",
      type: "Date",
      textFieldWidth: medium,
      valueFormatter: (params) => getFormattedDate(params.value),
      width: GridWidthMedium,
      minDateLabel: "worStartDate",
    },
    {
      field: "worStatus",
      headerName: "Status",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "resourceStatus",
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns =
      updatedConfig.sub_component?.wor_resource_allocation || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.wor_resource_allocation)
    ) {
      configColumns = updatedConfig.sub_component.wor_resource_allocation;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        wor_resource_allocation: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (
        !config ||
        !config.sub_component ||
        !config.sub_component.wor_resource_allocation
      ) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.wor_resource_allocation
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      // Check if there are any changes in the transformed columns
      const hasChanges = transformedColumns.some(
        (col, index) =>
          col.hide !== columns[index].hide ||
          col.headerName !== columns[index].headerName ||
          col.width !== columns[index].width
      );

      if (!hasChanges) {
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        const visibleColumns = transformedColumns.filter((col) => !col.hide);

        if (visibleColumns.length === 0) {
          console.warn(
            "All columns are hidden. Displaying all columns by default."
          );
          setColumns(columns.map((col) => ({ ...col, hide: false })));
        } else {
          setColumns(transformedColumns);
        }
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (resourceColumns) {
      setColumns(resourceColumns);
    } else {
      fetchColumnConfig();
    }
  }, [resourceColumns, columnConfig, defaultConfig, workOrderId]);

  const onAdd = () => {
    setShowResourceEditForm(true);
    setShowResourceForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleResourceTaskEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isResourceFetching}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Resource Allocation"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"worEmpId"}
      />

      {showResourceEditForm && (
        <AddEditForm
          onClose={handleClose}
          showForm={showResourceForm}
          setShowForm={setShowResourceForm}
          columns={columns}
          onUpData={saveUpdateData}
          onAdd={submitResourceData}
          editId={id}
          defaultValues={editDefValues}
          isLoading={updateResourceLoading || isResourceLoading}
          label={"Resource Allocation"}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Resource?"}
        handleApprove={handleDeleteClick}
        isLoading={deleteResourceLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
