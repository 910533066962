import React, { useEffect, useState, useCallback } from "react";
import { Box } from "@mui/material";
import { Viewer } from "@grapecity/activereports-react";
import { Progressor } from "../../common/Progressor";
import { WorkOrderPrefix } from "../../constants/global";
import useFetchApi from "../../hooks/useFetchApi";
import { useCommonData } from "../../context/CommonDataProvider";
import { useMenuContext } from "../../context/MenuContextProvider";
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-viewer.css";

const WorkorderList = () => {
  const { rightsArray, selectedMenuId } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const plantId = commonData?.userData?.lastLoginPlantID;
  const [rows, setRows] = useState([]);
  const [reportInstance, setReportInstance] = useState(null);

  const {
    data: workOrderData,
    isFetchedAfterMount: isFetch,
    isFetching: isWorkOrderLoading,
    refetch,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}?plantId=${plantId}`,
    retrieveOnMount: plantId && rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isFetch && workOrderData && canRead) {
      setRows(() =>
        workOrderData?.map((w) => {
          return { id: w.woId, ...w };
        })
      );
    }
  }, [isFetch, workOrderData, canRead]);

  const viewerRef = useCallback((instance) => {
    if (instance) {
      setReportInstance(instance);
    }
  }, []);

  async function loadReport() {
    try {
      const module = await import("./reports/workorder-detail.rdlx-json");
      const response = await fetch(module.default);
      const text = await response.text();
      return JSON.parse(text);
    } catch (error) {
      console.error("Error loading report:", error);
      throw error;
    }
  }

  async function loadData() {
    return {
      WorkOrders: rows.map((row) => {
        const transformedRow = {};
        for (const [key, value] of Object.entries(row)) {
          transformedRow[key] = value !== null ? String(value) : "";
        }
        return transformedRow;
      }),
    };
  }

  async function openReports() {
    try {
      const data = await loadData();
      console.log("Data being sent to report:", data);

      const report = await loadReport();
      if (!report || !report.DataSources || report.DataSources.length === 0) {
        console.error("Invalid report structure:", report);
        return;
      }

      report.DataSources[0].ConnectionProperties.ConnectString = `jsondata=${JSON.stringify(
        data
      )}`;
      report.DataSources[0].ConnectionProperties.DataProvider = "JSON";

      const userId = commonData?.userId || "";
      if (reportInstance && reportInstance.Viewer) {
        reportInstance.Viewer.open(report, {
          ReportParams: [
            {
              Name: "User",
              Value: userId,
            },
            {
              Name: "Plant",
              Value: plantId,
            },
          ],
        });
      } else {
        console.error("Report instance or Viewer is not available");
      }
    } catch (error) {
      console.error("Error opening report:", error);
    }
  }

  useEffect(() => {
    if (reportInstance && rows.length > 0) {
      openReports();
    }
  }, [reportInstance, rows]);

  useEffect(() => {
    return () => {
      if (reportInstance && reportInstance.Viewer) {
        reportInstance.Viewer.destroy();
      }
    };
  }, [selectedMenuId]);

  if (isWorkOrderLoading) {
    return <Progressor />;
  }

  return (
    <Box sx={{ height: "90vh", width: "100%" }}>
      <Viewer ref={viewerRef} theme="ActiveReports" />
    </Box>
  );
};

export default WorkorderList;
