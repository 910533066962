import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddEditForm } from "../../../common/AddEditForm";
import {
  GridWidthMedium,
  WorkOrderPrefix,
  medium,
  GeneralPrefix,
  defaultNumberFormatePrice,
  defaultNumberFormateQty,
} from "../../../constants/global";
import numeral from "numeral";
import DeleteDialog from "../../../common/DeleteDialog";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  mapDropdownValueToIdWithLabel,
  mapDropdownValueToLabelWithId,
} from "../../../utils/common";
import { useCommonData } from "../../../context/CommonDataProvider";
import { CommonBox } from "../assetManagementSteps/CommonBox";
import { useMenuContext } from "../../../context/MenuContextProvider";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";
import useColumnConfigStore from "../columnConfigStore";

export const WorkOrderServices = ({
  workOrderId,
  serviceColumns,
  selectedView,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const commonData = useCommonData();
  const [id, setId] = useState(0);
  const [rows, setRows] = useState([]);
  const [editDefValues, setEditDefValues] = useState();
  const [showServiceEditForm, setShowServiceEditForm] = useState(false);
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState();
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const { data: defaultConfig, refetch: refetchDefaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "workorder" },
    },
    retrieveOnMount: true,
    Menu_id: 23,
  });

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const handleServiceEditClick = () => {
    setShowServiceEditForm(true);
    setId(editData.id);
    const newStructure = mapDropdownValueToIdWithLabel(
      commonData.getCbsDataList,
      editData.wosCbsIdFk
    );
    const newUoM = mapDropdownValueToIdWithLabel(
      commonData.getUomDataList,
      editData.wosUomIdFk
    );
    const newService = mapDropdownValueToIdWithLabel(
      commonData.getServiceDataList,
      editData.wosSerIdFk
    );
    setEditDefValues({
      ...editData,
      wosCbsIdFk: newStructure,
      wosUomIdFk: newUoM,
      wosSerIdFk: newService,
    });
  };

  const {
    mutateAsync: addWorkOrderService,
    isSuccess: isWorkOrderServiceSuccess,
    isLoading: isServiceLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/service/addworkorderservice`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: updateWorkOrderService,
    isSuccess: isUpdateSuccess,
    isLoading: isUpdateLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/service?wosId=${id}`,
    method: "put",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });
  const {
    mutateAsync: deleteWorkOrderService,
    isSuccess: isDeleteSuccess,
    isLoading: isDeleteLoading,
  } = useMutationApi({
    endpoint: `${WorkOrderPrefix}/service?wosId=`,
    method: "delete",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    isSuccess: isServiceFetchSuccess,
    isFetchedAfterMount: isFetch,
    isLoading: isServiceFetching,
    refetch,
    data: workOrderService,
  } = useFetchApi({
    endpoint: `${WorkOrderPrefix}/service?workOrderId=${workOrderId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  useEffect(() => {
    if (isWorkOrderServiceSuccess || isUpdateSuccess || isDeleteSuccess) {
      refetch();
    }
  }, [isWorkOrderServiceSuccess, isUpdateSuccess, isDeleteSuccess, refetch]);

  const [getWoServiceData, setGetWoServiceData] = useState(workOrderService);

  useEffect(() => {
    if (workOrderService) {
      const serviceData = workOrderService.map((item) => {
        const newStructure = mapDropdownValueToLabelWithId(
          commonData.getCbsDataList,
          item.wosCbsIdFk
        );
        const newUoM = mapDropdownValueToLabelWithId(
          commonData.getUomDataList,
          item.wosUomIdFk
        );
        const newService = mapDropdownValueToLabelWithId(
          commonData.getServiceDataList,
          item.wosSerIdFk
        );
        return {
          ...item,
          wosCbsIdFk: newStructure,
          wosUomIdFk: newUoM,
          wosSerIdFk: newService,
        };
      });
      setGetWoServiceData(serviceData);
    }
  }, [workOrderService, commonData]);

  useEffect(() => {
    if (isFetch && getWoServiceData && canRead) {
      setRows(() => {
        return (
          isServiceFetchSuccess &&
          getWoServiceData?.map((w) => {
            return { id: w.wosId, ...w };
          })
        );
      });
    }
  }, [canRead, getWoServiceData, isFetch, isServiceFetchSuccess, rightsArray]);

  const saveUpdateData = async (WorkOrderServiceDataToSave) => {
    updateWorkOrderService({
      id: WorkOrderServiceDataToSave.wosId,
      ...WorkOrderServiceDataToSave,
      wosSerIdFk: WorkOrderServiceDataToSave?.wosSerIdFk?.id,
      wosCbsIdFk: WorkOrderServiceDataToSave?.wosCbsIdFk?.id,
      wosUomIdFk: WorkOrderServiceDataToSave?.wosUomIdFk?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Service updated successfully");
          setShowServiceEditForm(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const submitServiceData = async (newWorkOrderServiceData) => {
    addWorkOrderService({
      wosWoIdFk: workOrderId,
      ...newWorkOrderServiceData,
      wosSerIdFk: newWorkOrderServiceData?.wosSerIdFk?.id,
      wosCbsIdFk: newWorkOrderServiceData?.wosCbsIdFk?.id,
      wosUomIdFk: newWorkOrderServiceData?.wosUomIdFk?.id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Service added successfully");
          setShowServiceEditForm(!showServiceEditForm);
          setShowServiceForm(false);
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };

  const handleDeleteClick = async () => {
    deleteWorkOrderService({
      dynamicEndpointSuffix: id,
    })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Service delete successfully");
          setOpenDialog(false);
          setId();
        } else {
          toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        if (error?.response?.status === 400) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error);
        }
      });
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setShowServiceEditForm(!showServiceEditForm);
    setShowServiceForm(false);
  };

  const [columns, setColumns] = useState([
    {
      field: "wosSerIdFk",
      headerName: "Service",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getServiceDataList",
      required: true,
      width: GridWidthMedium,
    },
    {
      field: "wosUomIdFk",
      headerName: "UoM",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getUomDataList",
      width: GridWidthMedium,
    },
    {
      field: "wosQty",
      headerName: "Qty",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormateQty);
      },
    },
    {
      field: "wosCost",
      headerName: "Cost",
      textFieldWidth: medium,
      type: "number",
      width: GridWidthMedium,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return numeral(params.value).format(defaultNumberFormatePrice);
      },
    },
    {
      field: "wosCbsIdFk",
      headerName: "Structure",
      type: "select",
      textFieldWidth: medium,
      apiendpoint: "getCbsDataList",
      width: GridWidthMedium,
    },
  ]);

  const handleApplyViewConfig = async (updatedConfig) => {
    let configColumns = updatedConfig.sub_component?.services || [];

    if (
      updatedConfig.sub_component &&
      Array.isArray(updatedConfig.sub_component.services)
    ) {
      configColumns = updatedConfig.sub_component.services;
    } else if (Array.isArray(updatedConfig)) {
      configColumns = updatedConfig;
    } else {
      configColumns = Object.entries(updatedConfig).map(([field, details]) => ({
        name: field,
        title: details.headerName,
        visible: !details.hide,
        width: `${details.width}px`,
      }));
    }
    if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
      console.error("Invalid config structure:", updatedConfig);
      return;
    }

    const newColumns = columns.map((column) => {
      const updatedColumn = updatedConfig.grid.columns.find(
        (col) => col.name === column.field
      );
      if (updatedColumn) {
        return {
          ...column,
          headerName: updatedColumn.title,
          hide: !updatedColumn.visible,
          width: parseInt(updatedColumn.width, 10),
        };
      }
      return column;
    });

    setColumns(newColumns);

    const configToUpdate = {
      ...columnConfig,
      category: "workorder",
      view_name: selectedView.name,
      view_id: selectedView.id,
      sub_component: {
        ...columnConfig.sub_component,
        services: newColumns.map((col) => ({
          name: col.field,
          title: col.headerName,
          visible: !col.hide,
          width: `${col.width}px`,
        })),
      },
    };

    try {
      await updateColumnConfig(configToUpdate);
      toast.success("column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;
      if (!config || !config.sub_component || !config.sub_component.services) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.services
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);

      const visibleColumns = transformedColumns.filter((col) => !col.hide);

      if (visibleColumns.length === 0) {
        console.warn(
          "All columns are hidden. Displaying all columns by default."
        );
        setColumns(columns.map((col) => ({ ...col, hide: false })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, hide: false })));
    }
  };

  useEffect(() => {
    if (serviceColumns) {
      setColumns(serviceColumns);
    } else {
      fetchColumnConfig();
    }
  }, [serviceColumns, columnConfig, defaultConfig, workOrderId]);

  const onAdd = () => {
    setShowServiceEditForm(true);
    setShowServiceForm(true);
    setEditDefValues({});
  };

  return (
    <>
      <CommonBox
        onClickAdd={onAdd}
        onClickEdit={handleServiceEditClick}
        onClickDelete={handleClickOpen}
        onRowClick={handleRowClick}
        editId={id}
        isLoading={isServiceFetching}
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns.filter((col) => !col.hide && col.width > 0)}
        rows={rows}
        label={"Service"}
        urWrite={rightsArray[0]?.urWrite}
        urModify={rightsArray[0]?.urModify}
        urDelete={rightsArray[0]?.urDelete}
        urAdmin={rightsArray[0]?.urAdmin}
        sortingField={"wosSerIdFk"}
      />
      {showServiceEditForm && (
        <AddEditForm
          onClose={handleClose}
          rows={rows}
          columns={columns}
          showForm={showServiceForm}
          setShowForm={setShowServiceForm}
          onUpData={saveUpdateData}
          onAdd={submitServiceData}
          editId={id}
          defaultValues={editDefValues}
          setRows={setRows}
          label={"Services"}
          isLoading={isUpdateLoading || isServiceLoading}
          isWosService={true}
        />
      )}
      <DeleteDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title={"Are you sure you want to delete Work Order Services?"}
        handleApprove={handleDeleteClick}
        isLoading={isDeleteLoading}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="workorder"
        selectedViewName={selectedView.name}
      />
    </>
  );
};
