// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .addEditButton {
  height: fit-content;
  margin-right: 5px !important;
  margin-top: 5px !important;
  padding: 7px 16px !important;
} */
.saveCancelButton {
  color: #1976d2 !important;
  background-color: white !important;
  margin-right: 4px !important;
  padding: 4px 16px;
}
.saveCancelButton :hover {
  background-color: white !important;
}
`, "",{"version":3,"sources":["webpack://./src/common/CommonButton.css"],"names":[],"mappings":"AAAA;;;;;GAKG;AACH;EACE,yBAAyB;EACzB,kCAAkC;EAClC,4BAA4B;EAC5B,iBAAiB;AACnB;AACA;EACE,kCAAkC;AACpC","sourcesContent":["/* .addEditButton {\n  height: fit-content;\n  margin-right: 5px !important;\n  margin-top: 5px !important;\n  padding: 7px 16px !important;\n} */\n.saveCancelButton {\n  color: #1976d2 !important;\n  background-color: white !important;\n  margin-right: 4px !important;\n  padding: 4px 16px;\n}\n.saveCancelButton :hover {\n  background-color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
