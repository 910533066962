import React, { useState, useEffect } from "react";
import useFetchApi from "../../../hooks/useFetchApi";
import useMutationApi from "../../../hooks/useMutationApi";
import {
  GeneralPrefix,
  GridWidthLargeWidth,
  GridWidthMedium,
  GridWidthSmall,
} from "../../../constants/global";
import { ToastContainer, toast } from "react-toastify";
import { useMenuContext } from "../../../context/MenuContextProvider";
import DeleteDialog from "../../../common/DeleteDialog";
import AddEditToolBar from "../../../common/AddEditToolBar";
import { Box } from "@mui/material";
import { CommonBox } from "./CommonBox";
import useColumnConfigStore from "../columnConfigStore";
import ColumnCustomizationPopup from "../ColumnCustomizationPopup";

export const AssetSchedules = ({
  assetId,
  selectedView,
  scColumns,
  setScColumns,
}) => {
  const { rightsArray } = useMenuContext();
  const canRead = rightsArray[0]?.urAdmin || rightsArray[0]?.urRead;
  const [rows, setRows] = useState([]);
  const [id, setId] = useState(0);
  const [openConvertDialog, setOpenConvertDialog] = useState(false);
  const [convertType, setConvertType] = useState("");
  const [editData, setEditData] = useState(null);
  const [openColumnPopup, setOpenColumnPopup] = useState(false);
  const { columnConfig, setColumnConfig, updateColumnConfig, transformConfig } =
    useColumnConfigStore();

  const [columns, setColumns] = useState([
    {
      field: "itemCount",
      headerName: "Id",
      type: "number",
      width: GridWidthSmall,
    },
    {
      field: "caldDesc",
      headerName: "Description",
      type: "text",
      width: GridWidthLargeWidth,
    },
    {
      field: "caldDate",
      headerName: "Date",
      type: "date",
      width: GridWidthMedium,
    },
    {
      field: "caldCtype",
      headerName: "Type",
      type: "text",
      width: GridWidthMedium,
    },
  ]);

  const { data: defaultConfig } = useFetchApi({
    endpoint: `${GeneralPrefix}/config/default`,
    options: {
      params: { category: "asset" },
    },
    retrieveOnMount: true,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    data: getAssetSchedulesData,
    isFetchedAfterMount: isFetch,
    isFetching: isSchedulesFetching,
  } = useFetchApi({
    endpoint: `${GeneralPrefix}/calender-day-today?caldAsstIdFk=${assetId}`,
    retrieveOnMount: rightsArray[0]?.urMenuIdFk ? true : false,
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const {
    mutateAsync: convertToWorkOrder,
    isLoading: isConvertingToWorkOrder,
  } = useMutationApi({
    endpoint: `${GeneralPrefix}/calender-day-today/${id}/convert-to-workorder`,
    method: "post",
    Menu_id: rightsArray[0]?.urMenuIdFk,
  });

  const { mutateAsync: convertToRequest, isLoading: isConvertingToRequest } =
    useMutationApi({
      endpoint: `${GeneralPrefix}/calender-day-today/${id}/convert-to-request`,
      method: "post",
      Menu_id: rightsArray[0]?.urMenuIdFk,
    });

  const handleApplyViewConfig = async (updatedConfig) => {
    if (!updatedConfig) {
      console.error("Updated configuration is null or undefined");
      toast.error("Invalid configuration received");
      return;
    }

    try {
      let configColumns = [];

      if (updatedConfig.sub_component?.asst_sc) {
        configColumns = updatedConfig.sub_component.asst_sc;
      } else if (Array.isArray(updatedConfig.sub_component)) {
        configColumns = updatedConfig.sub_component;
      } else if (Array.isArray(updatedConfig)) {
        configColumns = updatedConfig;
      } else if (typeof updatedConfig === "object") {
        configColumns = Object.entries(updatedConfig).map(
          ([field, details]) => ({
            name: field,
            title: details?.headerName,
            visible: details?.visible,
            width: details?.width ? `${details.width}px` : undefined,
          })
        );
      }

      if (!updatedConfig.grid || !Array.isArray(updatedConfig.grid.columns)) {
        console.error("Invalid config structure:", updatedConfig);
        toast.error("Invalid grid configuration");
        return;
      }

      const newColumns = columns.map((column) => {
        const updatedColumn = updatedConfig.grid.columns.find(
          (col) => col.name === column.field
        );
        if (updatedColumn) {
          return {
            ...column,
            title: updatedColumn.title,
            visible: updatedColumn.visible,
            width: parseInt(updatedColumn.width, 10) || column.width,
          };
        }
        return column;
      });

      setColumns(newColumns);

      const configToUpdate = {
        ...columnConfig,
        category: "asset",
        view_name: selectedView.name,
        view_id: selectedView.id,
        sub_component: {
          ...columnConfig.sub_component,
          asst_sc: newColumns.map((col) => ({
            name: col.field,
            title: col.headerName,
            visible: col.visible,
            width: `${col.width}px`,
          })),
        },
      };

      await updateColumnConfig(configToUpdate);
      toast.success("Column customization applied successfully");
    } catch (error) {
      console.error("Error applying view config:", error);
      toast.error("Failed to apply view configuration");
    }
  };

  const fetchColumnConfig = async () => {
    try {
      let config = columnConfig;

      if (!config || !config.sub_component || !config.sub_component.asst_sc) {
        if (
          defaultConfig &&
          defaultConfig.sub_component &&
          defaultConfig.sub_component.asst_sc
        ) {
          config = defaultConfig;
          await updateColumnConfig(config);
          config = columnConfig;
        } else {
          setColumns(columns.map((col) => ({ ...col, hide: false })));
          return;
        }
      }

      const transformedColumns = transformConfig(config, columns);
      const visibleColumns = transformedColumns.filter((col) => col.visible);

      if (visibleColumns.length === 0) {
        setColumns(columns.map((col) => ({ ...col, visible: true })));
      } else {
        setColumns(transformedColumns);
      }
    } catch (error) {
      console.error("Error fetching column config:", error);
      setColumns(columns.map((col) => ({ ...col, visible: true })));
    }
  };

  useEffect(() => {
    if (scColumns) {
      setColumns(scColumns);
    } else {
      fetchColumnConfig();
    }
  }, [scColumns, columnConfig, defaultConfig, assetId]);

  useEffect(() => {
    if (isFetch && getAssetSchedulesData && canRead) {
      setRows(() =>
        getAssetSchedulesData.map((schedule, index) => ({
          id: schedule.caldId || `temp-id-${index}`,
          itemCount: index + 1,
          caldDesc: schedule.caldDesc || "N/A",
          caldDate: schedule.caldDate || "N/A",
          caldTime: schedule.caldTime
            ? new Date(schedule.caldTime).toLocaleString()
            : "N/A",
          caldCtype: schedule.caldCtype || "N/A",
          isConverted: false,
        }))
      );
    }
  }, [isFetch, getAssetSchedulesData, rightsArray, canRead]);

  const handleRowClick = (params) => {
    setId(params.row.id);
    setEditData(params.row);
  };

  const handleOpenColumnPopup = () => {
    setOpenColumnPopup(true);
  };

  const handleCloseColumnPopup = () => {
    setOpenColumnPopup(false);
  };

  const handleConvertToWorkOrder = () => {
    if (!id) {
      toast.error("Please select a schedule to convert");
      return;
    }

    const selectedSchedule = rows.find((row) => row.id === id);
    if (!selectedSchedule) {
      toast.error("Selected schedule not found");
      return;
    }

    if (selectedSchedule.isConverted) {
      toast.warning("This schedule has already been converted");
      return;
    }

    setConvertType("workorder");
    setOpenConvertDialog(true);
  };

  const handleConvertToRequest = () => {
    if (!id) {
      toast.error("Please select a schedule to convert");
      return;
    }

    const selectedSchedule = rows.find((row) => row.id === id);
    if (!selectedSchedule) {
      toast.error("Selected schedule not found");
      return;
    }

    if (selectedSchedule.isConverted) {
      toast.warning("This schedule has already been converted");
      return;
    }

    setConvertType("request");
    setOpenConvertDialog(true);
  };

  const handleConfirmConvert = async () => {
    try {
      let response;
      if (convertType === "workorder") {
        response = await convertToWorkOrder();
      } else if (convertType === "request") {
        response = await convertToRequest();
      }

      if (response.status === 201) {
        toast.success(
          `Schedule ID ${id} converted to ${
            convertType === "workorder" ? "Work Order" : "Request"
          } successfully`
        );

        const updatedRows = rows.map((row) =>
          row.id === id ? { ...row, isConverted: true } : row
        );
        setRows(updatedRows);
      } else {
        toast.error(
          `Failed to convert Schedule ID ${id} to ${
            convertType === "workorder" ? "Work Order" : "Request"
          }`
        );
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 400) {
          const errorMessage =
            error.response.data.message || error.response.data;
          if (
            typeof errorMessage === "string" &&
            errorMessage.includes("already been converted")
          ) {
            toast.error(`Schedule ID ${id} has already been converted`);
          } else {
            toast.error(
              `Schedule ID ${id}: ${errorMessage || "Bad request error"}`
            );
          }
        } else if (error.response.status === 500) {
          const errorMessage =
            error.response.data.message || error.response.data;
          toast.error(
            `Schedule ID ${id}: ${
              errorMessage ||
              "Server error occurred while converting the schedule"
            }`
          );
        } else {
          toast.error(`An error occurred while converting Schedule ID ${id}`);
        }
      } else {
        toast.error(`An error occurred while converting Schedule ID ${id}`);
      }
      console.error(`Error converting schedule to ${convertType}:`, error);
    } finally {
      setOpenConvertDialog(false);
    }
  };

  const customToolbarContent = (
    <AddEditToolBar
      onClickConvertToWorkOrder={handleConvertToWorkOrder}
      onClickConvertToRequest={handleConvertToRequest}
      onClickColumnCustom={handleOpenColumnPopup}
      editId={id}
      urWrite={rightsArray[0]?.urWrite}
      urModify={rightsArray[0]?.urModify}
      urDelete={rightsArray[0]?.urDelete}
      urAdmin={rightsArray[0]?.urAdmin}
      disableConvertToWorkOrder={
        !id ||
        isConvertingToWorkOrder ||
        isConvertingToRequest ||
        (editData && editData.isConverted)
      }
      disableConvertToRequest={
        !id ||
        isConvertingToWorkOrder ||
        isConvertingToRequest ||
        (editData && editData.isConverted)
      }
    />
  );

  return (
    <>
      <ToastContainer />

      <CommonBox
        onClickColumnCustom={handleOpenColumnPopup}
        columns={columns}
        rows={rows}
        isLoading={isSchedulesFetching}
        label="Asset Schedules"
        sortingField="caldDate"
        getRowId={(row) => row.id}
        onRowClick={handleRowClick}
        customToolbarContent={customToolbarContent}
      />

      <DeleteDialog
        open={openConvertDialog}
        setOpen={setOpenConvertDialog}
        title={`Are you sure you want to convert this schedule to a ${
          convertType === "workorder" ? "work order" : "request"
        }?`}
        handleApprove={handleConfirmConvert}
      />
      <ColumnCustomizationPopup
        open={openColumnPopup}
        onClose={handleCloseColumnPopup}
        columns={columns}
        defaultColumnConfig={defaultConfig}
        defaultConfig={defaultConfig}
        onApplyViewConfig={handleApplyViewConfig}
        category="asset"
        selectedViewName={selectedView.name}
      />
    </>
  );
};

export default AssetSchedules;
