import React, { useState } from "react";
import {
  Box,
  StepLabel,
  StepContent,
  IconButton,
  Typography,
} from "@mui/material";
import Step from "@mui/material/Step";
import Stepper from "@mui/material/Stepper";
import CloseIcon from "@mui/icons-material/Close";
import Options from "./Options";

export const GlobalMultiSteps = ({ onClose, open, setOpen, selectedGroup }) => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStep = (index) => () => {
    setActiveStep(index);
  };

  // Ensure we have a selected group
  if (!selectedGroup) {
    return (
      <Box sx={{ p: 3 }}>
        <Typography>Please select a group first</Typography>
      </Box>
    );
  }

  const steps = [
    {
      label: "Options",
      form: (
        <Options
          selectedGroup={selectedGroup}
          key={selectedGroup.code} // Force remount when selected group changes
        />
      ),
    },
  ];

  return (
    <Box sx={{ position: "relative", p: 2 }}>
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>

      <Box sx={{ maxWidth: "55vw", marginLeft: "4vh", mt: 6 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel onClick={handleStep(index)}>{step.label}</StepLabel>
              <StepContent>{steps[activeStep].form}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};
